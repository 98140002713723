<template>
	<span>{{ strategyName }}</span>
    <div v-if="strategy" class="preview-wrapper">
        <div v-for="row in strategy" :key="row.toString()" class="preview-row">
			<template v-for="troop_name in row" :key="troop_name">
				<TroopImage v-if="troop_name !== 'none'" :troop-name="troop_name" :is-name-visible="false" size="large"/>
				<div v-else class="empty-square" />
			</template>
		</div>
    </div>
</template>

<script>
import TroopImage from "@/components/generic/TroopImage";
export default {
    name: 'StrategyPreview',
	components: { TroopImage },
	props: {
		strategyName: {
			type: String,
		},
        strategy: {
			type: Object,
		},
    },
};
</script>

<style scoped>
.preview-wrapper {
    display: flex;
    flex-direction: column;
    padding: 4px;
    gap: 4px;
    background: black;
	width: fit-content;
}

.preview-row {
    display: flex;
    gap: 4px;
}

.empty-square {
	width: 3.5rem;
	height: 3.5rem;
	background: darkgray;
}
</style>

<template>
    <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ recruitmentBuildingInfo?.name }}</h5>
                    <div class="building-description">
                        {{ recruitmentBuildingInfo?.description }}
                    </div>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <img src="../../assets/close_icon.svg" width="20" height="20" aria-hidden="true" />
                    </button>
                </div>
                <div class="modal-body">
                    <ul class="nav nav-tabs" id="recruitment-nav" role="tablist">
                        <li
                            v-for="(troop, troop_index) in troops"
                            :class="troop.getName()"
                            :key="troop.getName()"
                            class="nav-item"
                            role="presentation"
                        >
                            <button
                                :id="`tab_id_${snakeCase(troop.getName())}`"
                                class="nav-link"
                                :class="{ active: troop_index === 0 }"
                                data-bs-toggle="tab"
                                :data-bs-target="`#content_id_${snakeCase(troop.getName())}`"
                                type="button"
                                role="tab"
                                :aria-controls="`aria_id_${snakeCase(troop.getName())}`"
                            >
                                <div class="tab-wrapper">
                                    <div class="current-troops">{{ troop.getTroopCount().toLocaleString('ro') }}</div>
                                    <div class="tab-img-wrapper">
                                        <img
                                            :src="require(`../../assets/icons/troops/${paramCase(troop.getName())}-tall.avif`)"
                                            :alt="troop.getName()"
                                            :class="{ unavailable: !areBuildingRequirementsMet(troop) }"
                                        />
                                    </div>
                                    <div class="max-troops">+ {{ getMaxTroopsThatCanBeRecruited(troop) }}</div>
                                </div>
                            </button>
                        </li>
                        <li class="nav-filler" />
                    </ul>
                    <div class="tab-content" id="recruitment-pages">
                        <div
                            v-for="(troop, troop_index) in troops"
                            :id="`content_id_${snakeCase(troop.getName())}`"
                            :key="troop.getName()"
                            class="tab-pane fade"
                            :class="{
                                show: troop_index === 0,
                                active: troop_index === 0,
                            }"
                            role="tabpanel"
                            :aria-labelledby="`aria_id_${snakeCase(troop.getName())}`"
                        >
                            <BuildingRequirements :troop="troop" />
                            <div
                                class="d-flex flex-row justify-content-between flex-wrap"
                                :class="{
                                    'opacity-50': !areBuildingRequirementsMet(troop),
                                    'pointer-events-none': !areBuildingRequirementsMet(troop),
                                }"
                            >
                                <TroopRecruitWindow :troop="troop" />
                                <TroopCostWindow :troop="troop" />
                                <TroopStatsWindow :troop="troop" />
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-column w-100 gap-2 mt-3">
                        <span>Recruiting</span>
                        <RecruitmentCommands :recruitment-building="recruitmentBuilding" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import 'vue-slider-component/theme/antd.css';
import { paramCase, snakeCase } from 'change-case';
import BuildingRequirements from '@/components/RecruitmentBuildings/BuildingRequirements';
import TroopRecruitWindow from '@/components/RecruitmentBuildings/TroopRecruitWindow';
import TroopCostWindow from '@/components/RecruitmentBuildings/TroopCostWindow';
import TroopStatsWindow from '@/components/RecruitmentBuildings/TroopStatsWindow';
import RecruitmentCommands from '@/components/RecruitmentBuildings/RecruitmentCommands';
import areBuildingRequirementsMet from '@/components/RecruitmentBuildings/helpers/areBuildingRequirementsMet';
import getBuildingRequirementAppearance from '@/components/RecruitmentBuildings/helpers/getBuildingRequirementAppearance';
import getMaxTroopsThatCanBeRecruited from '@/components/RecruitmentBuildings/helpers/getMaxTroopsThatCanBeRecruited';
import getMinTroopsThatCanBeRecruited from '@/components/RecruitmentBuildings/helpers/getMinTroopsThatCanBeRecruited';

export default {
    name: 'RecruitmentModal',
    setup() {},
    props: {
        recruitmentBuilding: {
            type: String,
        },
    },
    components: {
        TroopStatsWindow,
        TroopCostWindow,
        BuildingRequirements,
        TroopRecruitWindow,
        RecruitmentCommands,
    },
    computed: {
        recruitmentBuildingInfo() {
            return this.$store.getters.getBuildings?.find(building => building.name === this.recruitmentBuilding);
        },
        troops() {
            return this.$store.getters.getCurrentCityFormattedTroops?.filter(
                troop => troop.getRecruitmentLocation() === this.recruitmentBuilding,
            );
        },
    },
    methods: {
        snakeCase,
        paramCase,
        areBuildingRequirementsMet,
        getBuildingRequirementAppearance,
        getMaxTroopsThatCanBeRecruited,
        getMinTroopsThatCanBeRecruited,
    },
};
</script>

<style scoped>
.modal {
    background: rgba(0, 0, 0, 0.5);
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.modal::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.modal::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.modal-header {
    border: none;
    display: flex;
    flex-direction: column;
}

.modal-header {
    background: linear-gradient(to right, rgba(0, 0, 0, 0), black, rgba(0, 0, 0, 0));
    display: flex;
    justify-content: center;
}

.modal-title {
    width: 60%;
    font-family: 'bebas', sans-serif;
    letter-spacing: 1px;
    text-align: center;
    padding-bottom: 0.75rem;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0), lightgray, rgba(0, 0, 0, 0)) 1;
    border-left: none;
    border-right: none;
    border-top: none;
    font-size: 1.5rem;
}

.building-description {
    width: 60%;
    font-family: 'lato', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    margin-top: 0.5rem;
    margin-left: 1rem;
    padding-right: 1rem;
    max-height: 2.5rem;
    overflow-y: overlay;
    color: #b7b6b6;
    text-align: justify;
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.building-description::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.building-description::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.close {
    position: absolute;
    top: 16px;
    right: 24px;
    border: none;
    background: none;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.close:hover {
    opacity: 1;
}

.modal-content {
    font-family: 'sansation', sans-serif;
    top: 5vh;
    padding: 4rem;
    background-image: url('../../assets/background.avif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0;
    color: ghostwhite;
    border: none;
}

.modal-body {
    padding: 3rem 1rem 1rem 1rem;
}

.nav {
    align-items: baseline;
    border: none;
    transform: translateY(-2px);
}

.nav-item {
    margin-right: 0.25rem;
}

.nav-item:nth-last-child(2) {
    margin-right: 0;
}

.tab-wrapper {
    height: 6.5rem;
    width: calc(3rem + 8px);
    overflow: hidden;
    padding: 2px;
    border: 2px solid #434343;
    border-bottom: none;
    position: relative;
}

.tab-wrapper > .tab-img-wrapper {
    width: 3rem;
    transition: filter 0.3s;
    height: 4.25rem;
    overflow: hidden;
}

.tab-img-wrapper > img {
    width: 3rem;
}

.tab-img-wrapper > img.unavailable {
    filter: grayscale(1) contrast(1.25) brightness(0.5);
}

.nav-tabs .nav-link {
    padding: 0;
    height: 6rem;
    border-radius: 0;
    border: none;
}

.nav-tabs .nav-link.active {
    background: none;
    transform: translateY(-31px);
}

.nav-tabs .nav-link:not(.active) {
    transform: translateY(-7px);
}

.nav-link.active .tab-wrapper {
    height: 8rem;
    width: calc(4rem + 8px);
}

.nav-link.active .tab-wrapper > .tab-img-wrapper {
    width: 4rem;
    height: 5.625rem;
}

.nav-link.active .tab-wrapper > .tab-img-wrapper > img {
    width: 4rem;
}

.nav-link:hover:not(.active) img {
    filter: brightness(1.2) contrast(1.1);
}

.nav-link:hover:not(.active) img.unavailable {
    filter: grayscale(1) contrast(1.5) brightness(0.75);
}

.nav-filler {
    flex-grow: 1;
    border-bottom: 2px solid #434343;
    transform: translateY(80px);
}

.current-troops {
    width: 100%;
    height: 1rem;
    font-size: 0.75rem;
    color: ghostwhite;
}

.max-troops {
    position: absolute;
    bottom: 0;
    width: calc(100% - 4px);
    height: 1rem;
    font-size: 0.75rem;
    color: ghostwhite;
}

.tab-pane {
    background: rgba(0, 0, 0, 0.25);
    border: 2px solid #434343;
    border-top: none;
    padding: 3.5rem 2rem 2rem 2rem;
}

@media screen and (max-width: 1199px) {
    .modal-title {
        width: 60%;
    }
    .building-description {
        width: 60%;
    }
}

@media screen and (max-width: 991px) {
    .modal-content {
        padding: 3rem 1rem;
    }
    .modal-title {
        width: 80%;
    }
    .building-description {
        width: 80%;
    }
}

@media screen and (max-width: 460px) {
    .tab-pane {
        padding: 3.5rem 1rem 1rem 1rem;
    }
    .tab-wrapper {
        height: 5rem;
        width: calc(2rem + 8px);
    }
    .tab-wrapper > .tab-img-wrapper {
        width: 2rem;
        height: 2.75rem;
    }
    .tab-img-wrapper > img {
        width: 2rem;
    }
    .nav-tabs .nav-link {
        height: 2rem;
    }
    .nav-link.active .tab-wrapper {
        height: 6.375rem;
        width: calc(3rem + 8px);
    }
    .nav-link.active .tab-wrapper > .tab-img-wrapper {
        width: 3rem;
        height: 4.125rem;
    }
    .nav-link.active .tab-wrapper > .tab-img-wrapper > img {
        width: 3rem;
    }
    .nav-filler {
        transform: translateY(18px);
    }
    .current-troops {
        height: 1rem;
        font-size: 0.625rem;
    }
    .max-troops {
        height: 1rem;
        font-size: 0.625rem;
    }
    .nav-tabs .nav-link.active {
        transform: translateY(-69px);
    }
    .nav-tabs .nav-link:not(.active) {
        transform: translateY(-47px);
    }
    .modal-body {
        padding: 5rem 1rem 1rem 1rem;
    }
    .modal-title {
        width: 100%;
    }
    .building-description {
        width: 100%;
    }
}

@media screen and (orientation: landscape) and (max-height: 800px) {
    .modal-content {
        transform: scale(0.8);
        top: -10vh;
    }
}
</style>

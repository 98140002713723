<template>
    <div class="recruitment-window">
        <span class="section-title clickable pointer-events-all" @click="openTroopDetailsModal()">{{ troop.getName() }}</span>
        <div class="recruitment-image-wrapper">
            <img :src="require(`../../assets/icons/troops/${paramCase(troop.getName())}-tall.avif`)" alt="" />
        </div>
        <div class="recruitment-command-wrapper">
            <div class="recruitment-slider d-flex gap-3 align-items-center">
                <span class="min-recruit" @click="troop.setNumberToRecruit(getMinTroopsThatCanBeRecruited(troop))">{{
                    getMinTroopsThatCanBeRecruited(troop)
                }}</span>
                <vue-slider
                    @change="value => troop.setNumberToRecruit(value)"
                    :value="troop.getNumberToRecruit()"
                    v-bind="sliderOptions"
                    :min="getMinTroopsThatCanBeRecruited(troop)"
                    :max="getMaxTroopsThatCanBeRecruited(troop)"
                />
                <span class="max-recruit" @click="troop.setNumberToRecruit(getMaxTroopsThatCanBeRecruited(troop))">{{
                    getMaxTroopsThatCanBeRecruited(troop)
                }}</span>
            </div>
            <input
                class="text-input"
                @input="value => troop.setNumberToRecruit(value?.target?.value)"
                :value="troop.getNumberToRecruit()"
                type="number"
                :min="getMinTroopsThatCanBeRecruited(troop)"
                :max="getMaxTroopsThatCanBeRecruited(troop)"
            />
            <button
                class="btn recruit-button"
                :disabled="troop.getNumberToRecruit() === 0"
                @click="sendRecruitmentCommand(troop.getName())"
            >
                Recruit
            </button>
        </div>
    </div>
</template>

<script>
import VueSlider from 'vue-slider-component';
import Troop from '@/classes/troops/Troop';
import { paramCase } from 'change-case';
import getMaxTroopsThatCanBeRecruited from '@/components/RecruitmentBuildings/helpers/getMaxTroopsThatCanBeRecruited';
import getMinTroopsThatCanBeRecruited from '@/components/RecruitmentBuildings/helpers/getMinTroopsThatCanBeRecruited';

export default {
    name: 'TroopRecruitWindow',
    components: {
        VueSlider,
    },
    data() {
        return {
            sliderOptions: {
                dotSize: 14,
                width: 60,
                height: 4,
                contained: false,
                direction: 'ltr',
                data: null,
                dataLabel: 'label',
                dataValue: 'value',
                interval: 1,
                disabled: false,
                clickable: true,
                duration: 0,
                adsorb: true,
                lazy: false,
                tooltip: 'active',
                tooltipPlacement: 'top',
                useKeyboard: false,
                keydownHook: null,
                dragOnClick: false,
                enableCross: true,
                fixed: false,
                order: true,
                marks: false,
            },
        };
    },
    props: {
        troop: {
            type: Troop,
        },
    },
    methods: {
        paramCase,
        getMaxTroopsThatCanBeRecruited,
        getMinTroopsThatCanBeRecruited,

        sendRecruitmentCommand() {
            this.$store.dispatch('startTroopRecruitmentCommand', {
                name: this.troop.getName(),
                numOfTroops: this.troop.getNumberToRecruit(),
            });
        },
    },
};
</script>

<style scoped>
.recruitment-window {
    position: relative;
    border: 2px solid #434343;
    display: flex;
    flex-direction: row;
    padding: 2px;
    width: 32%;
    background: black;
}

.section-title {
    position: absolute;
    top: -2rem;
}

.recruitment-image-wrapper {
    position: relative;
}

.recruitment-image-wrapper > img {
    width: 6rem;
    height: 100%;
}

.recruitment-command-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    padding: 0.5rem;
    background: linear-gradient(to bottom, #2a2a2a, #181818);
    margin-left: 2px;
    width: 100%;
}

.min-recruit,
.max-recruit {
    display: block;
    width: 2rem;
    text-align: center;
    border: 2px solid darkgray;
}

.min-recruit:hover,
.max-recruit:hover {
    cursor: pointer;
    color: cornflowerblue;
}

.recruit-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.15), rgba(255, 200, 200, 0.15));
    border: 3px solid brown;
    font-size: 1rem;
    line-height: 1rem;
    color: ghostwhite;
    width: 100%;
}

.recruit-button:hover {
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.2), rgba(255, 200, 200, 0.2));
    color: ghostwhite;
}

@media screen and (max-width: 1199px) {
    .recruitment-window {
        width: 48%;
    }
}

@media screen and (max-width: 991px) {
    .recruitment-window {
        width: 100%;
    }
}

@media screen and (max-width: 380px) {
    .recruitment-window {
        height: 7rem;
    }
    .recruitment-slider {
        display: none !important;
    }
    .recruitment-image-wrapper {
        overflow-y: clip;
    }
    .recruitment-image-wrapper > img {
        height: initial;
        width: 5rem;
    }
}
</style>

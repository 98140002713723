<template>
    <div class="commands-wrapper">
        <RecruitmentCommandCard v-for="command in recruitmentCommands" :key="command.getId()" :command="command" />
        <div v-for="(slot, index) in emptySlots" :key="index" class="command-placeholder">
            <img src="../../assets/icons/recruitment_icon.svg" class="placeholder-icon" />
        </div>
    </div>
</template>

<script>
import RecruitmentCommandCard from '@/components/RecruitmentBuildings/RecruitmentCommandCard';
import { TroopRecruitmentSlots } from "@/utils/constants/troops";
import { BuildingIdentifier } from "@/utils/constants/city";
export default {
    name: 'RecruitmentCommands',
    components: { RecruitmentCommandCard },
	props: {
		recruitmentBuilding: {
			type: String,
		}
	},
    computed: {
        recruitmentCommands() {
			switch (this.recruitmentBuilding) {
				case BuildingIdentifier.BARRACKS:
					return this.$store.getters.getBarracksRecruitmentCommands;
				case BuildingIdentifier.ARMORED_FACTORY:
					return this.$store.getters.getArmoredRecruitmentCommands;
				case BuildingIdentifier.PLANE_FACTORY:
					return this.$store.getters.getPlaneRecruitmentCommands;
				case BuildingIdentifier.GENERAL_OFFICE:
					return this.$store.getters.getGeneralRecruitmentCommands;
				default:
					return [];
			}
        },
		maxCommands() {
			return TroopRecruitmentSlots[this.recruitmentBuilding];
		},
		numberOfCommands() {
			return this.recruitmentCommands?.size || 0;
		},
        emptySlots() {
            return new Array(this.maxCommands - this.numberOfCommands);
        },
    },
};
</script>

<style scoped>
.commands-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    padding-left: 2px;
}

.command-placeholder {
    background: linear-gradient(to bottom, #2a2a2a, #181818);
    width: 6rem;
    height: calc(6rem + 2px);
    display: flex;
    justify-content: center;
    align-items: center;
    outline: 2px solid #434343;
    border: 2px solid black;
}

.placeholder-icon {
    height: 1.75rem;
    filter: contrast(0);
}
</style>

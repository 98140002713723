import { ReinforcementType } from '@/utils/constants/actions';
import { CommandTypesForTroopSelection } from '@/utils/constants/troops';

// command_type determines what property from the toSend object to read from (what action the troops were selected for)
// reinforcement_control_level determines the list of reinforcements that are valid for the wanted action
export function addTroopsToPayload({ payload, troops, command_type, reinforcement_filters }) {
    let OwnedTroops = null;
    const Reinforcements = [];
    const ReinforcementIds = [];

    troops.forEach(troop => {
        if (troop.getNumberToSend(command_type) > 0) {
            if (!OwnedTroops) {
                OwnedTroops = {
                    troopsName: [troop.getName()],
                    numOfTroops: [troop.getNumberToSend(command_type)],
                    owned: true,
                };
            } else {
                OwnedTroops.troopsName.push(troop.getName());
                OwnedTroops.numOfTroops.push(troop.getNumberToSend(command_type));
            }
        }

        troop.getReinforcements(reinforcement_filters).forEach(reinforcement => {
            if (reinforcement.getNumberToSend(command_type) > 0) {
                const ReinforcementIndex = ReinforcementIds.findIndex(
                    reinforcement_id => reinforcement_id === reinforcement.getReinforcementId(),
                );
                if (ReinforcementIndex === -1) {
                    Reinforcements.push({
                        troopsName: [reinforcement.getTroopName()],
                        numOfTroops: [reinforcement.getNumberToSend(command_type)],
                        owned: false,
                    });

                    ReinforcementIds.push(reinforcement.getReinforcementId());
                } else {
                    const FoundReinforcement = Reinforcements[ReinforcementIndex];

                    FoundReinforcement.troopsName.push(reinforcement.getTroopName());
                    FoundReinforcement.numOfTroops.push(reinforcement.getNumberToSend(command_type));
                }
            }
        });
    });

    const FinalTroopsArray = OwnedTroops ? [...Reinforcements, OwnedTroops] : Reinforcements;

    payload.troops = FinalTroopsArray;

    if (ReinforcementIds.length > 0) {
        payload.reinforcements = ReinforcementIds;
    }
}

export function addTroopsToPayloadForCallingBackSpies({ payload, troops, reinforcement_filters }) {
    let OwnedTroops = null;
    const Reinforcements = [];
    const ReinforcementIds = [];

    troops.forEach(troop => {
        troop.getReinforcements(reinforcement_filters).forEach(reinforcement => {
            console.log('reinforcement ^^^', reinforcement.getNumberToSend(CommandTypesForTroopSelection.CALL_BACK_FROM_SPYING));
            if (reinforcement.getNumberToSend(CommandTypesForTroopSelection.CALL_BACK_FROM_SPYING) > 0) {
                if (reinforcement.isOwned()) {
                    OwnedTroops = {
                        troopsName: [reinforcement.getTroopName()],
                        numOfTroops: [reinforcement.getNumberToSend(CommandTypesForTroopSelection.CALL_BACK_FROM_SPYING)],
                        owned: true,
                    };
                } else {
                    const ReinforcementIndex = ReinforcementIds.findIndex(
                        reinforcement_id => reinforcement_id === reinforcement.getReinforcementId(),
                    );
                    if (ReinforcementIndex === -1) {
                        Reinforcements.push({
                            troopsName: [reinforcement.getTroopName()],
                            numOfTroops: [reinforcement.getNumberToSend(CommandTypesForTroopSelection.CALL_BACK_FROM_SPYING)],
                            owned: false,
                        });

                        ReinforcementIds.push(reinforcement.getReinforcementId());
                    } else {
                        const FoundReinforcement = Reinforcements[ReinforcementIndex];

                        FoundReinforcement.troopsName.push(reinforcement.getTroopName());
                        FoundReinforcement.numOfTroops.push(
                            reinforcement.getNumberToSend(CommandTypesForTroopSelection.CALL_BACK_FROM_SPYING),
                        );
                    }
                }
            }
        });
    });

    const FinalTroopsArray = OwnedTroops ? [...Reinforcements, OwnedTroops] : Reinforcements;

    payload.troops = FinalTroopsArray;

    if (ReinforcementIds.length > 0) {
        payload.reinforcements = ReinforcementIds;
    }
}

export function addTroopsToPayloadForSendingOrCallingBackReinforcements({
    payload,
    troops,
    command_type,
    reinforcement_filters,
}) {
    const Reinforcements = [];
    const ReinforcementIds = [];

    troops.forEach(troop => {
        troop.getReinforcements(reinforcement_filters).forEach(reinforcement => {
            if (reinforcement.getNumberToSend(command_type) > 0) {
                const ReinforcementIndex = ReinforcementIds.findIndex(
                    reinforcement_id => reinforcement_id === reinforcement.getReinforcementId(),
                );
                if (ReinforcementIndex === -1) {
                    Reinforcements.push({
                        troopsName: [reinforcement.getTroopName()],
                        numOfTroops: [reinforcement.getNumberToSend(command_type)],
                    });

                    ReinforcementIds.push(reinforcement.getReinforcementId());
                } else {
                    const FoundReinforcement = Reinforcements[ReinforcementIndex];

                    FoundReinforcement.troopsName.push(reinforcement.getTroopName());
                    FoundReinforcement.numOfTroops.push(reinforcement.getNumberToSend(command_type));
                }
            }
        });
    });

    payload.troops = Reinforcements;
    payload.reinforcements = ReinforcementIds;
}

export function addReinforcementPermissionsToPayload(payload, reinforcement_type) {
    switch (reinforcement_type) {
        case ReinforcementType.STATION: {
            payload.targetCanControl = false;
            payload.targetCanPassControl = false;
            break;
        }
        case ReinforcementType.PARTIAL_CONTROL: {
            payload.targetCanControl = true;
            payload.targetCanPassControl = false;
            break;
        }
        case ReinforcementType.FULL_CONTROL: {
            payload.targetCanControl = true;
            payload.targetCanPassControl = true;
            break;
        }
    }
}

export function getStringifiedTroops(troops) {
    let FinalString = `[`;

    troops.forEach((troop, index) => {
        FinalString += `{troopsName: ${JSON.stringify(troop.troopsName)}, numOfTroops: [${troop.numOfTroops}]${
            troop.owned !== undefined ? ', owned: ' + troop.owned : ''
        }}`;
        FinalString += index < troops.length - 1 ? ', ' : ']';
    });

    return FinalString;
}

import { ReinforcementFilterType, TroopsIdentifier } from '@/utils/constants/troops';
import MapActionsCollection from '@/classes/mapActions/MapActionsCollection';
import {
    AddedCityProperties,
    CITY_ADDITIONAL_PROPERTIES,
    CityProperties,
    CityPropertiesThatAreActions,
    ReinforcementCategory,
} from '@/utils/constants/actions';
import { addActionToMapArrows } from '@/utils/functions/mapArrowsUtils';
import Troop from '@/classes/troops/Troop';
import parseTemplateForInitialization from '@/utils/state/parsers/parseTemplateForInitialization';
import { COALITION_TEMPLATE } from '@/utils/state/templates/coalitionTemplate';
import { CITIES_PER_TILE_HORIZONTALLY, CITIES_PER_TILE_VERTICALLY } from '@/utils/constants/map';
import MapActionFactory from '@/classes/mapActions/MapActionFactory';
import {
    addAdditionalPropertiesToCity,
    addCityToMap,
    getRelevantReceivedReinforcements,
    getRelevantSentReinforcements,
    getSpiesOnMission,
    isReceivedReinforcement,
    updateArmoredRecruitmentCommands,
    updateBarracksRecruitmentCommands,
    updateGeneralRecruitmentCommands,
    updatePlaneRecruitmentCommands,
} from '@/store/modules/city/mutations/utils';
import Reinforcement from '@/classes/troops/Reinforcement';
import SpiesOnMission from '@/classes/troops/SpiesOnMission';
import BuildingCommandsCollection from '@/classes/buildingCommands/BuildingCommandsCollection';
import BuildingCommand from '@/classes/buildingCommands/BuildingCommand';
import RecruitmentCommand from '@/classes/recruitmentCommands/RecruitmentCommand';
import RecruitmentCommandsCollection from '@/classes/recruitmentCommands/RecruitmentCommandsCollection';
import Collection from '@/classes/generic/Collection';
import General from '@/classes/troops/General';

const ActionFactory = new MapActionFactory();

export default {
    SET_ADDITIONAL_PROPERTIES_OBJECT(state) {
        state.currentCity[CITY_ADDITIONAL_PROPERTIES] = {};
    },

    SET_OWNED_TROOPS_TO_SEND(state, { troop_name, command_type, number }) {
        const Troop = state.currentCity.formattedTroops.find(troop => troop.getName() === troop_name);
        Troop.setNumberToSend(command_type, number);
        console.log('SET_OWNED_TROOPS_TO_SEND', Troop.getNumberToSend(command_type));
    },

    SET_REINFORCEMENTS_TO_SEND(state, { troop_name, reinforcement_id, command_type, number }) {
        const Troop = state.currentCity.formattedTroops.find(troop => troop.getName() === troop_name);
        let Reinforcement;
        Troop.getReinforcements({
            category: ReinforcementCategory.ALL,
            filter_type: null,
            filter_value: null,
            include_not_arrived: true,
        }).forEach(reinforcement => {
            console.log(reinforcement.getId(), reinforcement_id);
            if (reinforcement.getId() === reinforcement_id) {
                Reinforcement = reinforcement;
            }
        });
        Reinforcement.setNumberToSend(command_type, number);
        console.log('SET_REINFORCEMENTS_TO_SEND', Reinforcement.getNumberToSend(command_type));
    },

    SET_ALL_REINFORCEMENTS_TO_SEND(state, { troop_name, command_type, reinforcement_filters }) {
        const Troop = state.currentCity.formattedTroops.find(troop => troop.getName() === troop_name);
        Troop.getReinforcements(reinforcement_filters).forEach(reinforcement => {
            reinforcement.setNumberToSend(command_type, reinforcement.getTroopCount());
        });
        console.log('SET_ALL_REINFORCEMENTS_TO_SEND', Troop.getReinforcements(reinforcement_filters));
    },

    setHighlightedPins(state, pins) {
        state.highlightedPins = pins;
    },

    setSelectedActionPin(state, action_id) {
        state.selectedActionPin = action_id;
    },

    setMapScaleFactor(state, value) {
        state.mapScaleFactor = value;
    },

    incrementMapScaleFactor(state, value) {
        state.mapScaleFactor += value;
    },

    setAwaitingResponse(state, value) {
        state.awaitingResponse = value;
    },

    setUserInfo(state, data) {
        Object.keys(data).forEach(key => {
            if (data[key]) {
                state.userState[key] = data[key];
            }
        });
    },

    setCurrentCity(state, data) {
        // data: { city_id, city_index }

        let CITY_INDEX;

        if (data.city_id) {
            CITY_INDEX = state.userState.cities.findIndex(city => city._id === data.city_id);
        } else {
            CITY_INDEX = data.city_index;
        }

        if (CITY_INDEX !== -1) {
            state.currentCity = state.userState.cities[CITY_INDEX];
            ActionFactory.setCurrentCityId(state.currentCity._id);
            console.log('--- state.currentCity ---', state.currentCity);
        } else {
            console.error('City not found for index:', CITY_INDEX);
        }
    },

    SET_FORMATTED_TROOPS(state) {
        const CurrentCity = state.currentCity;
        CurrentCity.formattedTroops = [];
        CurrentCity.troops.forEach(troop => {
            if (troop.name === TroopsIdentifier.GENERAL) {
                const CurrentCityGenerals = state.currentCity.generals;
                CurrentCity.formattedTroops.push(
                    new General({
                        generals: CurrentCityGenerals,
                        troop,
                        received_reinforcements: [],
                        sent_reinforcements: [],
                        spies_on_mission: [],
                    }),
                );
            } else {
                CurrentCity.formattedTroops.push(
                    new Troop({
                        troop,
                        received_reinforcements: getRelevantReceivedReinforcements({
                            current_city_id: state?.currentCity?._id,
                            troop: troop,
                            reinforcements: CurrentCity.reinforcements,
                        }),
                        sent_reinforcements: getRelevantSentReinforcements({
                            current_city_id: state?.currentCity?._id,
                            troop: troop,
                            reinforcements: CurrentCity.reinforcements,
                        }),
                        spies_on_mission: troop.name === TroopsIdentifier.SPY ? getSpiesOnMission(CurrentCity.foreignSpies) : [],
                    }),
                );
            }
        });
        console.log('!@!@!', CurrentCity.formattedTroops);
    },

    // used for Reinforcements tab in General Office
    SET_SENT_REINFORCEMENTS_BY_CITY(state) {
        const ReinforcementsByCity = [];

        state.currentCity.formattedTroops.forEach(troop => {
            troop
                .getReinforcements({
                    category: ReinforcementCategory.SENT,
                    filter_type: ReinforcementFilterType.NONE,
                    filter_value: null,
                    include_not_arrived: true,
                })
                .forEach(reinforcement => {
                    const FoundCityObject = ReinforcementsByCity.find(city => city.id === reinforcement.getDestinationCityId());
                    if (!FoundCityObject) {
                        ReinforcementsByCity.push({
                            id: reinforcement.getDestinationCityId(),
                            troops: new Set([troop]),
                        });
                    } else {
                        if (!FoundCityObject.troops.has(troop)) {
                            FoundCityObject.troops.add(troop);
                        }
                    }
                });
        });

        console.log('SentReinforcementsByCity -->', ReinforcementsByCity);
        state.currentCity.sentReinforcements = ReinforcementsByCity;
    },

    // used for Reinforcements tab in General Office
    SET_RECEIVED_REINFORCEMENTS_BY_CITY(state) {
        const ReinforcementsByCity = [];

        state.currentCity.formattedTroops.forEach(troop => {
            troop
                .getReinforcements({
                    category: ReinforcementCategory.RECEIVED,
                    filter_type: ReinforcementFilterType.NONE,
                    filter_value: null,
                    include_not_arrived: true,
                })
                .forEach(reinforcement => {
                    const FoundCityObject = ReinforcementsByCity.find(city => city.id === reinforcement.getLatestOriginCityId());
                    if (!FoundCityObject) {
                        ReinforcementsByCity.push({
                            id: reinforcement.getLatestOriginCityId(),
                            troops: new Set([troop]),
                        });
                    } else {
                        if (!FoundCityObject.troops.has(troop)) {
                            FoundCityObject.troops.add(troop);
                        }
                    }
                });
        });

        console.log('ReceivedReinforcementsByCity -->', ReinforcementsByCity);
        state.currentCity.receivedReinforcements = ReinforcementsByCity;
    },

    UPDATE_TROOP_NUMBERS(state, { command_type, reinforcement_filters }) {
        state.currentCity.formattedTroops.forEach(troop => {
            if (troop.getNumberToSend(command_type) > 0) {
                troop.decreaseTroopCount(troop.getNumberToSend(command_type));
                troop.setNumberToSend(command_type, 0);
            }

            troop.getReinforcements(reinforcement_filters).forEach(reinforcement => {
                if (reinforcement.getNumberToSend(command_type) > 0) {
                    reinforcement.decreaseTroopCount(reinforcement.getNumberToSend(command_type));
                    reinforcement.setNumberToSend(command_type, 0);
                }
                if (reinforcement.getTroopCount() === 0) {
                    reinforcement.selfDestruct();
                }
            });
        });
    },

    SET_BUILDING_COMMANDS(state) {
        const CommandsCollection = new BuildingCommandsCollection([]);

        state.currentCity.commands.forEach(command => {
            const Building = state.currentCity.buildings.find(building => building.name === command.name);
            CommandsCollection.addItem(new BuildingCommand(command, Building));
        });

        if (CommandsCollection.getItems().size) {
            CommandsCollection.startCountdowns();
        }
        state.currentCity.buildingCommands = CommandsCollection;
    },

    SET_RECRUITMENT_COMMANDS(state) {
        const AllCommandsCollection = new Collection([]);

        // BARRACKS COMMANDS
        const BarracksCommandsCollection = new RecruitmentCommandsCollection([]);
        state.currentCity[CityProperties.TROOP_COMMANDS_BARRACKS].forEach(command => {
            const Troop = state.currentCity.troops.find(troop => troop.name === command.name);
            const Command = new RecruitmentCommand(command, Troop);
            BarracksCommandsCollection.addItem(Command);
            AllCommandsCollection.addItem(Command);
        });
        if (BarracksCommandsCollection.getItems().size) {
            BarracksCommandsCollection.startCountdowns();
        }
        state.currentCity[CITY_ADDITIONAL_PROPERTIES][AddedCityProperties.BARRACKS_RECRUITMENT_COMMANDS] =
            BarracksCommandsCollection;

        // ARMORED COMMANDS
        const ArmoredCommandsCollection = new RecruitmentCommandsCollection([]);
        state.currentCity[CityProperties.TROOP_COMMANDS_ARMORED].forEach(command => {
            const Troop = state.currentCity.troops.find(troop => troop.name === command.name);
            const Command = new RecruitmentCommand(command, Troop);
            ArmoredCommandsCollection.addItem(Command);
            AllCommandsCollection.addItem(Command);
        });
        if (ArmoredCommandsCollection.getItems().size) {
            ArmoredCommandsCollection.startCountdowns();
        }
        state.currentCity[CITY_ADDITIONAL_PROPERTIES][AddedCityProperties.ARMORED_RECRUITMENT_COMMANDS] =
            ArmoredCommandsCollection;

        const PlaneCommandsCollection = new RecruitmentCommandsCollection([]);

        // PLANE COMMANDS
        state.currentCity[CityProperties.TROOP_COMMANDS_PLANES].forEach(command => {
            const Troop = state.currentCity.troops.find(troop => troop.name === command.name);
            const Command = new RecruitmentCommand(command, Troop);
            PlaneCommandsCollection.addItem(Command);
            AllCommandsCollection.addItem(Command);
        });
        if (PlaneCommandsCollection.getItems().size) {
            PlaneCommandsCollection.startCountdowns();
        }
        state.currentCity[CITY_ADDITIONAL_PROPERTIES][AddedCityProperties.PLANE_RECRUITMENT_COMMANDS] = PlaneCommandsCollection;

        // GENERAL COMMANDS
        const GeneralCommandsCollection = new RecruitmentCommandsCollection([]);
        state.currentCity[CityProperties.TROOP_COMMANDS_GENERAL].forEach(command => {
            const Troop = state.currentCity.troops.find(troop => troop.name === command.name);
            const Command = new RecruitmentCommand(command, Troop);
            GeneralCommandsCollection.addItem(Command);
            AllCommandsCollection.addItem(Command);
        });
        if (GeneralCommandsCollection.getItems().size) {
            GeneralCommandsCollection.startCountdowns();
        }
        state.currentCity[CITY_ADDITIONAL_PROPERTIES][AddedCityProperties.GENERAL_RECRUITMENT_COMMANDS] =
            GeneralCommandsCollection;

        // ALL COMMANDS
        state.currentCity[CITY_ADDITIONAL_PROPERTIES][AddedCityProperties.ALL_RECRUITMENT_COMMANDS] = AllCommandsCollection;
    },

    setAllMapActions(state, cities) {
        cities.forEach(city => {
            const CurrentCityActionsObject = {
                cityId: city._id,
                collection: new MapActionsCollection([]),
            };

            CityPropertiesThatAreActions.forEach(property => {
                city[property]?.forEach(action => {
                    const Action = ActionFactory.createAction({
                        action_data: action,
                        property_name: property,
                    });
                    if (Action) {
                        CurrentCityActionsObject.collection.addItem(Action);
                    }
                });
            });

            state.allMapActions.push(CurrentCityActionsObject);
        });
    },

    setCurrentCityMapActions(state, data) {
        // data: { city_id, city_index }

        let COLLECTION_INDEX;

        if (data.city_id) {
            COLLECTION_INDEX = state.allMapActions.findIndex(collection => collection.cityId === data.city_id);
        } else {
            COLLECTION_INDEX = data.city_index;
        }

        if (COLLECTION_INDEX !== -1) {
            state.currentCityMapActions = state.allMapActions[COLLECTION_INDEX];
            state.currentCityMapActions.collection.startCountdowns();
        } else {
            console.error('Map action collection not found for index:', COLLECTION_INDEX);
        }

        console.log('>>><<<>><><<', state.currentCityMapActions);
    },

    setAllMapArrows(state) {
        state.allMapActions.forEach(action_group => {
            action_group.collection.getItems().forEach(action => addActionToMapArrows(state.mapArrows, action));
        });

        console.log('@@@@@@@', state.mapArrows);
    },

    setOutsideOffers(state, data) {
        state.offers = [...data];
    },

    lowerResources(state, resources) {
        const CurrentCity = state.userState.cities.find(city => city._id === state.currentCity._id);

        CurrentCity.metal -= resources.metal || 0;
        CurrentCity.aluminum -= resources.aluminum || 0;
        CurrentCity.oil -= resources.oil || 0;
        CurrentCity.rations -= resources.rations || 0;
    },

    increaseResources(state, resources) {
        const CurrentCity = state.userState.cities.find(city => city._id === state.currentCity._id);

        CurrentCity.metal += resources.metal || 0;
        CurrentCity.aluminum += resources.aluminum || 0;
        CurrentCity.oil += resources.oil || 0;
        CurrentCity.rations -= resources.rations || 0;
    },

    setCountdownStarted(state, value) {
        state.countdownStarted = value;
    },

    setCountdownStartedTroopCommands(state, value) {
        state.countdownStartedTroopCommands = value;
    },

    updateDataLive(state, { data }) {
        // TO DO: only create class instances for actions, building commands etc. if update is for current city

        // "identifier" being null means we need to travel to the object property inside "name"

        // "identifier" not being null means we need to find each array element with property "identifier" === "name"[i];
        // for each array element inside "name" we traverse the rest of the "path" array and apply the provided
        // action with the data in "liveData" obtained by traversing the same paths

        // actions = [
        //     {
        //         path: [
        //             {
        //                 name: 'city',
        //                 identifier: null,
        //             },
        //             {
        //                 name: 'attacks',
        //                 identifier: null,
        //             },
        //             {
        //                 name: 'abc987654321',
        //                 identifier: '_id',
        //             },
        //             {
        //                 name: 'fromCity',
        //                 identifier: null,
        //             },
        //             {
        //                 name: '_id',
        //                 identifier: null,
        //             },
        //         ],
        //         action: 'replace',
        //     }
        // ]

        console.log(`LIVE DATA: `, JSON.parse(JSON.stringify(data)));

        let { data: parsedData } = data?.data;
        const paths = [];
        const actions = [...parsedData.actions];
        const indexes = [];
        const toReplace = [];

        for (let i = 0; i < parsedData.paths.length; i++) {
            paths.push(parsedData.paths[i].split('.'));
            if (actions[i] === 'remove') {
                toReplace.push(null);
                indexes.push(parsedData.indexes[0]);
                parsedData.indexes.splice(0, 1);
            } else if (['replacePartial', 'addPartial', 'addPartialArray'].includes(actions[i])) {
                indexes.push(parsedData.indexes[0]);
                toReplace.push(parsedData.toReplace[0]);
                parsedData.toReplace.splice(0, 1);
                parsedData.indexes.splice(0, 1);
            } else {
                indexes.push(null);
                toReplace.push(null);
            }
        }

        for (let i = 0; i < paths.length; i++) {
            let copyData = structuredClone(parsedData);

            let selector = state.userState;
            let accessFinalPath = true;

            const PastReferences = {};

            // FOR EACH NESTING WE UPDATE THE SELECTOR AND THE DATA
            for (let j = 0; j < paths[i].length; j++) {
                console.log('\npath group: ', paths[i]);
                console.log('\npath group item: ', paths[i][j]);

                if (j === paths[i].length - 1) {
                    const testSelector = selector[paths[i][j]];

                    if (
                        typeof testSelector === 'boolean' ||
                        typeof testSelector === 'string' ||
                        typeof testSelector === 'number'
                    ) {
                        accessFinalPath = false;
                    }
                }

                if (accessFinalPath) {
                    selector = selector[paths[i][j]];
                    copyData = copyData[paths[i][j]];

                    PastReferences[paths[i][j]] = selector;
                }

                console.log('\nSELECTOR: ', selector);
                console.log('\ncopyData: ', copyData);

                if (j < paths[i].length - 1) {
                    console.log('\nIF NOT LAST ELEMENT');

                    if (Array.isArray(selector)) {
                        for (let z = 0; z < selector.length; z++) {
                            if (selector[z]._id === copyData[0]._id) {
                                selector = selector[z];
                                break;
                            }
                        }

                        copyData = copyData[0];
                    }
                }
            }

            // paths[i][paths[i].length - 1] is the last property accessed in the current path group
            // paths is an array of arrays
            // ex: paths = [['cities', 'attacks'], ['cities', 'reinforcements']]
            // for i = 0, paths[i] is ['cities', 'attacks'] and paths[i][paths[i].length - 1] is 'attacks'
            const FinalAccessedProperty = paths[i][paths[i].length - 1];

            if (actions[i] === 'push') {
                selector.push(copyData[0]);
                if (CityPropertiesThatAreActions.includes(FinalAccessedProperty)) {
                    CityPropertiesThatAreActions.forEach(property => {
                        if (FinalAccessedProperty === property) {
                            const ReceivedAction = copyData[0];
                            const Action = ActionFactory.createAction({
                                action_data: ReceivedAction,
                                property_name: property,
                            });

                            if (Action) {
                                state.currentCityMapActions.collection.addItem(Action);
                                addActionToMapArrows(state.mapArrows, Action);
                                Action.startCountdown();

                                // console.log('@@@@@@@ live', state.mapArrows);
                            }
                        }
                    });
                }

                if (FinalAccessedProperty === CityProperties.BUILDING_COMMANDS) {
                    const Command = copyData[0];
                    const Building = state.currentCity.buildings.find(building => building.name === Command.name);
                    state.currentCity.buildingCommands.addItem(new BuildingCommand(Command, Building));
                    if (!state.currentCity.buildingCommands.isCountdownStarted()) {
                        state.currentCity.buildingCommands.startCountdowns();
                    }
                    console.log('fff', state.currentCity.buildingCommands);
                }

                if (FinalAccessedProperty === CityProperties.TROOP_COMMANDS_BARRACKS) {
                    updateBarracksRecruitmentCommands(state, copyData[0]);
                }

                if (FinalAccessedProperty === CityProperties.TROOP_COMMANDS_ARMORED) {
                    updateArmoredRecruitmentCommands(state, copyData[0]);
                }

                if (FinalAccessedProperty === CityProperties.TROOP_COMMANDS_PLANES) {
                    updatePlaneRecruitmentCommands(state, copyData[0]);
                }

                if (FinalAccessedProperty === CityProperties.TROOP_COMMANDS_GENERAL) {
                    updateGeneralRecruitmentCommands(state, copyData[0]);
                }

                if (FinalAccessedProperty === CityProperties.REINFORCEMENTS) {
                    copyData[0].troopsInCities.forEach(troops_in_cities_object => {
                        const FoundTroop = state.currentCity.formattedTroops.find(
                            troop => troop.getName() === troops_in_cities_object.troopName,
                        );
                        if (isReceivedReinforcement(copyData[0], state.currentCity._id)) {
                            FoundTroop.distributeReceivedReinforcement(new Reinforcement(copyData[0], troops_in_cities_object));
                        } else {
                            FoundTroop.addSentReinforcement(new Reinforcement(copyData[0], troops_in_cities_object));
                        }
                    });
                }

                if (
                    paths[i].includes(`${CityProperties.REINFORCEMENTS}.troopsInCities`) &&
                    FinalAccessedProperty === 'troopsInCities'
                ) {
                    const FoundTroop = state.currentCity.formattedTroops.find(troop => troop.getName() === copyData[0].troopName);
                    const FoundReinforcement = state.currentCity.reinforcements.find(
                        reinforcement => reinforcement._id === PastReferences[CityProperties.REINFORCEMENTS]._id,
                    );
                    if (isReceivedReinforcement(FoundReinforcement, state.currentCity._id)) {
                        FoundTroop.distributeReceivedReinforcement(new Reinforcement(FoundReinforcement, copyData[0]));
                    } else {
                        FoundTroop.addSentReinforcement(new Reinforcement(FoundReinforcement, copyData[0]));
                    }
                }

                if (FinalAccessedProperty === CityProperties.FOREIGN_SPIES) {
                    copyData[0].troops.forEach(troops_object => {
                        const Spies = state.currentCity.formattedTroops.find(troop => troop.getName() === TroopsIdentifier.SPY);
                        Spies.addSpiesOnMission(new SpiesOnMission(copyData[0], troops_object));
                    });
                }

                if (paths[i].includes(`${CityProperties.FOREIGN_SPIES}.troops`) && FinalAccessedProperty === 'troops') {
                    const Spies = state.currentCity.formattedTroops.find(troop => troop.getName() === TroopsIdentifier.SPY);
                    const FoundSpiesOnMission = state.currentCity.foreignSpies.find(
                        foreign_spies => foreign_spies.toCity === PastReferences[CityProperties.FOREIGN_SPIES].toCity,
                    );
                    Spies.addSpiesOnMission(new SpiesOnMission(FoundSpiesOnMission, copyData[0]));
                }
            } else if (actions[i] === 'remove') {
                if (indexes[i]) {
                    //IF WE HAVE SPECIFIC IDENTIFIER
                    for (let j = 0; j < selector.length; j++) {
                        for (let z = 0; z < copyData.length; z++) {
                            if (selector[j][indexes[i]] === copyData[z][indexes[i]]) {
                                // handle remove item in "reinforcements"
                                if (FinalAccessedProperty === CityProperties.REINFORCEMENTS) {
                                    const ReinforcementToRemove = selector[j]._id;
                                    selector[j].troopsInCities.forEach(troops_in_cities_object => {
                                        const FoundTroop = state.currentCity.formattedTroops.find(
                                            troop => troop.getName() === troops_in_cities_object.troopName,
                                        );
                                        FoundTroop.getReinforcements({
                                            category: ReinforcementCategory.ALL,
                                            filter_type: null,
                                            filter_value: null,
                                            include_not_arrived: true,
                                        }).forEach(reinforcement => {
                                            if (reinforcement.getReinforcementId() === ReinforcementToRemove) {
                                                reinforcement.selfDestruct();
                                            }
                                        });
                                    });
                                }

                                // handle remove item in "troopsInCities" from "reinforcements"
                                if (
                                    paths[i].includes(`${CityProperties.REINFORCEMENTS}.troopsInCities`) &&
                                    FinalAccessedProperty === 'troopsInCities'
                                ) {
                                    const ReinforcementFromWhichToRemove = PastReferences[CityProperties.REINFORCEMENTS]._id;
                                    selector[j].troopsInCities.forEach(troops_in_cities_object => {
                                        const FoundTroop = state.currentCity.formattedTroops.find(
                                            troop => troop.getName() === troops_in_cities_object.troopName,
                                        );
                                        FoundTroop.getReinforcements({
                                            category: ReinforcementCategory.ALL,
                                            filter_type: null,
                                            filter_value: null,
                                            include_not_arrived: true,
                                        }).forEach(reinforcement => {
                                            if (
                                                reinforcement.getReinforcementId() === ReinforcementFromWhichToRemove &&
                                                reinforcement.getId() === troops_in_cities_object.id
                                            ) {
                                                reinforcement.selfDestruct();
                                            }
                                        });
                                    });
                                }

                                // handle remove item in "foreignSpies"
                                if (FinalAccessedProperty === CityProperties.FOREIGN_SPIES) {
                                    const ForeignSpiesToRemove = selector[j].toCity;
                                    const Spies = state.currentCity.formattedTroops.find(
                                        troop => troop.getName() === TroopsIdentifier.SPY,
                                    );
                                    Spies.getReinforcements({
                                        category: ReinforcementCategory.SPIES,
                                        filter_type: ReinforcementFilterType.DESTINATION,
                                        filter_value: ForeignSpiesToRemove,
                                        include_not_arrived: true,
                                    }).forEach(spies_on_mission => {
                                        spies_on_mission.selfDestruct();
                                    });
                                }

                                // handle remove item in "troops" from "foreignSpies"
                                if (
                                    paths[i].includes(`${CityProperties.FOREIGN_SPIES}.troops`) &&
                                    FinalAccessedProperty === 'troops'
                                ) {
                                    const Spies = state.currentCity.formattedTroops.find(
                                        troop => troop.getName() === TroopsIdentifier.SPY,
                                    );
                                    const ForeignSpiesFromWhichToRemove = PastReferences[CityProperties.FOREIGN_SPIES].toCity;
                                    Spies.getReinforcements({
                                        category: ReinforcementCategory.SPIES,
                                        filter_type: ReinforcementFilterType.DESTINATION,
                                        filter_value: ForeignSpiesFromWhichToRemove,
                                        include_not_arrived: true,
                                    }).forEach(spies_on_mission => {
                                        if (
                                            // only works if id on troops comes from database
                                            // meaning that .getId() should return the "id" from BE,
                                            // rather than the randomly generated one
                                            spies_on_mission.getId() === selector[j].id
                                        ) {
                                            spies_on_mission.selfDestruct();
                                        }
                                    });
                                }

                                selector.splice(j, 1);
                                copyData.splice(z, 1);
                                j--;
                                break;
                            }
                        }
                    }
                } else {
                    //OTHERWISE
                    for (let j = 0; j < selector.length; j++) {
                        if (selector[j] === copyData[0]) {
                            selector.splice(j, 1);
                            break;
                        }
                    }
                }
            } else if (actions[i] === 'replace') {
                if (paths[i][0] === 'coalition' && paths[i].length === 1 && !copyData) {
                    //CASE WE REPLACE WHOLE COALITION
                    Object.assign(selector, parseTemplateForInitialization(COALITION_TEMPLATE));
                } else if (!accessFinalPath) {
                    //CASE WE HAVE PRIMITIVE VALUE
                    selector[paths[i][paths[i].length - 1]] = copyData[paths[i][paths[i].length - 1]];
                } else if (indexes[i]) {
                    //CASE WE HAVE ARRAY
                    for (let j = 0; j < selector.length; j++) {
                        for (let z = 0; z < copyData.length; z++) {
                            if (selector[j][indexes[i]] === copyData[z][indexes[i]]) {
                                selector[j][toReplace[i]] = copyData[z][toReplace[i]];
                                break;
                            }
                        }
                    }
                } else {
                    //ANY OTHER CASE
                    Object.assign(selector, copyData);
                }
            } else if (actions[i] === 'add') {
                if (indexes[i]) {
                    //IF WE HAVE ARRAY
                    for (let j = 0; j < selector.length; j++) {
                        for (let z = 0; z < copyData.length; z++) {
                            if (selector[j][indexes[i]] === copyData[z][indexes[i]]) {
                                selector[j][toReplace[i]] += copyData[z][toReplace[i]];
                                break;
                            }
                        }
                    }
                } else {
                    //OTHERWISE
                    selector[paths[i][paths[i].length - 1]] -= copyData[paths[i][paths[i].length - 1]];
                }
            } else if (actions[i] === 'substract') {
                if (indexes[i]) {
                    //IF WE HAVE ARRAY
                    for (let j = 0; j < selector.length; j++) {
                        for (let z = 0; z < copyData.length; z++) {
                            if (selector[j][indexes[i]] === copyData[z][indexes[i]]) {
                                selector[j][toReplace[i]] -= copyData[z][toReplace[i]];
                                break;
                            }
                        }
                    }
                } else {
                    //OTHERWISE
                    selector[paths[i][paths[i].length - 1]] -= copyData[paths[i][paths[i].length - 1]];
                }
            }
        }
    },

    updateOffersLive(state, { data }) {
        const LiveOffer = data.data.offer;
        console.log('OFFERS DATA:', LiveOffer);
        if (data.data.offer.actions.includes('push')) {
            state.offers.push(LiveOffer);
        }
        if (data.data.offer.actions.includes('remove')) {
            const OfferInState = state.offers.find(offer => offer._id === LiveOffer._id);
            OfferInState.toBeRemoved = true;
            OfferInState.repeats = 0;
        }
        if (data.data.offer.actions.includes('replacePartial')) {
            const OfferInState = state.offers.find(offer => offer._id === LiveOffer._id);
            LiveOffer.toReplace.forEach(property => (OfferInState[property] = LiveOffer[property]));
            OfferInState.newValue = true;
            setTimeout(() => {
                OfferInState.newValue = false;
            }, 2000);
        }
    },

    updateGameDataLive(state, { data }) {
        console.log(`UPDATE GAME DATA LIVE: `, data);

        let parsedData = data?.data?.gameData[0];
        const paths = [];
        const actions = [...parsedData.actions];
        const indexes = [];
        const toReplace = [];

        for (let i = 0; i < parsedData.paths.length; i++) {
            paths.push(parsedData.paths[i].split('.'));
            if (actions[i] === 'remove') {
                toReplace.push(null);
                indexes.push(parsedData.indexes[0]);
                parsedData.indexes.splice(0, 1);
            } else if (['replacePartial', 'addPartial', 'addPartialArray'].includes(actions[i])) {
                indexes.push(parsedData.indexes[0]);
                toReplace.push(parsedData.toReplace[0]);
                parsedData.toReplace.splice(0, 1);
                parsedData.indexes.splice(0, 1);
            } else {
                indexes.push(null);
                toReplace.push(null);
            }
        }

        for (let i = 0; i < paths.length; i++) {
            let copyData = structuredClone(parsedData);

            let selector = state.allUsers.find(user => user._id === copyData._id);
            console.log('SELECTOR :"|"::"|', selector);
            let accessFinalPath = true;

            const PastReferences = {};

            // FOR EACH NESTING WE UPDATE THE SELECTOR AND THE DATA
            for (let j = 0; j < paths[i].length; j++) {
                console.log('\npath group: ', paths[i]);
                console.log('\npath group item: ', paths[i][j]);

                if (j === paths[i].length - 1) {
                    const testSelector = selector[paths[i][j]];

                    if (
                        typeof testSelector === 'boolean' ||
                        typeof testSelector === 'string' ||
                        typeof testSelector === 'number' ||
                        testSelector === null
                    ) {
                        accessFinalPath = false;
                    }
                }

                if (accessFinalPath) {
                    selector = selector[paths[i][j]];
                    copyData = copyData[paths[i][j]];

                    PastReferences[paths[i][j]] = selector;
                }

                console.log('\nSELECTOR: ', selector);
                console.log('\ncopyData: ', copyData);

                if (j < paths[i].length - 1) {
                    console.log('\nIF NOT LAST ELEMENT');

                    if (Array.isArray(selector)) {
                        for (let z = 0; z < selector.length; z++) {
                            if (selector[z]._id === copyData[0]._id) {
                                selector = selector[z];
                                break;
                            }
                        }

                        copyData = copyData[0];
                    }
                }
            }

            if (actions[i] === 'push') {
                selector.push(copyData[0]);
                // paths[i][paths[i].length - 1] is the last property accessed in the current path group
                // paths is an array of arrays
                // ex: paths = [['cities', 'attacks'], ['cities', 'reinforcements']]
                // for i = 0, paths[i] is ['cities', 'attacks'] and paths[i][paths[i].length - 1] is 'attacks'
            } else if (actions[i] === 'remove') {
                if (indexes[i]) {
                    //IF WE HAVE SPECIFIC IDENTIFIER
                    for (let j = 0; j < selector.length; j++) {
                        for (let z = 0; z < copyData.length; z++) {
                            if (selector[j][indexes[i]] === copyData[z][indexes[i]]) {
                                selector.splice(j, 1);
                                copyData.splice(z, 1);
                                j--;
                                break;
                            }
                        }
                    }
                } else {
                    //OTHERWISE
                    for (let j = 0; j < selector.length; j++) {
                        if (selector[j] === copyData[0]) {
                            selector.splice(j, 1);
                            break;
                        }
                    }
                }
            } else if (actions[i] === 'replace') {
                console.log('selector $$$$$', selector);
                if (!accessFinalPath) {
                    //CASE WE HAVE PRIMITIVE VALUE
                    selector[paths[i][paths[i].length - 1]] = copyData[paths[i][paths[i].length - 1]];
                } else if (indexes[i]) {
                    //CASE WE HAVE ARRAY
                    for (let j = 0; j < selector.length; j++) {
                        for (let z = 0; z < copyData.length; z++) {
                            if (selector[j][indexes[i]] === copyData[z][indexes[i]]) {
                                selector[j][toReplace[i]] = copyData[z][toReplace[i]];
                                break;
                            }
                        }
                    }
                } else {
                    //ANY OTHER CASE
                    Object.assign(selector, copyData);
                }
            } else if (actions[i] === 'add') {
                if (indexes[i]) {
                    //IF WE HAVE ARRAY
                    for (let j = 0; j < selector.length; j++) {
                        for (let z = 0; z < copyData.length; z++) {
                            if (selector[j][indexes[i]] === copyData[z][indexes[i]]) {
                                selector[j][toReplace[i]] += copyData[z][toReplace[i]];
                                break;
                            }
                        }
                    }
                } else {
                    //OTHERWISE
                    selector[paths[i][paths[i].length - 1]] -= copyData[paths[i][paths[i].length - 1]];
                }
            } else if (actions[i] === 'substract') {
                if (indexes[i]) {
                    //IF WE HAVE ARRAY
                    for (let j = 0; j < selector.length; j++) {
                        for (let z = 0; z < copyData.length; z++) {
                            if (selector[j][indexes[i]] === copyData[z][indexes[i]]) {
                                selector[j][toReplace[i]] -= copyData[z][toReplace[i]];
                                break;
                            }
                        }
                    }
                } else {
                    //OTHERWISE
                    selector[paths[i][paths[i].length - 1]] -= copyData[paths[i][paths[i].length - 1]];
                }
            }
        }

        data.data.gameData.forEach(live_user => {
            const FoundUser = state.allUsers.find(state_user => state_user._id === live_user._id);
            if (live_user.actions.includes('remove')) {
                live_user.cities.forEach(live_city => {
                    if (FoundUser.cities.length <= 1) {
                        const FoundUserIndex = state.allUsers.findIndex(state_user => state_user._id === live_user._id);
                        state.allUsers.splice(FoundUserIndex, 1);
                    } else {
                        const FoundCityIndex = FoundUser.cities.findIndex(state_city => state_city._id === live_city._id);
                        FoundUser.cities.splice(FoundCityIndex, 1);
                    }
                });
            }
            if (live_user.actions.includes('push')) {
                live_user.cities.forEach(live_city => {
                    addAdditionalPropertiesToCity({ state, user: live_user, city: live_city });
                    addCityToMap({ state, city: live_city });
                    state.newlyReceivedCity = live_city;
                });
                if (!FoundUser) {
                    state.allUsers.push(live_user);
                } else {
                    live_user.cities.forEach(live_city => {
                        FoundUser.cities.push(live_city);
                    });
                }
            }
        });
    },

    removeInactiveOffers(state) {
        state.offers = state.offers.filter(offer => !offer.toBeRemoved);
    },

    removeOffer(state, offer_id) {
        const CurrentCity = state.userState.cities.find(city => city._id === state.currentCity._id);
        CurrentCity.offers = CurrentCity.offers.filter(offer => offer._id !== offer_id);
    },

    updateSubscriptions(state) {
        state.isSubscribed = true;
    },

    updatePactInvitations(state, data) {
        const PACT_INVITATION_INDEX = state.userState.coalition.pactInvitations.findIndex(invitation => invitation.id === data);
        if (PACT_INVITATION_INDEX !== -1) {
            state.userState.coalition.pactInvitations.splice(PACT_INVITATION_INDEX, 1);
        }
    },

    updateMemberRank(state, data) {
        // data: id, rank
        const MEMBER = state.userState.coalition.rights.find(member => member.user === data.id);
        MEMBER.rank = data.rank;
    },

    updateMemberRights(state, data) {
        // data: ids, permissions
        data.ids.forEach((id, index) => {
            const MEMBER = state.userState.coalition.rights.find(member => member.user === id);
            MEMBER.permissions.forEach(permission_to_change => {
                data.permissions[index].forEach(new_permission => {
                    if (permission_to_change.permission === new_permission.permission) {
                        permission_to_change.hasIt = new_permission.hasIt;
                    }
                });
            });
        });
    },

    updateMembers(state, data) {
        // data: member_to_add (to be handled later), member_to_remove
        const ALL_MEMBERS_ENTRY_INDEX = state.userState.coalition.allMembers.findIndex(
            member => member.memberId === data.member_to_remove,
        );
        state.userState.coalition.allMembers.splice(ALL_MEMBERS_ENTRY_INDEX, 1);

        const RIGHTS_ENTRY_INDEX = state.userState.coalition.rights.findIndex(member => member.user === data.member_to_remove);
        state.userState.coalition.rights.splice(RIGHTS_ENTRY_INDEX, 1);
    },

    SET_MAP_MATRIX(state, matrix) {
        // TO DO: move to city constants
        const CityLevels = [
            [0, 500],
            [501, 1500],
            [1501, 2500],
            [2501, 9999999999],
        ];

        matrix.forEach((row, row_index) => {
            row.forEach((tile, tile_index) => {
                tile.x = tile_index;
                tile.y = row_index;

                if (!tile.cities) {
                    tile.cities = [];
                }
                if (!tile.decorations) {
                    tile.decorations = [];
                }

                tile.cities.forEach((city, city_index) => {
                    state.allUsers.forEach(user => {
                        user.cities.forEach(user_city => {
                            if (city._id === user_city._id) {
                                user_city.localX = city.x % CITIES_PER_TILE_HORIZONTALLY;
                                user_city.localY = city.y % CITIES_PER_TILE_VERTICALLY;
                                // TO DO: extract util
                                user_city.level =
                                    CityLevels.findIndex(range => user_city.points >= range[0] && user_city.points <= range[1]) +
                                    1;
                                user_city.isBarbarian = user.isBarbarian;
                                user_city.owner = user.username;

                                tile.cities[city_index] = user_city;
                            }
                        });
                    });
                });

                tile.decorations.forEach(decoration => {
                    decoration.localX = decoration.x % CITIES_PER_TILE_HORIZONTALLY;
                    decoration.localY = decoration.y % CITIES_PER_TILE_VERTICALLY;
                });
            });
        });

        state.mapMatrix = matrix;
        console.log('*****', state.mapMatrix);
    },

    SET_ALL_CITIES(state, matrix) {
        const AllCities = [];

        matrix.forEach(row => {
            row.forEach(tile => {
                tile.cities.forEach(city => {
                    AllCities.push(city);
                });
            });
        });

        state.allCities = AllCities;
    },

    SET_CURRENT_TROOP(state, troop_name) {
        const General = state.currentCity.formattedTroops.find(troop => troop.getName() === TroopsIdentifier.GENERAL);
        const SpecificGeneral = General?.getGenerals().find(general => general.name === troop_name)

        if (SpecificGeneral) {
            state.currentTroop.troop = General;
            state.currentTroop.general = SpecificGeneral;
        } else {
            const Troop = state.currentCity.formattedTroops.find(troop => troop.getName() === troop_name);
            state.currentTroop.troop = Troop;
            state.currentTroop.general = null;
        }
    },

    setNewlyReceivedCity(state, data) {
        state.newlyReceivedCity = data;
    },

    setAllUsers(state, data) {
        state.allUsers = data;
        console.log('>> All Users >>', state.allUsers);
    },

    setMapActionTarget(state, data) {
        state.mapActionTarget = data;
    },

    removeWarEffects(state, { war_id }) {
        const WAR = state.userState.coalition.wars.find(war => war._id === war_id);
        WAR.effectsInitiator = [];
        WAR.effectsTarget = [];
    },
};

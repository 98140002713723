<template>
    <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Leadership</h4>
                    <div class="building-description">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean ullamcorper ligula diam.
                    </div>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <img src="../assets/close_icon.svg" width="20" height="20" aria-hidden="true" />
                    </button>
                </div>
                <div class="modal-body">
                    <h5 class="section-header">Current ruler:</h5>
                    <GeneralImage v-if="ruler" :general-name="ruler.name" />
                    <div v-else class="default-text">No ruler set on this city</div>
                    <div class="change-ruler-wrapper">
						<div v-if="isAtLeastOneGeneral" class="ruler-select">
                            <span>Select a new ruler:</span>
                            <GeneralSelectRow />
                        </div>
                        <div class="confirm-button-wrapper">
                            <button class="btn blue-button">Confirm Ruler</button>
                        </div>
                    </div>
					<div class="change-strategy-wrapper">
						<div class="current-strategy-wrapper">
							<h5 class="section-header">Current defensive strategy:</h5>
							<StrategyPreview :strategy="defensiveStrategy?.strategy" :strategy-name="defensiveStrategy?.name" />
						</div>
						<div class="select-strategy-wrapper">
							<span>Select a new defensive strategy:</span>
							<StrategySelect @strategy-change="setNewDefensiveStrategy" />
							<div v-if="newDefensiveStrategy" class="d-flex justify-content-end mt-3">
								<button class="btn blue-button">Confirm Strategy</button>
							</div>
						</div>
					</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { TroopsIdentifier } from '@/utils/constants/troops';
import GeneralSelectRow from '@/components/generic/TroopSelect/GeneralSelectRow';
import GeneralImage from '@/components/generic/GeneralImage';
import StrategyPreview from '@/components/generic/Strategy/StrategyPreview';
import StrategySelect from '@/components/generic/Strategy/StrategySelect';

export default {
    name: 'LeadershipModal',
    components: { StrategySelect, StrategyPreview, GeneralImage, GeneralSelectRow },
    props: {},
	data() {
		return {
			newDefensiveStrategy: false,
		}
	},
    computed: {
        ruler() {
            return this.$store.getters.getRuler;
        },
        isAtLeastOneGeneral() {
            const General = this.$store.getters.getCurrentCityFormattedTroops?.find(
                troop => troop.getName() === TroopsIdentifier.GENERAL,
            );
            return General?.getGenerals().length;
        },
        defensiveStrategy() {
            return this.$store.getters.getDefensiveStrategy;
        },
    },
	methods: {
		setNewDefensiveStrategy(new_strategy) {
			this.newDefensiveStrategy = new_strategy;
		}
	}
};
</script>

<style scoped>
.modal {
    background: rgba(0, 0, 0, 0.5);
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.modal::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.modal::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.modal-header {
    border: none;
    display: flex;
    flex-direction: column;
}

.modal-header {
    background: linear-gradient(to right, rgba(0, 0, 0, 0), black, rgba(0, 0, 0, 0));
    display: flex;
    justify-content: center;
}

.modal-title {
    width: 60%;
    font-family: 'bebas', sans-serif;
    letter-spacing: 1px;
    text-align: center;
    padding-bottom: 0.75rem;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0), lightgray, rgba(0, 0, 0, 0)) 1;
    border-left: none;
    border-right: none;
    border-top: none;
}

.building-description {
    width: 60%;
    font-family: 'lato', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    margin-top: 0.5rem;
    margin-left: 1rem;
    padding-right: 1rem;
    max-height: 2.5rem;
    overflow-y: overlay;
    color: #b7b6b6;
    text-align: justify;
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.building-description::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.building-description::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.close {
    position: absolute;
    top: 16px;
    right: 24px;
    border: none;
    background: none;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.close:hover {
    opacity: 1;
}

.modal-content {
    font-family: 'sansation', sans-serif;
    top: 5vh;
    padding: 4rem;
    background-image: url('../assets/background.avif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0;
    color: ghostwhite;
    border: none;
}

.modal-body {
    padding-top: 3rem;
}

.btn {
    padding: 0.25rem 1rem;
    width: fit-content;
    height: fit-content;
}

.blue-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgb(34, 41, 58), rgba(255, 255, 255, 0.2));
    border: 3px solid dodgerblue;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
}

.blue-button:hover {
    background: linear-gradient(to bottom, rgba(96, 95, 42, 0.2), rgb(52, 52, 70));
    color: ghostwhite;
}

.section-header {
    margin-bottom: 0.5rem;
    color: lightgoldenrodyellow;
}

.default-text {
    color: #afafaf;
    padding-left: 1rem;
}

.change-ruler-wrapper {
    display: flex;
    gap: 1rem;
    margin-top: 2rem;
}

.ruler-select {
    width: 75%;
}

.confirm-button-wrapper {
    padding-top: 34px;
}

.change-strategy-wrapper {
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
}

.current-strategy-wrapper,
.select-strategy-wrapper {
	width: 48%;
}

@media screen and (max-width: 991px) {
    .modal-content {
        padding: 3rem 1rem;
    }
    .modal-title {
        width: 80%;
    }
    .building-description {
        width: 80%;
    }
}

@media screen and (max-width: 450px) {
    .modal-title {
        width: 100%;
    }
    .building-description {
        width: 100%;
    }
}

@media screen and (orientation: landscape) and (max-height: 800px) {
    .modal-content {
        transform: scale(0.8);
        top: -10vh;
    }
}
</style>

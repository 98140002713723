import { arrayWrapper } from '@/utils/functions/templateUtils';

export const CITY_TEMPLATE = Object.freeze({
    id: '',
    _id: '',
    buildings: arrayWrapper({
        name: '',
        appearance: [],
        perLevel: [],
        maxLevel: '',
        level: 0,
        metal: [],
        oil: [],
        aluminum: [],
        population: [],
        requirements: {
            name: '',
            level: '',
        },
        time: [],
        description: '',
        resourcesPercent: [],
        maxTransports: 0,
        occupiedTransports: 0,
    }),
    commands: arrayWrapper({
        _id: '',
        name: '',
        time: 0,
        finishDate: '',
        finishDateToShow: '',
        initialLevel: 0,
        dateCreated: '',
    }),
    troopCommandsBarracks:arrayWrapper({
        name: '',
        dateCreated: '',
        time: 0,
        finishDate: '',
        finishDateToShow: '',
        numOfTroops: 0,
        perUnitTimeToShow: '',
        perUnitTime: 0,
    }),
    troopCommandsArmored:arrayWrapper({
        name: '',
        dateCreated: '',
        time: 0,
        finishDate: '',
        finishDateToShow: '',
        numOfTroops: 0,
        perUnitTimeToShow: '',
        perUnitTime: 0,
    }),
    troopCommandsPlanes:arrayWrapper({
        name: '',
        dateCreated: '',
        time: 0,
        finishDate: '',
        finishDateToShow: '',
        numOfTroops: 0,
        perUnitTimeToShow: '',
        perUnitTime: 0,
    }),
    generalCommands:arrayWrapper({
        name: '',
        dateCreated: '',
        time: 0,
        finishDate: '',
        finishDateToShow: '',
        numOfTroops: 0,
        perUnitTimeToShow: '',
        perUnitTime: 0,
    }),
    generals: arrayWrapper({
        _id: '',
        name: '',
        bonus: '',
        ammount: 0,
        description: ''
    }),
    ruler: {
        _id: '',
        name: '',
        bonus: '',
        ammount: 0,
        description: ''
    },
    defensiveStrategy: {
        _id: '',
        name: '',
        strategy: [],
    },
    troops: arrayWrapper({
        name: '',
        antiInfantryDefense: 0,
        antiArtilleryAndTankDefense: 0,
        antiAirDefense: 0,
        attack: 0,
        typeOfAttack: 0,
        typeOfUnit: 0,
        population: 0,
        rationsConsumption: 0,
        metal: 0,
        oil: 0,
        aluminum: 0,
        oilConsumption: 0,
        time: '',
        numOfTroops: 0,
        controlledReinforcements: 0,
        uncontrolledReinforcements: 0,
        carryCapacity: 0,
        movementSpeed: 0,
        requirements: {
            name: '',
            level: 0,
        },
        description: '',
    }),
    metal: 0,
    oil: 0,
    aluminum: 0,
    rations: 0,
    points: 0,
    metalProduction: 0,
    oilProduction: 0,
    aluminumProduction: 0,
    population: 0,
    name: '',
    attacks: arrayWrapper({
        _id: '',
        fromCity: {
            _id: '',
            name: '',
            x: 0,
            y: 0
        },
        toCity: {
            _id: '',
            name: '',
            x: 0,
            y: 0
        },
        toUser: {
            _id: '',
            username: ''
        },
        fromUser: {
            _id: '',
            username: ''
        },
        duration: '',
        name: '',
        // tile: 0,
        tilesToTravel: '',
        arrivalDate: 0,
        arrivalDateToShow: '',
        troops: arrayWrapper({
            troopsName: [],
            numOfTroops: [],
            lost: [],
            owned: false,
            reinforcement: '',
            lastCity: '',
        }),
        dateCreated: '',
    }),
    attacksReturning: arrayWrapper({
        _id: '',
        fromCity: {
            _id: '',
            name: '',
            x: 0,
            y: 0
        },
        toCity: {
            _id: '',
            name: '',
            x: 0,
            y: 0
        },
        toUser: {
            _id: '',
            username: '',
        },
        fromUser: {
            _id: '',
            username: '',
        },
        duration: '',
        tilesToTravel: '',
        arrivalDate: 0,
        arrivalDateToShow: '',
        resourcesStolen: {
            name: '',
            quantity: 0,
        },
        troops: arrayWrapper({
            troopsName: [],
            numOfTroops: [],
            lost: [],
            owned: false,
            reinforcement: '',
            lastCity: '',
        }),
        dateCreated: '',
    }),
    attackIntercepts: arrayWrapper({
        _id: '',
        fromCity: {
            _id: '',
            name: '',
            x: 0,
            y: 0
        },
        fromUser: {
            _id: '',
            username: '',
        },
        toCity: {
            _id: '',
            name: '',
            x: 0,
            y: 0
        },
        toUser: {
            _id: '',
            username: '',
        },
        interceptedAttack: '',
        duration: 0,
        percentTravelled: 0,
        interceptPoint: 0,
        troops: arrayWrapper({
            troopsName: [],
            numOfTroops: [],
            owned: false,
            reinforcement: '',
            lastCity: '',
        }),
        arrivalDate: 0,
        arrivalDateToShow: '',
        oil: 0,
        dateCreated: '',
    }),
    attackInterceptsReturning: arrayWrapper({
        fromCity: {
            _id: '',
            name: '',
            x: 0,
            y: 0
        },
        fromUser: {
            _id: '',
            username: '',
        },
        toCity: {
            _id: '',
            name: '',
            x: 0,
            y: 0
        },
        toUser: {
            _id: '',
            username: '',
        },
        intercept: '',
        duration: 0,
        interceptPoint: 0,
        troops: arrayWrapper({
            troopsName: [],
            numOfTroops: [],
            owned: false,
            reinforcement: '',
            lastCity: '',
        }),
        arrivalDate: 0,
        arrivalDateToShow: '',
        dateCreated: '',
    }),
    reinforcements: arrayWrapper({
        _id: '',
        fromCity: {
            _id: '',
            name: '',
            x: 0,
            y: 0
        },
        fromUser: {
            _id: '',
            username: '',
        },
        toCity: {
            _id: '',
            name: '',
            x: 0,
            y: 0
        },
        toUser: {
            _id: '',
            username: '',
        },
        duration: '',
        // name: '',
        // tile: 0,
        tilesToTravel: 0,
        arrivalDate: 0,
        arrivalDateToShow: '',
        arrived: '',
        targetCanControl: false,
        targetCanPassControl: false,
        troopsInCities: {
            currentCity: {
                _id: '',
                name: '',
                userid: '',
                username: ''
            },
            pastCities: {
                _id: '',
                name: '',
                userid: '',
                username: ''
            },
            troopName: '',
            numOfTroops: 0,
            arrived: false,
            mission: '',
            id: '',
            lost: 0
        },
        troops: arrayWrapper({
            troopsName: [],
            numOfTroops: [],
            owned: false,
            reinforcement: '',
            lastCity: '',
        }),
        dateCreated: '',
        power: 0,
        usable: false,
    }),
    reinforcementsReturning: arrayWrapper({
        _id: '',
        toCity: {
            _id: '',
            name: '',
            x: 0,
            y: 0
        },
        toUser: {
            _id: '',
            username: ''
        },
        fromCity: {
            _id: '',
            name: '',
            x: 0,
            y: 0
        },
        fromUser: {
            _id: '',
            username: ''
        },
        duration: '',
        tilesToTravel: 0,
        arrivalDate: 0,
        arrivalDateToShow: '',
        troops: arrayWrapper({
            // tile: 0,
            troopsName: [],
            numOfTroops: [],
            owned: false,
            reinforcement: '',
            lastCity: '',
        }),
    }),
    spyMovements: arrayWrapper({
        _id: '',
        fromCity: {
            _id: '',
            name: '',
            x: 0,
            y: 0
        },
        fromUser: {
            _id: '',
            username: ''
        },
        toCity: {
            _id: '',
            name: '',
            x: 0,
            y: 0
        },
        toUser: {
            _id: '',
            username: ''
        },
        troops: arrayWrapper({
            troopsName: [],
            numOfTroops: [],
            lost: [],
            owned: false,
            reinforcement: '',
            lastCity: '',
        }),
        name: '',
        // tile: 0,
        lost: 0,
        duration: '',
        tilesToTravel: 0,
        arrivalDate: 0,
        arrivalDateToShow: '',
        dateCreated: '',
    }),
    spyMovementsReturning: arrayWrapper({
        _id: '',
        fromCity: {
            _id: '',
            name: '',
            x: 0,
            y: 0
        },
        fromUser: {
            _id: '',
            username: ''
        },
        toCity: {
            _id: '',
            name: '',
            x: 0,
            y: 0
        },
        toUser: {
            _id: '',
            username: ''
        },
        troops: arrayWrapper({
            troopsName: [],
            numOfTroops: [],
            owned: false,
            reinforcement: '',
            lastCity: '',
        }),
        duration: '',
        tilesToTravel: 0,
        arrivalDate: 0,
        arrivalDateToShow: '',
        dateCreated: '',
    }),
    detectSpiesMissions: arrayWrapper({
        _id: '',
        fromCity: {
            _id: '',
            name: '',
            x: 0,
            y: 0
        },
        fromUser: {
            _id: '',
            username: ''
        },
        troops: arrayWrapper({
            troopsName: [],
            numOfTroops: [],
            owned: false,
            reinforcement: '',
            lastCity: '',
        }),
        duration: '',
        arrivalDate: 0,
        arrivalDateToShow: '',
        dateCreated: '',
    }),
    patrols: arrayWrapper({
        fromCity: {
            _id: '',
            name: '',
            x: 0,
            y: 0
        },
        fromUser: {
            _id: '',
            username: ''
        },
        duration: 0,
        range: 0,
        oilConsumed: 0,
        troops: arrayWrapper({
            troopsName: [],
            numOfTroops: [],
            owned: false,
            reinforcement: '',
            lastCity: '',
        }),
        arrivalDate: 0,
        arrivalDateToShow: '',
        dateCreated: '',
    }),
    tile: 0,
    foreignSpies: arrayWrapper({
        fromCity: '',
        toCity: '',
        fromUser: '',
        troops: {
            troopsName: [],
            numOfTroops: [],
            owned: true,
            lastOrigin: {
                reinforcement: '',
                city: {
                    _id: '',
                    name: ''
                },
                user: {
                    _id: '',
                    username: ''
                }
            }
        },
    }),
    offers: arrayWrapper({
        _id: '',
        fromCity: {
            _id: '',
            name: '',
        },
        fromUser: {
            _id: '',
            username: '',
        },
        resourceToOffer: {
            name: '',
            quantity: 0,
        },
        resourceToGet: {
            name: '',
            quantity: 0,
        },
        // tile: 0,
        x: 0,
        y: 0,
        repeats: 0,
        maximumTime: 0,
        numOfTransportsNeeded: 0,
        numOfTrucksNeeded: 0,
        dateCreated: '',
    }),
    transports: arrayWrapper({
        _id: '',
        fromCity: {
            _id: '',
            name: '',
            x: 0,
            y: 0
        },
        fromUser: {
            _id: '',
            username: '',
        },
        toCity: {
            _id: '',
            name: '',
            x: 0,
            y: 0
        },
        toUser: {
            _id: '',
            username: '',
        },
        resourceToGet: arrayWrapper({
            name: '',
            quantity: 0,
        }),
        duration: '',
        tilesToTravel: 0,
        arrivalDate: 0,
		arrivalDateToShow: '',
        transports: 0,
        isOffer: false,
        troops: arrayWrapper({
            troopsName: [],
            numOfTroops: [],
            owned: false,
            reinforcement: '',
            lastCity: '',
        }),
        dateCreated: '',
    }),
	transportsReturning: arrayWrapper({
        _id: '',
        fromCity: {
            _id: '',
            name: '',
            x: 0,
            y: 0
        },
        fromUser: {
            _id: '',
            username: '',
        },
        toCity: {
            _id: '',
            name: '',
            x: 0,
            y: 0
        },
        toUser: {
            _id: '',
            username: '',
        },
        duration: '',
        tilesToTravel: 0,
        arrivalDate: 0,
		arrivalDateToShow: '',
        transports: 0,
        troops: arrayWrapper({
            troopsName: [],
            numOfTroops: [],
            owned: false,
            reinforcement: '',
            lastCity: '',
        }),
        dateCreated: '',
    }),
    closeCoalitions: arrayWrapper({
        id: '',
        name: '',
        additionRule: ''
    }),
    x: 0,
    y: 0,
    isBeingConquered: false,
});

import axios from 'axios';
import parseTemplateForQuery from '@/utils/state/parsers/parseTemplateForQuery';
import { GENERAL_TEMPLATE } from '@/utils/state/templates/generalTemplate';
import {
    addReinforcementPermissionsToPayload,
    addTroopsToPayload,
    addTroopsToPayloadForCallingBackSpies,
    addTroopsToPayloadForSendingOrCallingBackReinforcements,
    getStringifiedTroops,
} from '@/utils/state/actionUtils';
import { CommandTypesForTroopSelection, ReinforcementControlLevel, ReinforcementFilterType } from '@/utils/constants/troops';
import { OFFERS_TEMPLATE } from '@/utils/state/templates/offersTemplate';
import { ReinforcementCategory } from '@/utils/constants/actions';

export default {
    setCurrentCityAction({ commit }, { city_id, city_index }) {
        commit('setCurrentCity', { city_id, city_index });
        commit('setCurrentCityMapActions', { city_id, city_index });
    },

    updateSubscriptionsAction({ commit }) {
        commit('updateSubscriptions');
    },

    updateDataLiveAction({ commit }, data) {
        commit(`updateDataLive`, data);
    },

    updateOffersLiveAction({ commit }, data) {
        commit('updateOffersLive', data);
    },

    updateGameDataLiveAction({ commit }, data) {
        commit('updateGameDataLive', data);
    },

    async getUserInformation({ commit }) {
        await axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `{
                          getUserInformation ${parseTemplateForQuery(GENERAL_TEMPLATE)}}`,
                },
                { withCredentials: true },
            )
            .then(response => {
                console.log('>>>>>>>>>>>>>', response);
                commit('setUserInfo', response.data.data.getUserInformation);
                commit('setCurrentCity', { city_id: null, city_index: 0 });
                commit('SET_ADDITIONAL_PROPERTIES_OBJECT')
                commit('SET_FORMATTED_TROOPS');
                commit('SET_SENT_REINFORCEMENTS_BY_CITY');
                commit('SET_RECEIVED_REINFORCEMENTS_BY_CITY');
                commit('SET_BUILDING_COMMANDS');
                commit('SET_RECRUITMENT_COMMANDS');

                commit('setAllMapActions', response.data.data.getUserInformation.cities);
                commit('setAllMapArrows', response.data.data.getUserInformation.cities);
                commit('setCurrentCityMapActions', { city_id: null, city_index: 0 });
            });
    },

    async getMap({ commit }) {
        await axios
            .get(
                `${process.env.VUE_APP_ADDRESS_MAIN}?query={
                        getMap{
                            matrix{
                                piece,
                                tile,
                                decorations{
                                    name,
                                    piece,
                                    x,
                                    y
                                },
                                cities{
                                    _id,
                                    x,
                                    y
                                }
                            }
                        }
                    }`,
                { withCredentials: true },
            )
            .then(response => {
                if (!response.data) return;

                commit('SET_MAP_MATRIX', response.data.data.getMap.matrix);
            })
            .catch(e => {
                console.log(e);
            });
    },

    async getAllUsers({ commit }) {
        await axios
            .get(
                `${process.env.VUE_APP_ADDRESS_MAIN}?query={
                        getGameData{
                            _id
                            isBarbarian
                            username
                            cities {
                              x
                              y
                              points
                              _id
                              name
                              color
                            }
                            points
                            ranking
                            coalition {
                              _id
                              name
                              abbreviation
                              numOfMembers
                              points
                              maxNumOfMembers
                              additionRule
                            }
                        }
                    }`,
                { withCredentials: true },
            )
            .then(response => {
                if (!response.data) return;

                commit('setAllUsers', response.data.data.getGameData);
            })
            .catch(e => {
                console.log(e);
            });
    },

    async sendAttack({ commit, state }, payload) {
        addTroopsToPayload({
            payload,
            troops: state.currentCity.formattedTroops,
            command_type: CommandTypesForTroopSelection.ATTACK_OR_REINFORCE,
            reinforcement_filters: {
                category: ReinforcementCategory.RECEIVED,
                filter_type: ReinforcementFilterType.CONTROL_LEVEL,
                filter_value: ReinforcementControlLevel.PARTIAL_OR_FULL,
                include_not_arrived: false,
            },
        });

        console.log('sendAttack', payload);

        commit('setAwaitingResponse', true);
        axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{
						attack(
						    attackedCity: "${payload.attackedCity}",
							isBarbarian: ${payload.isBarbarian},
							targetBuildings: ${JSON.stringify(payload.targetBuildings)}
							troops: ${getStringifiedTroops(payload.troops)},
							${payload.reinforcements ? 'reinforcements: ' + JSON.stringify(payload.reinforcements) + ', ' : ''}
							oil: ${payload.oil},
							rations: ${payload.rations},
							cityId: "${state.currentCity._id}",
							strategy: "${payload.strategy}",
							${payload.general ? 'general: ' + payload.general : ''}
						)
					}`,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(fulfilled => {
                commit('setAwaitingResponse', false);
                if (fulfilled) {
                    commit('UPDATE_TROOP_NUMBERS', {
                        command_type: CommandTypesForTroopSelection.ATTACK_OR_REINFORCE,
                        reinforcement_filters: {
                            category: ReinforcementCategory.RECEIVED,
                            filter_type: ReinforcementFilterType.CONTROL_LEVEL,
                            filter_value: ReinforcementControlLevel.PARTIAL_OR_FULL,
                            include_not_arrived: false,
                        },
                    });
                }
            });
    },

    async sendReinforcement({ commit, state }, { payload, reinforcement_filters, reinforcement_type }) {
        addTroopsToPayload({
            payload,
            troops: state.currentCity.formattedTroops,
            command_type: CommandTypesForTroopSelection.ATTACK_OR_REINFORCE,
            reinforcement_filters,
        });

        addReinforcementPermissionsToPayload(payload, reinforcement_type);

        console.log('sendReinforcement', payload);

        commit('setAwaitingResponse', true);
        axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{
                    	reinforcement(
                    		targetId: "${payload.targetId}",
                    		troops: ${getStringifiedTroops(payload.troops)},
                    		${payload.reinforcements ? 'reinforcements: ' + JSON.stringify(payload.reinforcements) + ', ' : ''}
                            targetCanControl: ${payload.targetCanControl},
                            targetCanPassControl: ${payload.targetCanPassControl},
                            cityId: "${state.currentCity._id}",
                        )
                    }`,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(fulfilled => {
                commit('setAwaitingResponse', false);
                if (fulfilled) {
                    commit('UPDATE_TROOP_NUMBERS', {
                        command_type: CommandTypesForTroopSelection.ATTACK_OR_REINFORCE,
                        reinforcement_filters,
                    });
                }
            });
    },

    async sendDetectSpiesMission({ commit, state }) {
        const payload = {};
        addTroopsToPayload({
            payload,
            troops: state.currentCity.formattedTroops,
            command_type: CommandTypesForTroopSelection.DETECT_SPIES,
            reinforcement_filters: {
                category: ReinforcementCategory.RECEIVED,
                filter_type: ReinforcementFilterType.CONTROL_LEVEL,
                filter_value: ReinforcementControlLevel.PARTIAL_OR_FULL,
                include_not_arrived: false,
            },
        });

        console.log('sendDetectSpiesMission', payload);

        commit('setAwaitingResponse', true);
        axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{
                        detectInfiltratedSpies(
                            troops: ${getStringifiedTroops(payload.troops)},
                            ${payload.reinforcements ? 'reinforcements: ' + JSON.stringify(payload.reinforcements) + ', ' : ''}
                            cityId: "${state.currentCity._id}",
                        )
                    }`,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(fulfilled => {
                commit('setAwaitingResponse', false);
                if (fulfilled) {
                    commit('UPDATE_TROOP_NUMBERS', {
                        command_type: CommandTypesForTroopSelection.DETECT_SPIES,
                        reinforcement_filters: {
                            category: ReinforcementCategory.RECEIVED,
                            filter_type: ReinforcementFilterType.CONTROL_LEVEL,
                            filter_value: ReinforcementControlLevel.PARTIAL_OR_FULL,
                            include_not_arrived: false,
                        },
                    });
                }
            });
    },

    async sendSpyMission({ commit, state }, payload) {
        addTroopsToPayload({
            payload,
            troops: state.currentCity.formattedTroops,
            command_type: CommandTypesForTroopSelection.SPY,
            reinforcement_filters: {
                category: ReinforcementCategory.RECEIVED,
                filter_type: ReinforcementFilterType.CONTROL_LEVEL,
                filter_value: ReinforcementControlLevel.PARTIAL_OR_FULL,
                include_not_arrived: false,
            },
        });

        console.log('sendSpyMission', payload);

        commit('setAwaitingResponse', true);
        axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{
                        spy(
                            targetCityId: "${payload.targetId}",
                            troops: ${getStringifiedTroops(payload.troops)},
                            ${payload.reinforcements ? 'reinforcements: ' + JSON.stringify(payload.reinforcements) + ', ' : ''}
                            cityId: "${state.currentCity._id}"
                        )
                    }`,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(fulfilled => {
                commit('setAwaitingResponse', false);
                if (fulfilled) {
                    commit('UPDATE_TROOP_NUMBERS', {
                        command_type: CommandTypesForTroopSelection.SPY,
                        reinforcement_filters: {
                            category: ReinforcementCategory.RECEIVED,
                            filter_type: ReinforcementFilterType.CONTROL_LEVEL,
                            filter_value: ReinforcementControlLevel.PARTIAL_OR_FULL,
                            include_not_arrived: false,
                        },
                    });
                }
            });
    },

    async callBackSpies({ commit, state }, city_id) {
        const Payload = {}
        addTroopsToPayloadForCallingBackSpies({
            payload: Payload,
            troops: state.currentCity.formattedTroops,
            reinforcement_filters: {
                category: ReinforcementCategory.SPIES,
                filter_type: ReinforcementFilterType.DESTINATION,
                filter_value: city_id,
                include_not_arrived: false,
            },
        });

        console.log('payload', city_id);

        commit('setAwaitingResponse', true);
        axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{
                        callBackSpies(
                            targetCityId: "${city_id}",
                            troops: ${getStringifiedTroops(Payload.troops)},
                            ${Payload.reinforcements ? 'reinforcements: ' + JSON.stringify(Payload.reinforcements) + ', ' : ''},
                            cityId: "${state.currentCity._id}"
                        )
                    }`,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(fulfilled => {
                commit('setAwaitingResponse', false);
                if (fulfilled) {
                    commit('UPDATE_TROOP_NUMBERS', {
                        command_type: CommandTypesForTroopSelection.CALL_BACK_FROM_SPYING,
                        reinforcement_filters: {
                            category: ReinforcementCategory.SPIES,
                            filter_type: ReinforcementFilterType.DESTINATION,
                            filter_value: city_id,
                            include_not_arrived: false,
                        },
                    });
                }
            });
    },

    async callBackReinforcements({ commit, state }, city_id) {
        const Payload = {};
        addTroopsToPayloadForSendingOrCallingBackReinforcements({
            payload: Payload,
            troops: state.currentCity.formattedTroops,
            command_type: CommandTypesForTroopSelection.CALL_BACK_FROM_REINFORCING,
            reinforcement_filters: {
                category: ReinforcementCategory.SENT,
                filter_type: ReinforcementFilterType.DESTINATION,
                filter_value: city_id,
                include_not_arrived: false,
            },
        });

        commit('setAwaitingResponse', true);
        axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{
                        callBackReinforcement(
                            troops: ${getStringifiedTroops(Payload.troops)},
                            ${Payload.reinforcements ? 'reinforcement: ' + JSON.stringify(Payload.reinforcements) + ', ' : ''},
                            cityId: "${state.currentCity._id}"
                        )
                    }`,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(fulfilled => {
                commit('setAwaitingResponse', false);
                if (fulfilled) {
                    commit('UPDATE_TROOP_NUMBERS', {
                        command_type: CommandTypesForTroopSelection.CALL_BACK_FROM_REINFORCING,
                        reinforcement_filters: {
                            category: ReinforcementCategory.SENT,
                            filter_type: ReinforcementFilterType.DESTINATION,
                            filter_value: city_id,
                            include_not_arrived: false,
                        },
                    });
                }
            });
    },

    async sendBackReinforcements({ commit, state }, city_id) {
        const Payload = {};
        addTroopsToPayloadForSendingOrCallingBackReinforcements({
            payload: Payload,
            troops: state.currentCity.formattedTroops,
            command_type: CommandTypesForTroopSelection.SEND_BACK_FROM_REINFORCING,
            reinforcement_filters: {
                category: ReinforcementCategory.RECEIVED,
                filter_type: ReinforcementFilterType.LATEST_ORIGIN,
                filter_value: city_id,
                include_not_arrived: false,
            },
        });

        commit('setAwaitingResponse', true);
        axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{
                        sendingBackReinforcement(
                            troops: ${getStringifiedTroops(Payload.troops)},
                            ${Payload.reinforcements ? 'reinforcement: ' + JSON.stringify(Payload.reinforcements) + ', ' : ''},
                            cityId: "${state.currentCity._id}"
                        )
                    }`,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(fulfilled => {
                commit('setAwaitingResponse', false);
                if (fulfilled) {
                    commit('UPDATE_TROOP_NUMBERS', {
                        command_type: CommandTypesForTroopSelection.SEND_BACK_FROM_REINFORCING,
                        reinforcement_filters: {
                            category: ReinforcementCategory.RECEIVED,
                            filter_type: ReinforcementFilterType.LATEST_ORIGIN,
                            filter_value: city_id,
                            include_not_arrived: false,
                        },
                    });
                }
            });
    },

    async sendPatrol({ commit, state }, payload) {
        addTroopsToPayload({
            payload,
            troops: state.currentCity.formattedTroops,
            command_type: CommandTypesForTroopSelection.PATROL_OR_INTERCEPT,
            reinforcement_filters: {
                category: ReinforcementCategory.RECEIVED,
                filter_type: ReinforcementFilterType.CONTROL_LEVEL,
                filter_value: ReinforcementControlLevel.PARTIAL_OR_FULL,
                include_not_arrived: false,
            },
        });

        commit('setAwaitingResponse', true);
        axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{
                        patrolWithPlanes(
                            range: ${payload.range},
                            troops: ${getStringifiedTroops(payload.troops)},
                            ${payload.reinforcements ? 'reinforcements: ' + JSON.stringify(payload.reinforcements) + ', ' : ''},
                            attackOnSight: ${payload.attackOnSight},
                            duration: ${payload.duration},
                            cityId: "${state.currentCity._id}"
                        )
                    }`,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(fulfilled => {
                commit('setAwaitingResponse', false);
                if (fulfilled) {
                    commit('UPDATE_TROOP_NUMBERS', {
                        command_type: CommandTypesForTroopSelection.PATROL_OR_INTERCEPT,
                        reinforcement_filters: {
                            category: ReinforcementCategory.RECEIVED,
                            filter_type: ReinforcementFilterType.CONTROL_LEVEL,
                            filter_value: ReinforcementControlLevel.PARTIAL_OR_FULL,
                            include_not_arrived: false,
                        },
                    });
                }
            });
    },

    async sendInterceptingAttack({ commit, state }, payload) {
        addTroopsToPayload({
            payload,
            troops: state.currentCity.formattedTroops,
            command_type: CommandTypesForTroopSelection.PATROL_OR_INTERCEPT,
            reinforcement_filters: {
                category: ReinforcementCategory.RECEIVED,
                filter_type: ReinforcementFilterType.CONTROL_LEVEL,
                filter_value: ReinforcementControlLevel.PARTIAL_OR_FULL,
                include_not_arrived: false,
            },
        });

        commit('setAwaitingResponse', true);
        axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{
                        interceptingAttack(
                            attack: "${payload.attackId}",
                            troops: ${getStringifiedTroops(payload.troops)},
                            ${payload.reinforcements ? 'reinforcements: ' + JSON.stringify(payload.reinforcements) + ', ' : ''},
                            cityId: "${state.currentCity._id}"
                        )
                    }`,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(fulfilled => {
                commit('setAwaitingResponse', false);
                if (fulfilled) {
                    commit('UPDATE_TROOP_NUMBERS', {
                        command_type: CommandTypesForTroopSelection.PATROL_OR_INTERCEPT,
                        reinforcement_filters: {
                            category: ReinforcementCategory.RECEIVED,
                            filter_type: ReinforcementFilterType.CONTROL_LEVEL,
                            filter_value: ReinforcementControlLevel.PARTIAL_OR_FULL,
                            include_not_arrived: false,
                        },
                    });
                }
            });
    },

    async modifyResourcesPercentage({ commit, state }, data) {
        commit('setAwaitingResponse', true);

        let percentages = `[`;

        for (let i = 0; i < data.percentages.length; i++) {
            if (i === data.percentages.length - 1) {
                percentages += `${data.percentages[i]}]`;
            } else {
                percentages += `${data.percentages[i]}, `;
            }
        }

        await axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{
              modifyPercentagesStorage(percentages:${percentages}, cityId: "${state.currentCity._id}")
            }`,
                },
                { withCredentials: true },
            )
            .then(response => {
                commit('setAwaitingResponse', false);
                if (!response.data) return false;
            })
            .catch(e => {
                console.log(e);
            });
    },

    async fetchOutsideOffers({ commit }) {
        await axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `{
              getOffers ${parseTemplateForQuery(OFFERS_TEMPLATE)}}`,
                },
                { withCredentials: true },
            )
            .then(response => {
                if (response.data) {
                    console.log('OUTSIDE OFFERS:::', response.data?.data?.getOffers);
                    commit('setOutsideOffers', response.data?.data?.getOffers);
                }
            })
            .catch(e => {
                console.log(e);
            });
    },

    async sendBuildingCommand({ commit, state }, data) {
        commit('setAwaitingResponse', true);
        axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{
                  commands(name: "${data.name}", cityId: "${state.currentCity._id}")
                    }
                    `,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(() => {
                commit('setAwaitingResponse', false);
            });
    },

    async startTroopRecruitmentCommand({ commit, state }, data) {
        commit('setAwaitingResponse', true);
        axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{
                      troopCommand(
                          name: "${data.name}", 
                          numOfTroops: ${data.numOfTroops},
                          cityId: "${state.currentCity._id}"
                          )
                    }
                    
                    `,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(() => {
                commit('setAwaitingResponse', false);
            });
    },

    async makeOffer({ commit, state }, { wanted, offered, repeats, maximumTime }) {
        // wanted/offered: { name: string, quantity: number }, repeats: number, maximumTime: number
        commit('setAwaitingResponse', true);
        await axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{makeOffer(
                            resourceToOffer: {name: "${offered.name}",quantity: ${offered.quantity}},
                            resourceToGet: {name: "${wanted.name}", quantity: ${wanted.quantity}},
                            repeats: ${repeats},
                            maximumTime: ${maximumTime},
                            cityId: "${state.currentCity._id}")
                        }`,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(() => {
                commit('setAwaitingResponse', false);
                commit('lowerResources', { [offered.name]: offered.quantity });
            });
    },

    async acceptOffer({ commit, state }, { offer, repeats, resources }) {
        // offer: string, repeats: number, resources: {name: string, quantity: number}[]
        let resourcesString = `[`;

        for (let i = 0; i < resources.length; i++) {
            resourcesString += `{name: "${resources[i].name}", quantity: ${resources[i].quantity}}`;
            if (i === resources.length - 1) {
                resourcesString += `]`;
            } else {
                resourcesString += `, `;
            }
        }

        commit('setAwaitingResponse', true);
        await axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{
                            acceptOffer(
                            offer: "${offer}",
                            repeats: ${repeats},
                            resources: ${resourcesString},
                            cityId: "${state.currentCity._id}"),
                        }`,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(() => {
                commit('setAwaitingResponse', false);

                const ResourcesToLower = {};
                resources.forEach(resource => {
                    ResourcesToLower[resource.name] = resource.quantity;
                });

                commit('lowerResources', ResourcesToLower);
            });
    },

    async cancelOffer({ commit, getters }, { offer_id }) {
        commit('setAwaitingResponse', true);
        await axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{
                            cancelOffer(offerId: "${offer_id}")
                        }`,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(() => {
                commit('setAwaitingResponse', false);

                const Offer = getters.getCurrentCityOffers.find(offer => offer._id === offer_id);
                commit('increaseResources', { [Offer.resourceToOffer.name]: Offer.resourceToOffer.quantity });

                commit('removeOffer', offer_id);
            });
    },

    async sendResources({ commit, state }, { resources, destination }) {
        // offer: string, repeats: number, resources: {name: string, quantity: number}[]
        let resourcesString = `[`;

        for (let i = 0; i < resources.length; i++) {
            resourcesString += `{name: "${resources[i].name}", quantity: ${resources[i].quantity}}`;
            if (i === resources.length - 1) {
                resourcesString += `]`;
            } else {
                resourcesString += `, `;
            }
        }

        commit('setAwaitingResponse', true);
        await axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{
                            sendResources(resources: ${resourcesString},
                            destination: "${destination},
                            cityId: "${state.currentCity._id}"")
                        }`,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(() => {
                commit('setAwaitingResponse', false);
            });
    },

    async createCoalition({ commit }, { name, abbreviation }) {
        commit('setAwaitingResponse', true);
        await axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{createCoalition(name: "${name}", abbreviation: "${abbreviation}")}`,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(() => {
                commit('setAwaitingResponse', false);
            });
    },

    async dissolveCoalition({ commit }) {
        commit('setAwaitingResponse', true);
        await axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{dissolveCoalition}`,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(() => {
                commit('setAwaitingResponse', false);
            });
    },

    async invitePlayerToCoalition({ commit }, { user_id }) {
        commit('setAwaitingResponse', true);
        await axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{inviteUserToCoalition(id: "${user_id}")}`,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(() => {
                commit('setAwaitingResponse', false);
            });
    },

    async retractInviteToCoalition({ commit }, { invite_id }) {
        commit('setAwaitingResponse', true);
        await axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{retractInviteToCoalition(inviteId: "${invite_id}")}`,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(() => {
                commit('setAwaitingResponse', false);
                // remove invite from state
            });
    },

    async acceptCoalitionInvite({ commit }, { invite_id }) {
        commit('setAwaitingResponse', true);
        await axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{acceptInviteToCoalition(inviteId: "${invite_id}")}`,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(() => {
                commit('setAwaitingResponse', false);
            });
    },

    async leaveCoalition({ commit }) {
        commit('setAwaitingResponse', true);
        await axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{leaveCoalition}`,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(() => {
                commit('setAwaitingResponse', false);
            });
    },

    async changeCoalitionMemberRank({ commit }, { promote, userId }) {
        const MEMBER_DEFAULT_RIGHTS = [
            { permission: 'SendAllMessage', hasIt: false },
            { permission: 'Diplomacy', hasIt: false },
            { permission: 'Invitation', hasIt: false },
            { permission: 'Exclude_Members', hasIt: false },
            { permission: 'Modify_Members_Rights', hasIt: false },
            { permission: 'Modify_Coalition', hasIt: false },
            { permission: 'Dissolute_Coalition', hasIt: false },
        ];
        const LIEUTENANT_DEFAULT_RIGHTS = [
            { permission: 'SendAllMessage', hasIt: true },
            { permission: 'Diplomacy', hasIt: false },
            { permission: 'Invitation', hasIt: true },
            { permission: 'Exclude_Members', hasIt: true },
            { permission: 'Modify_Members_Rights', hasIt: true },
            { permission: 'Modify_Coalition', hasIt: false },
            { permission: 'Dissolute_Coalition', hasIt: false },
        ];
        commit('setAwaitingResponse', true);
        await axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{promoteOrDemoteMember(promote: ${promote}, id: "${userId}")}`,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(() => {
                commit('setAwaitingResponse', false);
                commit('updateMemberRank', { id: userId, rank: promote ? 'Lieutenant' : 'Member' });
                commit('updateMemberRights', {
                    ids: [userId],
                    permissions: promote ? [LIEUTENANT_DEFAULT_RIGHTS] : [MEMBER_DEFAULT_RIGHTS],
                });
            });
    },

    async kickCoalitionMember({ commit }, { userId }) {
        commit('setAwaitingResponse', true);
        await axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{kickMemberOutOfCoalition(userId: "${userId}")}`,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(() => {
                commit('setAwaitingResponse', false);
            });
    },

    async changeCoalitionRights({ commit }, { ids, permissions }) {
        function parsePermissions(array) {
            let string = '[';
            array.forEach(member_array => {
                string += '[';
                member_array.forEach(permission_object => {
                    string += '{';
                    const keys = Object.keys(permission_object);
                    const values = Object.values(permission_object);
                    keys.forEach((k, index) => {
                        string += `${k}: ${typeof values[index] === 'boolean' ? values[index] : '"' + values[index] + '"'},`;
                    });
                    string = string.substring(0, string.length - 1);
                    string += '},';
                });
                string = string.substring(0, string.length - 1);
                string += '],';
            });
            string = string.substring(0, string.length - 1);
            string += ']';
            return string;
        }

        commit('setAwaitingResponse', true);
        await axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{modifyRightsOfMembers(ids: ${JSON.stringify(ids)}, permissions: ${parsePermissions(
                        permissions,
                    )})}`,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(() => {
                commit('setAwaitingResponse', false);
                commit('updateMemberRights', { ids, permissions });
            });
    },

    async changeCoalitionProperties({ commit }, { properties }) {
        // properties: name, abbreviation, canAttackAllies
        commit('setAwaitingResponse', true);
        await axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{changedPropertiesOfCoalition(name: "${properties.name}", abbreviation: "${properties.abbreviation}", canAttackAllies: ${properties.canAttackAllies})}`,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(() => {
                commit('setAwaitingResponse', false);
            });
    },

    async changeCoalitionDescription({ commit }, { description }) {
        commit('setAwaitingResponse', true);
        await axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{changeDescriptionOfCoalition(description: "${description}")}`,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(() => {
                commit('setAwaitingResponse', false);
            });
    },

    async changeCoalitionEnlistmentOption({ commit }, { option }) {
        commit('setAwaitingResponse', true);
        await axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{changeCoalitionAdditionRule(additionRule: "${option}")}`,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(() => {
                commit('setAwaitingResponse', false);
            });
    },

    async joinCoalition({ commit }, { coalition_id }) {
        commit('setAwaitingResponse', true);
        await axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{enterCoalition(coalitionId: "${coalition_id}")}`,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(() => {
                commit('setAwaitingResponse', false);
            });
    },

    async requestToJoinCoalition({ commit }, { coalition_id }) {
        commit('setAwaitingResponse', true);
        await axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{requestToJoinCoalition(coalitionId: "${coalition_id}")}`,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(() => {
                commit('setAwaitingResponse', false);
            });
    },

    async acceptRequestToJoinCoalition({ commit }, { request_id }) {
        commit('setAwaitingResponse', true);
        await axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{acceptRequestToJoinCoalition(requestId: "${request_id}")}`,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(() => {
                commit('setAwaitingResponse', false);
            });
    },

    async rejectRequestToJoinCoalition({ commit }, { request_id }) {
        commit('setAwaitingResponse', true);
        await axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{rejectRequestToJoinCoalition(requestId: "${request_id}")}`,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(() => {
                commit('setAwaitingResponse', false);
                // remove request from state
            });
    },

    async retractRequestToJoinCoalition({ commit }, { request_id }) {
        commit('setAwaitingResponse', true);
        await axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{retractRequestToJoinCoalition(requestId: "${request_id}")}`,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(() => {
                commit('setAwaitingResponse', false);
                // remove request from state
            });
    },

    async rejectDiplomacyRequest({ commit }, { invite_id }) {
        commit('setAwaitingResponse', true);
        await axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{rejectInviteToPact(inviteId: "${invite_id}")}`,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(() => {
                commit('setAwaitingResponse', false);
                commit('updatePactInvitations', invite_id);
            });
    },

    async retractDiplomacyRequest({ commit }, { invite_id }) {
        commit('setAwaitingResponse', true);
        await axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{retractInviteToPact(inviteId: "${invite_id}")}`,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(() => {
                commit('setAwaitingResponse', false);
                commit('updatePactInvitations', invite_id);
            });
    },

    async declareWarToCoalition({ commit }, { coalition_id }) {
        commit('setAwaitingResponse', true);
        await axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{declareWarToCoalition(coalitionId: "${coalition_id}")}`,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(() => {
                commit('setAwaitingResponse', false);
            });
    },

    async proposePeaceWithCoalition({ commit }, { coalition_id, war_id, type_of_peace, tribute }) {
        commit('setAwaitingResponse', true);
        await axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{proposePeaceWithCoalition(coalitionId: "${coalition_id}", warId: "${war_id}", typeOfPeace: "${type_of_peace}", tribute: ${tribute})}`,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(() => {
                commit('setAwaitingResponse', false);
            });
    },

    async acceptPeaceProposal({ commit }, { invite_id }) {
        commit('setAwaitingResponse', true);
        await axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{acceptPeaceProposal(inviteId: "${invite_id}")}`,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(() => {
                commit('setAwaitingResponse', false);
                commit('updatePactInvitations', invite_id);
            });
    },

    async inviteCoalitionToNonAggressionPact({ commit }, { coalition_id }) {
        commit('setAwaitingResponse', true);
        await axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{inviteCoalitionToNonAggresionPact(coalitionId: "${coalition_id}")}`,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(() => {
                commit('setAwaitingResponse', false);
            });
    },

    async acceptInviteToNonAggressionPact({ commit }, { invite_id }) {
        commit('setAwaitingResponse', true);
        await axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{acceptInviteToNonAggresionPact(inviteId: "${invite_id}")}`,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(() => {
                commit('setAwaitingResponse', false);
                commit('updatePactInvitations', invite_id);
            });
    },

    async breakNonAggressionPact({ commit }, { coalition_id }) {
        commit('setAwaitingResponse', true);
        await axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{breakNonAggressionPact(coalitionId: "${coalition_id}")}`,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(() => {
                commit('setAwaitingResponse', false);
            });
    },

    async inviteCoalitionToAlliance({ commit }, { coalition_id }) {
        commit('setAwaitingResponse', true);
        await axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{inviteCoalitionToAlliance(coalitionId: "${coalition_id}")}`,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(() => {
                commit('setAwaitingResponse', false);
            });
    },

    async acceptInviteToAlliance({ commit }, { invite_id }) {
        commit('setAwaitingResponse', true);
        await axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{acceptInviteToAlliance(inviteId: "${invite_id}")}`,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(() => {
                commit('setAwaitingResponse', false);
                commit('updatePactInvitations', invite_id);
            });
    },

    async breakAlliance({ commit }, { coalition_id }) {
        commit('setAwaitingResponse', true);
        await axios
            .post(
                `${process.env.VUE_APP_ADDRESS_MAIN}`,
                {
                    query: `mutation{breakAlliance(coalitionId: "${coalition_id}")}`,
                },
                { withCredentials: true },
            )
            .catch(e => {
                console.log(e);
            })
            .then(() => {
                commit('setAwaitingResponse', false);
            });
    },

    lowerResourcesAfterBuildingCommand({ commit }, resources) {
        commit('lowerResources', resources);
    },

    assignCountdownStartedTroopCommands({ commit }, value) {
        commit('setCountdownStartedTroopCommands', value);
    },

    removeWarEffectsAction({ commit }, { war_id }) {
        console.log('remove');
        commit('removeWarEffects', { war_id });
    },
};

import CollectionItem from "@/classes/generic/CollectionItem";

export default class Collection extends CollectionItem {
	_items = new Set();

	constructor(items) {
		super();
		items.forEach(item => {
			this.addItem(item)
		});
	}

	getItems() {
		return this._items;
	}

	getFirstItem() {
		return Array.from(this._items)[0];
	}

	addItem(item) {
		item.addCollectionPointer(this)
		this._items.add(item);
	}

	deleteItem(item) {
		item.deleteCollectionPointer(this)
		this._items.delete(item);
	}
}
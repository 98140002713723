<template>
    <div v-if="!areBuildingRequirementsMet(troop)" class="building-requirements-section">
        <span class="requirements-title">Needed:</span>
        <template v-for="requirement in troop.getBuildingRequirements()" :key="requirement.name">
            <div v-if="!isBuildingRequirementMet(requirement)" class="requirements-group">
                <img
                    :src="
                        require(`../../assets/icons/buildings/${snakeCase(
                            requirement.name,
                        )}_mini_${getBuildingRequirementAppearance(requirement)}.avif`)
                    "
                    alt=""
                    class="requirement-icon"
                    @click="openBuildingDetailsModal(getBuildingFromRequirementName(requirement.name))"
                />
                <div class="requirement-name-wrapper">
                    <span
                        class="requirement-name"
                        @click="openBuildingDetailsModal(getBuildingFromRequirementName(requirement.name))"
                        >{{ requirement.name }}</span
                    >
                    <span class="requirement-level">Level {{ requirement.level }}</span>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import areBuildingRequirementsMet from '@/components/RecruitmentBuildings/helpers/areBuildingRequirementsMet';
import isBuildingRequirementMet from '@/components/RecruitmentBuildings/helpers/isBuildingRequirementMet';
import getBuildingRequirementAppearance from '@/components/RecruitmentBuildings/helpers/getBuildingRequirementAppearance';
import { snakeCase } from 'change-case';
import Troop from '@/classes/troops/Troop';

export default {
    name: 'BuildingRequirements',
    props: {
        troop: {
            type: Troop,
        },
    },
    methods: {
        isBuildingRequirementMet,
        areBuildingRequirementsMet,
        getBuildingRequirementAppearance,
        snakeCase,
    },
};
</script>

<style scoped></style>

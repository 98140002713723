import { BuildingIdentifier } from "@/utils/constants/city";
import getters from "@/store/modules/city/getters";
import state from "@/store/modules/city/state";

export default function getMaxTroopsThatCanBeRecruited(troop) {
    const Resources = getters.getResources(state);

    const Farm = getters.getBuildings(state)?.find(building => building.name === BuildingIdentifier.FARM);
    const MaxPopulation = Farm ? Farm?.perLevel[Farm?.level] : 0
    const AvailablePopulation = MaxPopulation - Resources?.population;

    const MaxTroopsForMetal = Math.floor(Resources?.metal / troop.getMetalCost());
    const MaxTroopsForOil = Math.floor(Resources?.oil / troop.getOilCost());
    const MaxTroopsForAluminum = Math.floor(Resources?.aluminum / troop.getAluminumCost());
    const MaxTroopsForPopulation = Math.floor(AvailablePopulation / troop.getPopulationCost());

    return Math.min(MaxTroopsForMetal, MaxTroopsForOil, MaxTroopsForAluminum, MaxTroopsForPopulation);
}

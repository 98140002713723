export const ActionTypeIdentifier = Object.freeze({
    INCOMING_ATTACK: 'INCOMING_ATTACK',
    OUTGOING_ATTACK: 'OUTGOING_ATTACK',
    RETURNING_ATTACK: 'RETURNING_ATTACK',

    INCOMING_ATTACK_WITH_GENERAL: 'INCOMING_ATTACK_WITH_GENERAL',
    OUTGOING_ATTACK_WITH_GENERAL: 'OUTGOING_ATTACK_WITH_GENERAL',
    RETURNING_ATTACK_WITH_GENERAL: 'RETURNING_ATTACK_WITH_GENERAL',

    INCOMING_REINFORCEMENT: 'INCOMING_REINFORCEMENT',
    OUTGOING_REINFORCEMENT: 'OUTGOING_REINFORCEMENT',
    RETURNING_REINFORCEMENT: 'RETURNING_REINFORCEMENT',

    DETECT_SPIES_MISSION: 'DETECT_SPIES_MISSION',
    INCOMING_SPY_MISSION: 'INCOMING_SPY_MISSION',
    OUTGOING_SPY_MISSION: 'OUTGOING_SPY_MISSION',
    RETURNING_SPIES: 'RETURNING_SPIES',

    PATROL: 'PATROL',

    INCOMING_INTERCEPT: 'INCOMING_INTERCEPT',
    OUTGOING_INTERCEPT: 'OUTGOING_INTERCEPT',
    RETURNING_INTERCEPT: 'RETURNING_INTERCEPT',

    INCOMING_TRADING_TRANSPORT: 'INCOMING_TRADING_TRANSPORT',
    OUTGOING_TRADING_TRANSPORT: 'OUTGOING_TRADING_TRANSPORT',
    RETURNING_TRADING_TRANSPORT: 'RETURNING_TRADING_TRANSPORT',

    INCOMING_SEND_RESOURCES_TRANSPORT: 'INCOMING_SEND_RESOURCES_TRANSPORT',
    OUTGOING_SEND_RESOURCES_TRANSPORT: 'OUTGOING_SEND_RESOURCES_TRANSPORT',
    RETURNING_SEND_RESOURCES_TRANSPORT: 'RETURNING_SEND_RESOURCES_TRANSPORT',
});

export const ActionTypesNotShownOnMap = Object.freeze([ActionTypeIdentifier.DETECT_SPIES_MISSION, ActionTypeIdentifier.PATROL]);

export const ActionDescriptionIdentifier = Object.freeze({
    INCOMING_ATTACK: 'Attack from',
    OUTGOING_ATTACK: 'Attacking',
    RETURNING_ATTACK: 'Attack returning from',

    INCOMING_ATTACK_WITH_GENERAL: 'Attack from',
    OUTGOING_ATTACK_WITH_GENERAL: 'Attacking',
    RETURNING_ATTACK_WITH_GENERAL: 'Attack returning from',

    INCOMING_REINFORCEMENT: 'Reinforcement from',
    OUTGOING_REINFORCEMENT: 'Reinforcing',
    RETURNING_REINFORCEMENT: 'Reinforcement returning from',

    DETECT_SPIES_MISSION: 'Detecting spies in',
    INCOMING_SPY_MISSION: 'Enemy spies from',
    OUTGOING_SPY_MISSION: 'Infiltrating',
    RETURNING_SPIES: 'Spies returning from',

    PATROL: 'Patrolling',

    INCOMING_INTERCEPT: 'Intercepting',
    OUTGOING_INTERCEPT: 'Enemy intercept targeting',
    RETURNING_INTERCEPT: 'Intercept returning',

    INCOMING_TRADING_TRANSPORT: 'Trade from',
    OUTGOING_TRADING_TRANSPORT: 'Trading with',
    RETURNING_TRADING_TRANSPORT: 'Transport returning from',

    INCOMING_SEND_RESOURCES_TRANSPORT: 'Receiving resources from',
    OUTGOING_SEND_RESOURCES_TRANSPORT: 'Sending resources to',
    RETURNING_SEND_RESOURCES_TRANSPORT: 'Transport returning from',
});

export const ActionDescriptionTargetIdentifier = Object.freeze({
    INCOMING_ATTACK: 'fromCity',
    OUTGOING_ATTACK: 'toCity',
    RETURNING_ATTACK: 'fromCity',

    INCOMING_ATTACK_WITH_GENERAL: 'fromCity',
    OUTGOING_ATTACK_WITH_GENERAL: 'toCity',
    RETURNING_ATTACK_WITH_GENERAL: 'fromCity',

    INCOMING_REINFORCEMENT: 'fromCity',
    OUTGOING_REINFORCEMENT: 'toCity',
    RETURNING_REINFORCEMENT: 'fromCity',

    DETECT_SPIES_MISSION: 'initiatedInsideCity',
    INCOMING_SPY_MISSION: 'fromCity',
    OUTGOING_SPY_MISSION: 'toCity',
    RETURNING_SPIES: 'fromCity',

    PATROL: 'fromCity',

    INCOMING_INTERCEPT: 'interceptedAttack',
    OUTGOING_INTERCEPT: 'interceptedAttack',
    RETURNING_INTERCEPT: 'toCity',

    INCOMING_TRADING_TRANSPORT: 'fromCity',
    OUTGOING_TRADING_TRANSPORT: 'toCity',
    RETURNING_TRADING_TRANSPORT: 'fromCity',

    INCOMING_SEND_RESOURCES_TRANSPORT: 'fromCity',
    OUTGOING_SEND_RESOURCES_TRANSPORT: 'toCity',
    RETURNING_SEND_RESOURCES_TRANSPORT: 'fromCity',
});

export const ActionInitiatorIdentifier = Object.freeze({
    INCOMING_ATTACK: 'fromCity',
    OUTGOING_ATTACK: 'fromCity',
    RETURNING_ATTACK: 'fromCity',

    INCOMING_ATTACK_WITH_GENERAL: 'fromCity',
    OUTGOING_ATTACK_WITH_GENERAL: 'fromCity',
    RETURNING_ATTACK_WITH_GENERAL: 'fromCity',

    INCOMING_REINFORCEMENT: 'fromCity',
    OUTGOING_REINFORCEMENT: 'fromCity',
    RETURNING_REINFORCEMENT: 'fromCity',

    DETECT_SPIES_MISSION: 'initiatedInsideCity',
    INCOMING_SPY_MISSION: 'fromCity',
    OUTGOING_SPY_MISSION: 'fromCity',
    RETURNING_SPIES: 'fromCity',

    PATROL: 'fromCity',

    INCOMING_INTERCEPT: 'toCity',
    OUTGOING_INTERCEPT: 'fromCity',
    RETURNING_INTERCEPT: 'fromCity',

    INCOMING_TRADING_TRANSPORT: 'fromCity',
    OUTGOING_TRADING_TRANSPORT: 'fromCity',
    RETURNING_TRADING_TRANSPORT: 'fromCity',

    INCOMING_SEND_RESOURCES_TRANSPORT: 'fromCity',
    OUTGOING_SEND_RESOURCES_TRANSPORT: 'fromCity',
    RETURNING_SEND_RESOURCES_TRANSPORT: 'fromCity',
});

export const ActionTargetIdentifier = Object.freeze({
    INCOMING_ATTACK: 'toCity',
    OUTGOING_ATTACK: 'toCity',
    RETURNING_ATTACK: 'toCity',

    INCOMING_ATTACK_WITH_GENERAL: 'toCity',
    OUTGOING_ATTACK_WITH_GENERAL: 'toCity',
    RETURNING_ATTACK_WITH_GENERAL: 'toCity',

    INCOMING_REINFORCEMENT: 'toCity',
    OUTGOING_REINFORCEMENT: 'toCity',
    RETURNING_REINFORCEMENT: 'toCity',

    DETECT_SPIES_MISSION: 'initiatedInsideCity',
    INCOMING_SPY_MISSION: 'toCity',
    OUTGOING_SPY_MISSION: 'toCity',
    RETURNING_SPIES: 'toCity',

    PATROL: 'fromCity',

    INCOMING_INTERCEPT: 'fromCity',
    OUTGOING_INTERCEPT: 'toCity',
    RETURNING_INTERCEPT: 'toCity',

    INCOMING_TRADING_TRANSPORT: 'toCity',
    OUTGOING_TRADING_TRANSPORT: 'toCity',
    RETURNING_TRADING_TRANSPORT: 'toCity',

    INCOMING_SEND_RESOURCES_TRANSPORT: 'toCity',
    OUTGOING_SEND_RESOURCES_TRANSPORT: 'toCity',
    RETURNING_SEND_RESOURCES_TRANSPORT: 'toCity',
});

export const CityProperties = Object.freeze({
    ATTACKS: 'attacks',
    RETURNING_ATTACKS: 'attacksReturning',
    INTERCEPTS: 'attackIntercepts',
    RETURNING_INTERCEPTS: 'attackInterceptsReturning',
    REINFORCEMENTS: 'reinforcements',
    RETURNING_REINFORCEMENTS: 'reinforcementsReturning',
    SPY_MISSIONS: 'spyMovements',
    RETURNING_SPY_MISSIONS: 'spyMovementsReturning',
    DETECT_SPIES_MISSIONS: 'detectSpiesMissions',
    PATROLS: 'patrols',
    TRANSPORTS: 'transports',
    RETURNING_TRANSPORTS: 'transportsReturning',

    FOREIGN_SPIES: 'foreignSpies',

    BUILDING_COMMANDS: 'commands',

    TROOP_COMMANDS_BARRACKS: 'troopCommandsBarracks',
    TROOP_COMMANDS_ARMORED: 'troopCommandsArmored',
    TROOP_COMMANDS_PLANES: 'troopCommandsPlanes',
    TROOP_COMMANDS_GENERAL: 'generalCommands',
});

export const CITY_ADDITIONAL_PROPERTIES = 'additionalProperties'

// city properties that are added on mount
export const AddedCityProperties = Object.freeze({
    BARRACKS_RECRUITMENT_COMMANDS: 'barracksRecruitmentCommands',
    ARMORED_RECRUITMENT_COMMANDS: 'armoredRecruitmentCommands',
    PLANE_RECRUITMENT_COMMANDS: 'planeRecruitmentCommands',
    GENERAL_RECRUITMENT_COMMANDS: 'generalRecruitmentCommands',
    ALL_RECRUITMENT_COMMANDS: 'allRecruitmentCommands',
});

export const CityPropertiesThatAreActions = Object.freeze([
    CityProperties.ATTACKS,
    CityProperties.RETURNING_ATTACKS,
    CityProperties.INTERCEPTS,
    CityProperties.RETURNING_INTERCEPTS,
    CityProperties.REINFORCEMENTS,
    CityProperties.RETURNING_REINFORCEMENTS,
    CityProperties.SPY_MISSIONS,
    CityProperties.RETURNING_SPY_MISSIONS,
    CityProperties.DETECT_SPIES_MISSIONS,
    CityProperties.PATROLS,
    CityProperties.TRANSPORTS,
    CityProperties.RETURNING_TRANSPORTS,
]);

export const OutgoingActions = Object.freeze([
    ActionTypeIdentifier.OUTGOING_ATTACK,
    ActionTypeIdentifier.OUTGOING_ATTACK_WITH_GENERAL,
    ActionTypeIdentifier.OUTGOING_INTERCEPT,
    ActionTypeIdentifier.OUTGOING_REINFORCEMENT,
    ActionTypeIdentifier.OUTGOING_SPY_MISSION,
    ActionTypeIdentifier.OUTGOING_TRADING_TRANSPORT,
    ActionTypeIdentifier.OUTGOING_SEND_RESOURCES_TRANSPORT,
]);

export const IncomingActions = Object.freeze([
    ActionTypeIdentifier.INCOMING_ATTACK,
    ActionTypeIdentifier.INCOMING_ATTACK_WITH_GENERAL,
    ActionTypeIdentifier.INCOMING_REINFORCEMENT,
    ActionTypeIdentifier.INCOMING_TRADING_TRANSPORT,
    ActionTypeIdentifier.INCOMING_SEND_RESOURCES_TRANSPORT,
]);

export const ReturningActions = Object.freeze([
    ActionTypeIdentifier.RETURNING_ATTACK,
    ActionTypeIdentifier.RETURNING_ATTACK_WITH_GENERAL,
    ActionTypeIdentifier.RETURNING_REINFORCEMENT,
    ActionTypeIdentifier.RETURNING_SPIES,
    ActionTypeIdentifier.RETURNING_INTERCEPT,
    ActionTypeIdentifier.RETURNING_TRADING_TRANSPORT,
    ActionTypeIdentifier.RETURNING_SEND_RESOURCES_TRANSPORT,
]);

export const ActionsThatAreIntercepts = Object.freeze([
    ActionTypeIdentifier.INCOMING_INTERCEPT,
    ActionTypeIdentifier.OUTGOING_INTERCEPT,
    ActionTypeIdentifier.RETURNING_INTERCEPT,
]);

export const ReturningAttackTypes = Object.freeze([
    ActionTypeIdentifier.RETURNING_ATTACK,
    ActionTypeIdentifier.RETURNING_ATTACK_WITH_GENERAL,
]);

export const MapArrowDirection = Object.freeze({
    INCOMING: 'incoming',
    OUTGOING: 'outgoing',
});

// UNUSED:
// 'indicator-white'

export const ActionPinCssClass = Object.freeze({
    INCOMING_ATTACK: 'indicator-red',
    OUTGOING_ATTACK: 'indicator-red',
    RETURNING_ATTACK: 'indicator-purple',

    INCOMING_ATTACK_WITH_GENERAL: 'indicator-black',
    OUTGOING_ATTACK_WITH_GENERAL: 'indicator-black',
    RETURNING_ATTACK_WITH_GENERAL: 'indicator-black',

    INCOMING_REINFORCEMENT: 'indicator-blue',
    OUTGOING_REINFORCEMENT: 'indicator-blue',
    RETURNING_REINFORCEMENT: 'indicator-lightblue',

    INCOMING_SPY_MISSION: 'indicator-orange',
    OUTGOING_SPY_MISSION: 'indicator-orange',
    RETURNING_SPIES: 'indicator-yellow',

    INCOMING_INTERCEPT: 'indicator-brown',
    OUTGOING_INTERCEPT: 'indicator-brown',
    RETURNING_INTERCEPT: 'indicator-gray',

    INCOMING_TRADING_TRANSPORT: 'indicator-dark-green',
    OUTGOING_TRADING_TRANSPORT: 'indicator-dark-green',
    RETURNING_TRADING_TRANSPORT: 'indicator-green',

    INCOMING_SEND_RESOURCES_TRANSPORT: 'indicator-dark-green',
    OUTGOING_SEND_RESOURCES_TRANSPORT: 'indicator-dark-green',
    RETURNING_SEND_RESOURCES_TRANSPORT: 'indicator-green',
});

export const ReinforcementType = Object.freeze({
    STATION: 'STATION',
    PARTIAL_CONTROL: 'PARTIAL_CONTROL',
    FULL_CONTROL: 'FULL_CONTROL'
});

export const ReinforcementCategory = Object.freeze({
    SENT: 'SENT',
    RECEIVED: 'RECEIVED',
    ALL: 'ALL',
    SPIES: 'SPIES',
});


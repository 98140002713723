<template>
    <CityUI
        :user="user"
        :all-user-cities="allUserCities"
        :all-users="allUsers"
        :all-cities="allCities"
        :troops="{ soldiers, tanks, planes, other }"
        :current-city-name="currentCityName"
        :current-city-tile="currentCityTile"
        :current-city-id="currentCityId"
        :current-city-coords="currentCityCoords"
        :buildings="buildings"
        :resources="resources"
        :resource-production="resourcesProductions"
        :map-opened="worldMapOpened"
        :world-map-scroll-coords="worldMapScrollCoords"
        :window-size="windowSize"
        :map-scale-factor="mapScaleFactor"
        :unit-types="{ soldiers, tanks, planes, other }"
        :map-actions="mapActions"
        :coalition-info="coalitionInfo"
        :coalition-invites="coalitionInvites"
        :nearby-coalitions="nearbyCoalitions"
        :requests-to-join-coalition="requestsToJoinCoalition"
        :set-current-troop="setCurrentTroop"
        :set-current-building="setCurrentBuilding"
        :map-loaded="mapLoaded"
        :awaiting-response="awaitingResponse"
        @open-map="openMap"
        @close-map="closeMap"
        @hover-changed="state => setHoverOnTabs(state)"
        @zoom-out="zoomOutOnButtonClick"
        @zoom-in="zoomInOnButtonClick"
        @map-centered="setMapCentered"
        @minimap-scroll="coords => setWorldMapScrollCoordsFromMinimap(coords)"
    />
    <Transition name="map-transition">
        <world-map
            v-if="worldMapOpened"
            :all-cities="allCities"
            :map-scale-factor="mapScaleFactor"
            :is-map-centered="isWorldMapCentered"
            :world-map-scroll-coords-from-minimap="worldMapScrollCoordsFromMinimap"
            :current-city-id="currentCityId"
            :current-city-coords="currentCityCoords"
            :map-actions="mapActions"
            @world-map-scroll="coords => setWorldMapScrollCoords(coords)"
            @window-resize="dimensions => setWindowSize(dimensions)"
        />
    </Transition>
    <Transition name="city-transition">
        <div
            v-if="!worldMapOpened"
            v-dragscroll
            class="main-div"
            style="overflow: hidden"
            :style="`
          transform: scale(${scaleFactor});
          width: ${(1 / scaleFactor) * 100}vw;
          margin-left: ${-((1 / scaleFactor) * 100 - 100) / 2}vw;
          height: ${(1 / scaleFactor) * 100}vh;
          margin-top: ${-((1 / scaleFactor) * 100 - 100) / 2}vh;
        `"
        >
            <picture>
                <source media="(max-width: 799px)" srcset="../assets/city_map_mobile.avif" />
                <source media="(min-width: 800px)" srcset="../assets/city_map_desktop.avif" />
                <img
                    src="../assets/city_map_desktop.avif"
                    alt="city"
                    class="background-city"
                    usemap="#city"
                    onmousedown="if (event.preventDefault) event.preventDefault()"
                />
            </picture>
            <CityBuildingsLayer
                :buildings="buildings"
                :troops="{ soldiers, tanks, planes, other }"
                @set-resource-modal-data="resource_building => setResourceModalData(resource_building)"
                @set-current-troop="troop => setCurrentTroop(troop)"
            />
        </div>
    </Transition>
	<Modal />
	<recruitment-modal
		v-if="!isFetching"
		:id="`recruitment-modal-barracks`"
		:recruitment-building="BuildingIdentifier.BARRACKS"
	/>
	<recruitment-modal
		v-if="!isFetching"
		:id="`recruitment-modal-armored-factory`"
		:recruitment-building="BuildingIdentifier.ARMORED_FACTORY"
	/>
	<recruitment-modal
		v-if="!isFetching"
		:id="`recruitment-modal-plane-factory`"
		:recruitment-building="BuildingIdentifier.PLANE_FACTORY"
	/>
    <resource-building-modal id="resource-building-modal" v-if="!isFetching" :building="resourceBuildingProp" />
    <main-building-modal
        id="main-building-modal"
        v-if="!isFetching"
        :awaiting-response="awaitingResponse"
        :buildings="buildings"
        :description="getBuildingDescription(BuildingIdentifier.MAIN_BUILDING)"
        :set-current-building="setCurrentBuilding"
    />
    <commercial-center-modal
        id="commercial-center-modal"
        v-if="!isFetching"
        :commercial-zone="commercialZone"
        :all-cities="allCities"
        :outside-offers="outsideOffers"
        :resources="resources"
        :current-city-coords="currentCityCoords"
        :current-city-id="currentCityId"
        :reinforcements="reinforcements"
        :map-actions="mapActions"
    />
    <!-- TO DO: map action commands should be created directly by the general office modal -->
    <generals-office-modal
        v-if="!isFetching"
        id="generals-office-modal"
        :awaiting-response="awaitingResponse"
        :city-id="currentCityId"
        :troops="{ soldiers, tanks, planes, other }"
        :buildings="buildings"
        :description="getBuildingDescription(BuildingIdentifier.GENERAL_OFFICE)"
        :set-current-troop="setCurrentTroop"
        :set-current-building="setCurrentBuilding"
        :map-actions="mapActions"
        :foreign-spies="foreignSpies"
        :current-city-name="currentCityName"
        :current-city-coords="currentCityCoords"
        :target-city="targetCity"
    />
    <storage-building-modal v-if="!isFetching" id="storage-building-modal" :building="getBuilding(BuildingIdentifier.STORAGE)" />
    <airport-modal
        v-if="!isFetching"
        id="airport-modal"
        :awaiting-response="awaitingResponse"
        :city-id="currentCityId"
        :current-city-name="currentCityName"
        :current-city-coords="currentCityCoords"
        :map-actions="mapActions"
        :attacks="attacks"
        :patrols="patrols"
        :intercepts="intercepts"
        :set-current-troop="setCurrentTroop"
        :send-patrol="sendPatrol"
        :send-intercepting-attack="sendInterceptingAttack"
    />
    <farm-modal v-if="!isFetching" id="farm-modal" :building="getBuilding(BuildingIdentifier.FARM)" />
    <wall-modal id="wall-modal" v-if="!isFetching" />
	<ConfigureActionModal
		v-if="!isFetching"
		id="configure-action-modal"
	/>
	<LeadershipModal
		v-if="!isFetching"
		id="leadership-modal"
	/>
    <troop-details-modal
        id="troop-details-modal"
        :displayed-troop="currentTroop"
        :troop-type="troopType"
        :building="recruitmentBuilding"
        :buildings="buildings"
        :set-current-building="setCurrentBuilding"
    />
    <building-details-modal
        v-if="!isFetching"
        id="building-details-modal"
        :building="currentBuilding"
        :buildings="buildings"
        :set-current-building="setCurrentBuilding"
    />
</template>

<script>
import subscriptionClass from '../utils/subscriptionClass';
import CityUI from '@/components/CityUI';
import { mapActions, mapGetters, mapMutations } from "vuex";
import RecruitmentModal from '@/components/RecruitmentBuildings/RecruitmentModal';
import ResourceBuildingModal from '@/components/ResourceBuildingModal';
import MainBuildingModal from '@/components/MainBuilding/MainBuildingModal';
import { paramCase } from 'change-case';
import CommercialCenterModal from '@/components/CommercialCenter/CommercialCenterModal';
import GeneralsOfficeModal from '@/components/GeneralsOffice/GeneralsOfficeModal';
import StorageBuildingModal from '@/components/StorageBuildingModal';
import AirportModal from '@/components/AirportModal';
import FarmModal from '@/components/FarmModal';
import WallModal from '@/components/WallModal';
import WorldMap from '@/components/WorldMap/WorldMap';
import TroopDetailsModal from '@/components/TroopDetailsModal';
import BuildingDetailsModal from '@/components/BuildingDetailsModal';
import { GENERAL_TEMPLATE } from '@/utils/state/templates/generalTemplate';
import parseTemplateForSubscription from '@/utils/state/parsers/parseTemplateForSubscription';
import CityBuildingsLayer from '@/components/CityBuildingsLayer';
import { BuildingIdentifier } from '@/utils/constants/city';
import { TroopsIdentifier } from '@/utils/constants/troops';
import Modal from "@/components/generic/Modal/Modal";
import ConfigureActionModal from "@/components/ConfigureActionModal";
import LeadershipModal from "@/components/LeadershipModal";

export default {
    name: 'CityView',
    data() {
        return {
            BuildingIdentifier,
            TroopsIdentifier,

            awaitingResponse: this.getAwaitingResponse(),
            user: {},
            allUserCities: [],
            allUsers: [],
            currentCityName: '',
            currentCityTile: null,
            currentCityId: null,
            currentCityCoords: null,
            attacks: [],
            returningAttacks: [],
            reinforcements: [],
            returningReinforcements: [],
            detectSpiesMissions: [],
            spyMissions: [],
            returningSpies: [],
            patrols: [],
            intercepts: [],
            returningIntercepts: [],
            transports: [],
            returningTransports: [],
            foreignSpies: [],
            buildings: [],
            currentBuilding: null,
            resources: [],
            resourcesProductions: [],
            currentTroop: null,
            allTroops: [],
            troopsCommands: [],
            coalitionInfo: {},
            reports: {},
            requestsToJoinCoalition: [],
            nearbyCoalitions: [],

            troopsCommandsToShow: new Array(7).fill(null),
            isFetching: true,

            // TO DO: troopType, recruitmentBuilding and troopsList
            // should be wrapped in an object
            troopType: 'soldiers',
            recruitmentBuilding: BuildingIdentifier.BARRACKS,
            troopsList: null,
            resourceBuildingProp: null,
            scaleFactor: 1,
            worldMapOpened: false,
            isHoverOnTabs: false,
            isWorldMapCentered: false,
            worldMapScrollCoords: {},
            worldMapScrollCoordsFromMinimap: {},
            windowSize: {
                width: window.innerWidth,
                height: window.innerHeight,
            },
            mapLoaded: false,

            mapActions: new Set(),
        };
    },
    components: {
		LeadershipModal,
		ConfigureActionModal,
        CityBuildingsLayer,
        BuildingDetailsModal,
        TroopDetailsModal,
        WorldMap,
        WallModal,
        FarmModal,
        AirportModal,
        StorageBuildingModal,
        GeneralsOfficeModal,
        CommercialCenterModal,
        ResourceBuildingModal,
        RecruitmentModal,
        MainBuildingModal,
        CityUI,
		Modal,
    },
    computed: {
        mapScaleFactor() {
            return this.getMapScaleFactor();
        },
        outsideOffers() {
            return this.getOutsideOffers();
        },
        allCities() {
            const CITIES = [];
            this.allUsers.forEach(user => {
                user.cities.forEach(city => {
                    CITIES.push({
                        _id: city._id,
                        name: city.name,
                        color: city.color,
                        points: city.points,
                        coords: {
                            x: city.x,
                            y: city.y,
                        },
                        owner: user.username,
                        isBarbarian: user.isBarbarian,
                    });
                });
            });
            console.log('ALL CITIES', CITIES);
            return CITIES;
        },
        soldiers() {
            return this.allTroops.filter(troop => troop.requirements.some(req => req.name === BuildingIdentifier.BARRACKS));
        },
        tanks() {
            return this.allTroops.filter(troop =>
                troop.requirements.some(req => req.name === BuildingIdentifier.ARMORED_FACTORY),
            );
        },
        planes() {
            return this.allTroops.filter(troop => troop.requirements.some(req => req.name === BuildingIdentifier.PLANE_FACTORY));
        },
        other() {
            return this.allTroops.filter(troop => troop.requirements.some(req => req.name === BuildingIdentifier.GENERAL_OFFICE));
        },
        commercialZone() {
            return this.buildings.find(building => building.name === BuildingIdentifier.COMMERCIAL_ZONE);
        },
        trucks() {
            return this.tanks.find(troop => troop.name === TroopsIdentifier.TRUCK);
        },
        targetCity() {
            return this.$store._state.data.city.mapActionTarget;
        },
        coalitionInvites() {
            return this.reports.coalitionInvites;
        },
    },
    methods: {
		...mapMutations(['setMapScaleFactor', 'incrementMapScaleFactor']),
        ...mapActions([
            'sendAttack',
            'sendReinforcement',
            'sendDetectSpiesMission',
            'sendSpyMission',
            'callBackSpies',
            'callBackReinforcements',
            'sendBackReinforcements',
            'sendPatrol',
            'sendInterceptingAttack',
            'getUserInformation',
            'fetchOutsideOffers',
            'assignCountdownStartedTroopCommands',
            'updateSubscriptionsAction',
            'updateDataLiveAction',
            'updateOffersLiveAction',
            'updateGameDataLiveAction',
            'getMap',
            'getAllUsers',
        ]),
        ...mapGetters([
            'getAwaitingResponse',
            'getAttacks',
            'getAttacksReturning',
            'getReinforcements',
            'getReinforcementsReturning',
            'getDetectSpiesMissions',
            'getSpyMissions',
            'getReturningSpies',
            'getPatrols',
            'getIntercepts',
            'getReturningIntercepts',
            'getTransports',
            'getReturningTransports',
            'getForeignSpies',
            'getLastAttack',
            'getBuildings',
            'getResources',
            'getResourcesProductions',
            'getTroops',
            'getIsSubscribed',
            'getUserInfo',
            'getAllUserCities',
            'getCurrentCityTile',
            'getCurrentCityName',
            'getCurrentCityId',
            'getCurrentCityCoords',
            'getTroopsCommands',
            'getAllUsersFromState',
            'getCoalitionInfo',
            'getReports',
            'getRequests',
            'getCloseCoalitions',
            'getOutsideOffers',
            'getCurrentCityMapActions',
			'getMapScaleFactor',
        ]),
        requestCityInfo() {
            const PARSED_TEMPLATE = parseTemplateForSubscription(GENERAL_TEMPLATE);
            this.getUserInformation().then(() => {
                this.user = this.getUserInfo();
                if (!this.getIsSubscribed()) {
                    subscriptionClass.subscribeFunc(
                        `subscription{data(userId: "${this.user._id}")${PARSED_TEMPLATE}}`,
                        this.updateDataLiveAction,
                    );
                    //TO DO
                    //MAKE THIS READ FROM THE TEMPLATE ALSO
                    subscriptionClass.subscribeFunc(
                        `subscription{offer(userId: "${this.user._id}"){_id, fromUser {_id, username}, fromCity {_id, name}, resourceToOffer {name, quantity}, resourceToGet {name, quantity}, x, y, repeats, maximumTime, dateCreated, actions, toReplace}}`,
                        this.updateOffersLiveAction,
                    );

                    //TO DO
                    //MAKE THIS READ FROM THE TEMPLATE ALSO
                    subscriptionClass.subscribeFunc(
                        `subscription{gameData{_id,username,cities{_id,x,y,name,points,color},points,ranking,coalition{_id,name,abbreviation,numOfMembers,points,maxNumOfMembers,additionRule},isBarbarian,paths,indexes,actions,toReplace}}`,
                        this.updateGameDataLiveAction,
                    );
                    this.updateSubscriptionsAction();
                }

                this.allUserCities = this.getAllUserCities();
                this.currentCityTile = this.getCurrentCityTile();
                this.currentCityName = this.getCurrentCityName();
                this.currentCityId = this.getCurrentCityId();
                this.currentCityCoords = this.getCurrentCityCoords();
                this.buildings = this.getBuildings();
                this.resources = this.getResources();
                this.resourcesProductions = this.getResourcesProductions();
                this.allTroops = this.getTroops();
                this.troopsList = this.soldiers;
                this.attacks = this.getAttacks();
                this.returningAttacks = this.getAttacksReturning();
                this.reinforcements = this.getReinforcements();
                this.returningReinforcements = this.getReinforcementsReturning();
                this.detectSpiesMissions = this.getDetectSpiesMissions();
                this.spyMissions = this.getSpyMissions();
                this.returningSpies = this.getReturningSpies();
                this.patrols = this.getPatrols();
                this.intercepts = this.getIntercepts();
                this.returningIntercepts = this.getReturningIntercepts();
                this.transports = this.getTransports();
                this.returningTransports = this.getReturningTransports();
                console.log('TRANSPORTS >>>>', this.transports);
                this.foreignSpies = this.getForeignSpies();
                this.troopsCommands = this.getTroopsCommands();
                this.coalitionInfo = this.getCoalitionInfo();
                this.reports = this.getReports();
                this.requestsToJoinCoalition = this.getRequests();
                this.nearbyCoalitions = this.getCloseCoalitions();
                this.mapActions = this.getCurrentCityMapActions();
                this.isFetching = false;

                this.fetchOutsideOffers();
            });
        },

        onResize() {
            this.scaleFactor = Math.max(window.innerWidth / 1920, window.innerHeight / 1080);
        },

        setMapCentered() {
            this.isWorldMapCentered = true;
            setTimeout(() => {
                this.isWorldMapCentered = false;
            }, 250);
        },

        setHoverOnTabs(state) {
            this.isHoverOnTabs = state;
        },

        changeZoomOnScroll(event) {
            if (document.getElementsByTagName('body')[0].classList.contains('modal-open') || this.isHoverOnTabs) {
                return;
            }
            if (this.mapScaleFactor < 0.5) {
                this.$store.commit('setMapScaleFactor', 0.5);
            } else if (this.mapScaleFactor > 1.3) {
                this.$store.commit('setMapScaleFactor', 1.3);
            }
            if (event.deltaY < 0 && this.mapScaleFactor < 1.3) {
                this.$store.commit('incrementMapScaleFactor', 0.025);
            } else if (event.deltaY > 0 && this.mapScaleFactor > 0.5) {
                this.$store.commit('incrementMapScaleFactor', -0.025);
            }
        },

        zoomOutOnButtonClick() {
            this.$store.commit('incrementMapScaleFactor', -0.1);
            if (this.mapScaleFactor < 0.5) {
                this.$store.commit('setMapScaleFactor', 0.5);
            }
        },

        zoomInOnButtonClick() {
            this.$store.commit('incrementMapScaleFactor', 0.1);
            if (this.mapScaleFactor > 1.3) {
                this.$store.commit('setMapScaleFactor', 1.3);
            }
        },

        setWorldMapScrollCoords(coords) {
            this.worldMapScrollCoords = coords;
        },

        setWorldMapScrollCoordsFromMinimap(coords) {
            this.worldMapScrollCoordsFromMinimap = coords;
        },

        setWindowSize(dimensions) {
            this.windowSize = {
                width: dimensions.width,
                height: dimensions.height,
            };
        },

        getBuilding(name) {
            return this.buildings.find(building => building.name === name);
        },

        getBuildingDescription(name) {
            const allBuildings = this.buildings;
            const selectedBuilding = allBuildings.find(building => building.name === name);
            return selectedBuilding?.description;
        },

        openMap() {
			this.$store.commit('setMapScaleFactor', 1)
            this.worldMapOpened = true;
            window.addEventListener('wheel', this.changeZoomOnScroll);
        },

        closeMap() {
            this.worldMapOpened = false;
            window.removeEventListener('wheel', this.changeZoomOnScroll);
        },

        setRecruitmentModalData(building_name, type, troops_list) {
            this.recruitmentBuilding = building_name;
            this.troopType = type;
            this.troopsList = troops_list;
        },

        setResourceModalData(resource_building) {
            this.resourceBuildingProp = resource_building;
        },

        toKebabCase(string) {
            return paramCase(string);
        },

        setCurrentTroop(troop) {
            this.currentTroop = troop;
        },

        setCurrentBuilding(building) {
            this.currentBuilding = building;
        },
    },
    watch: {
        allCities: {
            handler() {
                if (this.allCities.length) {
                    this.mapLoaded = true;
                }
            },
            deep: true,
        },

        '$store._state.data.city.currentCity.buildings': {
            handler() {
                this.buildings = this.getBuildings();
            },
            deep: true,
        },

        '$store._state.data.city.currentCity.aluminum': {
            handler(newVal) {
                this.resources.aluminum = newVal;
            },
        },

        '$store._state.data.city.currentCity.oil': {
            handler(newVal) {
                this.resources.oil = newVal;
            },
        },

        '$store._state.data.city.currentCity.metal': {
            handler(newVal) {
                this.resources.metal = newVal;
            },
        },

        '$store._state.data.city.currentCity.rations': {
            handler(newVal) {
                this.resources.rations = newVal;
            },
        },

        '$store._state.data.city.currentCity._id': {
            handler() {
                // TO DO: see how switching cities affects countdown intervals (troops, buildings, map actions)
                this.currentCityTile = this.getCurrentCityTile();
                this.currentCityName = this.getCurrentCityName();
                this.currentCityId = this.getCurrentCityId();
                this.currentCityCoords = this.getCurrentCityCoords();
                this.buildings = this.getBuildings();
                this.resourcesProductions = this.getResourcesProductions();
                this.allTroops = this.getTroops();
                this.attacks = this.getAttacks();
                this.returningAttacks = this.getAttacksReturning();
                this.reinforcements = this.getReinforcements();
                this.returningReinforcements = this.getReinforcementsReturning();
                this.detectSpiesMissions = this.getDetectSpiesMissions();
                this.spyMissions = this.getSpyMissions();
                this.returningSpies = this.getReturningSpies();
                this.patrols = this.getPatrols();
                this.intercepts = this.getIntercepts();
                this.returningIntercepts = this.getReturningIntercepts();
                this.foreignSpies = this.getForeignSpies();
                this.troopsCommands = this.getTroopsCommands();
                this.nearbyCoalitions = this.getCloseCoalitions();
            },
        },

        '$store._state.data.city.awaitingResponse': {
            handler() {
                this.awaitingResponse = this.getAwaitingResponse();
            },
        },

        currentTroop: {
            handler() {
                const troops = [this.soldiers, this.tanks, this.planes, this.other];
                for (let i = 0; i < troops.length; i++) {
                    if (troops[i].find(troop => troop.name === this.currentTroop.name)) {
                        switch (i) {
                            case 0:
                                this.setRecruitmentModalData(BuildingIdentifier.BARRACKS, 'soldiers', troops[i]);
                                break;
                            case 1:
                                this.setRecruitmentModalData(BuildingIdentifier.ARMORED_FACTORY, 'tanks', troops[i]);
                                break;
                            case 2:
                                this.setRecruitmentModalData(BuildingIdentifier.PLANE_FACTORY, 'planes', troops[i]);
                                break;
                            case 3:
                                this.setRecruitmentModalData(BuildingIdentifier.BARRACKS, 'soldiers', troops[i]);
                                break;
                        }
                        return;
                    }
                }
            },
        },
    },
    created() {
        setTimeout(() => {
            this.onResize();
            window.addEventListener('resize', this.onResize);
            window.addEventListener('orientationchange', this.onResize);
        }, 0);
        this.getAllUsers().then(() => {
            this.allUsers = this.getAllUsersFromState();
            this.getMap();
        });
        this.requestCityInfo();
        console.log('$STORE', this.$store.state);
    },
};
</script>

<style scoped>
.map-transition-enter-active {
    animation: fade-map 0.5s ease-in-out;
}
.map-transition-leave-active {
    animation: fade-map 0.5s ease-in-out reverse;
}

.city-transition-enter-active {
    animation: fade-city 0.5s ease-in-out;
}
.city-transition-leave-active {
    animation: fade-city 0.5s ease-in-out reverse;
}

@keyframes fade-map {
    from {
        transform: scale(8);
    }

    to {
        transform: scale(1); /* don't know why this v-bind doesn't work here :(, need to research further */
    }
}

/*@keyframes fade-map-reverse {*/
/*    from {*/
/*        transform: scale(v-bind(mapScaleFactor)); !* here it works :) *!*/
/*    }*/

/*    to {*/
/*        transform: scale(8);*/
/*    }*/
/*}*/

@keyframes fade-city {
    from {
        opacity: 0;
        transform: scale(v-bind(scaleFactor / 8));
        /*border-radius: 50%;*/
        /*filter: blur(3px);*/
    }

    to {
        opacity: 1;
        transform: scale(v-bind(scaleFactor));
        /*border-radius: 0;*/
        /*filter: blur(0);*/
    }
}

.background-city {
    position: absolute;
    width: 1920px;
    height: 1080px;
    top: 0;
    left: 0;
}

.main-div {
    width: 100vw;
    height: 100vh;
    max-height: 100%;
    position: relative;
	/*filter: brightness(0.15);*/
}
</style>

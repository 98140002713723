<template>
    <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" tabindex="-1" role="dialog" data-bs-focus="false">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Configure Action</h4>
                    <button type="button" class="close" aria-label="Close" @click="closeModal">
                        <img src="../assets/close_icon.svg" width="20" height="20" aria-hidden="true" />
                    </button>
                </div>
                <div class="modal-body">
                    <template v-if="isAtLeastOneGeneral">
                        <span>Select general to send:</span>
                        <GeneralSelectRow />
                    </template>
                    <div class="mb-1">Select offensive strategy:</div>
                    <StrategySelect @strategy-change="setNewOffensiveStrategy" :show-no-selection-option="false" />
                    <div class="d-flex justify-content-center gap-3 mt-5">
                        <button class="btn yellow-button" @click="closeModal">Cancel</button>
                        <button class="btn red-button" @click="validateAndSendAttack">Attack</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import GeneralSelectRow from '@/components/generic/TroopSelect/GeneralSelectRow';
import { TroopsIdentifier } from '@/utils/constants/troops';
import StrategySelect from '@/components/generic/Strategy/StrategySelect';

export default {
    name: 'ConfigureActionModal',
    components: { GeneralSelectRow, StrategySelect },
    data() {
        return {
            TroopsIdentifier,

            offensiveStrategy: false,
        };
    },
    computed: {
        targetCity() {
            return this.$store._state.data.city.mapActionTarget;
        },
		general() {
			return this.$store.getters.getCurrentCityFormattedTroops?.find(
				troop => troop.getName() === TroopsIdentifier.GENERAL,
			)
		},
        isAtLeastOneGeneral() {
            return this.general?.getGenerals().length;
        },
    },
    methods: {
        setNewOffensiveStrategy(strategy) {
            this.offensiveStrategy = strategy;
        },
        closeModal() {
            document.getElementById('configure-action-modal').style.transition =
                'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
            document.getElementById('configure-action-modal').style.transform = 'translateY(-20px)';
            document.getElementById('configure-action-modal').style.opacity = '0';
            setTimeout(() => {
                document.getElementById('configure-action-modal').style.display = 'none';
                document.getElementById('configure-action-modal').classList.remove('show');
                document.getElementById('configure-action-modal').style.removeProperty('transform');
                document.getElementById('configure-action-modal').style.removeProperty('opacity');
                document.body.classList.remove('modal-open');
            }, 150);
        },
        validateAndSendAttack() {
            this.$store.dispatch('sendAttack', {
                attackedCity: this.targetCity._id,
                isBarbarian: this.targetCity.isBarbarian,
                targetBuildings: [],
                oil: 10,
                rations: 10,
                strategy: this.offensiveStrategy.name,
                general: this.general?.getSelectedGeneralName(),
            });
            this.closeModal();
        },
    },
};
</script>

<style scoped>
.modal {
    background: rgba(0, 0, 0, 0.5);
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.modal::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.modal::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.modal-dialog {
    max-width: 750px;
}

.modal-header {
    border: none;
    display: flex;
    flex-direction: column;
}

.modal-header {
    background: linear-gradient(to right, rgba(0, 0, 0, 0), black, rgba(0, 0, 0, 0));
    display: flex;
    justify-content: center;
    padding: 0;
}

.modal-title {
    width: 100%;
    text-align: center;
}

.close {
    position: absolute;
    top: 16px;
    right: 24px;
    border: none;
    background: none;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.close:hover {
    opacity: 1;
}

.modal-content {
    font-family: 'sansation', sans-serif;
    top: 8vh;
    padding: 4rem;
    background-image: url('../assets/background.avif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0;
    color: ghostwhite;
    border: none;
}

.modal-body {
    padding-top: 3rem;
}

.btn {
    padding: 0.25rem 1rem;
    width: fit-content;
    height: fit-content;
}

.red-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.15), rgba(255, 200, 200, 0.15));
    border: 3px solid brown;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
}

.red-button:hover {
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.2), rgba(255, 200, 200, 0.2));
    color: ghostwhite;
}

.yellow-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.15), rgba(255, 255, 255, 0.15));
    border: 3px solid burlywood;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
}

.yellow-button:hover {
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.2), rgba(255, 255, 255, 0.2));
    color: ghostwhite;
}

@media screen and (max-width: 991px) {
    .modal-dialog {
        max-width: 74%;
    }
    .modal-content {
        padding: 3rem 1rem;
    }
}

@media screen and (max-width: 576px) {
    .modal-dialog {
        max-width: 90%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 450px) {
    .modal-content {
        padding: 3rem 0;
    }
}

@media screen and (orientation: landscape) and (max-height: 800px) {
    .modal-content {
        transform: scale(0.8);
        top: -10vh;
    }
}
</style>
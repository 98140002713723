<template>
    <div class="command">
        <Transition name="blur-transition">
            <div v-if="isTooltipVisible" class="command-tooltip">
                <span class="tooltip-name">{{ command.getTroopName() }}</span>
                <span>{{ command.getFinishTimeString() }}</span>
            </div>
        </Transition>
        <div class="image-wrapper" @mouseover="setTooltipVisible(true)" @mouseleave="setTooltipVisible(false)">
            <img :src="require(`../../assets/icons/troops/${paramCase(command.getTroopName())}-tall.avif`)" alt="" />
			<div class="remaining-troops">{{ command?.getRemainingTroopsToRecruit() }}</div>
        </div>
        <div class="placed-command-info-wrapper">
            <span class="placed-command-total-time">{{ command.getRemainingTimeString() }}</span>
            <div class="d-flex flex-row gap-1">
                <button class="btn command-button">Cancel</button>
                <button class="btn premium-command-button">
                    <img src="../../assets/icons/aluminum_icon.svg" class="premium-icon" />
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import RecruitmentCommand from '@/classes/recruitmentCommands/RecruitmentCommand';
import { paramCase } from "change-case";

export default {
    name: 'RecruitmentCommandCard',
    props: {
        command: {
            type: RecruitmentCommand,
        },
    },
    data() {
        return {
            isTooltipVisible: false,
        };
    },
	methods: {
        paramCase,

        setTooltipVisible(value) {
            this.isTooltipVisible = value;
        },
    },
};
</script>

<style scoped>
.command {
    position: relative;
    display: flex;
    gap: 2px;
    background: black;
    padding: 2px;
    outline: 2px solid #434343;
}

.image-wrapper {
	position: relative;
	width: 3rem;
	height: calc(6rem - 2px);
}

.image-wrapper img {
    height: 100%;
    width: 100%;
}

.remaining-troops {
	position: absolute;
	bottom: 0;
	background: black;
	width: 100%;
	line-height: 1.5rem;
	font-size: 0.75rem;
	text-align: center;
}

.placed-command-info-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    padding: 0 0.375rem;
    background: linear-gradient(to bottom, #2a2a2a, #181818);
}

.command-button {
    line-height: 1.5rem;
    font-size: 0.875rem;
    padding: 0 0.375rem;
}

.placed-command-total-time {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    border: 2px solid #606060;
    font-size: 0.875rem;
    line-height: 1.5rem;
    padding: 0 1rem;
}

.command-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.15), rgba(255, 200, 200, 0.15));
    border: 3px solid brown;
    font-size: 1rem;
    line-height: 1rem;
    color: ghostwhite;
    width: 100%;
}

.command-button:hover {
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.2), rgba(255, 200, 200, 0.2));
    color: ghostwhite;
}

.premium-command-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.15), rgba(255, 255, 255, 0.15));
    border: 3px solid burlywood;
    font-size: 1rem;
    line-height: 1rem;
    color: ghostwhite;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.premium-command-button:hover {
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.2), rgba(255, 255, 255, 0.2));
    color: ghostwhite;
}

.premium-icon {
    height: 0.875rem;
}

.command-tooltip {
    z-index: 1;
    position: absolute;
    background: black;
    color: ghostwhite;
    font-size: 0.75rem;
    line-height: 0.75rem;
    bottom: 110%;
    display: flex;
    border: 1px solid #434343;
    padding: 1rem;
    gap: 1rem;
    align-items: center;
    flex-direction: column;
}

.tooltip-name {
    font-size: 1rem;
    padding: 0.25rem 1rem;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0), lightgray, rgba(0, 0, 0, 0)) 1;
    border-left: none;
    border-right: none;
    border-top: none;
}
</style>

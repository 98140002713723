<template>
    <div class="commands-wrapper">
        <BuildingCommandCard v-for="command in buildingCommands" :key="command.getId()" :command="command" />
        <div v-for="(slot, index) in emptySlots" :key="index" class="command-placeholder">
            <img src="../../assets/icons/construction_icon.svg" class="placeholder-icon" />
        </div>
    </div>
</template>

<script>
import BuildingCommandCard from '@/components/MainBuilding/BuildingCommandCard';
export default {
    name: 'BuildingCommands',
    components: { BuildingCommandCard },
    computed: {
        buildingCommands() {
            return this.$store.getters.getBuildingCommands;
        },
		numberOfCommands() {
			return this.buildingCommands?.size || 0;
		},
        maxCommands() {
            return 7;
        },
        emptySlots() {
			return new Array(this.maxCommands - this.numberOfCommands);
        },
    },
};
</script>

<style scoped>
.commands-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 3rem;
    margin-bottom: 3rem;
    padding-left: 2px;
}

.command-placeholder {
    background: linear-gradient(to bottom, #2a2a2a, #181818);
    width: 6rem;
    height: calc(6rem + 2px);
    display: flex;
    justify-content: center;
    align-items: center;
    outline: 2px solid #434343;
    border: 2px solid black;
}

.placeholder-icon {
    height: 1.75rem;
    filter: contrast(0);
}
</style>

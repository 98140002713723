export default class BuildingProgress {
    _commandReference = null;

    _remainingTimeObject = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    };
    _remainingTimeString = '';

    _currentTimeoutId = null;

    constructor(command) {
        this._commandReference = command;
        this._initializeRemainingTimeObject();
        this._updateRemainingTimeString();
    }

    startProgressUpdate() {
        this._updateRemainingTimeObject();
        this._updateRemainingTimeString();

        this._currentTimeoutId = setTimeout(() => this._updateProgress(), 1000);
    }

    stopProgressUpdate() {
        clearTimeout(this._currentTimeoutId);
        this._currentTimeoutId = null;
    }

    getRemainingTimeString() {
        return this._remainingTimeString;
    }

    _buildRemainingTimeObject(remaining_time) {
        return {
            days: Math.floor(remaining_time / (1000 * 60 * 60 * 24)),
            hours: Math.floor((remaining_time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
            minutes: Math.floor((remaining_time % (1000 * 60 * 60)) / (1000 * 60)),
            seconds: Math.floor((remaining_time % (1000 * 60)) / 1000),
        };
    }

    _initializeRemainingTimeObject() {
        this._remainingTimeObject = this._buildRemainingTimeObject(this._getStartingTime());
    }

    _updateRemainingTimeObject() {
        this._remainingTimeObject = this._buildRemainingTimeObject(this._getRemainingTime());
    }

    _subtractOneSecondFromRemainingTimeObject() {
        if (this._remainingTimeObject.seconds > 0) {
            this._remainingTimeObject.seconds--;
        } else {
            this._remainingTimeObject.seconds = 59;
            if (this._remainingTimeObject.minutes > 0) {
                this._remainingTimeObject.minutes--;
            } else {
                this._remainingTimeObject.minutes = 59;
                if (this._remainingTimeObject.hours > 0) {
                    this._remainingTimeObject.hours--;
                } else {
                    this._remainingTimeObject.hours = 23;
                    if (this._remainingTimeObject.days > 0) {
                        this._remainingTimeObject.days--;
                    } else {
                        this._remainingTimeObject.seconds = 0;
                        this._remainingTimeObject.minutes = 0;
                        this._remainingTimeObject.hours = 0;
                        this._remainingTimeObject.days = 0;
                    }
                }
            }
        }
    }

    _updateRemainingTimeString() {
        this._remainingTimeString = `
            ${this._remainingTimeObject.days ? this._remainingTimeObject.days + 'd ' : ''}
            ${this._remainingTimeObject.hours ? this._remainingTimeObject.hours + 'h ' : ''}
            ${this._remainingTimeObject.minutes ? this._remainingTimeObject.minutes + 'm ' : ''}
            ${this._remainingTimeObject.seconds ? this._remainingTimeObject.seconds + 's' : ''}
        `;
    }

    _getStartingTime() {
        // time comes in seconds and needs to be in miliseconds
        return this._commandReference?._originalData?.time * 1000;
    }

    _getRemainingTime() {
        const Now = new Date().getTime();
        return this._getFinishTimestamp() - Now;
    }

    _getFinishTimestamp() {
        return this._formatTimestamp(this._commandReference?._originalData?.finishDate);
    }

    // might not need this in the future
    _formatTimestamp(timestamp) {
        return typeof timestamp === 'string' ? parseInt(timestamp) : timestamp;
    }

    _updateProgress() {
        if (this._hasFinished()) {
            return this._commandReference.selfDestruct();
        }

        this._subtractOneSecondFromRemainingTimeObject();
        this._updateRemainingTimeString();

        this._currentTimeoutId = setTimeout(() => this._updateProgress(), 1000);
    }

    _hasFinished() {
        return this._getRemainingTime() <= 1000;
    }
}

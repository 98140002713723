<template>
    <div class="t-row">
        <div class="t-col troop-image-column">
            <GeneralImage :general-name="general?.getSelectedGeneralName()" :is-name-visible="false" />
        </div>
        <div class="t-col">
            <select class="select-input" @change="setSelectedGeneral">
                <!-- using false as value here instead of null/undefined because value is set to
				option text content if value is interpreted as missing (eg. is null or undefined) -->
                <option :value="false">No selection</option>
                <option
                    v-for="general_option in general?.getGenerals()"
                    :key="general_option.name"
                    :value="general_option.name"
                    :selected="general.getSelectedGeneralName() === general_option.name"
                >
                    {{ general_option.name }}
                </option>
            </select>
        </div>
    </div>
    <div class="general-info">
        {{ general.getSelectedGeneralBonusString() }}
    </div>
</template>

<script>
import { TroopsIdentifier } from '@/utils/constants/troops';
import GeneralImage from "@/components/generic/GeneralImage";

export default {
    name: 'GeneralSelectRow',
    components: { GeneralImage },
    data() {
        return {
            TroopsIdentifier,
        };
    },
    computed: {
        general() {
            return this.$store.getters.getCurrentCityFormattedTroops?.find(troop => troop.getName() === TroopsIdentifier.GENERAL);
        },
    },
    methods: {
        setSelectedGeneral(event) {
            const GeneralName = event?.target?.value;
            this.general.setSelectedGeneral(GeneralName);
        },
    },
};
</script>

<style scoped>
.troop-image-column {
    width: fit-content;
}

.general-info {
    min-height: 2rem;
    font-size: 0.875rem;
    color: navajowhite;
}
</style>

<template>
    <div class="command-group">
        <div class="map-actions-city-icon-wrapper">
            <!-- TO DO: add dynamic city image -->
            <img
                v-if="commandType === CommandTypes.MAP_ACTION"
                src="../../../assets/map_tiles/city_2_mobile.avif"
                class="map-actions-city-icon"
            />
            <img
                v-else-if="commandType === CommandTypes.BUILDING_COMMAND"
                :src="
                    require(`../../../assets/icons/buildings/${snakeCase(
                        command?.getBuildingName(),
                    )}_mini_${command?.getNextLevelBuildingAppearance()}.avif`)
                "
                alt=""
                @click="openBuildingDetailsModal()"
				class="map-actions-city-icon"
			/>
			<img
				v-else-if="commandType === CommandTypes.RECRUITMENT_COMMAND"
				:src="
                    require(`../../../assets/icons/troops/${paramCase(
                        command?.getTroopName(),
                    )}-tall.avif`)
                "
				alt=""
				@click="openBuildingDetailsModal()"
				class="map-actions-city-icon"
			/>
        </div>
        <span v-if="commandType === CommandTypes.MAP_ACTION" class="command-target-wrapper">
            <span @click="openMapActionModal(map_action)">{{ command?.actionDescription }}</span>
            <span class="command-target">{{ command?.target?.name }}</span>
        </span>
        <span v-else-if="commandType === CommandTypes.BUILDING_COMMAND" class="command-target-wrapper">
            <span>{{ command?.getBuildingName() }} Lvl. {{ command?.getLevelToBuild() }}</span>
        </span>
        <span class="command-duration">{{ command?.getRemainingTimeString() }}</span>
        <span class="command-finish">{{ command?.getFinishTimeString() }}</span>
    </div>
</template>

<script>
import { CommandTypes } from '@/components/generic/CommandRowCityUI/types';
import { paramCase, snakeCase } from "change-case";

export default {
    name: 'CommandRowCityUI',
    props: {
        commandType: {
            type: String,
        },
        command: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            CommandTypes,
        };
    },
    methods: {
        snakeCase,
		paramCase,
    },
};
</script>

<style scoped>
.command-target-wrapper {
    display: flex;
    flex-direction: column;
    min-width: 8rem;
}

.command-target {
    color: burlywood;
    cursor: pointer;
    width: 8rem;
}

.command-target:hover {
    color: cornflowerblue;
}

.command-group {
    font-family: 'lato', sans-serif;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.875rem;
    color: ghostwhite;
    display: flex;
    gap: 0.75rem;
    align-items: center;
}

.map-actions-city-icon-wrapper {
    min-width: 3.5rem;
}

.map-actions-city-icon {
    height: 2rem;
}

.command-duration {
    width: 5.5rem;
}

.command-finish {
    width: 8.75rem;
}

@media screen and (max-width: 660px) {
    .command-finish {
        width: 5rem;
    }
}

@media screen and (max-width: 560px) {
    .command-group {
        gap: 0.5rem;
    }
    .command-target-wrapper {
        display: unset;
        flex-direction: unset;
        min-width: unset;
        max-width: 6rem;
    }
}

@media screen and (max-width: 450px) {
    .command-finish {
        display: none;
    }
    .command-group {
        font-size: 0.625rem;
    }
    .map-actions-city-icon-wrapper {
        min-width: 2.5rem;
    }
    .map-actions-city-icon {
        height: 1.5rem;
    }
}
</style>

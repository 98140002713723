<template>
    <div
        data-bs-backdrop="false"
        data-bs-keyboard="false"
        class="modal fade"
        :class="[troop?.getType()?.toLowerCase()]"
        tabindex="-1"
        role="dialog"
    >
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
				<button type="button" class="close" @click="closeModal()">
					<img src="../assets/close_icon.svg" width="20" height="20" aria-hidden="true" />
				</button>
                <div class="modal-header position-relative">
                    <h4 class="modal-title">{{ modalName }}</h4>
                    <div class="troop-description">
                        {{ modalDescription }}
                    </div>

                    <div class="troop-image-wrapper">
                        <picture v-if="troop">
                            <source
                                media="(max-width: 799px)"
                                :srcset="require(`../assets/full_troop_images/${toKebabCase(troop?.getName())}-full-mobile.avif`)"
                            />
                            <source
                                media="(min-width: 800px)"
                                :srcset="
                                    require(`../assets/full_troop_images/${toKebabCase(troop?.getName())}-full-desktop.avif`)
                                "
                            />
                            <img
                                v-if="troop"
                                :src="require(`../assets/full_troop_images/${toKebabCase(troop?.getName())}-full-desktop.avif`)"
                            />
                        </picture>
                    </div>
                </div>
                <div class="modal-body">
                    <!--                    <picture v-if="troop">-->
                    <!--                        <source-->
                    <!--                            media="(max-width: 799px)"-->
                    <!--                            :srcset="-->
                    <!--                                require(`../assets/full_troop_images/${toKebabCase(troop?.getName())}-full-mobile.avif`)-->
                    <!--                            "-->
                    <!--                        />-->
                    <!--                        <source-->
                    <!--                            media="(min-width: 800px)"-->
                    <!--                            :srcset="-->
                    <!--                                require(`../assets/full_troop_images/${toKebabCase(troop?.getName())}-full-desktop.avif`)-->
                    <!--                            "-->
                    <!--                        />-->
                    <!--                        <img-->
                    <!--                            v-if="troop"-->
                    <!--                            :src="-->
                    <!--                                require(`../assets/full_troop_images/${toKebabCase(troop?.getName())}-full-desktop.avif`)-->
                    <!--                            "-->
                    <!--                            class="troop-image-wrapper"-->
                    <!--                        />-->
                    <!--                    </picture>-->
                    <div class="troop-info-window">
                        <div class="d-flex flex-wrap justify-content-evenly">
                            <div v-if="general" class="general-bonus-window">
                                <h6 class="small-header">Bonus</h6>
                                {{ troop.getGeneralBonusString(general.name) }}
                            </div>
                            <div v-else class="statistics-window">
                                <h6 class="small-header">Statistics</h6>
                                <div class="d-flex flex-wrap">
                                    <div class="troop-info-wrapper">
                                        <div class="icon-tooltip">Anti-air Defense</div>
                                        <img src="../assets/icons/anti_air_defense_icon.svg" class="troop-info-icon" />
                                        <span>{{ troop?.getAntiAirDefense() }}</span>
                                    </div>
                                    <div class="troop-info-wrapper">
                                        <div class="icon-tooltip">Anti-artillery Defense</div>
                                        <img src="../assets/icons/anti_artillery_tank_defense_icon.svg" class="troop-info-icon" />
                                        <span>{{ troop?.getAntiArtilleryAndTankDefense() }}</span>
                                    </div>
                                    <div class="troop-info-wrapper">
                                        <div class="icon-tooltip">Anti-infantry Defense</div>
                                        <img src="../assets/icons/anti_infantry_defense_icon.svg" class="troop-info-icon" />
                                        <span>{{ troop?.getAntiInfantryDefense() }}</span>
                                    </div>
                                    <div class="troop-info-wrapper">
                                        <div class="icon-tooltip">Attack</div>
                                        <img src="../assets/icons/attack_icon.svg" class="troop-info-icon" />
                                        <span>{{ troop?.getAttack() }}</span>
                                    </div>
                                    <div class="troop-info-wrapper">
                                        <div class="icon-tooltip">Carry Capacity</div>
                                        <img src="../assets/icons/carry_capacity_icon.svg" class="troop-info-icon" />
                                        <span>{{ troop?.getCarryCapacity() }}</span>
                                    </div>
                                    <div class="troop-info-wrapper">
                                        <div class="icon-tooltip">Movement Speed</div>
                                        <img src="../assets/icons/movement_speed_icon.svg" class="troop-info-icon" />
                                        <span>{{ troop?.getMovementSpeed() }}</span>
                                    </div>
                                    <div class="troop-info-wrapper">
                                        <div class="icon-tooltip">Oil Consumption</div>
                                        <img src="../assets/icons/oil_circle_icon.svg" class="troop-info-icon" />
                                        <span>{{ troop?.getOilConsumption() }}</span>
                                    </div>
                                    <div class="troop-info-wrapper">
                                        <div class="icon-tooltip">Rations Consumption</div>
                                        <img src="../assets/icons/food_consumption_icon.svg" class="troop-info-icon" />
                                        <span>{{ troop?.getRationsConsumption() }}</span>
                                    </div>
                                    <div class="troop-info-wrapper">
                                        <div class="icon-tooltip">Unit Type</div>
                                        <img src="../assets/icons/training_time_icon.svg" class="troop-info-icon" />
                                        <span>Infantry</span>
                                    </div>
                                    <div class="troop-info-wrapper">
                                        <div class="icon-tooltip">Type of Attack</div>
                                        <img src="../assets/icons/training_time_icon.svg" class="troop-info-icon" />
                                        <span>Light Attack</span>
                                    </div>
                                </div>
                            </div>
                            <div class="costs-window">
                                <h6 class="small-header">Costs</h6>
                                <div class="d-flex flex-wrap">
                                    <div class="troop-info-wrapper">
                                        <div class="icon-tooltip">Aluminium</div>
                                        <img src="../assets/icons/aluminum_circle_icon.svg" class="troop-info-icon" />
                                        <span>{{ troop?.getAluminumCost() }}</span>
                                    </div>
                                    <div class="troop-info-wrapper">
                                        <div class="icon-tooltip">Oil</div>
                                        <img src="../assets/icons/oil_circle_icon.svg" class="troop-info-icon" />
                                        <span>{{ troop?.getOilCost() }}</span>
                                    </div>
                                    <div class="troop-info-wrapper">
                                        <div class="icon-tooltip">Metal</div>
                                        <img src="../assets/icons/metal_circle_icon.svg" class="troop-info-icon" />
                                        <span>{{ troop?.getMetalCost() }}</span>
                                    </div>
                                    <div class="troop-info-wrapper">
                                        <div class="icon-tooltip">Population</div>
                                        <img src="../assets/icons/population_circle_icon.svg" class="troop-info-icon" />
                                        <span>{{ troop?.getPopulationCost() }}</span>
                                    </div>
                                    <div class="troop-info-wrapper">
                                        <div class="icon-tooltip">Recruitment Time</div>
                                        <img src="../assets/icons/training_time_icon.svg" class="troop-info-icon" />
                                        <span>{{ troop?.getRecruitmentTime() }}</span>
                                    </div>
                                </div>
                                <div v-if="shouldDisplayRequirements" class="requirements-wrapper">
                                    <h6 class="small-header">Requirements</h6>
                                    <div v-for="req in requirementsNotMet" :key="req.name" class="requirements-group">
                                        <img
                                            :src="
                                                require(`../assets/icons/buildings/${toSnakeCase(
                                                    req.name,
                                                )}_mini_${getBuildingAppearance(req)}.avif`)
                                            "
                                            alt=""
                                            class="requirement-icon"
                                            @click="openBuildingDetailsModal(getBuildingFromRequirementName(req.name))"
                                        />
                                        <div class="requirement-name-wrapper">
                                            <span
                                                class="requirement-name"
                                                @click="openBuildingDetailsModal(getBuildingFromRequirementName(req.name))"
                                                >{{ req.name }}</span
                                            >
                                            <span class="requirement-level">Level {{ req.level }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { paramCase, snakeCase } from 'change-case';

export default {
    name: 'TroopDetailsModal',
    props: {
        setCurrentBuilding: {
            type: Function,
            default: () => null,
        },
    },
    computed: {
        buildings() {
            return this.$store.getters.getBuildings;
        },
        troop() {
            return this.$store.getters.getCurrentTroop?.troop;
        },
        general() {
            return this.$store.getters.getCurrentTroop?.general;
        },
        modalName() {
            return this.general ? this.general.name : this.troop?.getName();
        },
        modalDescription() {
            return this.general ? this.general.description : this.troop?.getDescription();
        },
        requirementsNotMet() {
            return this.troop.getBuildingRequirements().filter(requirement => !this.isRequirementMet(requirement));
        },
        shouldDisplayRequirements() {
            return this.troop && this.requirementsNotMet.length;
        },
    },
    methods: {
        closeModal() {
            document.getElementById('troop-details-modal').style.transition =
                'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
            document.getElementById('troop-details-modal').style.transform = 'translateY(-20px)';
            document.getElementById('troop-details-modal').style.opacity = '0';
            setTimeout(() => {
                document.getElementById('troop-details-modal').style.display = 'none';
                document.getElementById('troop-details-modal').classList.remove('show');
                document.getElementById('troop-details-modal').style.removeProperty('transform');
                document.getElementById('troop-details-modal').style.removeProperty('opacity');
            }, 150);
        },
        openBuildingDetailsModal(building) {
            if (building) {
                this.setCurrentBuilding(building);
            }
            document.getElementById('building-details-modal').style.transform = 'translateY(-20px)';
            document.getElementById('building-details-modal').style.opacity = '0';
            document.getElementById('building-details-modal').style.display = 'block';
            document.getElementById('building-details-modal').classList.add('show');
            setTimeout(() => {
                document.getElementById('building-details-modal').style.transition =
                    'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
                document.getElementById('building-details-modal').style.transform = 'translateY(0)';
                document.getElementById('building-details-modal').style.opacity = '1';
            }, 0);
        },
        getBuildingFromRequirementName(requirement_name) {
            return this.buildings.find(building => building.name === requirement_name);
        },
        isRequirementMet(requirement) {
            const RequiredBuilding = this.buildings.find(building => building.name === requirement.name);
            return RequiredBuilding?.level >= requirement.level;
        },
        getBuildingAppearance(requirement) {
            const building = this.buildings.filter(bld => bld.name === requirement.name)[0];
            if (building) {
                for (let i = building?.appearance?.length; i >= 0; i--) {
                    if (requirement.level >= building.appearance[i]) {
                        return i + 2;
                    }
                }
            }
            return 1;
        },
        toSnakeCase(string) {
            return snakeCase(string);
        },
        toKebabCase(string) {
            return paramCase(string);
        },
    },
};
</script>

<style scoped>
.modal {
    background: rgba(0, 0, 0, 0.5);
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.modal::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.modal::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.modal-header,
.modal-footer {
    border: none;
}

.modal-header {
    background: linear-gradient(to right, rgba(0, 0, 0, 0), black, rgba(0, 0, 0, 0));
    display: flex;
    align-items: flex-end;
    flex-direction: column;
}

.modal-title {
    width: 60%;
    font-style: italic;
    font-family: 'bebas', sans-serif;
    font-size: 2rem;
    line-height: 2rem;
    letter-spacing: 1px;
    padding-bottom: 0.75rem;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0), lightgray, rgba(0, 0, 0, 0)) 1;
    border-left: none;
    border-right: none;
    border-top: none;
}

.troop-description {
    width: 60%;
    font-family: 'lato', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    margin-top: 0.5rem;
    padding-right: 1rem;
    max-height: 3.5rem;
    overflow-y: overlay;
    color: #b7b6b6;
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.troop-description::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.troop-description::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.close {
    position: absolute;
    top: 16px;
    right: 24px;
    border: none;
    background: none;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.close:hover {
    opacity: 1;
}

.modal-content {
    font-family: 'sansation', sans-serif;
    top: 12vh;
    padding: 4rem 0;
    background-image: url('../assets/background.avif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0;
    color: ghostwhite;
    border: none;
}

.modal-body {
    padding: 2rem;
    display: flex;
    justify-content: flex-end;
}

.troop-info-window {
    width: 60%;
}

.general-bonus-window,
.statistics-window,
.costs-window {
    width: 50%;
    padding: 0.5rem;
}

.small-header {
    font-family: 'bebas', sans-serif;
    font-size: 1rem;
    font-style: italic;
    margin: 0 2rem 1rem 0;
    padding: 0.375rem 0 0.5rem 0;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0), lightgray, rgba(0, 0, 0, 0)) 1;
    border-left: none;
    border-right: none;
    color: wheat;
    letter-spacing: 0.5px;
}

.troop-info-wrapper {
    font-size: 0.875rem;
    display: flex;
    align-items: center;
    flex-grow: 1;
    gap: 1rem;
    width: 50%;
    padding: 0.25rem 0.5rem;
    position: relative;
}

.icon-tooltip {
    opacity: 0;
    filter: blur(3px);
    position: absolute;
    top: -1.375rem;
    pointer-events: none;
    transition: opacity 0.3s, filter 0.3s;
    background: black;
    color: ghostwhite;
    font-size: 0.75rem;
    line-height: 0.75rem;
    padding: 0.25rem;
    border: 1px solid #434343;
}

.troop-info-wrapper:hover .icon-tooltip {
    opacity: 1;
    filter: blur(0);
}

.troop-info-icon {
    width: 1.875rem;
}

.troop-image-wrapper {
    width: 38%;
    position: absolute;
    top: -0.5rem;
    left: 1rem;
	height: 450px;
	display: flex;
	align-items: center;
	justify-content: center;
}

.troop-image-wrapper img {
	max-width: 100%;
	max-height: 100%;
}

.armored .troop-info-window,
.plane .troop-info-window {
    width: 100%;
}

.armored .statistics-window,
.armored .costs-window,
.plane .statistics-window,
.plane .costs-window {
    width: 40%;
}

.armored .troop-image-wrapper,
.plane .troop-image-wrapper {
    width: 35%;
    height: 140px;
}

.requirements-wrapper {
    margin-top: 2rem;
}

.requirements-group {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin-top: 0.5rem;
}

.requirement-icon {
    filter: grayscale(1) contrast(1.25);
    cursor: pointer;
    height: 2.5rem;
    width: auto;
}

.requirement-name-wrapper {
    display: flex;
    flex-direction: column;
}

.requirement-name {
    font-size: 1rem;
    line-height: 1rem;
    color: lightgray;
    cursor: pointer;
}

.requirement-name:hover {
    color: cornflowerblue;
}

.requirement-level {
    font-size: 0.875rem;
    line-height: 0.875rem;
    color: darkgray;
}

@media screen and (max-width: 991px) {
    .troop-info-window {
        width: 100%;
    }
    .armored .statistics-window,
    .armored .costs-window {
        width: 50%;
    }
    .troop-image-wrapper {
        width: 32%;
        top: -68%;
        left: 5%;
    }
    .modal-content {
        padding: 4rem 0 1rem 0;
    }

    .armored .troop-image-wrapper {
        top: -42%;
    }
}

@media screen and (max-width: 577px) {
    .troop-image-wrapper {
        width: 8rem;
        top: -11rem;
        left: 5%;
    }

    .plane .troop-image-wrapper {
        top: -32%;
    }
}

@media screen and (max-width: 400px) {
    .modal-body {
        padding: 2rem 1rem;
    }
    .troop-info-wrapper {
        gap: 0.5rem;
    }
    .troop-info-icon {
        width: 1.5rem;
    }
    .troop-image-wrapper {
        width: 7rem;
        top: -10rem;
        left: 5%;
    }
}

@media screen and (orientation: landscape) and (max-height: 800px) {
    .modal-content {
        transform: scale(0.8);
        top: -10vh;
    }
}
</style>

<template>
    <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade ref" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Main Building</h4>
                    <div class="building-description">
                        {{ description }}
                    </div>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <img src="../../assets/close_icon.svg" width="20" height="20" aria-hidden="true" />
                    </button>
                </div>
                <div class="modal-body">
					<BuildingCommands />
                    <div class="buildings-table-wrapper">
                        <div class="building-info-wrapper">
                            <div class="building-info label">Building</div>
                            <div v-for="(building, index) in buildingsWithRequirementsMet" :key="index" class="building-info">
                                <img
                                    :src="
                                        require(`../../assets/icons/buildings/${toSnakeCase(
                                            building.name,
                                        )}_mini_${getNextLevelBuildingAppearance(building.name)}.avif`)
                                    "
                                    class="building-icon"
                                    @click="openModal(building)"
                                />
                                <div class="building-name-wrapper">
                                    <span class="building-name" @click="openModal(building)">{{ building.name }}</span>
                                    <span class="building-level">Level {{ building.level + 1 }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="requirements-wrapper">
                            <div class="requirements label">Requirements</div>
                            <div v-for="(building, index) in buildingsWithRequirementsMet" :key="index" class="requirements">
                                <div class="requirements-group">
                                    <div class="icon-tooltip">Metal</div>
                                    <img src="../../assets/icons/metal_circle_icon.svg" class="requirements-icon" />
                                    <span class="requirements-value">{{ building.metal[building.level] }}</span>
                                </div>
                                <div class="requirements-group">
                                    <div class="icon-tooltip">Oil</div>
                                    <img src="../../assets/icons/oil_circle_icon.svg" class="requirements-icon" />
                                    <span class="requirements-value">{{ building.oil[building.level] }}</span>
                                </div>
                                <div class="requirements-group">
                                    <div class="icon-tooltip">Aluminium</div>
                                    <img src="../../assets/icons/aluminum_circle_icon.svg" class="requirements-icon" />
                                    <span class="requirements-value">{{ building.aluminum[building.level] }}</span>
                                </div>
                                <div class="requirements-group">
                                    <div class="icon-tooltip">Population</div>
                                    <img src="../../assets/icons/population_circle_icon.svg" class="requirements-icon" />
                                    <span class="requirements-value">{{ building.population[building.level] }}</span>
                                </div>
                                <div class="requirements-group">
                                    <div class="icon-tooltip">Build Time</div>
                                    <img src="../../assets/icons/building_time_icon.svg" class="requirements-icon" />
                                    <span class="requirements-value">{{ building.time[building.level] }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="build-actions-wrapper">
                            <div class="build-actions label">Build</div>
                            <div v-for="(building, index) in buildingsWithRequirementsMet" :key="index" class="build-actions">
                                <button class="btn build-button" :disabled="awaitingResponse" @click="sendConstructionCommand(building)">Build</button>
                                <!-- TO DO: handle premium button differently -->
                                <button class="btn build-premium-button" :disabled="awaitingResponse" @click="sendConstructionCommand(building)">-20%</button>
                            </div>
                        </div>
                    </div>

                    <div class="unavailable-buildings-table-wrapper">
                        <div class="building-info-wrapper">
                            <div class="building-info label">Building</div>
                            <div
                                v-for="(building, index) in buildingsWithRequirementsNotMet"
                                :key="index"
                                class="building-info unavailable building-row"
                            >
                                <img
                                    :src="
                                        require(`../../assets/icons/buildings/${toSnakeCase(
                                            building.name,
                                        )}_mini_${getCurrentBuildingAppearance(building.name)}.avif`)
                                    "
                                    class="building-icon"
                                    @click="openModal(building)"
                                />
                                <div class="building-name-wrapper">
                                    <span class="building-name" @click="openModal(building)">{{ building.name }}</span>
                                    <span class="building-level">Requirements not met</span>
                                </div>
                            </div>
                        </div>
                        <div class="requirements-wrapper">
                            <div class="requirements label">Requirements</div>
                            <div
                                v-for="(building, index) in buildingsWithRequirementsNotMet"
                                :key="index"
                                class="requirements requirements-row"
                            >
                                <div
                                    v-for="(requirement, index) in building.requirements"
                                    :key="index"
                                    class="requirements-group"
                                    :class="{ unavailable: !checkRequirementIsMet(requirement) }"
                                >
                                    <img
                                        :src="
                                            require(`../../assets/icons/buildings/${toSnakeCase(
                                                requirement.name,
                                            )}_mini_${getCurrentBuildingAppearance(requirement.name)}.avif`)
                                        "
                                        class="building-icon"
                                        @click="openModal(getBuildingFromRequirementName(requirement.name))"
                                    />
                                    <div class="building-name-wrapper">
                                        <span
                                            class="building-name"
                                            @click="openModal(getBuildingFromRequirementName(requirement.name))"
                                            >{{ requirement.name }}</span
                                        >
                                        <span class="building-level">Level {{ requirement.level }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="change-city-name-wrapper d-flex gap-2">
                        <span>Change city name:</span>
                        <input type="text" class="form-control" id="city-name" name="city-name" maxlength="16" />
                        <button class="btn build-button" :disabled="awaitingResponse">Change</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { snakeCase } from 'change-case';
import BuildingCommands from "@/components/MainBuilding/BuildingCommands";

export default {
    name: 'MainBuildingModal',
	components: { BuildingCommands },
	props: {
		awaitingResponse: {
			type: Boolean,
			default: false,
		},
        buildings: {
            type: Array,
            default: () => [],
        },
        description: {
            type: String,
            default: '',
        },
        setCurrentBuilding: {
            type: Function,
            default: () => null,
        },
    },
    computed: {
        buildingsWithRequirementsMet() {
            const allBuildings = this.buildings;
            const filteredBuildings = [];
            for (let i = 0; i < allBuildings.length; i++) {
                let requirementsMet = true;
                for (let j = 0; j < allBuildings[i].requirements.length; j++) {
                    const requiredBuilding = allBuildings.find(
                        building => building.name === allBuildings[i].requirements[j].name,
                    );
                    if (requiredBuilding.level < allBuildings[i].requirements[j].level) {
                        requirementsMet = false;
                    }
                }
                if (requirementsMet) {
                    filteredBuildings.push(allBuildings[i]);
                }
            }
            return filteredBuildings;
        },
        buildingsWithRequirementsNotMet() {
            const allBuildings = this.buildings;
            const buildingsWithRequirementsMet = new Set(this.buildingsWithRequirementsMet);
            return allBuildings.filter(building => !buildingsWithRequirementsMet.has(building));
        },
    },
    created() {
        setTimeout(() => {
            if (window) {
                window.addEventListener('resize', this.onResize);
                window.addEventListener('orientationchange', this.onResize);
            }

            const modal = document.querySelector('.ref');

            modal.addEventListener('show.bs.modal', () => {
                setTimeout(() => {
                    for (let i = 0; i < document.getElementsByClassName('requirements-row').length; i++) {
                        if (this.buildingRowHeight(i) > this.reqRowHeight(i)) {
                            document.getElementsByClassName('requirements-row')[i].style.minHeight =
                                this.buildingRowHeight(i) + 'px';
                        } else if (this.buildingRowHeight(i) < this.reqRowHeight(i)) {
                            document.getElementsByClassName('building-row')[i].style.minHeight = this.reqRowHeight(i) + 'px';
                        } else {
                            document.getElementsByClassName('requirements-row')[i].style.minHeight = '';
                            document.getElementsByClassName('building-row')[i].style.minHeight = '';
                        }
                    }
                }, 0);
            });
        }, 0);
    },
    methods: {
        ...mapActions(['sendBuildingCommand', 'lowerResourcesAfterBuildingCommand']),
        toSnakeCase(string) {
            return snakeCase(string);
        },
        onResize() {
            for (let i = 0; i < document.getElementsByClassName('requirements-row').length; i++) {
                document.getElementsByClassName('requirements-row')[i].style.minHeight = '';
                document.getElementsByClassName('building-row')[i].style.minHeight = '';
                setTimeout(() => {
                    if (this.buildingRowHeight(i) > this.reqRowHeight(i)) {
                        document.getElementsByClassName('requirements-row')[i].style.minHeight = this.buildingRowHeight(i) + 'px';
                    } else if (this.buildingRowHeight(i) < this.reqRowHeight(i)) {
                        document.getElementsByClassName('building-row')[i].style.minHeight = this.reqRowHeight(i) + 'px';
                    } else {
                        document.getElementsByClassName('requirements-row')[i].style.minHeight = '';
                        document.getElementsByClassName('building-row')[i].style.minHeight = '';
                    }
                }, 10);
            }
        },
        openModal(building) {
            if (building) {
                this.setCurrentBuilding(building);
            }
            document.getElementById('building-details-modal').style.transform = 'translateY(-20px)';
            document.getElementById('building-details-modal').style.opacity = '0';
            document.getElementById('building-details-modal').style.display = 'block';
            document.getElementById('building-details-modal').classList.add('show');
            setTimeout(() => {
                document.getElementById('building-details-modal').style.transition =
                    'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
                document.getElementById('building-details-modal').style.transform = 'translateY(0)';
                document.getElementById('building-details-modal').style.opacity = '1';
            }, 0);
        },
        reqRowHeight(index) {
            let rows = document.getElementsByClassName('requirements-row');
            return rows[index].offsetHeight;
        },
        buildingRowHeight(index) {
            let rows = document.getElementsByClassName('building-row');
            return rows[index].offsetHeight;
        },
        sendConstructionCommand(data) {
            // TO DO
            // WE NEED VALIDATION HERE
            // NEED TO CHECK WE HAVE THE AMOUNT OF RESOURCES NEEDED FOR THE BUILDING

            this.sendBuildingCommand({
                name: data.name,
            });
        },
        getNextLevelBuildingAppearance(name) {
            const building = this.buildings.filter(bld => bld.name === name)[0];
            if (building) {
                for (let i = building?.appearance?.length; i >= 0; i--) {
                    if (building.level + 1 >= building.appearance[i]) {
                        return i + 2;
                    }
                }
            }
            return 1;
        },
        getCurrentBuildingAppearance(name) {
            const building = this.buildings.filter(bld => bld.name === name)[0];
            if (building) {
                for (let i = building?.appearance?.length; i >= 0; i--) {
                    if (building.level >= building.appearance[i]) {
                        return i + 2;
                    }
                }
            }
            return 1;
        },
        getBuildingFromRequirementName(requirement_name) {
            return this.buildings.find(building => building.name === requirement_name);
        },
        checkRequirementIsMet(requirement) {
            const allBuildings = this.buildings;
            const buildingToCheck = allBuildings.find(building => building.name === requirement.name);
            return buildingToCheck.level >= requirement.level;
        },
    },
};
</script>

<style scoped>
.modal {
    background: rgba(0, 0, 0, 0.5);
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.modal::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.modal::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.modal-header {
    border: none;
    display: flex;
    flex-direction: column;
}

.modal-header {
    background: linear-gradient(to right, rgba(0, 0, 0, 0), black, rgba(0, 0, 0, 0));
    display: flex;
    justify-content: center;
}

.modal-title {
    width: 60%;
    font-family: 'bebas', sans-serif;
    letter-spacing: 1px;
    text-align: center;
    padding-bottom: 0.75rem;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0), lightgray, rgba(0, 0, 0, 0)) 1;
    border-left: none;
    border-right: none;
    border-top: none;
}

.building-description {
    width: 60%;
    font-family: 'lato', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    margin-top: 0.5rem;
    margin-left: 1rem;
    padding-right: 1rem;
    max-height: 2.5rem;
    overflow-y: overlay;
    color: #b7b6b6;
    text-align: justify;
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.building-description::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.building-description::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.close {
    position: absolute;
    top: 16px;
    right: 24px;
    border: none;
    background: none;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.close:hover {
    opacity: 1;
}

.modal-content {
    font-family: 'sansation', sans-serif;
    top: 5vh;
    padding: 4rem;
    background-image: url('../../assets/background.avif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0;
    color: ghostwhite;
    border: none;
}

.modal-body {
    padding-top: 3rem;
}

.buildings-table-wrapper {
    display: flex;
    flex-direction: row;
    gap: 2px;
}

.unavailable-buildings-table-wrapper {
    display: flex;
    flex-direction: row;
    gap: 2px;
    margin-top: 2rem;
}

.unavailable-buildings-table-wrapper .requirements:not(.label) {
    width: fit-content;
}

.unavailable-buildings-table-wrapper .requirements-group {
    justify-content: flex-start;
}

.building-info-wrapper,
.requirements-wrapper,
.build-actions-wrapper {
    width: auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.building-info-wrapper {
    flex-basis: 30%;
}

.requirements-wrapper {
    flex-basis: 60%;
}

.build-actions-wrapper {
    flex-basis: 10%;
}

.building-info,
.requirements,
.build-actions {
    color: lightgray;
    display: flex;
    flex-direction: row;
    height: 3rem;
    align-items: center;
}

.building-info {
    gap: 0.5rem;
    background: rgba(0, 0, 0, 0.5);
}

.requirements {
    background: rgba(0, 0, 0, 0.5);
}

.build-actions {
    padding: 0 0.5rem;
    gap: 0.5rem;
    background: rgba(0, 0, 0, 0.5);
}

.building-info,
.requirements-group {
    padding: 0 0.5rem;
}

.label {
    background: linear-gradient(to bottom, #2a2a2a, #181818);
    color: ghostwhite;
    padding: 0 0.5rem;
}

.building-name-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 0.125rem;
}

.building-icon {
    cursor: pointer;
}

.building-icon,
.building-name-wrapper {
    height: 2.5rem;
}

.building-name {
    font-size: 1rem;
    line-height: 1rem;
}

.building-name:hover {
    color: cornflowerblue;
    cursor: pointer;
}

.building-level {
    font-size: 0.875rem;
    line-height: 0.875rem;
    color: darkgray;
}

.requirements-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    height: 3rem;
    flex-grow: 1;
    position: relative;
}

.icon-tooltip {
    opacity: 0;
    filter: blur(3px);
    position: absolute;
    top: -1rem;
    pointer-events: none;
    transition: opacity 0.3s, filter 0.3s;
    background: black;
    color: ghostwhite;
    font-size: 0.75rem;
    line-height: 0.75rem;
    padding: 0.25rem;
    border: 1px solid #434343;
}

.requirements-group:hover .icon-tooltip {
    opacity: 1;
    filter: blur(0);
}

.requirements-icon {
    height: 1.25rem;
}

.requirements-value {
    font-size: 0.875rem;
}

.build-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.15), rgba(255, 200, 200, 0.15));
    border: 3px solid brown;
    font-size: 1rem;
    line-height: 1rem;
    color: ghostwhite;
    min-width: 8rem;
}

.build-button:hover {
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.2), rgba(255, 200, 200, 0.2));
    color: ghostwhite;
}

.build-premium-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.15), rgba(255, 255, 255, 0.15));
    border: 3px solid burlywood;
    font-size: 1rem;
    line-height: 1rem;
    color: ghostwhite;
}

.build-premium-button:hover {
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.2), rgba(255, 255, 255, 0.2));
    color: ghostwhite;
}

.unavailable-buildings-table-wrapper .building-info-wrapper {
    flex-basis: 26%;
}

.unavailable-buildings-table-wrapper .requirements-wrapper {
    flex-basis: 74%;
}

.unavailable-buildings-table-wrapper .requirements-group {
    flex-grow: initial;
}

.unavailable-buildings-table-wrapper .building-name-wrapper {
    height: initial;
}

.unavailable img {
    filter: grayscale(1) contrast(1.25) brightness(0.5);
}

.change-city-name-wrapper {
    margin-top: 5rem;
    position: relative;
    width: fit-content;
}

.change-city-name-wrapper > span {
    position: absolute;
    top: -2.5rem;
    left: 0;
    font-size: 0.875rem;
    background: linear-gradient(to bottom, #2a2a2a, #181818);
    padding: 0.5rem 0.75rem;
    width: 100%;
}

.form-control {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    border: 1px solid whitesmoke;
    border-radius: 0;
    padding-left: 1rem;
    opacity: 0.8;
    color: ghostwhite;
    width: 14rem;
}

.form-control:focus {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    color: ghostwhite;
    border: 1px solid ghostwhite;
}

.form-control:-webkit-autofill,
.form-control:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #1a1a1a inset;
    -webkit-text-fill-color: ghostwhite;
    caret-color: ghostwhite;
}

@media screen and (max-width: 1199px) {
    .build-button {
        min-width: 4rem;
    }
    .building-info,
    .requirements,
    .requirements-group,
    .build-actions {
        height: 3.5rem;
    }
    .modal-dialog {
        max-width: 90%;
        margin: 0 auto;
    }

    .unavailable-buildings-table-wrapper .building-info:not(.label) {
        flex-grow: initial;
        height: auto;
        padding: 0.5rem;
    }
}

@media screen and (max-width: 900px) {
    .modal-content {
        padding: 4rem 1rem;
    }
    .building-info-wrapper,
    .requirements-wrapper,
    .build-actions-wrapper {
        gap: 2rem;
    }
    .requirements:not(.label) {
        flex-wrap: wrap;
        height: auto;
        flex-grow: 1;
    }
    .building-info:not(.label),
    .build-actions:not(.label) {
        flex-grow: 1;
    }
    .requirements-group {
        height: 2rem;
    }
    .unavailable-buildings-table-wrapper .requirements-group {
        height: 3.5rem;
    }
}

@media screen and (max-width: 550px) {
    .building-description {
        width: 100%;
    }
    .label {
        height: 3.5rem;
    }
    .building-info,
    .build-actions {
        flex-direction: column;
        justify-content: center;
    }
    .building-name-wrapper {
        text-align: center;
    }
}

@media screen and (max-width: 400px) {
    .building-info,
    .requirements-group,
    .build-actions {
        padding: 0 0.125rem;
    }
    .label,
    .requirements-value,
    .build-button,
    .build-premium-button,
    .building-name,
    .building-description {
        font-size: 0.75rem;
        line-height: 0.75rem;
    }
    .building-level {
        font-size: 0.625rem;
        line-height: 0.625rem;
    }
    .requirements-group {
        gap: 0.25rem;
    }
    .requirements-icon {
        height: 0.875rem;
    }
}

@media screen and (orientation: landscape) and (max-height: 800px) {
    .modal-content {
        transform: scale(0.8);
        top: -10vh;
    }
}
</style>

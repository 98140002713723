<template>
    <div class="cost-window">
        <span class="section-title">Cost</span>
        <div class="resource-wrapper">
            <div class="icon-tooltip">Aluminium</div>
            <img src="../../assets/icons/aluminum_circle_icon.svg" class="resource-icon" />
            <span>{{ troop.getAluminumCost() * troop.getNumberToRecruit() }} ( {{ troop.getAluminumCost() }} )</span>
        </div>
        <div class="resource-wrapper">
            <div class="icon-tooltip">Oil</div>
            <img src="../../assets/icons/oil_circle_icon.svg" class="resource-icon" />
            <span>{{ troop.getOilCost() * troop.getNumberToRecruit() }} ( {{ troop.getOilCost() }} )</span>
        </div>
        <div class="resource-wrapper">
            <div class="icon-tooltip">Metal</div>
            <img src="../../assets/icons/metal_circle_icon.svg" class="resource-icon" />
            <span>{{ troop.getMetalCost() * troop.getNumberToRecruit() }} ( {{ troop.getMetalCost() }} )</span>
        </div>
        <div class="resource-wrapper">
            <div class="icon-tooltip">Population</div>
            <img src="../../assets/icons/population_circle_icon.svg" class="resource-icon" />
            <span>{{ troop.getPopulationCost() * troop.getNumberToRecruit() }} ( {{ troop.getPopulationCost() }} )</span>
        </div>
        <div class="resource-wrapper">
            <div class="icon-tooltip">Recruitment Time</div>
            <img src="../../assets/icons/training_time_icon.svg" class="resource-icon" />
            <span
                >{{ getTotalRecruitTime(troop) }}
                <span class="d-inline-block">( {{ troop.getRecruitmentTime() }} )</span></span
            >
        </div>
    </div>
</template>

<script>
import Troop from "@/classes/troops/Troop";
import getTotalRecruitTime from "@/components/RecruitmentBuildings/helpers/getTotalRecruitTime";

export default {
    name: 'TroopCostWindow',
	props: {
		troop: {
			type: Troop,
		}
	},
	methods: {
		getTotalRecruitTime,
	}
};
</script>

<style scoped>
.cost-window {
	position: relative;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 2px;
	width: 32%;
	border: 2px solid #434343;
	padding: 2px;
	background: black;
}

.resource-wrapper {
	font-size: 0.875rem;
	display: flex;
	align-items: center;
	flex-grow: 1;
	gap: 1rem;
	width: 8rem;
	background: linear-gradient(to bottom, #2a2a2a, #181818);
	padding: 0.25rem 0.5rem;
	position: relative;
}

.icon-tooltip {
	opacity: 0;
	filter: blur(3px);
	position: absolute;
	top: -1.375rem;
	pointer-events: none;
	transition: opacity 0.3s, filter 0.3s;
	background: black;
	color: ghostwhite;
	font-size: 0.75rem;
	line-height: 0.75rem;
	padding: 0.25rem;
	border: 1px solid #434343;
}

.resource-wrapper:hover .icon-tooltip {
	opacity: 1;
	filter: blur(0);
}

.resource-icon {
	width: 1.5rem;
}

.section-title {
	position: absolute;
	top: -2rem;
}

@media screen and (max-width: 1199px) {
	.cost-window {
		width: 48%;
	}
}

@media screen and (max-width: 991px) {
	.cost-window {
		margin-top: 3rem;
		width: 100%;
	}
}
</style>
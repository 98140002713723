<template>
    <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Airport</h4>
                    <div class="building-description">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur.
                    </div>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <img src="../assets/close_icon.svg" width="20" height="20" aria-hidden="true" />
                    </button>
                </div>
                <div class="modal-body">
                    <div class="planes-capacity-wrapper">
                        <div class="row">
                            <div class="col label">Current Capacity: 297 / 300</div>
                        </div>
                        <div class="row">
                            <div class="col label">Capacity at Level 5: 410</div>
                        </div>
                    </div>
                    <div class="t-wrapper planes-table">
                        <div class="t-row t-header">
                            <div class="t-col">Unit</div>
                            <div class="t-col">Owned</div>
                            <div class="t-col">Controlled</div>
                            <div class="t-col">In Mission</div>
                        </div>
                        <div v-for="plane in planes" :key="plane.getName()" class="t-row">
                            <TroopSelectRow
                                :troop="plane"
                                :command-type="CommandTypesForTroopSelection.PATROL_OR_INTERCEPT"
                                :reinforcements-category="ReinforcementCategory.RECEIVED"
                                :reinforcements-filter-type="ReinforcementFilterType.CONTROL_LEVEL"
                                :reinforcements-filter-value="ReinforcementControlLevel.PARTIAL_OR_FULL"
								:include-not-arrived="false"
                                :is-troop-name-visible="true"
                            />
                            <div class="t-col">{{ calculatePlanesInMission(plane.getName()) }}</div>
                        </div>
                    </div>
                    <h4 class="table-header">Missions:</h4>
                    <div class="planes-table">
                        <div class="row">
                            <div class="col label">Mission Name</div>
                            <div class="col label">Target</div>
                            <div class="col label">Oil Needed</div>
                            <div class="col label button-column">Action</div>
                        </div>
                        <div class="row">
                            <div class="col mission-name">Patrol</div>
                            <div class="col">
                                <div class="city-icon-wrapper">
                                    <!-- TO DO: add dynamic city image -->
                                    <img src="../assets/map_tiles/city_2_mobile.avif" class="city-icon" />
                                </div>
                                <span class="city-name-and-coords">
                                    {{ currentCityName }}
                                    <span class="city-coords"> ( {{ currentCityCoords?.x }} | {{ currentCityCoords?.y }} ) </span>
                                </span>
                            </div>
                            <div class="col">{{ oilNeeded.toLocaleString('ro') }}</div>
                            <div class="col button-column">
                                <button class="btn blue-button" :disabled="awaitingResponse" @click="validateAndSendPatrol">
                                    Patrol
                                </button>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col mission-name">Intercept attack</div>
                            <div class="col attacks-dropdown-column">
                                <span v-if="!incomingAttacks.length">No incoming attacks</span>
                                <div v-else class="attacks-dropdown-wrapper">
                                    <div class="selected-attack">
                                        <div class="city-icon-wrapper">
                                            <!-- TO DO: add dynamic city image // stop using getCityNameFromId function -->
                                            <img src="../assets/map_tiles/city_2_mobile.avif" class="city-icon" />
                                        </div>
                                        <span class="command-target-wrapper">
                                            Attack from
                                            <span class="command-target">{{ selectedAttack.fromCity?.name }}</span>
                                        </span>
                                    </div>
                                    <button
                                        class="btn neutral-button reinforcements-dropdown-button"
                                        @click="toggleAttacksDropdownVisibility"
                                    >
                                        <span>&#10095;</span>
                                    </button>
                                </div>
                                <Transition name="tooltip-transition">
                                    <div v-if="attacksDropdownVisible" class="attacks-dropdown">
                                        <div class="row">
                                            <div class="col label">Attack from</div>
                                            <div class="col label">Duration</div>
                                            <div class="col label"></div>
                                        </div>
                                        <div v-for="(attack, attack_index) in incomingAttacks" :key="attack_index" class="row">
                                            <div class="col">
                                                <div class="city-icon-wrapper">
                                                    <!-- TO DO: add dynamic city image // stop using getCityNameFromId function -->
                                                    <img src="../assets/map_tiles/city_2_mobile.avif" class="city-icon" />
                                                </div>
                                                <span class="command-target-wrapper">
                                                    Attack from
                                                    <span class="command-target">{{ attack.fromCity?.name }}</span>
                                                </span>
                                            </div>
                                            <div class="col">{{ attack.duration }}</div>
                                            <div class="col">
                                                <button class="btn yellow-button" @click="selectAttack(attack)">Select</button>
                                            </div>
                                        </div>
                                    </div>
                                </Transition>
                            </div>
                            <div class="col">{{ oilNeeded.toLocaleString('ro') }}</div>
                            <div class="col button-column">
                                <button
                                    class="btn blue-button"
                                    :disabled="awaitingResponse"
                                    @click="validateAndSendInterceptingAttack"
                                >
                                    Intercept
                                </button>
                            </div>
                        </div>
                    </div>
                    <h4 class="table-header">Active Missions:</h4>
                    <div class="planes-table active-missions-table">
                        <div class="row">
                            <div class="col label">Mission</div>
                            <div class="col label">Planes</div>
                            <div class="col label">Duration</div>
                            <div class="col label button-column">Action</div>
                        </div>
                        <div v-for="(mission, mission_index) in missions" :key="mission.originalData._id" class="row">
                            <div class="col">
                                <div class="city-icon-wrapper">
                                    <!-- TO DO: add dynamic city image -->
                                    <img src="../assets/map_tiles/city_2_mobile.avif" class="city-icon" />
                                </div>
                                <span class="city-name-and-coords">
                                    {{ mission.actionDescription }} {{ mission.target.name }}
                                </span>
                            </div>
                            <div class="col mission-planes-dropdown-column">
                                <div class="mission-planes-dropdown-wrapper">
                                    <div class="total-planes">{{ calculateAllPlanes(mission.originalData) }}</div>
                                    <button
                                        class="btn neutral-button reinforcements-dropdown-button"
                                        @click="togglePatrolPlanesDropdownVisibility(mission_index)"
                                    >
                                        <span>&#10095;</span>
                                    </button>
                                </div>
                                <Transition name="tooltip-transition">
                                    <div v-if="patrolPlanesDropdownVisible[mission_index]" class="planes-dropdown">
                                        <div
                                            v-for="(plane, plane_index) in calculatePlanesFromMission(mission.originalData)"
                                            :key="plane_index"
                                            class="row"
                                        >
                                            <div class="col">
                                                <div class="troop-image-wrapper">
                                                    <img
                                                        :src="
                                                            require(`../assets/icons/troops/${toKebabCase(
                                                                plane.troopName,
                                                            )}-tall.avif`)
                                                        "
                                                        alt=""
                                                        class="troop-image"
                                                        @click="openTroopDetailsModal(getPlaneFromName(plane.troopName))"
                                                    />
                                                </div>
                                                <span
                                                    class="troop-name"
                                                    @click="openTroopDetailsModal(getPlaneFromName(plane.troopName))"
                                                    >{{ plane.troopName }}</span
                                                >
                                            </div>
                                            <div class="col">{{ plane.numOfTroops }}</div>
                                        </div>
                                    </div>
                                </Transition>
                            </div>
                            <div class="col">{{ mission.progressTracking.dynamicDurationString }}</div>
                            <div class="col button-column">
                                <button class="btn red-button" :disabled="awaitingResponse">Cancel</button>
                            </div>
                        </div>
                        <div
                            v-for="(intercept_mission, intercept_mission_index) in intercepts"
                            :key="intercept_mission_index"
                            class="row"
                        >
                            <div class="col">Intercepting attack</div>
                            <div class="col">
                                {{ intercept_mission.interceptedAttack }}
                            </div>
                            <div class="col mission-planes-dropdown-column">
                                <div class="mission-planes-dropdown-wrapper">
                                    <div class="total-planes">{{ calculateAllPlanes(intercept_mission) }}</div>
                                    <button
                                        class="btn neutral-button reinforcements-dropdown-button"
                                        @click="toggleInterceptPlanesDropdownVisibility(intercept_mission_index)"
                                    >
                                        <span>&#10095;</span>
                                    </button>
                                </div>
                                <Transition name="tooltip-transition">
                                    <div v-if="interceptPlanesDropdownVisible[intercept_mission_index]" class="planes-dropdown">
                                        <div
                                            v-for="(plane, plane_index) in calculatePlanesFromMission(intercept_mission)"
                                            :key="plane_index"
                                            class="row"
                                        >
                                            <div class="col">
                                                <div class="troop-image-wrapper">
                                                    <img
                                                        :src="
                                                            require(`../assets/icons/troops/${toKebabCase(plane.troopName)}-tall.avif`)
                                                        "
                                                        alt=""
                                                        class="troop-image"
                                                        @click="openTroopDetailsModal(getPlaneFromName(plane.troopName))"
                                                    />
                                                </div>
                                                <span
                                                    class="troop-name"
                                                    @click="openTroopDetailsModal(getPlaneFromName(plane.troopName))"
                                                    >{{ plane.troopName }}</span
                                                >
                                            </div>
                                            <div class="col">{{ plane.numOfTroops }}</div>
                                        </div>
                                    </div>
                                </Transition>
                            </div>
                            <div class="col">{{ intercept_mission.duration }}</div>
                            <div class="col button-column">
                                <button class="btn red-button" :disabled="awaitingResponse">Cancel</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { paramCase } from 'change-case';
import { ActionTypeIdentifier } from '@/utils/constants/actions';
import {
    TroopTypes,
    CommandTypesForTroopSelection,
    ReinforcementFilterType,
    ReinforcementControlLevel,
} from '@/utils/constants/troops';
import TroopSelectRow from '@/components/generic/TroopSelect/TroopSelectRow';
import { ReinforcementCategory } from '@/utils/constants/actions';

export default {
    name: 'AirportModal',
    components: { TroopSelectRow },
    props: {
        awaitingResponse: {
            type: Boolean,
            default: false,
        },
        cityId: {
            type: String,
            default: '',
        },
        currentCityName: {
            type: String,
            default: '',
        },
        currentCityCoords: {
            type: Object,
            default: () => {},
        },
        mapActions: {
            type: Set,
            default: () => new Set(),
        },
        attacks: {
            type: Array,
            default: () => [],
        },
        patrols: {
            type: Array,
            default: () => [],
        },
        intercepts: {
            type: Array,
            default: () => [],
        },
        setCurrentTroop: {
            type: Function,
            default: () => null,
        },
    },
    data() {
        return {
            TroopTypes,
            CommandTypesForTroopSelection,
            ReinforcementFilterType,
            ReinforcementControlLevel,
            ReinforcementCategory,

			selectedAttack: null,
			attacksDropdownVisible: false,
            patrolPlanesDropdownVisible: new Array(this.patrols.length).fill(false),
            interceptPlanesDropdownVisible: new Array(this.intercepts.length).fill(false),
        };
    },
    computed: {
        formattedTroops() {
            return this.$store.getters.getCurrentCityFormattedTroops;
        },
        planes() {
            return this.formattedTroops.filter(troop => troop.getType() === TroopTypes.PLANE);
        },
        incomingAttacks() {
            return this.attacks.filter(attack => attack.toCity?._id === this.cityId);
        },
        missions() {
            const RelevantActions = [
                ActionTypeIdentifier.PATROL,
                ActionTypeIdentifier.INCOMING_INTERCEPT,
                ActionTypeIdentifier.OUTGOING_INTERCEPT,
                ActionTypeIdentifier.RETURNING_INTERCEPT,
            ];
            const FilteredActions = new Set();

            this.mapActions.forEach(action => {
                if (RelevantActions.includes(action.actionType)) {
                    FilteredActions.add(action);
                }
            });
            return FilteredActions;
        },
        oilNeeded() {
            return this.planes.reduce((accumulator, current_plane) => accumulator + current_plane.getOilNeededForMission(), 0);
        },
    },
    created() {
        this.selectedAttack = this.incomingAttacks[0] || null;
    },
    methods: {
        toKebabCase(string) {
            return paramCase(string);
        },

        toggleAttacksDropdownVisibility() {
            this.attacksDropdownVisible = !this.attacksDropdownVisible;
        },

        togglePatrolPlanesDropdownVisibility(index) {
            this.patrolPlanesDropdownVisible[index] = !this.patrolPlanesDropdownVisible[index];
        },

        toggleInterceptPlanesDropdownVisibility(index) {
            this.interceptPlanesDropdownVisible[index] = !this.interceptPlanesDropdownVisible[index];
        },

        selectAttack(attack) {
            if (this.selectedAttack && this.selectedAttack._id === attack._id) {
                this.toggleAttacksDropdownVisibility();
                return;
            }
            this.selectedAttack = attack;
            this.toggleAttacksDropdownVisibility();
        },

        calculatePlanesFromMission(mission) {
            const planes = mission.troops;
            const PLANES_ARRAY = [];
            for (let i = 0; i < planes.length; i++) {
                for (let j = 0; j < planes[i].troopsName.length; j++) {
                    if (!PLANES_ARRAY.find(pln => pln.troopName === planes[i].troopsName[j])) {
                        const PLANE_OBJECT = {
                            troopName: planes[i].troopsName[j],
                            numOfTroops: 0,
                        };
                        PLANES_ARRAY.push(PLANE_OBJECT);
                    }
                    PLANES_ARRAY.filter(pln => pln.troopName === planes[i].troopsName[j])[0].numOfTroops +=
                        planes[i].numOfTroops[j];
                }
            }
            return PLANES_ARRAY;
        },

        calculateAllPlanes(mission) {
            return this.calculatePlanesFromMission(mission).reduce((accumulator, currentValue) => {
                return accumulator + currentValue.numOfTroops;
            }, 0);
        },

        calculatePlanesInMission(plane_name) {
            let total = 0;
            const MISSIONS = {
                patrols: this.patrols,
                intercepts: this.intercepts,
            };
            for (const mission_type of Object.keys(MISSIONS)) {
                if (!MISSIONS[mission_type] || !MISSIONS[mission_type].length) {
                    continue;
                }
                for (let i = 0; i < MISSIONS[mission_type].length; i++) {
                    for (let j = 0; j < MISSIONS[mission_type][i].troops.length; j++) {
                        for (let k = 0; k < MISSIONS[mission_type][i].troops[j].troopsName.length; k++) {
                            if (MISSIONS[mission_type][i].troops[j].troopsName[k] === plane_name) {
                                total += MISSIONS[mission_type][i].troops[j].numOfTroops[k];
                            }
                        }
                    }
                }
            }
            return total;
        },

        getPlaneFromName(plane_name) {
            return this.planes.find(plane => plane.getName() === plane_name);
        },

        openTroopDetailsModal(troop) {
            if (troop) {
                this.setCurrentTroop(troop);
            } else {
                return;
            }
            document.getElementById('troop-details-modal').style.transform = 'translateY(-20px)';
            document.getElementById('troop-details-modal').style.opacity = '0';
            document.getElementById('troop-details-modal').style.display = 'block';
            document.getElementById('troop-details-modal').classList.add('show');
            setTimeout(() => {
                document.getElementById('troop-details-modal').style.transition =
                    'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
                document.getElementById('troop-details-modal').style.transform = 'translateY(0)';
                document.getElementById('troop-details-modal').style.opacity = '1';
            }, 0);
        },

        validateAndSendPatrol() {
            this.$store.dispatch('sendPatrol', {
                range: 50,
                attackOnSight: true,
                duration: 5,
            });
        },

        validateAndSendInterceptingAttack() {
            this.$store.dispatch('sendInterceptingAttack', {
                attackId: this.selectedAttack._id,
            });
        },
    },
};
</script>

<style scoped>
.modal {
    background: rgba(0, 0, 0, 0.5);
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.modal::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.modal::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.modal-header {
    border: none;
    display: flex;
    flex-direction: column;
}

.modal-header {
    background: linear-gradient(to right, rgba(0, 0, 0, 0), black, rgba(0, 0, 0, 0));
    display: flex;
    justify-content: center;
}

.modal-title {
    width: 60%;
    font-family: 'bebas', sans-serif;
    letter-spacing: 1px;
    text-align: center;
    padding-bottom: 0.75rem;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0), lightgray, rgba(0, 0, 0, 0)) 1;
    border-left: none;
    border-right: none;
    border-top: none;
}

.building-description {
    width: 60%;
    font-family: 'lato', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    margin-top: 0.5rem;
    margin-left: 1rem;
    padding-right: 1rem;
    max-height: 2.5rem;
    overflow-y: overlay;
    color: #b7b6b6;
    text-align: justify;
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.building-description::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.building-description::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.close {
    position: absolute;
    top: 16px;
    right: 24px;
    border: none;
    background: none;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.close:hover {
    opacity: 1;
}

.modal-content {
    font-family: 'sansation', sans-serif;
    top: 5vh;
    padding: 4rem;
    background-image: url('../assets/background.avif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0;
    color: ghostwhite;
    border: none;
}

.modal-body {
    padding-top: 3rem;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 2px;
    margin: 0;
    min-height: 2rem;
}

.col {
    flex: 1 1 auto;
    background: rgba(0, 0, 0, 0.5);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    gap: 0.5rem;
    display: flex;
    font-size: 0.875rem;
    align-items: center;
    min-width: 4.5rem;
}

.col.label {
    background: linear-gradient(to bottom, #2a2a2a, #181818);
    font-size: 1rem;
}

.reinforcements-dropdown-button > span {
    writing-mode: vertical-lr;
}

.btn {
    padding: 0.25rem;
}

.neutral-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgb(30, 30, 30), rgba(255, 255, 255, 0.2));
    border: 3px solid dimgray;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
}

.neutral-button:hover {
    background: linear-gradient(to bottom, rgba(40, 40, 40, 0.2), rgb(60, 60, 60));
    color: ghostwhite;
}

.blue-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgb(34, 41, 58), rgba(255, 255, 255, 0.2));
    border: 3px solid dodgerblue;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
    width: 100%;
}

.blue-button:hover {
    background: linear-gradient(to bottom, rgba(96, 95, 42, 0.2), rgb(52, 52, 70));
    color: ghostwhite;
}

.yellow-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.15), rgba(255, 255, 255, 0.15));
    border: 3px solid burlywood;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
    width: 100%;
}

.yellow-button:hover {
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.2), rgba(255, 255, 255, 0.2));
    color: ghostwhite;
}

.red-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.15), rgba(255, 200, 200, 0.15));
    border: 3px solid brown;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
    width: 100%;
}

.red-button:hover {
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.2), rgba(255, 200, 200, 0.2));
    color: ghostwhite;
}

.tooltip-transition-enter-active,
.tooltip-transition-leave-active {
    transition: filter 0.05s linear;
}

.tooltip-transition-enter-from,
.tooltip-transition-leave-to {
    filter: blur(3px);
}

.troop-image-wrapper {
    min-width: 2.5rem;
    height: 2.5rem;
    overflow: hidden;
}

.troop-image {
    width: 2.5rem;
    cursor: pointer;
}

.troop-name {
    color: burlywood;
    cursor: pointer;
}

.troop-name:hover {
    color: cornflowerblue;
}

.table-header {
    margin: 3rem 0 1rem 0;
    color: lightgoldenrodyellow;
}

.planes-capacity-wrapper {
    width: 50%;
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-bottom: 2rem;
}


.planes-table:deep(.t-col) {
    width: 100%;
}

.troop-image-wrapper {
    width: 2.5rem;
    height: 2.5rem;
    overflow: hidden;
}

.troop-image {
    width: 2.5rem;
    transform: translateY(-0.75rem);
}

.mission-name {
    color: lightgray;
}

.selected-attack {
    display: flex;
    gap: 0.25rem;
    align-items: center;
}

.city-icon-wrapper {
    width: fit-content;
}

.city-icon {
    height: 2rem;
}

.city-name-and-coords {
    display: flex;
    column-gap: 0.25rem;
    flex-wrap: wrap;
}

.city-coords {
    text-wrap: none;
}

.command-target-wrapper {
    display: flex;
    flex-direction: column;
    min-width: 8rem;
}

.command-target {
    color: burlywood;
    cursor: pointer;
}

.command-target:hover {
    color: cornflowerblue;
}

.attacks-dropdown-column,
.mission-planes-dropdown-column {
    position: relative;
}

.attacks-dropdown-wrapper,
.mission-planes-dropdown-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.attacks-dropdown,
.planes-dropdown {
    position: absolute;
    top: 3.5rem;
    right: -1rem;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 2px;
    background: black;
    padding: 2px;
    width: max-content;
}

.attacks-dropdown .col:not(.label),
.planes-dropdown .col:not(.label) {
    background: rgba(150, 150, 150, 0.125);
}

.attacks-dropdown .col:last-child {
    width: 4rem;
}

.active-missions-table .col {
    flex-basis: 20%;
}

.active-missions-table .col:first-child {
    flex-basis: 40%;
}

@media screen and (max-width: 1199px) {
    .modal-dialog {
        max-width: 90%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 991px) {
    .modal-dialog {
        max-width: 80%;
    }
    .modal-content {
        padding: 3rem 1rem;
    }
    .modal-title {
        width: 80%;
    }
    .building-description {
        width: 80%;
    }
    .planes-capacity-wrapper {
        width: 100%;
    }
}

@media screen and (max-width: 700px) {
    .col {
        font-size: 0.75rem;
        min-width: 3rem;
        padding: 0.25rem;
    }
    .col.label {
        font-size: 0.875rem;
    }
}

@media screen and (max-width: 576px) {
    .modal-dialog {
        max-width: 96%;
        margin: 0 auto;
    }
    .building-description {
        font-size: 0.75rem;
        line-height: 0.75rem;
    }
}

@media screen and (max-width: 450px) {
    .modal-content {
        padding: 3rem 0;
    }
    .modal-title {
        width: 100%;
    }
    .building-description {
        width: 100%;
    }
}

@media screen and (max-width: 350px) {
    .troop-name {
        display: none;
    }
    .planes-table .col:first-child {
        flex-basis: 6rem;
        justify-content: center;
    }
    .planes-table .col.label:first-child {
        justify-content: flex-start;
    }
    .planes-table .col:not(:first-child) {
        flex-basis: 50%;
    }
}

@media screen and (orientation: landscape) and (max-height: 800px) {
    .modal-content {
        transform: scale(0.8);
        top: -10vh;
    }
}
</style>

<template>
    <div
        id="test-modal"
        data-bs-backdrop="false"
        data-bs-keyboard="false"
        class="modal fade"
        tabindex="-1"
        role="dialog"
        data-bs-focus="false"
    >
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Armored Factory</h4>
                    <div class="modal-description">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur.
                    </div>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
                        <img src="../../../assets/close_icon.svg" width="20" height="20" aria-hidden="true" />
                    </button>
                </div>
                <div class="modal-body">
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur.
                    </p>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur.
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Modal',
};
</script>

<style scoped>
.modal {
    background: rgba(0, 0, 0, 0.5);
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.modal::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}

.modal::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.modal-dialog {
    max-width: 74rem;
    width: 96%;
    margin: 0 auto;
}

.modal-content {
    font-family: 'sansation', sans-serif;
    top: 5vh;
    padding: 3.5rem 0 0 0;
    background-image: url('../../../assets/background.avif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0;
    color: ghostwhite;
    border: none;
    outline: 1px solid silver;
}

.modal-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border: none;
    background: linear-gradient(to right, rgba(0, 0, 0, 0), black, rgba(0, 0, 0, 0));
	padding: 0 0 1rem 0;
}

.modal-title {
	max-width: 30rem;
    width: 80%;
    font-family: 'bebas', sans-serif;
    letter-spacing: 1px;
    text-align: center;
	font-size: 2.125rem;
	color: navajowhite;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0), white, rgba(0, 0, 0, 0)) 1;
    border-left: none;
    border-right: none;
    border-top: none;
}

.modal-description {
	max-width: 40rem;
    width: 90%;
    font-family: 'lato', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
	padding: 0 1rem;
	margin-top: 0.5rem;
    max-height: 2.5rem;
    overflow-y: overlay;
    color: #b7b6b6;
	text-align: justify;
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.modal-description::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.modal-description::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.close {
    position: absolute;
    top: 16px;
    right: 24px;
    border: none;
    background: none;
    opacity: 0.7;
	padding: 0;
    transition: opacity 0.25s;
}

.close img {
	width: 1.5rem;
	height: 1.5rem;
}

.close:hover {
    opacity: 1;
}

.modal-body {
    padding: 4rem 5%;
}

@media screen and (max-width: 991px) {
	.modal-body {
		padding: 6% 5%;
	}
}

@media screen and (max-width: 520px) {
	.modal-dialog {
		width: 100%;
	}
	.modal-content {
		padding: 3rem 0 0 0;
	}
	.close {
		top: 10px;
		right: 12px;
	}
	.modal-title {
		font-size: 1.5rem;
	}
	.modal-description {
		font-size: 0.75rem;
		line-height: 0.875rem;
		padding: 0 1rem;
		margin-top: 0.25rem;
		max-height: 2rem;
	}
}

/*@media screen and (max-width: 1199px) {*/
/*    .modal-dialog {*/
/*        max-width: 90%;*/
/*        margin: 0 auto;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 991px) {*/
/*    .modal-dialog {*/
/*        max-width: 80%;*/
/*    }*/
/*    .modal-content {*/
/*        padding: 3rem 1rem;*/
/*    }*/
/*    .modal-title {*/
/*        width: 80%;*/
/*    }*/
/*    .modal-description {*/
/*        width: 80%;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 576px) {*/
/*    .modal-dialog {*/
/*        max-width: 96%;*/
/*        margin: 0 auto;*/
/*    }*/
/*    .modal-description {*/
/*        font-size: 0.75rem;*/
/*        line-height: 0.75rem;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 450px) {*/
/*    .modal-content {*/
/*        padding: 3rem 0;*/
/*    }*/
/*    .modal-title {*/
/*        width: 100%;*/
/*    }*/
/*    .modal-description {*/
/*        width: 100%;*/
/*    }*/
/*}*/

/*@media screen and (orientation: landscape) and (max-height: 800px) {*/
/*    .modal-content {*/
/*        transform: scale(0.8);*/
/*        top: -10vh;*/
/*    }*/
/*}*/
</style>

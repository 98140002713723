import Collection from '@/classes/generic/Collection';

export default class RecruitmentCommandsCollection extends Collection {
	_isCountdownStarted = false;

	constructor(commands) {
		super(commands);
	}

	deleteItem(item) {
		super.deleteItem(item);
		if (this._items.size) {
			this.startCountdowns();
		} else {
			this._isCountdownStarted = false;
		}
	}

	startCountdowns() {
		this.getFirstItem().startCountdown();
		this._isCountdownStarted = true;
	}

	stopCountdowns() {
		this.getFirstItem().stopCountdown();
		this._isCountdownStarted = false;
	}

	isCountdownStarted() {
		return this._isCountdownStarted;
	}
}
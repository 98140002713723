import { AddedCityProperties, CITY_ADDITIONAL_PROPERTIES } from '@/utils/constants/actions';

export default {
    getHighlightedPins(state) {
        return state.highlightedPins;
    },

    getSelectedActionPin(state) {
        return state.selectedActionPin;
    },

    getMapScaleFactor(state) {
        return state.mapScaleFactor;
    },

    getAwaitingResponse(state) {
        return state.awaitingResponse;
    },

    getAttacks(state) {
        return state.currentCity.attacks;
    },

    getAttacksReturning(state) {
        return state.currentCity.attacksReturning;
    },

    getReinforcements(state) {
        return state.currentCity.reinforcements;
    },

    getReinforcementsReturning(state) {
        return state.currentCity.reinforcementsReturning;
    },

    getDetectSpiesMissions(state) {
        return state.currentCity.detectSpiesMissions;
    },

    getSpyMissions(state) {
        return state.currentCity.spyMovements;
    },

    getReturningSpies(state) {
        return state.currentCity.spyMovementsReturning;
    },

    getPatrols(state) {
        return state.currentCity.patrols;
    },

    getIntercepts(state) {
        return state.currentCity.attackIntercepts;
    },

    getReturningIntercepts(state) {
        return state.currentCity.attackInterceptsReturning;
    },

    getTransports(state) {
        return state.currentCity.transports;
    },

    getReturningTransports(state) {
        return state.currentCity.transportsReturning;
    },

    getForeignSpies(state) {
        return state.currentCity.foreignSpies;
    },

    getLastAttack(state) {
        if (state.currentCity.attacks.length) return state.currentCity.attacks[state.currentCity.attacks.length - 1];
    },

    getBuildings(state) {
        return state.currentCity.buildings;
    },

    getResources(state) {
        return {
            aluminum: state.currentCity.aluminum,
            oil: state.currentCity.oil,
            metal: state.currentCity.metal,
            rations: state.currentCity.rations,
            population: state.currentCity.population,
        };
    },

    getResourcesProductions(state) {
        return {
            aluminumProduction: state.currentCity.aluminumProduction,
            oilProduction: state.currentCity.oilProduction,
            metalProduction: state.currentCity.metalProduction,
            rationsProduction: state.currentCity.population,
        };
    },

    getTroops(state) {
        return state.currentCity.troops;
    },

    getTroopsCommands(state) {
        return state.currentCity.troopCommands;
    },

    getIsSubscribed(state) {
        return state.isSubscribed;
    },

    getMapMatrix(state) {
        return state.mapMatrix;
    },

    getAllCities(state) {
        return state.allCities;
    },

    getCurrentTroop(state) {
        return state.currentTroop;
    },

    getNewlyReceivedCity(state) {
        return state.newlyReceivedCity;
    },

    getAllUsersFromState(state) {
        return state.allUsers;
    },

    getUserInfo(state) {
        return {
            email: state.userState.email,
            username: state.userState.username,
            _id: state.userState._id,
            verified: state.userState.verified,
        };
    },

    getAllUserCities(state) {
        return state.userState.cities;
    },

    getCurrentCityName(state) {
        return state.currentCity.name;
    },

    getCurrentCityTile(state) {
        return state.currentCity.tile;
    },

    getCurrentCityId(state) {
        return state.currentCity._id;
    },

    getCurrentCityCoords(state) {
        return {
            x: state.currentCity.x,
            y: state.currentCity.y,
        };
    },

    getMapActionTarget(state) {
        return state.mapActionTarget;
    },

    getCoalitionInfo(state) {
        return state.userState.coalition;
    },

    getReports(state) {
        return state.userState.reports;
    },

    getRequests(state) {
        return state.userState.requests;
    },

    getCloseCoalitions(state) {
        return state.currentCity.closeCoalitions;
    },

    getCurrentCityOffers(state) {
        return state.currentCity.offers;
    },

    getOutsideOffers(state) {
        return state.offers;
    },

    getCurrentCityMapActions(state) {
        return state.currentCityMapActions.collection.getItems();
    },

    getMapArrows(state) {
        return state.mapArrows;
    },

    getCurrentCityFormattedTroops(state) {
        return state.currentCity.formattedTroops;
    },

    getSentReinforcements(state) {
        return state.currentCity.sentReinforcements;
    },

    getReceivedReinforcements(state) {
        return state.currentCity.receivedReinforcements;
    },

    getBuildingCommands(state) {
        return state.currentCity.buildingCommands?.getItems();
    },

    getBarracksRecruitmentCommands(state) {
        return state.currentCity[CITY_ADDITIONAL_PROPERTIES]?.[AddedCityProperties.BARRACKS_RECRUITMENT_COMMANDS]?.getItems();
    },

    getArmoredRecruitmentCommands(state) {
        return state.currentCity[CITY_ADDITIONAL_PROPERTIES]?.[AddedCityProperties.ARMORED_RECRUITMENT_COMMANDS]?.getItems();
    },

    getPlaneRecruitmentCommands(state) {
        return state.currentCity[CITY_ADDITIONAL_PROPERTIES]?.[AddedCityProperties.PLANE_RECRUITMENT_COMMANDS]?.getItems();
    },

    getGeneralRecruitmentCommands(state) {
        return state.currentCity[CITY_ADDITIONAL_PROPERTIES]?.[AddedCityProperties.GENERAL_RECRUITMENT_COMMANDS]?.getItems();
    },

    getAllRecruitmentCommands(state) {
        return state.currentCity[CITY_ADDITIONAL_PROPERTIES]?.[AddedCityProperties.ALL_RECRUITMENT_COMMANDS]?.getItems();
    },
    getRuler(state) {
        return state.currentCity.ruler;
    },
    getDefensiveStrategy(state) {
        return state.currentCity.defensiveStrategy;
    },
    getStrategies(state) {
        return state.userState.strategies;
    }
};

import { ActionTypeIdentifier, CityProperties } from '@/utils/constants/actions';
import MapAction from '@/classes/mapActions/MapAction';
import { TroopsIdentifier } from '@/utils/constants/troops';

export default class MapActionFactory {
    currentCityId = null;

    constructor() {}

    setCurrentCityId(city_id) {
        this.currentCityId = city_id;
    }

    createAction({ action_data, property_name }) {
        const isOutgoingAction = action_data.fromCity?._id === this.currentCityId;
        const hasGeneral = action_data.troops?.find(troop => troop.troopsName?.includes(TroopsIdentifier.GENERAL));
        const isArrived = action_data.arrived === true;
        const isOffer = action_data.isOffer === true;

        let ActionType = null;

        switch (property_name) {
            case CityProperties.ATTACKS: {
                if (isOutgoingAction) {
                    if (hasGeneral) {
                        ActionType = ActionTypeIdentifier.OUTGOING_ATTACK_WITH_GENERAL;
                    } else {
                        ActionType = ActionTypeIdentifier.OUTGOING_ATTACK;
                    }
                } else {
                    if (hasGeneral) {
                        ActionType = ActionTypeIdentifier.INCOMING_ATTACK_WITH_GENERAL;
                    } else {
                        ActionType = ActionTypeIdentifier.INCOMING_ATTACK;
                    }
                }
                break;
            }
            case CityProperties.RETURNING_ATTACKS: {
                if (hasGeneral) {
                    ActionType = ActionTypeIdentifier.RETURNING_ATTACK_WITH_GENERAL;
                } else {
                    ActionType = ActionTypeIdentifier.RETURNING_ATTACK;
                }
                break;
            }
            case CityProperties.PATROLS: {
                ActionType = ActionTypeIdentifier.PATROL;
                break;
            }
            case CityProperties.INTERCEPTS: {
                if (isOutgoingAction) {
                    ActionType = ActionTypeIdentifier.INCOMING_INTERCEPT;
                } else {
                    ActionType = ActionTypeIdentifier.OUTGOING_INTERCEPT;
                }
                break;
            }
            case CityProperties.RETURNING_INTERCEPTS: {
                ActionType = ActionTypeIdentifier.RETURNING_INTERCEPT;
                break;
            }
            case CityProperties.REINFORCEMENTS: {
                if (isArrived) {
                    break;
                }

                if (isOutgoingAction) {
                    ActionType = ActionTypeIdentifier.OUTGOING_REINFORCEMENT;
                } else {
                    ActionType = ActionTypeIdentifier.INCOMING_REINFORCEMENT;
                }
                break;
            }
            case CityProperties.RETURNING_REINFORCEMENTS: {
                ActionType = ActionTypeIdentifier.RETURNING_REINFORCEMENT;
                break;
            }
            case CityProperties.SPY_MISSIONS: {
                if (isOutgoingAction) {
                    ActionType = ActionTypeIdentifier.OUTGOING_SPY_MISSION;
                } else {
                    ActionType = ActionTypeIdentifier.INCOMING_SPY_MISSION;
                }
                break;
            }
            case CityProperties.RETURNING_SPY_MISSIONS: {
                ActionType = ActionTypeIdentifier.RETURNING_SPIES;
                break;
            }
            case CityProperties.DETECT_SPIES_MISSIONS: {
                ActionType = ActionTypeIdentifier.DETECT_SPIES_MISSION;
                break;
            }
            case CityProperties.TRANSPORTS: {
                if (isOffer) {
                    if (isOutgoingAction) {
                        ActionType = ActionTypeIdentifier.OUTGOING_TRADING_TRANSPORT;
                    } else {
                        ActionType = ActionTypeIdentifier.INCOMING_TRADING_TRANSPORT;
                    }
                } else {
                    if (isOutgoingAction) {
                        ActionType = ActionTypeIdentifier.OUTGOING_SEND_RESOURCES_TRANSPORT;
                    } else {
                        ActionType = ActionTypeIdentifier.INCOMING_SEND_RESOURCES_TRANSPORT;
                    }
                }
                break;
            }
            case CityProperties.RETURNING_TRANSPORTS: {
                if (isOffer) {
                    ActionType = ActionTypeIdentifier.RETURNING_TRADING_TRANSPORT;
                } else {
                    ActionType = ActionTypeIdentifier.RETURNING_SEND_RESOURCES_TRANSPORT;
                }
                break;
            }
        }

        if (!ActionType) {
            return null;
        }

        return new MapAction({
            action: action_data,
            action_type: ActionType,
        });
    }
}

<template>
    <select v-model="selectedStrategy" class="select-input mb-2">
        <!-- using false as value here instead of null/undefined because value is set to
		option text content if value is interpreted as missing (eg. is null or undefined) -->
        <option v-if="showNoSelectionOption" :value="false">No selection</option>
        <option
			v-for="strategy in strategies"
			:key="strategy.name"
			:value="strategy"
			:selected="strategy.name === selectedStrategy.name"
		>
            {{ strategy.name }}
        </option>
    </select>
    <StrategyPreview v-if="selectedStrategy" :strategy="selectedStrategy.strategy" />
</template>

<script>
import { TroopsIdentifier } from '@/utils/constants/troops';
import StrategyPreview from '@/components/generic/Strategy/StrategyPreview';

export default {
    name: 'StrategySelect',
    components: { StrategyPreview },
    props: {
        showNoSelectionOption: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            TroopsIdentifier,

            selectedStrategy: false,
        };
    },
    computed: {
        strategies() {
            return this.$store.getters.getStrategies;
        },
    },
    emits: ['strategy-change'],
    watch: {
        selectedStrategy: {
            handler(selected_strategy) {
                this.$emit('strategy-change', selected_strategy);
            },
        },
    },
};
</script>

<style scoped></style>

import { reactive } from 'vue';
import CollectionItem from '@/classes/generic/CollectionItem';
import RecruitmentProgress from '@/classes/recruitmentCommands/RecruitmentProgress';

export default class RecruitmentCommand extends CollectionItem {
    _originalData = null;
    _troop = null;
    _recruitmentProgress = null;

    constructor(command, troop) {
        super();

        this._originalData = command;
        this._troop = troop;
        this._recruitmentProgress = reactive(new RecruitmentProgress(this));
    }

    startCountdown() {
        this._recruitmentProgress?.startProgressUpdate();
    }

    stopCountdown() {
        this._recruitmentProgress?.stopProgressUpdate();
    }

    getId() {
        this._originalData?._id;
    }

    getRemainingTimeString() {
        return this._recruitmentProgress?.getRemainingTimeString();
    }

    getFinishTimeString() {
        return this._originalData?.finishDateToShow;
    }

    getTroopName() {
        return this._originalData?.name;
    }

    getRemainingTroopsToRecruit() {
        return this._recruitmentProgress?.getRemainingTroopsToRecruit();
    }
}

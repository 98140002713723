<template>
    <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">General Office</h5>
                    <div class="building-description">
                        {{ description }}
                    </div>
                    <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" @click="closeModal">
                        <img src="../../assets/close_icon.svg" width="20" height="20" aria-hidden="true" />
                    </button>
                </div>
                <div class="modal-body">
                    <ul class="nav nav-tabs" id="general-office-nav" role="tablist">
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link active"
                                id="commands"
                                data-bs-toggle="tab"
                                data-bs-target="#commands-page"
                                type="button"
                                role="tab"
                                aria-controls="commands-tab"
                                aria-selected="true"
                            >
                                Commands
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link"
                                id="espionage"
                                data-bs-toggle="tab"
                                data-bs-target="#espionage-page"
                                type="button"
                                role="tab"
                                aria-controls="espionage-tab"
                                aria-selected="false"
                            >
                                Espionage
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link"
                                id="reinforcements"
                                data-bs-toggle="tab"
                                data-bs-target="#reinforcements-page"
                                type="button"
                                role="tab"
                                aria-controls="reinforcements-tab"
                                aria-selected="false"
                            >
                                Reinforcements
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link"
                                id="commands"
                                data-bs-toggle="tab"
                                data-bs-target="#troops-page"
                                type="button"
                                role="tab"
                                aria-controls="troops-tab"
                                aria-selected="false"
                            >
                                Troops
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link"
                                id="recruitment"
                                data-bs-toggle="tab"
                                data-bs-target="#recruitment-page"
                                type="button"
                                role="tab"
                                aria-controls="recruitment-tab"
                                aria-selected="false"
                            >
                                Recruitment
                            </button>
                        </li>
                        <li class="nav-item" role="presentation">
                            <button
                                class="nav-link"
                                id="simulator"
                                data-bs-toggle="tab"
                                data-bs-target="#simulator-page"
                                type="button"
                                role="tab"
                                aria-controls="simulator-tab"
                                aria-selected="false"
                            >
                                Simulator
                            </button>
                        </li>
                        <li class="nav-filler" />
                    </ul>
                    <div class="tab-content" id="general-office-pages">
                        <div class="tab-pane fade show active" id="commands-page" role="tabpanel" aria-labelledby="commands-tab">
                            <div class="send-command-wrapper">
                                <div class="coords-wrapper">
                                    <div class="row">
                                        <div class="col label">Target</div>
                                    </div>
                                    <div class="row">
                                        <div class="col justify-content-around">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault4"
                                                    id="coords"
                                                    checked
                                                />
                                                <label class="form-check-label" for="coords">Coordinates</label>
                                            </div>
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault4"
                                                    id="city-name"
                                                />
                                                <label class="form-check-label" for="city-name">City Name</label>
                                            </div>
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault4"
                                                    id="player-name"
                                                />
                                                <label class="form-check-label" for="player-name">Player Name</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <input class="form-control" :placeholder="formattedTargetCity" />
                                        </div>
                                    </div>
                                </div>
                                <div class="command-buttons-wrapper">
                                    <button
                                        class="btn red-button"
                                        :disabled="awaitingResponse || isAttackButtonDisabled"
                                        @click="openConfigureActionModal"
                                    >
                                        Attack
                                    </button>
                                    <button
                                        class="btn yellow-button"
                                        :disabled="awaitingResponse || isReinforceButtonDisabled"
                                        @click="validateAndSendReinforcement"
                                    >
                                        Reinforce
                                    </button>
                                </div>
                            </div>
                            <div class="mb-1">Select what permissions to give your reinforcements:</div>
                            <select class="select-input mb-5" v-model="reinforcementType">
                                <option :value="null">No selection</option>
                                <option :value="ReinforcementType.STATION">Cannot leave target city</option>
                                <option :value="ReinforcementType.PARTIAL_CONTROL">
                                    Can go on missions, except reinforcements
                                </option>
                                <option :value="ReinforcementType.FULL_CONTROL">Can go an any mission</option>
                            </select>
                            <div class="troops-table-wrapper">
                                <div class="troops-table">
                                    <div class="row">
                                        <div class="col label">Infantry</div>
                                    </div>
                                    <div class="row">
                                        <div class="col label">Unit</div>
                                        <div class="col label">Owned</div>
                                        <div class="col label">Controlled</div>
                                    </div>
                                    <div v-for="troop in infantry" :key="troop.getName()" class="t-row">
                                        <TroopSelectRow
                                            :troop="troop"
                                            :command-type="CommandTypesForTroopSelection.ATTACK_OR_REINFORCE"
                                            :reinforcements-category="ReinforcementCategory.RECEIVED"
                                            :reinforcements-filter-type="ReinforcementFilterType.CONTROL_LEVEL"
                                            :reinforcements-filter-value="reinforcementControlLevel"
                                            :include-not-arrived="false"
                                        />
                                    </div>
                                </div>
                                <div class="troops-table">
                                    <div class="row">
                                        <div class="col label">Armored</div>
                                    </div>
                                    <div class="row">
                                        <div class="col label">Unit</div>
                                        <div class="col label">Owned</div>
                                        <div class="col label">Controlled</div>
                                    </div>
                                    <div v-for="troop in armored" :key="troop.getName()" class="t-row">
                                        <TroopSelectRow
                                            :troop="troop"
                                            :command-type="CommandTypesForTroopSelection.ATTACK_OR_REINFORCE"
                                            :reinforcements-category="ReinforcementCategory.RECEIVED"
                                            :reinforcements-filter-type="ReinforcementFilterType.CONTROL_LEVEL"
                                            :reinforcements-filter-value="reinforcementControlLevel"
                                            :include-not-arrived="false"
                                        />
                                    </div>
                                </div>
                                <div class="troops-table">
                                    <div class="row">
                                        <div class="col label">Planes</div>
                                    </div>
                                    <div class="row">
                                        <div class="col label">Unit</div>
                                        <div class="col label">Owned</div>
                                        <div class="col label">Controlled</div>
                                    </div>
                                    <div v-for="troop in planes" :key="troop.getName()" class="t-row">
                                        <TroopSelectRow
                                            :troop="troop"
                                            :command-type="CommandTypesForTroopSelection.ATTACK_OR_REINFORCE"
                                            :reinforcements-category="ReinforcementCategory.RECEIVED"
                                            :reinforcements-filter-type="ReinforcementFilterType.CONTROL_LEVEL"
                                            :reinforcements-filter-value="reinforcementControlLevel"
                                            :include-not-arrived="false"
                                        />
                                    </div>
                                </div>
                                <div class="troops-table">
                                    <div class="row">
                                        <div class="col label">Other</div>
                                    </div>
                                    <div class="row">
                                        <div class="col label">Unit</div>
                                        <div class="col label">Owned</div>
                                        <div class="col label">Controlled</div>
                                    </div>
                                    <div v-for="troop in specialTroops" :key="troop.getName()" class="t-row">
                                        <TroopSelectRow
                                            :troop="troop"
                                            :command-type="CommandTypesForTroopSelection.ATTACK_OR_REINFORCE"
                                            :reinforcements-category="ReinforcementCategory.RECEIVED"
                                            :reinforcements-filter-type="ReinforcementFilterType.CONTROL_LEVEL"
                                            :reinforcements-filter-value="reinforcementControlLevel"
                                            :include-not-arrived="false"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="espionage-page" role="tabpanel" aria-labelledby="espionage-tab">
                            <div class="table-wrapper">
                                <div class="troops-table">
                                    <div class="row label">
                                        <div class="col">Unit</div>
                                        <div class="col">Total</div>
                                        <div class="col">Owned</div>
                                        <div class="col">Controlled</div>
                                        <div class="col">Stationed</div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div class="troop-image-wrapper">
                                                <img
                                                    src="../../assets/icons/troops/spy-tall.avif"
                                                    alt=""
                                                    class="troop-image"
                                                    @click="openTroopDetailsModal(spies)"
                                                />
                                            </div>
                                            <span class="troop-name" @click="openTroopDetailsModal(spies)">{{ spies.name }}</span>
                                        </div>
                                        <div class="col">
                                            {{
                                                (
                                                    spies.numOfTroops +
                                                    spies.controlledReinforcements +
                                                    spies.uncontrolledReinforcements
                                                ).toLocaleString('ro')
                                            }}
                                        </div>
                                        <div class="col">
                                            {{ spies.numOfTroops.toLocaleString('ro') }}
                                        </div>
                                        <div class="col">
                                            {{ spies.controlledReinforcements.toLocaleString('ro') }}
                                        </div>
                                        <div class="col">
                                            {{ spies.uncontrolledReinforcements.toLocaleString('ro') }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="send-command-wrapper">
                                <div class="coords-wrapper">
                                    <div class="row">
                                        <div class="col label">Target</div>
                                    </div>
                                    <div class="row">
                                        <div class="col justify-content-around">
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault55"
                                                    id="coords"
                                                    checked
                                                />
                                                <label class="form-check-label" for="coords">Coordinates</label>
                                            </div>
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault55"
                                                    id="city-name"
                                                />
                                                <label class="form-check-label" for="city-name">City Name</label>
                                            </div>
                                            <div class="form-check">
                                                <input
                                                    class="form-check-input"
                                                    type="radio"
                                                    name="flexRadioDefault55"
                                                    id="player-name"
                                                />
                                                <label class="form-check-label" for="player-name">Player Name</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <input class="form-control" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <h4 class="table-header">Missions:</h4>
                            <div class="troops-table">
                                <div class="t-row t-header">
                                    <div class="t-col">Mission Name</div>
                                    <div class="t-col">City</div>
                                    <div class="t-col">Owned</div>
                                    <div class="t-col">Controlled</div>
                                    <div class="t-col">Action</div>
                                </div>
                                <div class="t-row">
                                    <div class="t-col mission-name">Detect infiltrated spies</div>
                                    <div class="t-col">
                                        <div class="city-icon-wrapper">
                                            <!-- TO DO: add dynamic city image -->
                                            <img src="../../assets/map_tiles/city_2_mobile.avif" class="city-icon" />
                                        </div>
                                        <span class="city-name-and-coords">
                                            {{ currentCityName }}
                                            <span class="city-coords">
                                                ( {{ currentCityCoords?.x }} | {{ currentCityCoords?.y }} )
                                            </span>
                                        </span>
                                    </div>
                                    <TroopSelectRow
                                        :troop="newSpies"
                                        :command-type="CommandTypesForTroopSelection.DETECT_SPIES"
                                        :reinforcements-category="ReinforcementCategory.RECEIVED"
                                        :reinforcements-filter-type="ReinforcementFilterType.CONTROL_LEVEL"
                                        :reinforcements-filter-value="ReinforcementControlLevel.PARTIAL_OR_FULL"
                                        :include-not-arrived="false"
                                        :is-troop-preview-visible="false"
                                    />
                                    <div class="t-col">
                                        <button
                                            class="btn blue-button"
                                            :disabled="awaitingResponse"
                                            @click="validateAndSendDetectSpiesMission"
                                        >
                                            Send
                                        </button>
                                    </div>
                                </div>
                                <div class="t-row">
                                    <div class="t-col mission-name">Espionage mission</div>
                                    <div class="t-col">
                                        <div v-if="targetCity?.name" class="city-icon-wrapper">
                                            <!-- TO DO: add dynamic city image -->
                                            <img src="../../assets/map_tiles/city_2_mobile.avif" class="city-icon" />
                                        </div>
                                        <!-- TO DO: change to target city name and coordinates -->
                                        <span v-if="targetCity?.name" class="city-name-and-coords">
                                            {{ targetCity.name }}
                                            <span class="city-coords">
                                                ( {{ targetCity.coords.x }} | {{ targetCity.coords.y }} )
                                            </span>
                                        </span>
                                    </div>
                                    <TroopSelectRow
                                        :troop="newSpies"
                                        :command-type="CommandTypesForTroopSelection.SPY"
                                        :reinforcements-category="ReinforcementCategory.RECEIVED"
                                        :reinforcements-filter-type="ReinforcementFilterType.CONTROL_LEVEL"
                                        :reinforcements-filter-value="ReinforcementControlLevel.PARTIAL_OR_FULL"
                                        :include-not-arrived="false"
                                        :is-troop-preview-visible="false"
                                    />
                                    <div class="t-col">
                                        <button
                                            class="btn blue-button"
                                            :disabled="awaitingResponse"
                                            @click="validateAndSendSpyMission"
                                        >
                                            Send
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <h4 class="table-header">Active Missions:</h4>
                            <div class="troops-table active-missions-table">
                                <div class="row">
                                    <div class="col label">Mission</div>
                                    <div class="col label">Spies</div>
                                    <div class="col label">Duration</div>
                                    <div class="col label button-column">Action</div>
                                </div>
                                <div v-for="spy_mission in spyMissions" :key="spy_mission.originalData._id" class="row">
                                    <div class="col">
                                        <div class="city-icon-wrapper">
                                            <!-- TO DO: add dynamic city image -->
                                            <img src="../../assets/map_tiles/city_2_mobile.avif" class="city-icon" />
                                        </div>
                                        <span class="city-name-and-coords">
                                            {{ spy_mission.actionDescription }} {{ spy_mission.target.name }}
                                        </span>
                                    </div>
                                    <div class="col">
                                        {{ numberOfSpiesPerMission[spy_mission.originalData._id].toLocaleString('ro') }}
                                    </div>
                                    <div class="col">{{ spy_mission.progressTracking.dynamicDurationString }}</div>
                                    <div class="col button-column">
                                        <button class="btn red-button" :disabled="awaitingResponse">Cancel</button>
                                    </div>
                                </div>
                            </div>
                            <h4 class="table-header">Infiltrated Spies:</h4>
                            <div class="troops-table">
                                <div class="t-row t-header">
                                    <div class="t-col">City</div>
                                    <div class="t-col">Controlled</div>
                                    <div class="t-col">Action</div>
                                </div>
                                <div v-for="foreign_spies in foreignSpies" :key="foreign_spies.toCity" class="t-row">
                                    <div class="t-col">
                                        {{ foreign_spies.toCity }}
                                    </div>
                                    <TroopSelectRow
                                        :troop="newSpies"
                                        :command-type="CommandTypesForTroopSelection.CALL_BACK_FROM_SPYING"
                                        :reinforcements-category="ReinforcementCategory.SPIES"
                                        :reinforcements-filter-type="ReinforcementFilterType.DESTINATION"
                                        :reinforcements-filter-value="foreign_spies.toCity"
                                        :include-not-arrived="false"
                                        :is-troop-preview-visible="false"
                                        :is-owned-troops-column-visible="false"
                                    />
                                    <div class="t-col">
                                        <button
                                            class="btn yellow-button"
                                            :disabled="awaitingResponse"
                                            @click="validateAndCallBackSpies(foreign_spies.toCity)"
                                        >
                                            Call Back
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="reinforcements-page" role="tabpanel" aria-labelledby="reinforcements-tab">
                            <h4 class="table-header">Sent Reinforcements:</h4>
                            <div class="troops-table t-wrapper">
                                <div class="t-header t-row">
                                    <div class="t-col">City</div>
                                    <div class="t-col">Troops</div>
                                    <div class="t-col">Action</div>
                                </div>
                                <div v-for="city in sentReinforcements" :key="city.id" class="t-row">
                                    <div class="t-col">
                                        {{ city.id }}
                                    </div>
                                    <div class="t-wrapper-col">
                                        <ExpandableSection
                                            :content-ref="$refs[`sent-${city.id}`]?.[0]"
                                            collapsed-label="See all troops"
                                            expanded-label="Hide troops"
                                        >
                                            <div :ref="`sent-${city.id}`">
                                                <div v-for="troop in city.troops" :key="troop.getName()" class="d-flex">
                                                    <TroopSelectRow
                                                        :troop="troop"
                                                        :command-type="CommandTypesForTroopSelection.CALL_BACK_FROM_REINFORCING"
                                                        :reinforcements-category="ReinforcementCategory.SENT"
                                                        :reinforcements-filter-type="ReinforcementFilterType.DESTINATION"
                                                        :include-not-arrived="true"
                                                        :reinforcements-filter-value="city.id"
                                                        :is-dropdown-always-available="true"
                                                        :is-owned-troops-column-visible="false"
                                                        :show-background="false"
                                                    />
                                                </div>
                                            </div>
                                        </ExpandableSection>
                                    </div>
                                    <div class="t-col">
                                        <button
                                            class="btn blue-button"
                                            :disabled="awaitingResponse"
                                            @click="validateAndCallBackReinforcements(city.id)"
                                        >
                                            Call Back
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <h4 class="table-header">Received Reinforcements:</h4>
                            <div class="troops-table t-wrapper">
                                <div class="t-header t-row">
                                    <div class="t-col">City</div>
                                    <div class="t-col">Troops</div>
                                    <div class="t-col">Action</div>
                                </div>
                                <div v-for="city in receivedReinforcements" :key="city.id" class="t-row">
                                    <div class="t-col">
                                        {{ city.id }}
                                    </div>
                                    <div class="t-wrapper-col">
                                        <ExpandableSection
                                            :content-ref="$refs[`received-${city.id}`]?.[0]"
                                            collapsed-label="See all troops"
                                            expanded-label="Hide troops"
                                        >
                                            <div :ref="`received-${city.id}`">
                                                <div v-for="troop in city.troops" :key="troop.getName()" class="d-flex">
                                                    <TroopSelectRow
                                                        :troop="troop"
                                                        :command-type="CommandTypesForTroopSelection.SEND_BACK_FROM_REINFORCING"
                                                        :reinforcements-category="ReinforcementCategory.RECEIVED"
                                                        :reinforcements-filter-type="ReinforcementFilterType.LATEST_ORIGIN"
                                                        :reinforcements-filter-value="city.id"
                                                        :include-not-arrived="true"
                                                        :is-dropdown-always-available="true"
                                                        :is-owned-troops-column-visible="false"
                                                        :show-background="false"
                                                    />
                                                </div>
                                            </div>
                                        </ExpandableSection>
                                    </div>
                                    <div class="t-col">
                                        <button
                                            class="btn blue-button"
                                            :disabled="awaitingResponse"
                                            @click="validateAndSendBackReinforcements(city.id)"
                                        >
                                            Send Back
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="tab-pane fade" id="troops-page" role="tabpanel" aria-labelledby="troops-tab">
                            <TroopsTab />
                        </div>
                        <div class="tab-pane fade" id="recruitment-page" role="tabpanel" aria-labelledby="recruitment-tab">
                            <BuildingRequirements :troop="general" />
                            <div
                                class="d-flex flex-row justify-content-between flex-wrap"
                                :class="{
                                    'opacity-50': !areBuildingRequirementsMet(general),
                                    'pointer-events-none': !areBuildingRequirementsMet(general),
                                }"
                            >
                                <TroopRecruitWindow :troop="general" />
                                <TroopCostWindow :troop="general" />
                            </div>
                            <div class="d-flex flex-column w-100 gap-2 mt-3">
                                <span>Recruiting</span>
                                <RecruitmentCommands :recruitment-building="BuildingIdentifier.GENERAL_OFFICE" />
                            </div>
                        </div>
                        <div class="tab-pane fade" id="simulator-page" role="tabpanel" aria-labelledby="simulator-tab">
                            <div class="troops-table">
                                <div class="row label">
                                    <div class="col">Unit</div>
                                    <div class="col">Attacker</div>
                                    <div class="col">Defender</div>
                                    <div class="col no-background"></div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="troop-image-wrapper">
                                            <img src="../../assets/icons/troops/infantryman-tall.avif" class="troop-image" />
                                        </div>
                                        <span class="troop-name">Infantryman</span>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="select-input" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="select-input" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col no-background">
                                        <button class="btn yellow-button add-button">+</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="troop-image-wrapper">
                                            <img src="../../assets/icons/troops/infantryman-tall.avif" class="troop-image" />
                                        </div>
                                        <span class="troop-name">Infantryman</span>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="select-input" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="select-input" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col no-background">
                                        <button class="btn yellow-button add-button">+</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="troop-image-wrapper">
                                            <img src="../../assets/icons/troops/infantryman-tall.avif" class="troop-image" />
                                        </div>
                                        <span class="troop-name">Infantryman</span>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="select-input" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="select-input" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col no-background">
                                        <button class="btn yellow-button add-button">+</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="troop-image-wrapper">
                                            <img src="../../assets/icons/troops/infantryman-tall.avif" class="troop-image" />
                                        </div>
                                        <span class="troop-name">Infantryman</span>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="select-input" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="select-input" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col no-background">
                                        <button class="btn yellow-button add-button">+</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="troop-image-wrapper">
                                            <img src="../../assets/icons/troops/infantryman-tall.avif" class="troop-image" />
                                        </div>
                                        <span class="troop-name">Infantryman</span>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="select-input" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="select-input" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col no-background">
                                        <button class="btn yellow-button add-button">+</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="troop-image-wrapper">
                                            <img src="../../assets/icons/troops/infantryman-tall.avif" class="troop-image" />
                                        </div>
                                        <span class="troop-name">Infantryman</span>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="select-input" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="select-input" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col no-background">
                                        <button class="btn yellow-button add-button">+</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="troop-image-wrapper">
                                            <img src="../../assets/icons/troops/infantryman-tall.avif" class="troop-image" />
                                        </div>
                                        <span class="troop-name">Infantryman</span>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="select-input" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="select-input" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col no-background">
                                        <button class="btn yellow-button add-button">+</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="troop-image-wrapper">
                                            <img src="../../assets/icons/troops/infantryman-tall.avif" class="troop-image" />
                                        </div>
                                        <span class="troop-name">Infantryman</span>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="select-input" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="select-input" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col no-background">
                                        <button class="btn yellow-button add-button">+</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="troop-image-wrapper">
                                            <img src="../../assets/icons/troops/infantryman-tall.avif" class="troop-image" />
                                        </div>
                                        <span class="troop-name">Infantryman</span>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="select-input" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="select-input" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col no-background">
                                        <button class="btn yellow-button add-button">+</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="troop-image-wrapper">
                                            <img src="../../assets/icons/troops/infantryman-tall.avif" class="troop-image" />
                                        </div>
                                        <span class="troop-name">Infantryman</span>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="select-input" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="select-input" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col no-background">
                                        <button class="btn yellow-button add-button">+</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="troop-image-wrapper">
                                            <img src="../../assets/icons/troops/infantryman-tall.avif" class="troop-image" />
                                        </div>
                                        <span class="troop-name">Infantryman</span>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="select-input" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="select-input" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col no-background">
                                        <button class="btn yellow-button add-button">+</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="troop-image-wrapper">
                                            <img src="../../assets/icons/troops/infantryman-tall.avif" class="troop-image" />
                                        </div>
                                        <span class="troop-name">Infantryman</span>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="select-input" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="select-input" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col no-background">
                                        <button class="btn yellow-button add-button">+</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="troop-image-wrapper">
                                            <img src="../../assets/icons/troops/infantryman-tall.avif" class="troop-image" />
                                        </div>
                                        <span class="troop-name">Infantryman</span>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="select-input" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="select-input" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col no-background">
                                        <button class="btn yellow-button add-button">+</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="troop-image-wrapper">
                                            <img src="../../assets/icons/troops/infantryman-tall.avif" class="troop-image" />
                                        </div>
                                        <span class="troop-name">Infantryman</span>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="select-input" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="select-input" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col no-background">
                                        <button class="btn yellow-button add-button">+</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="troop-image-wrapper">
                                            <img src="../../assets/icons/troops/infantryman-tall.avif" class="troop-image" />
                                        </div>
                                        <span class="troop-name">Infantryman</span>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="select-input" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="select-input" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col no-background">
                                        <button class="btn yellow-button add-button">+</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="troop-image-wrapper">
                                            <img src="../../assets/icons/troops/infantryman-tall.avif" class="troop-image" />
                                        </div>
                                        <span class="troop-name">Infantryman</span>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="select-input" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="select-input" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col no-background">
                                        <button class="btn yellow-button add-button">+</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="troop-image-wrapper">
                                            <img src="../../assets/icons/troops/infantryman-tall.avif" class="troop-image" />
                                        </div>
                                        <span class="troop-name">Infantryman</span>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="select-input" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col">
                                        <input class="form-control" />
                                        <select class="select-input" aria-label="">
                                            <option selected>100%</option>
                                            <option value="1">75%</option>
                                            <option value="2">50%</option>
                                            <option value="3">25%</option>
                                        </select>
                                    </div>
                                    <div class="col no-background">
                                        <button class="btn yellow-button add-button">+</button>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <div class="troop-image-wrapper">
                                            <img src="../../assets/icons/buildings/fortifications_mini_1.avif" class="troop-image" />
                                        </div>
                                        <span class="troop-name">Wall</span>
                                    </div>
                                    <div class="col"></div>
                                    <div class="col">Level: <input class="form-control" /></div>
                                    <div class="col no-background"></div>
                                </div>
                                <div class="row">
                                    <div class="col">Night Bonus</div>
                                    <div class="col"></div>
                                    <div class="col">
                                        <div class="form-check">
                                            <input class="form-check-input" type="checkbox" value="" id="night-bonus-check" />
                                            <label class="form-check-label" for="night-bonus-check"> +100% to Defense </label>
                                        </div>
                                    </div>
                                    <div class="col no-background"></div>
                                </div>
                                <div class="row">
                                    <div class="col">Luck (-25% to 25%)</div>
                                    <div class="col">
                                        <input class="form-control" />
                                    </div>
                                    <div class="col"></div>
                                    <div class="col no-background"></div>
                                </div>
                                <div class="row">
                                    <div class="col">
                                        <button class="btn yellow-button" :disabled="awaitingResponse">Simulate</button>
                                    </div>
                                    <div class="col">
                                        <button class="btn red-button" :disabled="awaitingResponse">Reset Attackers</button>
                                    </div>
                                    <div class="col">
                                        <button class="btn red-button" :disabled="awaitingResponse">Reset Defenders</button>
                                    </div>
                                    <div class="col no-background"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { paramCase } from 'change-case';
import {
    TroopsIdentifier,
    InfantryTroops,
    ArmoredTroops,
    Planes,
    SpecialTroops,
    CommandTypesForTroopSelection,
    ReinforcementFilterType,
    ReinforcementControlLevel,
    TroopTypes,
} from '@/utils/constants/troops';
import { ActionTypeIdentifier, ReinforcementType, ReinforcementCategory } from '@/utils/constants/actions';
import TroopSelectRow from '@/components/generic/TroopSelect/TroopSelectRow';
import ExpandableSection from '@/components/generic/ExpandableSection';
import BuildingRequirements from '@/components/RecruitmentBuildings/BuildingRequirements';
import TroopRecruitWindow from '@/components/RecruitmentBuildings/TroopRecruitWindow';
import TroopCostWindow from '@/components/RecruitmentBuildings/TroopCostWindow';
import RecruitmentCommands from '@/components/RecruitmentBuildings/RecruitmentCommands';
import areBuildingRequirementsMet from '@/components/RecruitmentBuildings/helpers/areBuildingRequirementsMet';
import { BuildingIdentifier } from '@/utils/constants/city';
import TroopsTab from "@/components/GeneralsOffice/TroopsTab";

export default {
    name: 'GeneralsOfficeModal',
    props: {
        awaitingResponse: {
            type: Boolean,
            default: false,
        },
        cityId: {
            type: String,
            default: '',
        },
        troops: {
            type: Object,
            default: () => {},
        },
        buildings: {
            type: Array,
            default: () => [],
        },
        description: {
            type: String,
            default: '',
        },
        setCurrentTroop: {
            type: Function,
            default: () => null,
        },
        setCurrentBuilding: {
            type: Function,
            default: () => null,
        },
        mapActions: {
            type: Set,
            default: () => new Set(),
        },
        foreignSpies: {
            type: Array,
            default: () => [],
        },
        currentCityName: {
            type: String,
            default: '',
        },
        currentCityCoords: {
            type: Object,
            default: () => {},
        },
        targetCity: {
            type: Object,
            default: () => {},
        },
    },
    components: {
		TroopsTab,
        ExpandableSection,
        TroopSelectRow,
        BuildingRequirements,
        TroopRecruitWindow,
        TroopCostWindow,
        RecruitmentCommands,
    },
    data() {
        return {
            TroopsIdentifier,
            InfantryTroops,
            ArmoredTroops,
            Planes,
            SpecialTroops,
            CommandTypesForTroopSelection,
            ReinforcementType,
            ReinforcementFilterType,
            ReinforcementControlLevel,
            ReinforcementCategory,
            TroopTypes,
            BuildingIdentifier,

            reinforcementType: null,
        };
    },
    computed: {
        reinforcementControlLevel() {
            switch (this.reinforcementType) {
                default:
                case null:
                case ReinforcementType.STATION:
                    return ReinforcementControlLevel.PARTIAL_OR_FULL;
                case ReinforcementType.PARTIAL_CONTROL:
                case ReinforcementType.FULL_CONTROL:
                    return ReinforcementControlLevel.FULL;
            }
        },
        isAttackButtonDisabled() {
            return this.reinforcementType !== null;
        },
        isReinforceButtonDisabled() {
            return this.reinforcementType === null;
        },
        formattedTroops() {
            return this.$store.getters.getCurrentCityFormattedTroops;
        },
        infantry() {
            return this.formattedTroops.filter(troop => troop.getType() === TroopTypes.INFANTRY);
        },
        armored() {
            return this.formattedTroops.filter(troop => troop.getType() === TroopTypes.ARMORED);
        },
        planes() {
            return this.formattedTroops.filter(troop => troop.getType() === TroopTypes.PLANE);
        },
        specialTroops() {
            return this.formattedTroops.filter(troop => SpecialTroops.includes(troop.getName()));
        },
        general() {
            return this.formattedTroops.find(troop => troop.getName() === TroopsIdentifier.GENERAL);
        },
        // !!! rename newSpies to spies when refactor is done
        newSpies() {
            return this.formattedTroops.find(troop => troop.getName() === TroopsIdentifier.SPY);
        },
        spies() {
            return this.troops.soldiers.find(troop => troop.name === TroopsIdentifier.SPY);
        },
        spyMissions() {
            const RelevantActions = [
                ActionTypeIdentifier.DETECT_SPIES_MISSION,
                ActionTypeIdentifier.INCOMING_SPY_MISSION,
                ActionTypeIdentifier.OUTGOING_SPY_MISSION,
                ActionTypeIdentifier.RETURNING_SPIES,
            ];
            const FilteredActions = new Set();

            this.mapActions.forEach(action => {
                if (RelevantActions.includes(action.actionType)) {
                    FilteredActions.add(action);
                }
            });
            return FilteredActions;
        },
        numberOfSpiesPerMission() {
            const numOfSpiesObj = {};
            this.spyMissions.forEach(mission => {
                numOfSpiesObj[mission.originalData._id] = mission.originalData.troops.reduce((accumulator, currentValue) => {
                    return accumulator + Number(currentValue.numOfTroops);
                }, 0);
            });
            return numOfSpiesObj;
        },
        sentReinforcements() {
            return this.$store.getters.getSentReinforcements;
        },
        receivedReinforcements() {
            return this.$store.getters.getReceivedReinforcements;
        },
        formattedTargetCity() {
            if (!this.targetCity) {
                return;
            }
            const city = this.targetCity;
            return `${city.name}` + ' ( ' + `${city.coords.x}` + ' | ' + `${city.coords.y}` + ' )';
        },
    },
    methods: {
        paramCase,
        areBuildingRequirementsMet,

        openConfigureActionModal() {
            document.getElementById('configure-action-modal').style.transform = 'translateY(-20px)';
            document.getElementById('configure-action-modal').style.opacity = '0';
            document.getElementById('configure-action-modal').style.display = 'block';
            document.getElementById('configure-action-modal').classList.add('show');
            setTimeout(() => {
                document.getElementById('configure-action-modal').style.transition =
                    'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
                document.getElementById('configure-action-modal').style.transform = 'translateY(0)';
                document.getElementById('configure-action-modal').style.opacity = '1';
            }, 0);
        },

        openTroopDetailsModal(troop) {
            if (troop) {
                this.setCurrentTroop(troop);
            } else {
                return;
            }
            document.getElementById('troop-details-modal').style.transform = 'translateY(-20px)';
            document.getElementById('troop-details-modal').style.opacity = '0';
            document.getElementById('troop-details-modal').style.display = 'block';
            document.getElementById('troop-details-modal').classList.add('show');
            setTimeout(() => {
                document.getElementById('troop-details-modal').style.transition =
                    'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
                document.getElementById('troop-details-modal').style.transform = 'translateY(0)';
                document.getElementById('troop-details-modal').style.opacity = '1';
            }, 0);
        },

        closeModal() {
            document.getElementById('generals-office-modal').style.transition =
                'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
            document.getElementById('generals-office-modal').style.transform = 'translateY(-20px)';
            document.getElementById('generals-office-modal').style.opacity = '0';
            setTimeout(() => {
                document.getElementById('generals-office-modal').style.display = 'none';
                document.getElementById('generals-office-modal').classList.remove('show');
                document.getElementById('generals-office-modal').style.removeProperty('transform');
                document.getElementById('generals-office-modal').style.removeProperty('opacity');
            }, 150);
        },

        validateAndSendReinforcement() {
            this.$store.dispatch('sendReinforcement', {
                payload: {
                    targetId: this.targetCity._id,
                },
                reinforcement_filters: {
                    category: ReinforcementCategory.RECEIVED,
                    filter_type: ReinforcementFilterType.CONTROL_LEVEL,
                    filter_value: this.reinforcementControlLevel,
                    include_not_arrived: false,
                },
                reinforcement_type: this.reinforcementType,
            });
        },

        validateAndSendDetectSpiesMission() {
            this.$store.dispatch('sendDetectSpiesMission');
        },

        validateAndSendSpyMission() {
            this.$store.dispatch('sendSpyMission', {
                targetId: this.targetCity._id,
            });
        },

        validateAndCallBackSpies(city_id) {
            this.$store.dispatch('callBackSpies', city_id);
        },

        validateAndCallBackReinforcements(city_id) {
            this.$store.dispatch('callBackReinforcements', city_id);
        },

        validateAndSendBackReinforcements(city_id) {
            this.$store.dispatch('sendBackReinforcements', city_id);
        },
    },
};
</script>

<style scoped>
.modal {
    background: rgba(0, 0, 0, 0.5);
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.modal::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.modal::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.modal-header {
    border: none;
    display: flex;
    flex-direction: column;
}

.modal-header {
    background: linear-gradient(to right, rgba(0, 0, 0, 0), black, rgba(0, 0, 0, 0));
    display: flex;
    justify-content: center;
}

.modal-title {
    width: 60%;
    font-family: 'bebas', sans-serif;
    letter-spacing: 1px;
    text-align: center;
    padding-bottom: 0.75rem;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0), lightgray, rgba(0, 0, 0, 0)) 1;
    border-left: none;
    border-right: none;
    border-top: none;
    font-size: 1.5rem;
}

.building-description {
    width: 60%;
    font-family: 'lato', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    margin-top: 0.5rem;
    margin-left: 1rem;
    padding-right: 1rem;
    max-height: 2.5rem;
    overflow-y: overlay;
    color: #b7b6b6;
    text-align: justify;
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.building-description::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.building-description::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.close {
    position: absolute;
    top: 16px;
    right: 24px;
    border: none;
    background: none;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.close:hover {
    opacity: 1;
}

.modal-content {
    font-family: 'sansation', sans-serif;
    top: 5vh;
    padding: 4rem;
    /*background-image: url('../assets/background.avif');*/
    /*background-size: 100%;*/
    /*background-position: center;*/
    /*background-repeat: no-repeat repeat;*/
    background-image: linear-gradient(to bottom, transparent 750px, rgb(30, 30, 30) 1000px), url('../../assets/background.avif');
    background-size: auto, auto 1000px;
    background-position: center top;
    background-repeat: no-repeat;
    border-radius: 0;
    color: ghostwhite;
    border: none;
}

.modal-body {
    padding: 3rem 1rem 1rem 1rem;
}

.nav-tabs {
    border: none;
}

.nav-tabs .nav-link {
    border-radius: 0;
    border: 2px solid #434343;
    border-bottom: none;
    color: #afafaf;
    transform: translateY(15px);
}

.nav-tabs .nav-item {
    margin-right: 0.25rem;
}

.nav-tabs .nav-item:nth-last-child(2) {
    margin-right: 0;
}

.nav-tabs .nav-link:hover {
    color: lightgray;
    border: 2px solid #434343;
    border-bottom: none;
}

.nav-tabs .nav-link.active {
    background: none;
    border: 2px solid #434343;
    border-bottom: none;
    color: ghostwhite;
    padding-top: 1rem;
    padding-bottom: 1rem;
    transform: translateY(-1px);
}

.nav-filler {
    flex-grow: 1;
    border-bottom: 2px solid #434343;
}

.tab-pane {
    padding: 3rem 1.5rem 1.5rem 1.5rem;
    background: none;
    border: 2px solid #434343;
    border-top: none;
}

.row {
    flex-wrap: nowrap;
    gap: 2px;
    margin: 0;
    min-height: 2rem;
}

.label .col {
    background: linear-gradient(to bottom, #2a2a2a, #181818);
    color: ghostwhite;
    height: 3rem;
    display: flex;
    align-items: center;
    font-size: 1rem;
}

.col {
    position: relative;
    flex: 1 1 auto;
    background: rgba(0, 0, 0, 0.5);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    gap: 0.5rem;
    display: flex;
    font-size: 0.875rem;
    align-items: center;
    min-width: 4.5rem;
}

.col.label {
    background: linear-gradient(to bottom, #2a2a2a, #181818);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type='number'] {
    -moz-appearance: textfield;
}

.form-control {
    width: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    border: 1px solid whitesmoke;
    border-radius: 0;
    padding-left: 1rem;
    opacity: 0.8;
    color: ghostwhite;
    height: 2rem;
    text-align: center;
}

.form-control:focus {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    color: ghostwhite;
    border: 1px solid ghostwhite;
}

.form-control:-webkit-autofill,
.form-control:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px #1a1a1a inset;
    -webkit-text-fill-color: ghostwhite;
    caret-color: ghostwhite;
}

.form-control:disabled {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    color: ghostwhite;
    border: 1px solid ghostwhite;
    opacity: 0.25;
}

.form-check-input {
    cursor: pointer;
}

.form-check-input:checked {
    background-color: #d34848;
    border: none;
    background-size: 10px;
}

.form-check-input:focus {
    box-shadow: none;
}

.btn {
    padding: 0.25rem;
}

.red-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.15), rgba(255, 200, 200, 0.15));
    border: 3px solid brown;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
    width: 100%;
}

.red-button:hover {
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.2), rgba(255, 200, 200, 0.2));
    color: ghostwhite;
}

.yellow-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.15), rgba(255, 255, 255, 0.15));
    border: 3px solid burlywood;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
    width: 100%;
}

.yellow-button:hover {
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.2), rgba(255, 255, 255, 0.2));
    color: ghostwhite;
}

.blue-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgb(34, 41, 58), rgba(255, 255, 255, 0.2));
    border: 3px solid dodgerblue;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
    width: 100%;
}

.blue-button:hover {
    background: linear-gradient(to bottom, rgba(96, 95, 42, 0.2), rgb(52, 52, 70));
    color: ghostwhite;
}

.add-button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    width: 1.5rem;
    height: 1.5rem;
    font-size: 1.25rem !important;
}

/*          COMMANDS          */

.send-command-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
}

.coords-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 80%;
}

.command-buttons-wrapper {
    width: 18%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

#commands-page .troops-table {
    width: 49%;
}

.troops-table-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.coords-wrapper .form-control {
    text-align: left;
}

#commands-page .troops-table .form-control,
#simulator-page .troops-table .form-control {
    padding: 0.25rem;
    font-size: 0.75rem;
    text-align: left;
}

#commands-page .troops-table .col:first-child {
    width: 3rem;
}

/*          ESPIONAGE          */

#espionage-page .send-command-wrapper {
    justify-content: center;
    margin-bottom: 0;
}

#espionage-page .table-wrapper {
    margin-bottom: 4rem;
}

#espionage-page .troop-name {
    color: burlywood;
    cursor: pointer;
}

#espionage-page .troop-name:hover {
    color: cornflowerblue;
}

.active-missions-table .col {
    flex-basis: 20%;
}

.active-missions-table .col:first-child {
    flex-basis: 40%;
}

#espionage-page .troops-table .form-control {
    padding: 0.25rem;
    font-size: 0.75rem;
    text-align: left;
}

.table-header {
    /*width: 80%;*/
    margin: 3rem 0 1rem 0;
    color: lightgoldenrodyellow;
}

.mission-name {
    color: lightgray;
}

.city-icon-wrapper {
    width: fit-content;
}

.city-icon {
    height: 2rem;
}

.city-name-and-coords {
    display: flex;
    column-gap: 0.25rem;
    flex-wrap: wrap;
}

.city-coords {
    text-wrap: none;
}

/*          REINFORCEMENTS          */

#reinforcements-page .troop-name {
    color: burlywood;
    cursor: pointer;
}

#reinforcements-page .troop-name:hover {
    color: cornflowerblue;
}

#reinforcements-page .table-header {
    margin-left: 0;
}

#reinforcements-page .table-header:first-child {
    margin-top: 0;
}

/*          TROOPS          */

.troops-table {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-bottom: 1rem;
}

#troops-page .troops-table .col {
    flex-basis: 15%;
}

#troops-page .troops-table .col:first-child {
    flex-basis: 40%;
}

#troops-page .troop-name {
    color: burlywood;
    cursor: pointer;
}

#troops-page .troop-name:hover {
    color: cornflowerblue;
}

.troop-image-wrapper {
    min-width: 2.5rem;
    height: 2.5rem;
    overflow: hidden;
}

.troop-image {
    width: 2.5rem;
    cursor: pointer;
}

/*          RECRUITMENT         */

/*          SIMULATOR         */

#simulator-page .no-background {
    background: none;
    flex-basis: 0;
    min-width: 2rem;
    justify-content: center;
    padding: 0 0 0 0.5rem;
}

@media screen and (max-width: 1199px) {
    .modal-dialog {
        max-width: 90%;
        margin: 0 auto;
    }
    .modal-title {
        width: 60%;
    }
    .building-description {
        width: 60%;
    }
}

@media screen and (max-width: 970px) {
    .modal-content {
        padding: 3rem 1rem;
    }
    .modal-title {
        width: 80%;
    }
    .building-description {
        width: 80%;
    }
    #espionage-page .troops-table,
    #espionage-page .coords-wrapper {
        width: 100%;
    }
    .table-header {
        margin: 3rem auto 1rem 0;
    }
}

@media screen and (max-width: 850px) {
    .col,
    .label .col {
        font-size: 0.75rem;
        min-width: 3rem;
        padding: 0.25rem;
    }
    .col {
        gap: 0.25rem;
    }
    .form-check-input {
        transform: scale(0.8);
    }
    .nav-link {
        font-size: 0.875rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    .trade-offers-wrapper .col,
    .created-offers-wrapper .col {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
    }
    .btn {
        font-size: 0.75rem;
        padding: 0.25rem;
    }
    .modal-content {
        padding: 4rem 0 1rem 0;
    }
    #commands-page,
    #espionage-page,
    #troops-page,
    #recruitment-page,
    #simulator-page {
        padding: 3rem 0.5rem 0.5rem 0.5rem;
    }
    .form-control {
        padding: 0.25rem;
    }
}

@media screen and (max-width: 790px) {
    .reinforcements-dropdown-wrapper .col {
        width: 20%;
    }

    .reinforcements-dropdown-wrapper .col .troop-name {
        display: none;
    }

    .reinforcements-dropdown-wrapper .col:last-child {
        min-width: unset;
    }
}

@media screen and (max-width: 650px) {
    #commands-page .troops-table .col {
        flex-direction: column;
    }
    #commands-page .troops-table .form-control {
        height: 1.25rem;
    }
    #simulator-page .select-input {
        background-position: right 0.5rem center;
        background-size: 8px 12px;
    }
}

@media screen and (max-width: 576px) {
    .modal-dialog {
        max-width: 96%;
    }
    .building-description {
        width: 100%;
        font-size: 0.75rem;
        line-height: 0.75rem;
    }
}

@media screen and (max-width: 500px) {
    .nav-tabs .nav-item {
        margin-right: 0.125rem;
    }
    .nav-link {
        font-size: 0.75rem;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
    }
    #espionage-page .troops-table .col:first-child,
    #troops-page .troops-table .col:first-child {
        flex-basis: 2rem;
    }
    #simulator-page .troops-table .col:first-child {
        flex-basis: 10rem;
    }
    #espionage-page .troop-name,
    #troops-page .troop-name,
    #simulator-page .troop-name {
        display: none;
    }
    #simulator-page .troops-table .col {
        flex-direction: column;
    }
}

@media screen and (max-width: 460px) {
    #commands-page .troops-table {
        width: 100%;
    }
    #commands-page .troops-table .col {
        flex-direction: row;
    }
    #recruitment-page {
        padding-top: 6rem;
    }
    #commands-page .troops-table .form-control {
        font-size: 0.625rem;
        height: 2rem;
    }
    .modal-body {
        padding: 5rem 1rem 1rem 1rem;
    }
    .modal-title {
        width: 100%;
    }
    .building-description {
        width: 100%;
    }
}

@media screen and (max-width: 380px) {
    #espionage-page .troops-table .col,
    #troops-page .troops-table .col {
        font-size: 0.625rem;
    }
}

@media screen and (orientation: landscape) and (max-height: 800px) {
    .modal-content {
        transform: scale(0.8);
        top: -10vh;
    }
}
</style>

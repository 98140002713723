import { reactive } from 'vue';
import CollectionItem from '@/classes/generic/CollectionItem';
import BuildingProgress from "@/classes/buildingCommands/BuildingProgress";

export default class BuildingCommand extends CollectionItem {
    _originalData = null;
    _building = null;
    _buildingProgress = null;

    constructor(command, building) {
        super();

        this._originalData = command;
        this._building = building;
        this._buildingProgress = reactive(new BuildingProgress(this));
    }

    startCountdown() {
        this._buildingProgress?.startProgressUpdate();
    }

    stopCountdown() {
        this._buildingProgress?.stopProgressUpdate();
    }

    getId() {
        this._originalData?._id;
    }

    getRemainingTimeString() {
        return this._buildingProgress?.getRemainingTimeString();
    }

    getFinishTimeString() {
        return this._originalData?.finishDateToShow;
    }

    getBuildingName() {
        return this._originalData?.name;
    }

    getLevelToBuild() {
        return this._originalData?.initialLevel + 1;
    }

    getNextLevelBuildingAppearance() {
        for (let i = this._building?.appearance?.length; i >= 0; i--) {
            if (this.getLevelToBuild() >= this._building?.appearance[i]) {
                return i + 2;
            }
        }
        return 1;
    }
}

import Troop from '@/classes/troops/Troop';

export default class General extends Troop {
    _generals = null;

    _selectedGeneral = null;

    constructor({ generals, ...rest }) {
        super(rest);
        this._generals = generals;
    }

    getGenerals() {
        return this._generals;
    }

    setSelectedGeneral(general_name) {
        const FoundGeneral = this.getGenerals().find(general => general.name === general_name) || null;
        this._selectedGeneral = FoundGeneral;
    }

    getSelectedGeneralName() {
        return this._selectedGeneral?.name;
    }

    getSelectedGeneralBonusString() {
        return this._selectedGeneral ? `+${this._selectedGeneral.ammount} ${this._selectedGeneral.bonus}` : '';
    }

    getGeneralBonusString(general_name) {
        const FoundGeneral = this.getGenerals().find(general => general.name === general_name) || null;
        return FoundGeneral ? `+${FoundGeneral.ammount} ${FoundGeneral.bonus}` : '';
    }
}

export default class RecruitmentProgress {
    _commandReference = null;

    _remainingTimeObject = {
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
    };
    _remainingTimeString = '';
    _remainingTroopsToRecruit = 0;

    _currentTimeoutId = null;

    constructor(command) {
        this._commandReference = command;
        this._initializeRemainingTimeObject();
        this._updateRemainingTimeString();
        this._initializeRemainingTroopsToRecruit();
    }

    startProgressUpdate() {
        this._updateRemainingTimeObject();
        this._updateRemainingTimeString();

        this._currentTimeoutId = setTimeout(() => {
            this._updateRemainingTroopsToRecruit();
            this._updateProgress();
        }, 1000);
    }

    stopProgressUpdate() {
        clearTimeout(this._currentTimeoutId);
        this._currentTimeoutId = null;
    }

    getRemainingTimeString() {
        return this._remainingTimeString;
    }

    getRemainingTroopsToRecruit() {
        return this._remainingTroopsToRecruit;
    }

    _buildRemainingTimeObject(remaining_time) {
        return {
            days: Math.floor(remaining_time / (1000 * 60 * 60 * 24)),
            hours: Math.floor((remaining_time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
            minutes: Math.floor((remaining_time % (1000 * 60 * 60)) / (1000 * 60)),
            seconds: Math.floor((remaining_time % (1000 * 60)) / 1000),
        };
    }

    _initializeRemainingTimeObject() {
        this._remainingTimeObject = this._buildRemainingTimeObject(this._getStartingTime());
    }

    _updateRemainingTimeObject() {
        this._remainingTimeObject = this._buildRemainingTimeObject(this._getRemainingTime());
    }

    _updateRemainingTimeString() {
        this._remainingTimeString = `
            ${this._remainingTimeObject.days ? this._remainingTimeObject.days + 'd ' : ''}
            ${this._remainingTimeObject.hours ? this._remainingTimeObject.hours + 'h ' : ''}
            ${this._remainingTimeObject.minutes ? this._remainingTimeObject.minutes + 'm ' : ''}
            ${this._remainingTimeObject.seconds + 's'}
        `;
    }

    _initializeRemainingTroopsToRecruit() {
        this._remainingTroopsToRecruit = this._commandReference?._originalData?.numOfTroops;
    }

    _updateRemainingTroopsToRecruit() {
        const RemainingTime = this._getRemainingTime();
        const TimePerTroopInMs = this._commandReference?._originalData?.perUnitTime * 1000;

        this._remainingTroopsToRecruit = Math.ceil(RemainingTime / TimePerTroopInMs);
    }

    _subtractOneFromRemainingTroopsToRecruit() {
        this._remainingTroopsToRecruit--;
    }

    _getStartingTime() {
        return this._commandReference?._originalData?.perUnitTime * this._commandReference?._originalData?.numOfTroops * 1000;
    }

    _getRemainingTime() {
        const Now = new Date().getTime();
        return this._getFinishTimestamp() - Now;
    }

    _getRemainingTimeForCurrentTroop() {
        const TimePerTroopInMs = this._commandReference?._originalData?.perUnitTime * 1000;
        const RemainingTimeForCurrentTroop = this._getRemainingTime() % TimePerTroopInMs;

        return RemainingTimeForCurrentTroop;
    }

    _getFinishTimestamp() {
        return this._formatTimestamp(this._commandReference?._originalData?.finishDate);
    }

    // might not need this in the future
    _formatTimestamp(timestamp) {
        return typeof timestamp === 'string' ? parseInt(timestamp) : timestamp;
    }

    _updateProgress() {
        if (this._isCurrentTroopFinished()) {
            this._subtractOneFromRemainingTroopsToRecruit();
            if (this._noTroopsRemaining() || this._areAllTroopFinished()) {
                return this._commandReference.selfDestruct();
            }
        }

        this._updateRemainingTimeObject();
        this._updateRemainingTimeString();

        this._currentTimeoutId = setTimeout(() => this._updateProgress(), 1000);
    }

    _isCurrentTroopFinished() {
        return this._getRemainingTimeForCurrentTroop() <= 1000;
    }

    _areAllTroopFinished() {
        return this._getRemainingTime() <= 1000;
    }

    _noTroopsRemaining() {
        return this._remainingTroopsToRecruit === 0;
    }
}

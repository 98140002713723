<template>
    <div v-if="troop && isTroopPreviewVisible" class="t-col troop-image-column" :class="{ 'no-background': !showBackground }">
        <TroopImage :troop-name="troop?.getName()" :is-name-visible="isTroopNameVisible" />
    </div>
    <div v-if="isOwnedTroopsColumnVisible" class="t-col" :class="{ 'no-background': !showBackground }">
        <input
            class="text-input"
            type="number"
            min="0"
            :value="troop?.getNumberToSend(commandType)"
            @input="event => setOwnedTroops(getInputEventValue(event))"
        />
        <div class="clickable overflow-wrap-normal" @click="setOwnedTroops(troop?.getTroopCount())">
            ({{ troop?.getTroopCount().toLocaleString('ro') }})
        </div>
    </div>
    <div class="t-col position-relative" :class="{ 'no-background': !showBackground }">
        <input
            class="text-input"
            type="number"
            min="0"
            :value="totalReinforcementsSelected"
            @input="event => setReinforcements(getFirstReinforcementId(), getInputEventValue(event))"
            :disabled="isReinforcementsInputDisabled"
        />
        <div class="clickable overflow-wrap-normal" @click="setAllReinforcements">
            ({{ totalFilteredReinforcements?.toLocaleString('ro') }})
        </div>
        <ReinforcementsPanel
            :is-button-visible="isDropdownButtonVisible"
            :reinforcements="filteredReinforcements"
            :command-type="commandType"
            :troop-name="troop?.getName()"
            :is-from-user-column-visible="isFromUserColumnVisible"
            :is-from-city-column-visible="isFromCityColumnVisible"
            :is-control-type-column-visible="isControlTypeColumnVisible"
            :is-sent-origin-column-visible="isSentOriginColumnVisible"
            :is-received-origin-column-visible="isReceivedOriginColumnVisible"
            :is-spies-origin-column-visible="isSpiesOriginColumnVisible"
            :is-availability-column-visible="isAvailabilityColumnVisible"
            :is-mission-column-visible="isMissionColumnVisible"
        />
    </div>
</template>

<script>
import { CommandTypesForTroopSelection, TroopsIdentifier } from '@/utils/constants/troops';
import { ReinforcementFilterType, ReinforcementControlLevel } from '@/utils/constants/troops';
import { ReinforcementCategory } from '@/utils/constants/actions';
import Troop from '@/classes/troops/Troop';
import { paramCase } from 'change-case';
import ReinforcementsPanel from '@/components/generic/TroopSelect/ReinforcementsPanel';
import TroopImage from '@/components/generic/TroopImage';
import { getInputEventValue } from '@/utils/functions/miscellaneousUtils';

export default {
    name: 'TroopSelectRow',
    components: { TroopImage, ReinforcementsPanel },
    props: {
        troop: {
            type: Troop,
        },
        commandType: {
            type: String,
        },
        reinforcementsCategory: {
            type: String,
            default: ReinforcementCategory.RECEIVED,
        },
        reinforcementsFilterType: {
            type: String,
            default: ReinforcementFilterType.CONTROL_LEVEL,
        },
        reinforcementsFilterValue: {
            type: String,
            default: ReinforcementControlLevel.FULL,
        },
		includeNotArrived: {
			type: Boolean,
			default: false,
		},
        isDropdownAlwaysAvailable: {
            type: Boolean,
            default: false,
        },
        isTroopPreviewVisible: {
            type: Boolean,
            default: true,
        },
        isTroopNameVisible: {
            type: Boolean,
            default: false,
        },
        isOwnedTroopsColumnVisible: {
            type: Boolean,
            default: true,
        },
        showBackground: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            CommandTypesForTroopSelection,
            TroopsIdentifier,
            ReinforcementFilterType,
            ReinforcementControlLevel,
            ReinforcementCategory,
        };
    },
    computed: {
        reinforcementFilters() {
            return {
                category: this.reinforcementsCategory,
                filter_type: this.reinforcementsFilterType,
                filter_value: this.reinforcementsFilterValue,
				include_not_arrived: this.includeNotArrived,
            };
        },
        filteredReinforcements() {
            return this.troop?.getReinforcements(this.reinforcementFilters);
        },
        isReinforcementsInputDisabled() {
            return (
                this.filteredReinforcements?.size === 0 || this.filteredReinforcements?.size > 1 || this.isDropdownAlwaysAvailable
            );
        },
        isDropdownButtonVisible() {
            return this.filteredReinforcements?.size > 1 || this.isDropdownAlwaysAvailable;
        },
        totalFilteredReinforcements() {
            let count = 0;
            this.filteredReinforcements?.forEach(reinforcement => {
                count += reinforcement.getTroopCount();
            });
            return count;
        },
        totalReinforcementsSelected() {
            let count = 0;
            this.filteredReinforcements?.forEach(reinforcement => {
                count += reinforcement.getNumberToSend(this.commandType);
            });
            return count;
        },
        isFromUserColumnVisible() {
            return [
                CommandTypesForTroopSelection.ATTACK_OR_REINFORCE,
                CommandTypesForTroopSelection.DETECT_SPIES,
                CommandTypesForTroopSelection.SPY,
                CommandTypesForTroopSelection.PATROL_OR_INTERCEPT,
                CommandTypesForTroopSelection.TRADE,
				CommandTypesForTroopSelection.CALL_BACK_FROM_SPYING,
            ].includes(this.commandType);
        },
        isFromCityColumnVisible() {
            return [
                CommandTypesForTroopSelection.ATTACK_OR_REINFORCE,
                CommandTypesForTroopSelection.DETECT_SPIES,
                CommandTypesForTroopSelection.SPY,
                CommandTypesForTroopSelection.PATROL_OR_INTERCEPT,
                CommandTypesForTroopSelection.TRADE,
				CommandTypesForTroopSelection.CALL_BACK_FROM_SPYING,
            ].includes(this.commandType);
        },
        isControlTypeColumnVisible() {
            return [
                CommandTypesForTroopSelection.CALL_BACK_FROM_REINFORCING,
                CommandTypesForTroopSelection.SEND_BACK_FROM_REINFORCING,
            ].includes(this.commandType);
        },
        isSentOriginColumnVisible() {
            return [CommandTypesForTroopSelection.CALL_BACK_FROM_REINFORCING].includes(this.commandType);
        },
        isReceivedOriginColumnVisible() {
            return [CommandTypesForTroopSelection.SEND_BACK_FROM_REINFORCING].includes(this.commandType);
        },
        isSpiesOriginColumnVisible() {
            return [CommandTypesForTroopSelection.CALL_BACK_FROM_SPYING].includes(this.commandType);
        },
        isAvailabilityColumnVisible() {
            return [
                CommandTypesForTroopSelection.CALL_BACK_FROM_REINFORCING,
                CommandTypesForTroopSelection.SEND_BACK_FROM_REINFORCING,
            ].includes(this.commandType);
        },
        isMissionColumnVisible() {
            return [
                CommandTypesForTroopSelection.CALL_BACK_FROM_REINFORCING,
                CommandTypesForTroopSelection.SEND_BACK_FROM_REINFORCING,
            ].includes(this.commandType);
        },
    },
    methods: {
        paramCase,
        getInputEventValue,

        getFirstReinforcementId() {
            return Array.from(this.filteredReinforcements)[0]?.getId();
        },

        setOwnedTroops(value) {
            this.$store.commit('SET_OWNED_TROOPS_TO_SEND', {
                troop_name: this.troop?.getName(),
                command_type: this.commandType,
                number: value,
            });
        },

        setReinforcements(reinforcement_id, value) {
            this.$store.commit('SET_REINFORCEMENTS_TO_SEND', {
                troop_name: this.troop?.getName(),
                reinforcement_id,
                command_type: this.commandType,
                number: value,
            });
        },

        setAllReinforcements() {
			console.log(this.troop);
            this.$store.commit('SET_ALL_REINFORCEMENTS_TO_SEND', {
                troop_name: this.troop?.getName(),
                command_type: this.commandType,
                reinforcement_filters: this.reinforcementFilters,
				include_not_arrived: this.includeNotArrived,
            });
        },
    },
};
</script>

<style scoped>
.troop-image-column {
    width: fit-content;
}
</style>

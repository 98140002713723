import getters from "@/store/modules/city/getters";
import state from "@/store/modules/city/state";

export default function getBuildingRequirementAppearance(requirement) {
    const Building = getters.getBuildings(state)?.getBuildings?.find(building => building.name === requirement.name);
    if (Building) {
        for (let i = Building.appearance?.length; i >= 0; i--) {
            if (requirement.level >= Building.appearance[i]) {
                return i + 2;
            }
        }
    }
    return 1;
}

import {
    ActionDescriptionIdentifier,
    ActionDescriptionTargetIdentifier,
    ActionInitiatorIdentifier,
    ActionsThatAreIntercepts,
    ActionTargetIdentifier,
    ReturningAttackTypes,
} from '@/utils/constants/actions';
import { reactive } from 'vue';
import CollectionItem from '@/classes/generic/CollectionItem';
import ProgressTracking from '@/classes/mapActions/ProgressTracking';
import Troops from '@/classes/mapActions/Troops';
import { ResourceIdentifier } from '@/utils/constants/trading';

export default class MapAction extends CollectionItem {
    originalData = null;
    actionType = null;
    isIntercept = false;

    progressTracking = null;
    troops = null;

    // city
    initiator = {
        _id: '',
        name: '',
        x: 0,
        y: 0,
    };

    // city
    target = {
        _id: '',
        name: '',
        x: 0,
        y: 0,
    };

    actionDescription = '';
    actionDescriptionTarget = '';

    constructor({ action, action_type }) {
        super();

        this.originalData = action;
        console.log('originalData', this.originalData);
        this.actionType = action_type;
        this.isIntercept = this.checkIsIntercept(action_type);

        this.progressTracking = reactive(new ProgressTracking(this));
        this.troops = new Troops(this.originalData);

        this.initializeActionInfo();
    }

    checkIsIntercept(action_type) {
        return ActionsThatAreIntercepts.includes(action_type);
    }

    initializeActionInfo() {
        this.initiator = this.getInitiatorInfo();
        this.target = this.getTargetInfo();

        this.actionDescription = this.getActionDescription();
        this.actionDescriptionTarget = this.getActionDescriptionTarget();
    }

    getActionDescription() {
        return ActionDescriptionIdentifier[this.actionType];
    }

    getActionDescriptionTarget() {
        const PropertyToAccess = ActionDescriptionTargetIdentifier[this.actionType];
        return this.originalData[PropertyToAccess]?.name;
    }

    getInitiatorInfo() {
        const PropertyToAccess = ActionInitiatorIdentifier[this.actionType];
        return this.originalData[PropertyToAccess];
    }

    getTargetInfo() {
        const PropertyToAccess = ActionTargetIdentifier[this.actionType];
        return this.originalData[PropertyToAccess];
    }

    startCountdown() {
        this.progressTracking.startProgressUpdate(this);
    }

    stopCountdown() {
        this.progressTracking.stopProgressUpdate();
    }

    isReturningAttack() {
        return ReturningAttackTypes.includes(this.actionType);
    }

    getOriginUserName() {
        return this.originalData?.fromUser?.username;
    }

    getTargetUserName() {
        return this.originalData?.fromUser?.username;
    }

    getOriginCityName() {
        return this.initiator?.name;
    }

    getTargetCityName() {
        return this.target?.name;
    }

    getOriginCityFormattedCoords() {
        return `( ${this.initiator?.x} | ${this.initiator?.y} )`;
    }

    getTargetCityFormattedCoords() {
        return `( ${this.target?.x} | ${this.target?.y} )`;
    }

    getStaticDuration() {
        return this.progressTracking?.staticDuration;
    }

    getDynamicDurationString() {
        return this.progressTracking?.dynamicDurationString;
    }

    getArrivalDate() {
        return this.originalData?.arrivalDateToShow;
    }

    getReturnDate() {
        return this.progressTracking?.returnDate;
    }

    getStolenAluminum() {
        return this.originalData?.resourcesStolen?.find(resource => resource.name === ResourceIdentifier.ALUMINUM)?.quantity;
    }

    getStolenOil() {
        return this.originalData?.resourcesStolen?.find(resource => resource.name === ResourceIdentifier.OIL)?.quantity;
    }

    getStolenMetal() {
        return this.originalData?.resourcesStolen?.find(resource => resource.name === ResourceIdentifier.METAL)?.quantity;
    }

    getStolenRations() {
        return this.originalData?.resourcesStolen?.find(resource => resource.name === ResourceIdentifier.RATIONS)?.quantity;
    }

    // methods below are for interface consistency between all command types (map actions, building commands, recruitment commands)

    getRemainingTimeString() {
        return this.progressTracking.dynamicDurationString;
    }

    getFinishTimeString() {
        return this.originalData.arrivalDateToShow || this.originalData.arrivalDate;
    }
}

<template>
    <h4 class="table-header">Generals:</h4>
    <div v-if="general.getGenerals().length" class="t-wrapper mb-5">
        <div class="t-row t-header">
            <div class="t-col">Name</div>
            <div class="t-col">Bonus</div>
        </div>
        <div v-for="general_option in general.getGenerals()" :key="general_option.name" class="t-row">
            <div class="t-col">
                <GeneralImage :general-name="general_option.name" />
            </div>
            <div class="t-col">
                {{ general.getGeneralBonusString(general_option.name) }}
            </div>
        </div>
    </div>
    <div v-else class="default-text mb-5">No generals in this city</div>
    <h4 class="table-header">Troops:</h4>
    <div v-if="isAtLeastOneTroop" class="t-wrapper">
        <div class="t-row t-header">
            <div class="t-col">Unit</div>
            <div class="t-col">Total</div>
            <div class="t-col">Owned</div>
            <div class="t-col">Controlled</div>
            <div class="t-col">Stationed</div>
        </div>
        <div v-for="troop in troops" :key="troop.getName()" class="t-row">
            <div class="t-col">
                <TroopImage :troop-name="troop.getName()" />
            </div>
            <div class="t-col">
                {{
                    getTotalTroopCount(troop).toLocaleString('ro')
                }}
            </div>
            <div class="t-col">
                {{ troop.getTroopCount().toLocaleString('ro') }}
            </div>
            <div class="t-col">
                {{ troop.getReinforcementCount().toLocaleString('ro') }}
            </div>
            <div class="t-col">
                {{ troop.getUncontrolledReinforcementCount().toLocaleString('ro') }}
            </div>
        </div>
    </div>
	<div v-else class="default-text mb-5">No troops in this city</div>
</template>

<script>
import { TroopsIdentifier } from '@/utils/constants/troops';
import GeneralImage from "@/components/generic/GeneralImage";
import TroopImage from "@/components/generic/TroopImage";

export default {
    name: 'TroopsTab',
    components: { GeneralImage, TroopImage },
    data() {
        return {
            TroopsIdentifier,
        };
    },
    computed: {
        troops() {
            return this.$store.getters.getCurrentCityFormattedTroops.filter(
                troop => troop.getName() !== TroopsIdentifier.GENERAL,
            );
        },
        isAtLeastOneTroop() {
            return this.troops.some(troop => this.getTotalTroopCount(troop) > 0);
        },
        general() {
            return this.$store.getters.getCurrentCityFormattedTroops.find(troop => troop.getName() === TroopsIdentifier.GENERAL);
        },
    },
    methods: {
        getTotalTroopCount(troop) {
            return troop.getTroopCount() + troop.getReinforcementCount() + troop.getUncontrolledReinforcementCount();
        },
    },
};
</script>

<style scoped>
.table-header {
    margin-bottom: 1rem;
    color: lightgoldenrodyellow;
}

.default-text {
    color: #afafaf;
    padding-left: 1rem;
}
</style>

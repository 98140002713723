import { BuildingIdentifier } from "@/utils/constants/city";

export const TroopsIdentifier = Object.freeze({
    INFANTRYMAN: 'Infantryman',
    SPY: 'Spy',
    MACHINE_GUNNER: 'Machine Gunner',
    BAZOOKA_SOLDIER: 'Bazooka soldier',
    SNIPER: 'Sniper',
    OFFICER: 'Officer',
    MEDIC: 'Medic',
    GENERAL: 'General',
    TRUCK: 'Truck',
    ANTI_TANK: 'Anti Tank',
    ANTI_AIR: 'Anti Air',
    LIGHT_TANK: 'Light Tank',
    MEDIUM_TANK: 'Medium Tank',
    HEAVY_TANK: 'Heavy Tank',
    SPY_PLANE: 'Spy Plane',
    TRANSPORT_PLANE: 'Transport Plane',
    FIGHTER: 'Fighter',
    CLOSE_AIR_SUPPORT: 'Close Air Support',
    BOMBING_PLANE: 'Bombing Plane',
});

export const TroopTypes = Object.freeze({
    INFANTRY: 'Infantry',
    ARMORED: 'Armored',
    PLANE: 'Plane',
    SPECIAL: 'Special',
});

export const InfantryTroops = Object.freeze([
    TroopsIdentifier.INFANTRYMAN,
    TroopsIdentifier.SPY,
    TroopsIdentifier.MACHINE_GUNNER,
    TroopsIdentifier.BAZOOKA_SOLDIER,
    TroopsIdentifier.SNIPER,
    TroopsIdentifier.MEDIC,
]);

export const ArmoredTroops = Object.freeze([
    TroopsIdentifier.TRUCK,
    TroopsIdentifier.ANTI_TANK,
    TroopsIdentifier.ANTI_AIR,
    TroopsIdentifier.LIGHT_TANK,
    TroopsIdentifier.MEDIUM_TANK,
    TroopsIdentifier.HEAVY_TANK,
]);

export const Planes = Object.freeze([
    TroopsIdentifier.SPY_PLANE,
    TroopsIdentifier.TRANSPORT_PLANE,
    TroopsIdentifier.FIGHTER,
    TroopsIdentifier.CLOSE_AIR_SUPPORT,
    TroopsIdentifier.BOMBING_PLANE,
]);

export const SpecialTroops = Object.freeze([TroopsIdentifier.OFFICER]);

export const TroopRecruitmentSlots = Object.freeze({
    [BuildingIdentifier.BARRACKS]: 5,
    [BuildingIdentifier.ARMORED_FACTORY]: 5,
    [BuildingIdentifier.PLANE_FACTORY]: 5,
    [BuildingIdentifier.GENERAL_OFFICE]: 1,
});

export const TroopsRecruitedInBarracks = Object.freeze([
    TroopsIdentifier.INFANTRYMAN,
    TroopsIdentifier.SPY,
    TroopsIdentifier.MACHINE_GUNNER,
    TroopsIdentifier.BAZOOKA_SOLDIER,
    TroopsIdentifier.SNIPER,
    TroopsIdentifier.MEDIC,
    TroopsIdentifier.OFFICER,
]);

export const TroopsRecruitedInArmoredFactory = Object.freeze([
    TroopsIdentifier.TRUCK,
    TroopsIdentifier.ANTI_TANK,
    TroopsIdentifier.ANTI_AIR,
    TroopsIdentifier.LIGHT_TANK,
    TroopsIdentifier.MEDIUM_TANK,
    TroopsIdentifier.HEAVY_TANK,
]);

export const TroopsRecruitedInPlaneFactory = Object.freeze([
    TroopsIdentifier.SPY_PLANE,
    TroopsIdentifier.TRANSPORT_PLANE,
    TroopsIdentifier.FIGHTER,
    TroopsIdentifier.CLOSE_AIR_SUPPORT,
    TroopsIdentifier.BOMBING_PLANE,
]);

export const TroopsRecruitedInGeneralOffice = Object.freeze([TroopsIdentifier.GENERAL]);

export const ReinforcementMissions = Object.freeze({
    REINFORCING: 'Reinforcing',
});

export const CommandTypesForTroopSelection = Object.freeze({
    ATTACK_OR_REINFORCE: 'ATTACK_OR_REINFORCE',
    DETECT_SPIES: 'DETECT_SPIES',
    SPY: 'SPY',
    CALL_BACK_FROM_SPYING: 'CALL_BACK_FROM_SPYING',
    CALL_BACK_FROM_REINFORCING: 'CALL_BACK_FROM_REINFORCING',
    SEND_BACK_FROM_REINFORCING: 'SEND_BACK_FROM_REINFORCING',
    PATROL_OR_INTERCEPT: 'PATROL_OR_INTERCEPT',
    CREATE_TRADE_OFFER: 'CREATE_TRADE_OFFER',
    ACCEPT_TRADE_OFFER: 'ACCEPT_TRADE_OFFER',
    SEND_RESOURCES: 'SEND_RESOURCES',
});

export const ReinforcementFilterType = Object.freeze({
    CONTROL_LEVEL: 'CONTROL_LEVEL', // relevant for received reinforcements
    EARLIEST_ORIGIN: 'EARLIEST_ORIGIN', // relevant for received reinforcements
    LATEST_ORIGIN: 'LATEST_ORIGIN', // relevant for received reinforcements
    DESTINATION: 'DESTINATION', // relevant for sent reinforcements
    NONE: 'NONE', // relevant for both
});

export const ReinforcementControlLevel = Object.freeze({
    PARTIAL: 'PARTIAL', // those that can go on actions, except reinforcement
    FULL: 'FULL', // those that can go on any action
    PARTIAL_OR_FULL: 'PARTIAL_OR_FULL', // those that have control of some kind, either partial or full
});

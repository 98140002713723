<template>
	<div class="troop-info-window">
		<span class="section-title">Statistics</span>
		<div class="troop-info-wrapper">
			<div class="icon-tooltip">Anti-air Defense</div>
			<img src="../../assets/icons/anti_air_defense_icon.svg" class="troop-info-icon" />
			<span>{{ troop.getAntiAirDefense() }}</span>
		</div>
		<div class="troop-info-wrapper">
			<div class="icon-tooltip">Anti-artillery Defense</div>
			<img src="../../assets/icons/anti_artillery_tank_defense_icon.svg" class="troop-info-icon" />
			<span>{{ troop.getAntiArtilleryAndTankDefense() }}</span>
		</div>
		<div class="troop-info-wrapper">
			<div class="icon-tooltip">Anti-infantry Defense</div>
			<img src="../../assets/icons/anti_infantry_defense_icon.svg" class="troop-info-icon" />
			<span>{{ troop.getAntiInfantryDefense() }}</span>
		</div>
		<div class="troop-info-wrapper">
			<div class="icon-tooltip">Attack</div>
			<img src="../../assets/icons/attack_icon.svg" class="troop-info-icon" />
			<span>{{ troop.getAttack() }}</span>
		</div>
		<div class="troop-info-wrapper">
			<div class="icon-tooltip">Carry Capacity</div>
			<img src="../../assets/icons/carry_capacity_icon.svg" class="troop-info-icon" />
			<span>{{ troop.getCarryCapacity() }}</span>
		</div>
		<div class="troop-info-wrapper">
			<div class="icon-tooltip">Movement Speed</div>
			<img src="../../assets/icons/movement_speed_icon.svg" class="troop-info-icon" />
			<span>{{ troop.getMovementSpeed() }}</span>
		</div>
		<div class="troop-info-wrapper">
			<div class="icon-tooltip">Oil Consumption</div>
			<img src="../../assets/icons/oil_circle_icon.svg" class="troop-info-icon" />
			<span>{{ troop.getOilConsumption() }}</span>
		</div>
		<div class="troop-info-wrapper">
			<div class="icon-tooltip">Rations Consumption</div>
			<img src="../../assets/icons/food_consumption_icon.svg" class="troop-info-icon" />
			<span>{{ troop.getRationsConsumption() }}</span>
		</div>
		<div class="troop-info-wrapper">
			<div class="icon-tooltip">Unit Type</div>
			<img src="../../assets/icons/food_consumption_icon.svg" class="troop-info-icon" />
			<span>{{ troop.getType() }}</span>
		</div>
		<div class="troop-info-wrapper">
			<div class="icon-tooltip">Type of Attack</div>
			<img src="../../assets/icons/food_consumption_icon.svg" class="troop-info-icon" />
			<span>Light Attack</span>
		</div>
	</div>
</template>

<script>
import Troop from "@/classes/troops/Troop";

export default {
	name: "TroopStatsWindow",
	props: {
		troop: {
			type: Troop,
		}
	},
};
</script>

<style scoped>
.troop-info-window {
	position: relative;
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 2px;
	border: 2px solid #434343;
	padding: 2px;
	background: black;
	width: 32%;
}

.troop-info-wrapper {
	font-size: 0.875rem;
	display: flex;
	align-items: center;
	flex-grow: 1;
	gap: 1rem;
	width: 8rem;
	background: linear-gradient(to bottom, #2a2a2a, #181818);
	padding: 0.25rem 0.5rem;
	position: relative;
}

.icon-tooltip {
	opacity: 0;
	filter: blur(3px);
	position: absolute;
	top: -1.375rem;
	pointer-events: none;
	transition: opacity 0.3s, filter 0.3s;
	background: black;
	color: ghostwhite;
	font-size: 0.75rem;
	line-height: 0.75rem;
	padding: 0.25rem;
	border: 1px solid #434343;
}

.troop-info-wrapper:hover .icon-tooltip {
	opacity: 1;
	filter: blur(0);
}

.troop-info-icon {
	width: 1.5rem;
}

.section-title {
	position: absolute;
	top: -2rem;
}
@media screen and (max-width: 1199px) {
	.troop-info-window {
		width: 100%;
		margin-top: 3rem;
	}
}

@media screen and (max-width: 991px) {
	.troop-info-window {
		width: 100%;
		margin-top: 3rem;
	}
}
</style>
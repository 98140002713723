<template>
    <div data-bs-backdrop="false" data-bs-keyboard="false" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Coalition</h5>
                    <div class="building-description">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                        ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu
                        fugiat nulla pariatur.
                    </div>
                    <button
                        type="button"
                        class="close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                    >
                        <img src="../assets/close_icon.svg" width="20" height="20" aria-hidden="true" />
                    </button>
                </div>
                <div class="modal-body">
                    <div v-if="isUserInCoalition">
                        <ul class="nav nav-tabs" id="coalition-nav" role="tablist">
                            <li class="nav-item" role="presentation">
                                <button
                                    class="nav-link active"
                                    id="events"
                                    data-bs-toggle="tab"
                                    data-bs-target="#events-page"
                                    type="button"
                                    role="tab"
                                    aria-controls="events-tab"
                                    aria-selected="true"
                                    @click="isWarsTabSelected = false"
                                >
                                    Events
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button
                                    class="nav-link"
                                    id="members"
                                    data-bs-toggle="tab"
                                    data-bs-target="#members-page"
                                    type="button"
                                    role="tab"
                                    aria-controls="members-tab"
                                    aria-selected="false"
                                    @click="isWarsTabSelected = false"
                                >
                                    Members
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button
                                    class="nav-link"
                                    id="enlistment"
                                    data-bs-toggle="tab"
                                    data-bs-target="#enlistment-page"
                                    type="button"
                                    role="tab"
                                    aria-controls="enlistment-tab"
                                    aria-selected="false"
                                    @click="isWarsTabSelected = false"
                                >
                                    Enlistment
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button
                                    class="nav-link"
                                    id="properties"
                                    data-bs-toggle="tab"
                                    data-bs-target="#properties-page"
                                    type="button"
                                    role="tab"
                                    aria-controls="properties-tab"
                                    aria-selected="false"
                                    @click="isWarsTabSelected = false"
                                >
                                    Properties
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button
                                    class="nav-link"
                                    id="diplomacy"
                                    data-bs-toggle="tab"
                                    data-bs-target="#diplomacy-page"
                                    type="button"
                                    role="tab"
                                    aria-controls="diplomacy-tab"
                                    aria-selected="false"
                                    @click="isWarsTabSelected = false"
                                >
                                    Diplomacy
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button
                                    class="nav-link"
                                    id="wars"
                                    data-bs-toggle="tab"
                                    data-bs-target="#wars-page"
                                    type="button"
                                    role="tab"
                                    aria-controls="wars-tab"
                                    aria-selected="false"
                                    @click="isWarsTabSelected = true"
                                >
                                    Wars
                                </button>
                            </li>
                            <li class="nav-item" role="presentation">
                                <button
                                    class="nav-link"
                                    id="wars"
                                    data-bs-toggle="tab"
                                    data-bs-target="#tributes-page"
                                    type="button"
                                    role="tab"
                                    aria-controls="wars-tab"
                                    aria-selected="false"
                                    @click="isWarsTabSelected = false"
                                >
                                    Tributes
                                </button>
                            </li>
                            <li class="nav-filler" />
                        </ul>
                        <div class="tab-content" id="coalition-pages">
                            <div class="tab-pane fade show active" id="events-page" role="tabpanel" aria-labelledby="events-tab">
                                <p class="section-description">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                    labore et dolore magna aliqua. Duis aute irure dolor in reprehenderit in voluptate velit esse
                                    cillum dolore eu fugiat nulla pariatur.
                                </p>
                                <TableWithPagination
                                    :all-rows="coalitionInfo?.events"
									no-items-text="No coalition events"
                                    :on-page-change="
                                        () => createEventHandlersForMarkup(MarkupLocationIdentifier.COALITION_EVENTS)
                                    "
                                    v-slot="props"
                                >
                                    <div class="events-table-wrapper">
                                        <div class="events-table">
                                            <div class="row label">
                                                <div class="col small">Date</div>
                                                <div class="col large">Event</div>
                                            </div>
                                            <div
                                                v-for="(event, event_index) in props.currentRows"
                                                :key="event.dateCreated + event_index"
                                                class="row"
                                            >
                                                <div class="col small">
                                                    {{ event.dateCreated }}
                                                </div>
                                                <div
                                                    class="col large d-block"
                                                    v-html="
                                                        addCustomMarkupToString(
                                                            event.text,
                                                            MarkupLocationIdentifier.COALITION_EVENTS,
                                                        )
                                                    "
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                </TableWithPagination>
                                <div class="d-flex gap-3 mt-3">
                                    <button
                                        class="btn red-button"
                                        @click="
                                            setConfirmationModalProps({
                                                title: 'Are you sure you want to leave the coalition?',
                                                action: () => leaveCoalitionCommand(),
                                            })
                                        "
                                    >
                                        Leave coalition
                                    </button>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="members-page" role="tabpanel" aria-labelledby="members-tab">
                                <p class="section-description">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                    labore et dolore magna aliqua. Duis aute irure dolor in reprehenderit in voluptate velit esse
                                    cillum dolore eu fugiat nulla pariatur.
                                </p>
                                <div class="members-table">
                                    <div class="row label">
                                        <div class="col">Name</div>
                                        <div class="col small">
                                            <div class="members-icon-wrapper">
                                                <img src="../assets/icons/interface/global_rank.svg" class="members-icon" />
                                                <div class="icon-tooltip">Global Rank</div>
                                            </div>
                                        </div>
                                        <div class="col large">
                                            <div class="members-icon-wrapper">
                                                <img src="../assets/icons/interface/points.svg" class="members-icon" />
                                                <div class="icon-tooltip">Points</div>
                                            </div>
                                        </div>
                                        <div class="col small">
                                            <div class="members-icon-wrapper">
                                                <img src="../assets/icons/interface/city.svg" class="members-icon" />
                                                <div class="icon-tooltip">Nr. of Cities</div>
                                            </div>
                                        </div>
                                        <div class="col large">
                                            <div class="members-icon-wrapper">
                                                <img src="../assets/icons/interface/coalition_rank.svg" class="members-icon" />
                                                <div class="icon-tooltip">Coalition Rank</div>
                                            </div>
                                        </div>
                                        <div class="col small">
                                            <div class="members-icon-wrapper">
                                                <img src="../assets/icons/interface/send_all_message.svg" class="members-icon" />
                                                <div class="icon-tooltip">Send Coalition Message</div>
                                            </div>
                                        </div>
                                        <div class="col small">
                                            <div class="members-icon-wrapper">
                                                <img src="../assets/icons/interface/diplomacy.svg" class="members-icon" />
                                                <div class="icon-tooltip">Diplomacy</div>
                                            </div>
                                        </div>
                                        <div class="col small">
                                            <div class="members-icon-wrapper">
                                                <img src="../assets/icons/interface/invitation.svg" class="members-icon" />
                                                <div class="icon-tooltip">Invitation</div>
                                            </div>
                                        </div>
                                        <div class="col small">
                                            <div class="members-icon-wrapper">
                                                <img src="../assets/icons/interface/exclude_members.svg" class="members-icon" />
                                                <div class="icon-tooltip">Exclude Members</div>
                                            </div>
                                        </div>
                                        <div class="col small">
                                            <div class="members-icon-wrapper">
                                                <img src="../assets/icons/interface/modify_rights.svg" class="members-icon" />
                                                <div class="icon-tooltip">Modify Rights</div>
                                            </div>
                                        </div>
                                        <div class="col small">
                                            <div class="members-icon-wrapper">
                                                <img src="../assets/icons/interface/modify_coalition.svg" class="members-icon" />
                                                <div class="icon-tooltip">Modify Coalition</div>
                                            </div>
                                        </div>
                                        <div class="col small">
                                            <div class="members-icon-wrapper">
                                                <img
                                                    src="../assets/icons/interface/dissolve_coalition.svg"
                                                    class="members-icon"
                                                />
                                                <div class="icon-tooltip">Dissolve Coalition</div>
                                            </div>
                                        </div>
                                        <div class="col large">Actions</div>
                                    </div>
                                    <div
                                        v-for="(member, member_index) in members"
                                        :key="member_index"
                                        class="row"
                                        :class="{ 'current-user': member._id === user._id }"
                                    >
                                        <div class="col">
                                            <span
                                                :class="{
                                                    inactive: member.activity === 0,
                                                    active: member.activity === 1,
                                                    'very-active': member.status === 'Green',
                                                    active: member.status === 'Yellow',
                                                    inactive: member.status === 'Red',
                                                }"
                                                class="coalition-member"
                                                >{{ member.username }}</span
                                            >
                                        </div>
                                        <div class="col small">
                                            {{ member.rank }}
                                        </div>
                                        <div class="col large">
                                            {{ member.points }}
                                        </div>
                                        <div class="col small">
                                            {{ member.cities.length }}
                                        </div>
                                        <div class="col large">
                                            {{ member.coalitionRank }}
                                        </div>
                                        <div v-for="(right, right_index) in member.rights" :key="right_index" class="col small">
                                            <input
                                                v-model="right.hasIt"
                                                class="form-check-input"
                                                type="checkbox"
                                                :value="true"
                                                :disabled="!checkIsRightModifiable(member, right.permission)"
                                            />
                                        </div>
                                        <div class="col large">
                                            <button
                                                v-if="
                                                    checkIsKickVisible(member) ||
                                                    checkIsPromoteVisible(member) ||
                                                    checkIsDemoteVisible(member)
                                                "
                                                class="btn neutral-button members-table-combined-button"
                                                @click="toggleMemberActionsWrapperVisible(member_index)"
                                            >
                                                <span>&#10095;</span>
                                            </button>
                                            <Transition name="buttons-transition">
                                                <div
                                                    v-if="memberActionsWrapperVisible[member_index] || !smallScreen"
                                                    class="members-table-buttons-wrapper"
                                                >
                                                    <button
                                                        v-if="checkIsKickVisible(member)"
                                                        class="btn red-button"
                                                        @click="
                                                            setConfirmationModalProps({
                                                                title: 'Are you sure you want to kick player out of coalition?',
                                                                action: () => kickMember(member._id),
                                                            })
                                                        "
                                                    >
                                                        &#10006;
                                                    </button>
                                                    <button
                                                        v-if="checkIsPromoteVisible(member)"
                                                        class="btn blue-button"
                                                        @click="
                                                            setConfirmationModalProps({
                                                                title: 'Are you sure you want to promote player?',
                                                                action: () => promoteMember(member._id),
                                                            })
                                                        "
                                                    >
                                                        <span>&#10094;</span>
                                                    </button>
                                                    <button
                                                        v-if="checkIsDemoteVisible(member)"
                                                        class="btn yellow-button"
                                                        @click="
                                                            setConfirmationModalProps({
                                                                title: 'Are you sure you want to demote player?',
                                                                action: () => demoteMember(member._id),
                                                            })
                                                        "
                                                    >
                                                        <span>&#10095;</span>
                                                    </button>
                                                </div>
                                            </Transition>
                                        </div>
                                    </div>
                                </div>
                                <div v-if="!showEditRightsButtons && canUserModifyRights" class="d-flex gap-3 mt-3">
                                    <button class="btn yellow-button" @click="toggleEditRightsButtons({ reset: false })">
                                        Edit rights
                                    </button>
                                </div>
                                <div v-if="showEditRightsButtons && canUserModifyRights" class="d-flex gap-3 mt-3">
                                    <button
                                        class="btn blue-button"
                                        @click="
                                            setConfirmationModalProps({
                                                title: 'Are you sure you want to change the rights of these members?',
                                                action: () => changeCoalitionRightsCommand(),
                                            })
                                        "
                                    >
                                        Confirm rights
                                    </button>
                                    <button class="btn red-button" @click="toggleEditRightsButtons({ reset: true })">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="enlistment-page" role="tabpanel" aria-labelledby="enlistment-tab">
                                <p class="section-description">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                    labore et dolore magna aliqua. Duis aute irure dolor in reprehenderit in voluptate velit esse
                                    cillum dolore eu fugiat nulla pariatur.
                                </p>
                                <div
                                    :class="{
                                        'justify-content-center': coalitionInfo?.additionRule !== 'withApplication',
                                        'justify-content-between': coalitionInfo?.additionRule === 'withApplication',
                                    }"
                                    class="d-flex"
                                >
                                    <div class="left-wrapper">
                                        <div class="invitations-table">
                                            <div class="row label">
                                                <div class="col">Invitations</div>
                                            </div>
                                            <div class="scrollable-wrapper">
                                                <div
                                                    v-for="(invite, invite_index) in coalitionInvitesInEnlistment"
                                                    :key="invite_index"
                                                    class="row"
                                                >
                                                    <div class="col large">
                                                        <span class="clickable">{{ invite.username }}</span>
                                                    </div>
                                                    <div class="col small">
                                                        <button
                                                            class="btn yellow-button"
                                                            @click="
                                                                setConfirmationModalProps({
                                                                    title: `Are you sure you want to retract coalition invite for ${invite.username}?`,
                                                                    action: () => retractInviteToCoalitionCommand(invite._id),
                                                                })
                                                            "
                                                        >
                                                            Retract
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="invite-player-table mt-4">
                                            <div class="row label">
                                                <div class="col">Invite Player</div>
                                            </div>
                                            <div class="row">
                                                <div class="col small">Name</div>
                                                <div class="col">
                                                    <div class="search-input-wrapper">
                                                        <input
                                                            class="form-control"
                                                            v-model="inviteSearchTerm"
                                                            @focus="setInvitePlayerInputFocus(true)"
                                                            @blur="setInvitePlayerInputFocus(false)"
                                                        />
                                                        <Transition name="tooltip-transition">
                                                            <div
                                                                v-if="areInvitePlayerSearchResultsVisible"
                                                                class="search-results-wrapper"
                                                            >
                                                                <div
                                                                    v-if="!getMatchedUsers(inviteSearchTerm).length"
                                                                    class="search-result"
                                                                >
                                                                    No players found
                                                                </div>
                                                                <div
                                                                    v-for="(player, player_index) in getMatchedUsers(
                                                                        inviteSearchTerm,
                                                                    )"
                                                                    :key="player_index"
                                                                    class="search-result"
                                                                    @click="setInviteSearchTerm(player.username)"
                                                                >
                                                                    {{ player.username }}
                                                                </div>
                                                            </div>
                                                        </Transition>
                                                    </div>
                                                </div>
                                                <div class="col small">
                                                    <button
                                                        class="btn yellow-button"
                                                        @click="invitePlayerToCoalitionCommand(inviteSearchTerm)"
                                                    >
                                                        Invite
                                                    </button>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col flex-column gap-1">
                                                    Too few members? Invite your friends now!
                                                    <button class="btn blue-button" @click="openInviteLinkModal">
                                                        Invite friends
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="enlistment-options mt-4">
                                            <div class="row label">
                                                <div class="col">Enlistment Options</div>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <div class="form-check">
                                                        <input
                                                            class="form-check-input"
                                                            type="radio"
                                                            name="flexRadioDefault5"
                                                            id="open-enlistment"
                                                            value="freeEnter"
                                                            v-model="enlistmentOption"
                                                            :checked="enlistmentOption === 'freeEnter'"
                                                            :disabled="!showEditEnlistmentOptionButton"
                                                        />
                                                        <label class="form-check-label" for="open-enlistment">
                                                            Open (anyone can join)
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <div class="form-check">
                                                        <input
                                                            class="form-check-input"
                                                            type="radio"
                                                            name="flexRadioDefault5"
                                                            id="request-enlistment"
                                                            value="withApplication"
                                                            v-model="enlistmentOption"
                                                            :checked="enlistmentOption === 'withApplication'"
                                                            :disabled="!showEditEnlistmentOptionButton"
                                                        />
                                                        <label class="form-check-label" for="request-enlistment">
                                                            Request (players must submit a request in order to join)
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <div class="form-check">
                                                        <input
                                                            class="form-check-input"
                                                            type="radio"
                                                            name="flexRadioDefault5"
                                                            id="invite-enlistment"
                                                            value="onlyWithInvite"
                                                            v-model="enlistmentOption"
                                                            :checked="enlistmentOption === 'onlyWithInvite'"
                                                            :disabled="!showEditEnlistmentOptionButton"
                                                        />
                                                        <label class="form-check-label" for="invite-enlistment">
                                                            Invite (players can only join if they are invited)
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div v-if="!showEditEnlistmentOptionButton" class="col">
                                                    <button class="btn yellow-button" @click="toggleEditEnlistmentOptionButton">
                                                        Edit Option
                                                    </button>
                                                </div>
                                                <div v-else class="col d-flex gap-3">
                                                    <button
                                                        class="btn blue-button"
                                                        @click="
                                                            setConfirmationModalProps({
                                                                title: 'Are you sure you want to change the enlistment option?',
                                                                action: () => changeCoalitionEnlistmentOptionCommand(),
                                                            })
                                                        "
                                                    >
                                                        Confirm Option
                                                    </button>
                                                    <button
                                                        class="btn red-button"
                                                        @click="toggleEditEnlistmentOptionButton({ reset: true })"
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-if="coalitionInfo?.additionRule === 'withApplication'" class="right-wrapper">
                                        <div class="requests-table">
                                            <div class="row label">
                                                <div class="col">Requests</div>
                                            </div>
                                            <div class="scrollable-wrapper">
                                                <div
                                                    v-for="request in coalitionsRequestsToJoin"
                                                    :key="`request-${request._id}`"
                                                    class="row"
                                                >
                                                    <div class="col large">
                                                        <span class="clickable">{{ request.username }}</span>
                                                    </div>
                                                    <div class="col small gap-2">
                                                        <button
                                                            class="btn blue-button"
                                                            @click="
                                                                setConfirmationModalProps({
                                                                    title: `Are you sure you want to accept ${request.username}'s request to join the Coalition?`,
                                                                    action: () =>
                                                                        acceptRequestToJoinCoalitionCommand(request._id),
                                                                })
                                                            "
                                                        >
                                                            Accept
                                                        </button>
                                                        <button
                                                            class="btn red-button"
                                                            @click="
                                                                setConfirmationModalProps({
                                                                    title: `Are you sure you want to reject ${request.username}'s request to join the Coalition?`,
                                                                    action: () =>
                                                                        rejectRequestToJoinCoalitionCommand(request._id),
                                                                })
                                                            "
                                                        >
                                                            Reject
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="properties-page" role="tabpanel" aria-labelledby="properties-tab">
                                <p class="section-description">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                    labore et dolore magna aliqua. Duis aute irure dolor in reprehenderit in voluptate velit esse
                                    cillum dolore eu fugiat nulla pariatur.
                                </p>
                                <div class="d-flex justify-content-between">
                                    <div class="left-wrapper">
                                        <div class="coalition-properties-table">
                                            <div class="row label">
                                                <div class="col">Coalition Properties</div>
                                            </div>
                                            <div class="row">
                                                <div class="col">Name</div>
                                                <div class="col">
                                                    <input
                                                        class="form-control"
                                                        v-model="editableCoalitionProperties.name"
                                                        :disabled="!showEditCoalitionPropertiesButtons"
                                                    />
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col">Abbreviation (4 characters or less)</div>
                                                <div class="col">
                                                    <input
                                                        class="form-control"
                                                        v-model="editableCoalitionProperties.abbreviation"
                                                        :disabled="!showEditCoalitionPropertiesButtons"
                                                    />
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col">Members</div>
                                                <div class="col">13</div>
                                            </div>
                                            <div class="row">
                                                <div class="col">Points</div>
                                                <div class="col">14.798</div>
                                            </div>
                                            <div class="row">
                                                <div class="col">Ranking</div>
                                                <div class="col">276</div>
                                            </div>
                                            <div class="row">
                                                <div class="col">Allow members to attack allies</div>
                                                <div class="col">
                                                    <input
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        v-model="editableCoalitionProperties.canAttackAllies"
                                                        :disabled="!showEditCoalitionPropertiesButtons"
                                                    />
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div v-if="!showEditCoalitionPropertiesButtons" class="col">
                                                    <button
                                                        class="btn yellow-button"
                                                        @click="toggleEditCoalitionPropertiesButtons"
                                                    >
                                                        Edit Properties
                                                    </button>
                                                </div>
                                                <div v-else class="col d-flex gap-3">
                                                    <button
                                                        class="btn blue-button"
                                                        @click="
                                                            setConfirmationModalProps({
                                                                title: 'Are you sure you want to change the properties of your coalition?',
                                                                action: () => changeCoalitionPropertiesCommand(),
                                                            })
                                                        "
                                                    >
                                                        Confirm properties
                                                    </button>
                                                    <button
                                                        class="btn red-button"
                                                        @click="toggleEditCoalitionPropertiesButtons({ reset: true })"
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <button
                                            class="btn red-button mt-3"
                                            @click="
                                                setConfirmationModalProps({
                                                    title: 'Are you sure you want to dissolve the Coalition?',
                                                    action: () => dissolveCoalitionCommand(),
                                                })
                                            "
                                        >
                                            Dissolve Coalition
                                        </button>
                                    </div>
                                    <div class="right-wrapper">
                                        <div v-if="!editDescriptionWindowVisible" class="coalition-description-table">
                                            <div class="row label">
                                                <div class="col">Description</div>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <div
                                                        v-html="
                                                            addCustomMarkupToString(
                                                                coalitionInfo.description,
                                                                MarkupLocationIdentifier.COALITION_DESCRIPTION,
                                                            )
                                                        "
                                                    />
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <button
                                                        class="btn yellow-button"
                                                        @click="toggleEditDescriptionWindow({ reset: false })"
                                                    >
                                                        Edit description
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else class="edit-description-table">
                                            <div class="row label">
                                                <div class="col">
                                                    <div
                                                        class="markup-icon-wrapper"
                                                        @click="addPlayerMarkupTags(descriptionInputSelectionPoints)"
                                                    >
                                                        <img src="../assets/icons/population_icon.svg" class="markup-icon" />
                                                        <div class="icon-tooltip">Player</div>
                                                    </div>
                                                    <div
                                                        class="markup-icon-wrapper"
                                                        @click="addCityMarkupTags(descriptionInputSelectionPoints)"
                                                    >
                                                        <img src="../assets/icons/city_icon.svg" class="markup-icon" />
                                                        <div class="icon-tooltip">City</div>
                                                    </div>
                                                    <div
                                                        class="markup-icon-wrapper"
                                                        @click="addCoalitionMarkupTags(descriptionInputSelectionPoints)"
                                                    >
                                                        <img src="../assets/icons/alliance_icon.svg" class="markup-icon" />
                                                        <div class="icon-tooltip">Coalition</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col">
                                                    <textarea
                                                        id="coalition-description-input"
                                                        class="write-message-window"
                                                        v-model="editableCoalitionDescription"
                                                    />
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col gap-2">
                                                    <button
                                                        class="btn blue-button"
                                                        @click="
                                                            setConfirmationModalProps({
                                                                title: 'Are you sure you want to change the description of your coalition?',
                                                                action: () => changeCoalitionDescriptionCommand(),
                                                            })
                                                        "
                                                    >
                                                        Confirm description
                                                    </button>
                                                    <button
                                                        class="btn red-button"
                                                        @click="toggleEditDescriptionWindow({ reset: true })"
                                                    >
                                                        Cancel
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="diplomacy-page" role="tabpanel" aria-labelledby="diplomacy-tab">
                                <p class="section-description">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                    labore et dolore magna aliqua. Duis aute irure dolor in reprehenderit in voluptate velit esse
                                    cillum dolore eu fugiat nulla pariatur.
                                </p>
                                <div class="left-wrapper mb-4">
                                    <div class="diplomacy-table">
                                        <div class="row label">
                                            <div class="col">Diplomatic Request</div>
                                        </div>
                                        <div class="row">
                                            <div class="col">Coalition Name</div>
                                            <div class="col">
                                                <div class="search-input-wrapper">
                                                    <input
                                                        class="form-control"
                                                        v-model="diplomacyRequestSearchTerm"
                                                        @focus="setDiplomacyRequestInputFocus(true)"
                                                        @blur="setDiplomacyRequestInputFocus(false)"
                                                    />
                                                    <Transition name="tooltip-transition">
                                                        <div
                                                            v-if="areDiplomacyRequestSearchResultsVisible"
                                                            class="search-results-wrapper"
                                                        >
                                                            <div
                                                                v-if="!getMatchedCoalitions(diplomacyRequestSearchTerm).length"
                                                                class="search-result"
                                                            >
                                                                No coalitions found
                                                            </div>
                                                            <div
                                                                v-for="(coalition, coalition_index) in getMatchedCoalitions(
                                                                    diplomacyRequestSearchTerm,
                                                                )"
                                                                :key="`coalition-result-${coalition_index}`"
                                                                class="search-result"
                                                                @click="setDiplomacyRequestData(coalition)"
                                                            >
                                                                {{ coalition.name + ' (' + coalition.abbreviation + ')' }}
                                                            </div>
                                                        </div>
                                                    </Transition>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col">Type</div>
                                            <div class="col">
                                                <select v-model="diplomacyRequestType" class="form-select">
                                                    <option value="alliance">Alliance</option>
                                                    <option value="nap">Non-Aggression Pact (NAP)</option>
                                                    <option value="war">War</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col justify-content-start">
                                                <button
                                                    class="btn yellow-button"
                                                    @click="
                                                        setConfirmationModalProps({
                                                            title: `${diplomacyRequestType}`,
                                                            action: () =>
                                                                sendDiplomacyRequest(
                                                                    diplomacyRequestCoalitionId,
                                                                    diplomacyRequestType,
                                                                ),
                                                        })
                                                    "
                                                >
                                                    Send
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="diplomacy-tables-wrapper d-flex justify-content-between">
                                    <div class="left-wrapper">
                                        <div class="diplomacy-table">
                                            <div class="row label">
                                                <div class="col">Alliances</div>
                                            </div>
                                            <div class="scrollable-wrapper">
                                                <div
                                                    v-for="(alliance, alliance_index) in alliances"
                                                    :key="`alliance-${alliance_index}`"
                                                    class="row"
                                                >
                                                    <div class="col">{{ alliance.name }}</div>
                                                    <div class="col">
                                                        <button
                                                            class="btn red-button"
                                                            @click="
                                                                setConfirmationModalProps({
                                                                    title: `Are you sure you want to break the Alliance with ${alliance.name}?`,
                                                                    action: () => breakAllianceCommand(alliance._id),
                                                                })
                                                            "
                                                        >
                                                            Break alliance
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="diplomacy-table">
                                            <div class="row label">
                                                <div class="col">Non-Aggression Pact (NAP)</div>
                                            </div>
                                            <div class="scrollable-wrapper">
                                                <div
                                                    v-for="(nap, nap_index) in nonAggressionPacts"
                                                    :key="`nap-${nap_index}`"
                                                    class="row"
                                                >
                                                    <div class="col">{{ nap.name }}</div>
                                                    <div class="col">
                                                        <button
                                                            class="btn red-button"
                                                            @click="
                                                                setConfirmationModalProps({
                                                                    title: `Are you sure you want to break the Non Aggression Pact with ${nap.name}?`,
                                                                    action: () => breakNonAggressionPactCommand(nap._id),
                                                                })
                                                            "
                                                        >
                                                            Break NAP
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="diplomacy-table">
                                            <div class="row label">
                                                <div class="col">Wars</div>
                                            </div>
                                            <div class="scrollable-wrapper">
                                                <div v-for="(war, war_index) in wars" :key="`war-${war_index}`" class="row">
                                                    <div class="col">{{ war.targetCoalition.name }}</div>
                                                    <div class="col">
                                                        <button
                                                            class="btn blue-button"
                                                            :disabled="isPeaceRequestAlreadySent(war._id)"
                                                            @click="
                                                                setEndWarModalProps({
                                                                    coalition_id: getEnemyCoalitionId(war._id),
                                                                    war_id: war._id,
                                                                })
                                                            "
                                                        >
                                                            Propose Peace Agreement
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="right-wrapper">
                                        <div class="diplomacy-table">
                                            <div class="row label">
                                                <div class="col">Alliance Requests</div>
                                            </div>
                                            <div class="scrollable-wrapper">
                                                <div
                                                    v-for="alliance_request in allianceRequests"
                                                    :key="`alliance-request-${alliance_request.id}`"
                                                    class="row"
                                                >
                                                    <div class="col">
                                                        {{ alliance_request.from?.name || alliance_request.to?.name }}
                                                    </div>
                                                    <div v-if="alliance_request.from" class="col">
                                                        <button
                                                            class="btn blue-button"
                                                            @click="
                                                                setConfirmationModalProps({
                                                                    title: `Accept Alliance with ${alliance_request.from?.name}?`,
                                                                    action: () =>
                                                                        acceptInviteToAllianceCommand(alliance_request.id),
                                                                })
                                                            "
                                                        >
                                                            Accept
                                                        </button>
                                                        <button
                                                            class="btn red-button"
                                                            @click="
                                                                setConfirmationModalProps({
                                                                    title: `Refuse Alliance with ${alliance_request.from?.name}?`,
                                                                    action: () =>
                                                                        rejectDiplomacyRequestCommand(alliance_request.id),
                                                                })
                                                            "
                                                        >
                                                            Refuse
                                                        </button>
                                                    </div>
                                                    <div v-if="alliance_request.to" class="col">
                                                        <button
                                                            class="btn yellow-button"
                                                            @click="
                                                                setConfirmationModalProps({
                                                                    title: `Retract Alliance request to ${alliance_request.to?.name}?`,
                                                                    action: () =>
                                                                        retractDiplomacyRequestCommand(alliance_request.id),
                                                                })
                                                            "
                                                        >
                                                            Retract
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="diplomacy-table">
                                            <div class="row label">
                                                <div class="col">Non-Aggression Pact (NAP) Requests</div>
                                            </div>
                                            <div class="scrollable-wrapper">
                                                <div
                                                    v-for="nap_request in nonAggressionPactRequests"
                                                    :key="`nap-request-${nap_request.id}`"
                                                    class="row"
                                                >
                                                    <div class="col">{{ nap_request.from?.name || nap_request.to?.name }}</div>
                                                    <div v-if="nap_request.from" class="col">
                                                        <button
                                                            class="btn blue-button"
                                                            @click="
                                                                setConfirmationModalProps({
                                                                    title: `Accept Non Aggression Pact with ${nap_request.from?.name}?`,
                                                                    action: () =>
                                                                        acceptInviteToNonAggressionPactCommand(nap_request.id),
                                                                })
                                                            "
                                                        >
                                                            Accept
                                                        </button>
                                                        <button
                                                            class="btn red-button"
                                                            @click="
                                                                setConfirmationModalProps({
                                                                    title: `Refuse Non Aggression Pact with ${nap_request.from?.name}?`,
                                                                    action: () => rejectDiplomacyRequestCommand(nap_request.id),
                                                                })
                                                            "
                                                        >
                                                            Refuse
                                                        </button>
                                                    </div>
                                                    <div v-if="nap_request.to" class="col">
                                                        <button
                                                            class="btn yellow-button"
                                                            @click="
                                                                setConfirmationModalProps({
                                                                    title: `Retract Non Aggression Pact request to ${nap_request.to?.name}?`,
                                                                    action: () => retractDiplomacyRequestCommand(nap_request.id),
                                                                })
                                                            "
                                                        >
                                                            Retract
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="diplomacy-table">
                                            <div class="row label">
                                                <div class="col">Peace Agreement Requests</div>
                                            </div>
                                            <div class="scrollable-wrapper">
                                                <div
                                                    v-for="peace_request in peaceRequests"
                                                    :key="`peace-request-${peace_request.id}`"
                                                    class="row"
                                                >
                                                    <div class="col">
                                                        {{ peace_request.from?.name || peace_request.to?.name }}
                                                        <img
                                                            src="../assets/icons/info_icon.svg"
                                                            class="info-icon"
                                                            @click="setTributeInfoModalProps({ peace_proposal: peace_request })"
                                                        />
                                                    </div>
                                                    <div v-if="peace_request.from" class="col">
                                                        <button
                                                            class="btn blue-button"
                                                            @click="
                                                                setConfirmationModalProps({
                                                                    title: `Accept Peace with ${peace_request.from?.name}?`,
                                                                    action: () => acceptPeaceProposalCommand(peace_request.id),
                                                                })
                                                            "
                                                        >
                                                            Accept
                                                        </button>
                                                        <button
                                                            class="btn red-button"
                                                            @click="
                                                                setConfirmationModalProps({
                                                                    title: `Refuse Peace with ${peace_request.from?.name}?`,
                                                                    action: () => rejectDiplomacyRequestCommand(peace_request.id),
                                                                })
                                                            "
                                                        >
                                                            Refuse
                                                        </button>
                                                    </div>
                                                    <div v-if="peace_request.to" class="col">
                                                        <button
                                                            class="btn yellow-button"
                                                            @click="
                                                                setConfirmationModalProps({
                                                                    title: `Retract Peace request to ${peace_request.to?.name}?`,
                                                                    action: () =>
                                                                        retractDiplomacyRequestCommand(peace_request.id),
                                                                })
                                                            "
                                                        >
                                                            Retract
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="wars-page" role="tabpanel" aria-labelledby="wars-tab">
                                <p class="section-description">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                    labore et dolore magna aliqua. Duis aute irure dolor in reprehenderit in voluptate velit esse
                                    cillum dolore eu fugiat nulla pariatur.
                                </p>
                                <div v-for="war in wars" :key="`war-${war._id}`" class="war-table">
                                    <div class="row label">
                                        <div class="col">
                                            {{ war.initiatorCoalition?.name }} vs. {{ war.targetCoalition?.name }}
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <div v-if="effectsTimeRemaining[war._id] > 0" class="war-bonuses-wrapper">
                                                <div
                                                    v-for="effect in getWarEffects(war._id)"
                                                    :key="`effect-${effect.name}`"
                                                    class="bonus-icon-wrapper"
                                                    @click="setWarEffectModalProps({ effect, start_time: war?.dateCreated })"
                                                >
                                                    <div class="icon-tooltip">{{ effect.name }}</div>
                                                    <img src="../assets/icons/construction_icon.svg" class="bonus-icon" />
                                                </div>
                                            </div>
                                            <div class="progress" style="width: 100%; background: lightcoral">
                                                <div
                                                    class="progress-bar"
                                                    role="progressbar"
                                                    :style="`width: ${getWarProgress(war._id)}%`"
                                                    :aria-valuenow="getWarProgress(war._id)"
                                                    aria-valuemin="0"
                                                    aria-valuemax="100"
                                                >
                                                    {{ getWarProgress(war._id) }}%
                                                </div>
                                            </div>
                                            <div class="war-objectives-wrapper">
                                                <div
                                                    v-for="(goal, goal_index) in war.achievedGoals"
                                                    :key="`goal-${goal_index}`"
                                                    class="war-objective"
                                                    :class="{ completed: goal.owner.includes(coalitionInfo._id) }"
                                                >
                                                    <div class="icon-tooltip">{{ goal.description }}</div>
                                                    {{ goal.name }}
                                                </div>
                                            </div>
                                            <button
                                                class="btn blue-button"
                                                :disabled="isPeaceRequestAlreadySent(war._id)"
                                                @click="
                                                    setEndWarModalProps({
                                                        coalition_id: getEnemyCoalitionId(war._id),
                                                        war_id: war._id,
                                                    })
                                                "
                                            >
                                                Propose Peace Agreement
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="tributes-page" role="tabpanel" aria-labelledby="tributes-tab">
                                <p class="section-description">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                                    labore et dolore magna aliqua. Duis aute irure dolor in reprehenderit in voluptate velit esse
                                    cillum dolore eu fugiat nulla pariatur.
                                </p>
                                <div class="tribute-table">
                                    <div class="row label">
                                        <div class="col">Paid Tributes</div>
                                    </div>
                                    <template
                                        v-for="tribute_to_pay in coalitionInfo.tributeToPay"
                                        :key="`tribute-to-pay-${tribute_to_pay.coalition._id}`"
                                    >
                                        <div
                                            v-for="player_tribute in tribute_to_pay.tributePerUser"
                                            :key="`player-tribute-${tribute_to_pay.coalition_id}-${player_tribute.user._id}`"
                                            class="row"
                                        >
                                            <div class="col small">
                                                <span class="clickable">{{ player_tribute.user.username }}</span>
                                            </div>
                                            <div class="col gap-4 large">
                                                <div class="tribute-resources-group">
                                                    <img src="../assets/icons/aluminum_icon.svg" class="tribute-resources-icon" />
                                                    <span>{{ player_tribute.totalAmount.aluminum }}</span>
                                                </div>
                                                <div class="tribute-resources-group">
                                                    <img src="../assets/icons/oil_icon.svg" class="tribute-resources-icon" />
                                                    <span>{{ player_tribute.totalAmount.oil }}</span>
                                                </div>
                                                <div class="tribute-resources-group">
                                                    <img src="../assets/icons/metal_icon.svg" class="tribute-resources-icon" />
                                                    <span>{{ player_tribute.totalAmount.metal }}</span>
                                                </div>
                                                <div class="tribute-resources-group">
                                                    <img src="../assets/icons/rations_icon.svg" class="tribute-resources-icon" />
                                                    <span>{{ player_tribute.totalAmount.rations }}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </template>
                                </div>
                                <div class="tribute-table mt-4">
                                    <div class="row label">
                                        <div class="col">Received Tributes</div>
                                    </div>
                                    <div
                                        v-for="tribute_to_receive in coalitionInfo.tributeToReceive"
                                        :key="`tribute-to-pay-${tribute_to_receive.coalition._id}`"
                                        class="row"
                                    >
                                        <div class="col small">
                                            <span class="clickable">{{ tribute_to_receive.coalition.name }}</span>
                                        </div>
                                        <div class="col gap-4 large">
                                            <div class="tribute-resources-group">
                                                <img src="../assets/icons/aluminum_icon.svg" class="tribute-resources-icon" />
                                                <span>{{ tribute_to_receive.aluminum }}</span>
                                            </div>
                                            <div class="tribute-resources-group">
                                                <img src="../assets/icons/oil_icon.svg" class="tribute-resources-icon" />
                                                <span>{{ tribute_to_receive.oil }}</span>
                                            </div>
                                            <div class="tribute-resources-group">
                                                <img src="../assets/icons/metal_icon.svg" class="tribute-resources-icon" />
                                                <span>{{ tribute_to_receive.metal }}</span>
                                            </div>
                                            <div class="tribute-resources-group">
                                                <img src="../assets/icons/rations_icon.svg" class="tribute-resources-icon" />
                                                <span>{{ tribute_to_receive.rations }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="not-in-coalition-wrapper">
                        <p class="section-description mb-4">
                            Aici aveti posibilitatea sa aplicati la un trib care v-a sarit in ochi, sau sa acceptati o invitatie
                            primita de la un membru de recrutare dintr-un trib. Aveti, de asemenea, posibilitatea de a crea
                            propriul trib, daca doriti sa incercati sa-i conduceti pe altii.
                        </p>
                        <div class="d-flex justify-content-between flex-column flex-md-row">
                            <div class="left-wrapper">
                                <div class="invitations-table">
                                    <div class="row label">
                                        <div class="col">Invitations</div>
                                    </div>
                                    <div class="scrollable-wrapper">
                                        <div
                                            v-for="(invite, invite_index) in coalitionInvites.filter(inv => !inv.accepted)"
                                            :key="invite_index"
                                            class="row"
                                        >
                                            <div class="col">
                                                <span class="clickable"
                                                    >{{ invite.coalitionName }} ({{ invite.coalitionAbbreviation }})</span
                                                >
                                            </div>
                                            <div class="col" @click="acceptCoalitionInviteCommand(invite._id)">
                                                <button class="btn yellow-button">Accept</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="coalition-properties-table mt-4">
                                    <div class="row label">
                                        <div class="col">Create a Coalition</div>
                                    </div>
                                    <div class="row">
                                        <div class="col">Name:</div>
                                        <div class="col">
                                            <input v-model="coalitionNameForCreate" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">Abbreviation (4 characters or less):</div>
                                        <div class="col">
                                            <input v-model="coalitionAbbreviationForCreate" maxlength="4" class="form-control" />
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col">
                                            <button
                                                class="btn yellow-button"
                                                @click="createCoalitionCommand"
                                                :disabled="awaitingResponse"
                                            >
                                                Create Coalition
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="right-wrapper">
                                <div class="nearby-coalitions-table">
                                    <div class="row label">
                                        <div class="col">Nearby Coalitions</div>
                                    </div>
                                    <div
                                        v-for="nearby_coalition in nearbyCoalitions"
                                        :key="`nearby-coalition-${nearby_coalition.id}`"
                                        class="row"
                                    >
                                        <div class="col large">
                                            <span class="clickable">{{ nearby_coalition.name }}</span>
                                        </div>
                                        <div
                                            v-if="nearby_coalition.additionRule === 'freeEnter'"
                                            class="col small"
                                        >
                                            <button
                                                v-if="!getCoalitionCapacityReached(nearby_coalition.id)"
                                                class="btn yellow-button"
                                                @click="joinCoalitionCommand(nearby_coalition.id)"
                                            >
                                                Join
                                            </button>
                                            <span v-else>Already full</span>
                                        </div>
                                        <div
                                            v-if="nearby_coalition.additionRule === 'withApplication'"
                                            class="col small"
                                        >
                                            <span v-if="checkIfAlreadyInvitedToCoalition(nearby_coalition.id)">
                                                Already invited
                                            </span>
                                            <button
                                                v-else-if="!checkIfAlreadyAppliedToCoalition(nearby_coalition.id)"
                                                class="btn yellow-button"
                                                @click="requestToJoinCoalitionCommand(nearby_coalition.id)"
                                            >
                                                Request to Join
                                            </button>
                                            <span v-else>Already applied</span>
                                        </div>
                                        <div
                                            v-if="nearby_coalition.additionRule === 'onlyWithInvite'"
                                            class="col small"
                                        >
                                            <span v-if="checkIfAlreadyInvitedToCoalition(nearby_coalition.id)">
                                                Already invited
                                            </span>
                                            <span v-else> Coalition is invite only </span>
                                        </div>
                                    </div>
                                </div>
                                <div class="requests-table mt-4">
                                    <div class="row label">
                                        <div class="col">Requests</div>
                                    </div>
                                    <div
                                        v-for="request_to_join in requestsToJoinCoalition"
                                        :key="`request-to-join-${request_to_join}`"
                                        class="row"
                                    >
                                        <div class="col large">
                                            <span class="clickable">{{ request_to_join.name }}</span>
                                        </div>
                                        <div class="col small">
                                            <button
                                                class="btn yellow-button"
                                                @click="retractRequestToJoinCoalitionCommand(request_to_join._id)"
                                            >
                                                Retract
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <end-war-modal id="end-war-modal" :coalition-id="endWarModalProps.coalitionId" :war-id="endWarModalProps.warId" />
        <invite-link-modal id="invite-link-modal-2" />
        <war-effect-modal id="war-effect-modal" :effect="selectedWarEffect" :start-time="selectedWarEffectStartTime" />
        <tribute-info-modal
            id="tribute-info-modal"
            :user-coalition-name="tributeInfoModalProps.userCoalitionName"
            :user-coalition-id="tributeInfoModalProps.userCoalitionId"
            :peace-proposal="tributeInfoModalProps.peaceProposal"
            @open-confirm-modal-accept="
                peace_request =>
                    setConfirmationModalProps({
                        title: `Accept Peace with ${peace_request.from?.name}?`,
                        action: () => acceptPeaceProposalCommand(peace_request.id),
                    })
            "
            @open-confirm-modal-refuse="
                peace_request =>
                    setConfirmationModalProps({
                        title: `Refuse Peace with ${peace_request.from?.name}?`,
                        action: () => rejectDiplomacyRequestCommand(peace_request.id),
                    })
            "
            @open-confirm-modal-retract="
                peace_request =>
                    setConfirmationModalProps({
                        title: `Retract Peace request to ${peace_request.to?.name}?`,
                        action: () => retractDiplomacyRequestCommand(peace_request.id),
                    })
            "
        />
        <confirmation-modal
            id="confirmation-modal-coalition"
            :title="confirmationModalProps.title"
            :action="confirmationModalProps.action"
        />
    </div>
</template>

<script>
import EndWarModal from '@/components/EndWarModal';
import InviteLinkModal from '@/components/InviteLinkModal';
import ConfirmationModal from '@/components/ConfirmationModal';
import WarEffectModal from '@/components/WarEffectModal';
import TributeInfoModal from '@/components/TributeInfoModal';
import { mapActions } from 'vuex';
import { MarkupTypeIdentifier, MarkupLocationIdentifier } from '@/utils/constants/markup';
import TableWithPagination from '@/components/TableWithPagination';

export default {
    name: 'CoalitionModal',
    components: { TableWithPagination, ConfirmationModal, InviteLinkModal, EndWarModal, WarEffectModal, TributeInfoModal },
    props: {
        user: {
            type: Object,
            default: () => {},
        },
        allUsers: {
            type: Array,
            default: () => [],
        },
        coalitionInfo: {
            type: Object,
            default: () => {},
        },
        coalitionInvites: {
            type: Array,
            default: () => [],
        },
        nearbyCoalitions: {
            type: Array,
            default: () => [],
        },
        requestsToJoinCoalition: {
            type: Array,
            default: () => [],
        },
        awaitingResponse: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            MarkupTypeIdentifier,
            MarkupLocationIdentifier,
            showEditRightsButtons: false,
            rightsModifiableByLieutenant: ['SendAllMessage', 'Diplomacy', 'Invitation', 'Modify_Members_Rights'],
            rightsModifiableByMember: ['SendAllMessage', 'Invitation'],
            coalitionNameForCreate: '',
            coalitionAbbreviationForCreate: '',
            eventsPerPage: 5,
            currentPage: 1,
            members: [],
            editDescriptionWindowVisible: false,
            memberActionsWrapperVisible: [],
            smallScreen: false,
            enlistmentOption: '', // freeEnter | withApplication | onlyWithInvite
            confirmationModalProps: {
                title: '',
                action: () => {},
            },
            endWarModalProps: {
                coalitionId: '',
                warId: '',
            },
            tributeInfoModalProps: {
                userCoalitionName: '',
                userCoalitionId: '',
                peaceProposal: null,
            },
            showEditCoalitionPropertiesButtons: false,
            showEditEnlistmentOptionButton: false,
            editableCoalitionDescription: '',
            editableCoalitionProperties: {
                name: '',
                abbreviation: '',
                canAttackAllies: '',
            },
            inviteSearchTerm: '',
            isInvitePlayerInputFocused: false,
            diplomacyRequestSearchTerm: '',
            diplomacyRequestCoalitionId: '',
            isDiplomacyRequestInputFocused: false,
            diplomacyRequestType: 'alliance', // alliance | nap | war
            descriptionInputSelectionPoints: {
                start: null,
                end: null,
            },
            selectedWarEffect: null,
            selectedWarEffectStartTime: null,
            isWarsTabSelected: false,
            effectsTimeRemaining: {},
            effectsTimeoutIds: {},
        };
    },
    computed: {
        isUserInCoalition() {
            return !!this.coalitionInfo.name;
        },
        userCoalitionRank() {
            return this.members.find(member => member._id === this.user._id).coalitionRank;
        },
        canUserModifyRights() {
            return this.members
                .find(member => member._id === this.user._id)
                .rights?.find(right => right.permission === 'Modify_Members_Rights').hasIt;
        },
        canUserKickMember() {
            return this.members
                .find(member => member._id === this.user._id)
                .rights?.find(right => right.permission === 'Exclude_Members').hasIt;
        },
        coalitionsRequestsToJoin() {
            return this.coalitionInfo.receivedRequests;
        },
        coalitionInvitesInEnlistment() {
            return this.coalitionInfo.invites;
        },
        realUsers() {
            return this.allUsers.filter(user => !user.isBarbarian);
        },
        usersNotInCoalition() {
            return this.allUsers.filter(user => !user.isBarbarian && user.username !== this.user.username && !user.coalition);
        },
        areInvitePlayerSearchResultsVisible() {
            return this.isInvitePlayerInputFocused && this.inviteSearchTerm;
        },
        areDiplomacyRequestSearchResultsVisible() {
            return this.isDiplomacyRequestInputFocused && this.diplomacyRequestSearchTerm;
        },
        usersAndCoalitionLoaded() {
            return this.allUsers.length && this.coalitionInfo.name;
        },
        alliances() {
            console.log('USER:::', this.user);
            return this.coalitionInfo.alliances;
        },
        nonAggressionPacts() {
            return this.coalitionInfo.nonAggressionPacts;
        },
        wars() {
            return this.coalitionInfo.wars;
        },
        pactInvitations() {
            return this.coalitionInfo.pactInvitations;
        },
        allCoalitions() {
            const COALITIONS = [];
            const USERS_WITH_COALITION = this.allUsers.filter(user => !!user.coalition);
            USERS_WITH_COALITION.forEach(user => {
                if (!COALITIONS.some(coalition => coalition.name === user.coalition.name)) {
                    COALITIONS.push(user.coalition);
                }
            });
            return COALITIONS;
        },
        allianceRequests() {
            return this.coalitionInfo?.pactInvitations?.filter(request => request.pact === 'Alliance') || [];
        },
        nonAggressionPactRequests() {
            return this.coalitionInfo?.pactInvitations?.filter(request => request.pact === 'Non Aggression Pact') || [];
        },
        peaceRequests() {
            return this.coalitionInfo?.pactInvitations?.filter(request => request.pact === 'Peace') || [];
        },
    },
    created() {
        if (window) {
            window.addEventListener('resize', this.onResize);
            if (window.innerWidth <= 850) {
                this.smallScreen = true;
            }
        }

        for (let i = 0; i < this.members.length; i++) {
            this.memberActionsWrapperVisible.push(false);
        }
    },
    watch: {
        coalitionInfo: {
            handler() {
                if (this.coalitionInfo.name) {
                    this.members = this.createUnifiedMembersArray();
                    this.editableCoalitionProperties.name = this.coalitionInfo.name;
                    this.editableCoalitionProperties.abbreviation = this.coalitionInfo.abbreviation;
                    this.editableCoalitionProperties.canAttackAllies = this.coalitionInfo.canAttackAllies;
                    this.editableCoalitionDescription = this.coalitionInfo.description;
                    this.enlistmentOption = this.coalitionInfo.additionRule;
                    this.addCustomMarkupToString(this.coalitionInfo.description, MarkupLocationIdentifier.COALITION_DESCRIPTION);
                }
            },
            deep: true,
        },
        usersAndCoalitionLoaded: {
            handler() {
                this.createEventHandlersForMarkup(MarkupLocationIdentifier.COALITION_EVENTS);
                this.createEventHandlersForMarkup(MarkupLocationIdentifier.COALITION_DESCRIPTION);
            },
        },
        isWarsTabSelected: {
            handler(newVal) {
                if (newVal) {
                    this.getEffectsTimeRemaining();
                    this.wars.forEach(war => {
						if (!this.effectsTimeoutIds[war._id]) {
                            this.effectsTimeoutIds[war._id] = setTimeout(() => {
                                this.removeWarEffectsAction({ war_id: war._id });
                            }, this.effectsTimeRemaining[war._id]);
                        }
                    });
				}
            },
        },
    },
    methods: {
        ...mapActions([
            'createCoalition',
            'dissolveCoalition',
            'acceptCoalitionInvite',
            'kickCoalitionMember',
            'leaveCoalition',
            'changeCoalitionMemberRank',
            'changeCoalitionRights',
            'changeCoalitionProperties',
            'changeCoalitionDescription',
            'changeCoalitionEnlistmentOption',
            'joinCoalition',
            'invitePlayerToCoalition',
            'retractInviteToCoalition',
            'requestToJoinCoalition',
            'acceptRequestToJoinCoalition',
            'rejectRequestToJoinCoalition',
            'retractRequestToJoinCoalition',
            'declareWarToCoalition',
            'acceptPeaceProposal',
            'inviteCoalitionToNonAggressionPact',
            'acceptInviteToNonAggressionPact',
            'breakNonAggressionPact',
            'inviteCoalitionToAlliance',
            'acceptInviteToAlliance',
            'breakAlliance',
            'rejectDiplomacyRequest',
            'retractDiplomacyRequest',
            'removeWarEffectsAction',
        ]),
        checkIfAlreadyAppliedToCoalition(coalition_id) {
            return this.requestsToJoinCoalition.find(request => request._id === coalition_id);
        },
        checkIfAlreadyInvitedToCoalition(coalition_id) {
            return this.coalitionInvites.find(invite => invite.coalitionId === coalition_id);
        },
        isPeaceRequestAlreadySent(war_id) {
            return this.peaceRequests.find(request => request.warId === war_id);
        },
        getEffectsTimeRemaining() {
            this.wars.forEach(war => {
                this.effectsTimeRemaining[war._id] = this.getRemainingTime(war.dateCreated);
            });
        },
        getCoalitionCapacityReached(coalition_id) {
            const COALITION = this.allCoalitions.find(coalition => coalition._id === coalition_id);
            return COALITION?.numOfMembers === COALITION?.maxNumOfMembers;
        },
        getEnemyCoalitionId(war_id) {
            const WAR = this.wars.find(war => war._id === war_id);
            return this.coalitionInfo._id === WAR.initiatorCoalition._id ? WAR.targetCoalition._id : WAR.initiatorCoalition._id;
        },
        getWarProgress(war_id) {
            const WAR = this.wars.find(war => war._id === war_id);
            return this.coalitionInfo._id === WAR.initiatorCoalition._id ? WAR.progressForInitiator : WAR.progressForTarget;
        },
        getWarEffects(war_id) {
            const WAR = this.wars.find(war => war._id === war_id);
            return this.coalitionInfo._id === WAR.initiatorCoalition._id ? WAR.effectsInitiator : WAR.effectsTarget;
        },
        getMatchedUsers(search_term) {
            if (!search_term) {
                return [];
            }
            return this.usersNotInCoalition.filter(user => user.username.toLowerCase().includes(search_term.toLowerCase()));
        },
        getMatchedCoalitions(search_term) {
            if (!search_term) {
                return [];
            }
            return this.allCoalitions.filter(coalition => coalition.name.toLowerCase().includes(search_term.toLowerCase()));
        },
        getRemainingTime(date_string) {
            // Parse the input string into a Date object
            const dateParts = date_string.split(/[/ :]/);
            // Note: Months are 0-based in JavaScript Date objects, so subtract 1 from the month
            const currentDate = new Date(
                dateParts[2],
                dateParts[1] - 1,
                dateParts[0],
                dateParts[3],
                dateParts[4],
                dateParts[5],
                dateParts[6],
            );

            // Add 24 hours to the dateCreated
            const futureDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000);

            // Return the time remaining from current time to adjusted time
            return futureDate.getTime() - Date.now();
        },
        setInviteSearchTerm(search_term) {
            this.inviteSearchTerm = search_term;
        },
        setDiplomacyRequestData(coalition) {
            this.diplomacyRequestSearchTerm = coalition.name;
            this.diplomacyRequestCoalitionId = coalition._id;
        },
        setInvitePlayerInputFocus(status) {
            setTimeout(() => {
                this.isInvitePlayerInputFocused = status;
            }, 10);
        },
        setDiplomacyRequestInputFocus(status) {
            setTimeout(() => {
                this.isDiplomacyRequestInputFocused = status;
            }, 10);
        },
        toggleEditCoalitionPropertiesButtons({ reset }) {
            if (reset) {
                this.resetCoalitionProperties();
            }
            this.showEditCoalitionPropertiesButtons = !this.showEditCoalitionPropertiesButtons;
        },
        toggleEditEnlistmentOptionButton({ reset }) {
            if (reset) {
                this.resetEnlistmentOption();
            }
            this.showEditEnlistmentOptionButton = !this.showEditEnlistmentOptionButton;
        },
        resetCoalitionProperties() {
            this.editableCoalitionProperties = {
                name: this.coalitionInfo.name,
                abbreviation: this.coalitionInfo.abbreviation,
                canAttackAllies: this.coalitionInfo.canAttackAllies,
            };
        },
        resetEnlistmentOption() {
            this.changeEnlistmentOption(this.coalitionInfo.additionRule);
        },
        toggleEditRightsButtons({ reset }) {
            if (reset) {
                this.resetMemberRights();
            }
            this.showEditRightsButtons = !this.showEditRightsButtons;
        },
        resetMemberRights() {
            this.members.forEach((member, member_index) => {
                if (!this.coalitionInfo.rights[member_index]) {
                    return;
                }
                member.rights = JSON.parse(JSON.stringify(this.coalitionInfo.rights[member_index].permissions));
            });
        },
        checkIsRightModifiable(member_checked, right_name) {
            if (!this.showEditRightsButtons) {
                return false;
            }
            if (!this.canUserModifyRights) {
                return false;
            }
            if (member_checked._id === this.user._id) {
                return false;
            }
            if (this.userCoalitionRank === 'General') {
                return true;
            }
            if (this.userCoalitionRank === 'Lieutenant') {
                return (
                    !['General', 'Lieutenant'].includes(member_checked.coalitionRank) &&
                    this.rightsModifiableByLieutenant.includes(right_name)
                );
            }
            return (
                !['General', 'Lieutenant'].includes(member_checked.coalitionRank) &&
                this.rightsModifiableByMember.includes(right_name)
            );
        },
        checkIsKickVisible(member_checked) {
            if (!this.canUserKickMember) {
                return false;
            }
            if (member_checked._id === this.user._id) {
                return false;
            }
            if (this.userCoalitionRank === 'General') {
                return true;
            }
            if (this.userCoalitionRank === 'Lieutenant') {
                return !['General', 'Lieutenant'].includes(member_checked.coalitionRank);
            }
            return !['General', 'Lieutenant'].includes(member_checked.coalitionRank);
        },
        checkIsPromoteVisible(member_checked) {
            if (member_checked.coalitionRank === 'Lieutenant') {
                return false;
            }
            if (!this.canUserModifyRights) {
                return false;
            }
            if (member_checked._id === this.user._id) {
                return false;
            }
            if (this.userCoalitionRank === 'General') {
                return true;
            }
            if (this.userCoalitionRank === 'Lieutenant') {
                return member_checked.coalitionRank === 'Member';
            }
            return false;
        },
        checkIsDemoteVisible(member_checked) {
            if (member_checked.coalitionRank === 'Member') {
                return false;
            }
            if (!this.canUserModifyRights) {
                return false;
            }
            if (member_checked._id === this.user._id) {
                return false;
            }
            if (this.userCoalitionRank === 'General') {
                return true;
            }
            if (this.userCoalitionRank === 'Lieutenant') {
                return member_checked.coalitionRank === 'Member';
            }
            return false;
        },
        kickMember(user_id) {
            this.kickCoalitionMember({ userId: user_id });
        },
        promoteMember(user_id) {
            this.changeCoalitionMemberRank({ promote: true, userId: user_id });
        },
        demoteMember(user_id) {
            this.changeCoalitionMemberRank({ promote: false, userId: user_id });
        },
        leaveCoalitionCommand() {
            this.leaveCoalition();
        },
        createCoalitionCommand() {
            this.createCoalition({ name: this.coalitionNameForCreate, abbreviation: this.coalitionAbbreviationForCreate });
        },
        dissolveCoalitionCommand() {
            this.dissolveCoalition();
        },
        joinCoalitionCommand(coalition_id) {
            this.joinCoalition({ coalition_id });
        },
        async invitePlayerToCoalitionCommand(player_name) {
            if (!player_name) {
                return;
            }
            const PLAYER = this.usersNotInCoalition.find(user => user.username === player_name);
            if (!PLAYER) {
                return;
            }
            await this.invitePlayerToCoalition({ user_id: PLAYER._id });
            this.setInviteSearchTerm('');
        },
        retractInviteToCoalitionCommand(invite_id) {
            this.retractInviteToCoalition({ invite_id });
        },
        requestToJoinCoalitionCommand(coalition_id) {
            this.requestToJoinCoalition({ coalition_id });
        },
        acceptRequestToJoinCoalitionCommand(request_id) {
            this.acceptRequestToJoinCoalition({ request_id });
        },
        rejectRequestToJoinCoalitionCommand(request_id) {
            this.rejectRequestToJoinCoalition({ request_id });
        },
        retractRequestToJoinCoalitionCommand(request_id) {
            this.retractRequestToJoinCoalition({ request_id });
        },
        acceptCoalitionInviteCommand(invite_id) {
            this.acceptCoalitionInvite({ invite_id });
        },
        acceptPeaceProposalCommand(invite_id) {
            this.acceptPeaceProposal({ invite_id });
        },
        acceptInviteToNonAggressionPactCommand(invite_id) {
            this.acceptInviteToNonAggressionPact({ invite_id });
        },
        breakNonAggressionPactCommand(coalition_id) {
            this.breakNonAggressionPact({ coalition_id });
        },
        acceptInviteToAllianceCommand(invite_id) {
            this.acceptInviteToAlliance({ invite_id });
        },
        breakAllianceCommand(coalition_id) {
            this.breakAlliance({ coalition_id });
        },
        rejectDiplomacyRequestCommand(invite_id) {
            this.rejectDiplomacyRequest({ invite_id });
        },
        retractDiplomacyRequestCommand(invite_id) {
            this.retractDiplomacyRequest({ invite_id });
        },
        sendDiplomacyRequest(coalition_id, type) {
            switch (type) {
                case 'alliance':
                    this.inviteCoalitionToAlliance({ coalition_id });
                    break;
                case 'nap':
                    this.inviteCoalitionToNonAggressionPact({ coalition_id });
                    break;
                case 'war':
                    this.declareWarToCoalition({ coalition_id });
                    break;
                default:
                    return false;
            }
        },
        setConfirmationModalProps({ title, action }) {
            this.confirmationModalProps.title = title;
            this.confirmationModalProps.action = action;
            this.openConfirmationModal();
        },
        setEndWarModalProps({ coalition_id, war_id }) {
            this.endWarModalProps.coalitionId = coalition_id;
            this.endWarModalProps.warId = war_id;
            this.openEndWarModal();
        },
        setWarEffectModalProps({ effect, start_time }) {
            this.selectedWarEffect = null;
            this.selectedWarEffectStartTime = null;
            this.openWarEffectModal();
            setTimeout(() => {
                this.selectedWarEffect = effect;
                this.selectedWarEffectStartTime = start_time;
            }, 0);
        },
        setTributeInfoModalProps({ peace_proposal }) {
            this.tributeInfoModalProps.userCoalitionName = this.coalitionInfo.name;
            this.tributeInfoModalProps.userCoalitionId = this.coalitionInfo._id;
            this.tributeInfoModalProps.peaceProposal = peace_proposal;
            this.openTributeInfoModal();
        },
        async changeCoalitionRightsCommand() {
            await this.changeCoalitionRights(this.createChangeCoalitionRightsPayload());
            this.toggleEditRightsButtons({ reset: false });
        },
        async changeCoalitionPropertiesCommand() {
            await this.changeCoalitionProperties({ properties: this.editableCoalitionProperties });
            this.toggleEditCoalitionPropertiesButtons({ reset: true });
        },
        async changeCoalitionDescriptionCommand() {
            // to do: refactor tags to be accessed from a
            // single place, preferably a constant
            const PLAYER_OPENING_TAG = /<player>/g;
            const PLAYER_CLOSING_TAG = /<\/player>/g;

            const CITY_OPENING_TAG = /<city>/g;
            const CITY_CLOSING_TAG = /<\/city>/g;

            const COALITION_OPENING_TAG = /<coalition>/g;
            const COALITION_CLOSING_TAG = /<\/coalition>/g;

            const PLAYER_NAMES = this.getPlayerNamesFromMarkup(
                this.editableCoalitionDescription,
                PLAYER_OPENING_TAG,
                PLAYER_CLOSING_TAG,
            );
            const CITY_COORDS = this.getCityCoordsFromMarkup(
                this.editableCoalitionDescription,
                CITY_OPENING_TAG,
                CITY_CLOSING_TAG,
            );
            const COALITION_NAMES = this.getCoalitionNamesFromMarkup(
                this.editableCoalitionDescription,
                COALITION_OPENING_TAG,
                COALITION_CLOSING_TAG,
            );

            console.log('>>>>>', PLAYER_NAMES, CITY_COORDS, COALITION_NAMES);
            if (!this.validateMarkup(PLAYER_NAMES, CITY_COORDS, COALITION_NAMES)) {
                return;
            }
            await this.changeCoalitionDescription({
                description: this.addBreakLineTagsToString(this.editableCoalitionDescription),
            });
            this.toggleEditDescriptionWindow({ reset: false });
        },
        async changeCoalitionEnlistmentOptionCommand() {
            await this.changeCoalitionEnlistmentOption({ option: this.enlistmentOption });
            this.toggleEditEnlistmentOptionButton({ reset: false });
        },
        createChangeCoalitionRightsPayload() {
            const PAYLOAD = {
                ids: [],
                permissions: [],
            };

            this.coalitionInfo.rights.forEach(member => {
                const MEMBER_TO_COMPARE = this.members.find(member_to_compare => member_to_compare._id === member.user);
                const DIFFERING_RIGHTS_ARRAY = [];
                member.permissions.forEach(right => {
                    const RIGHT_TO_COMPARE = MEMBER_TO_COMPARE.rights.find(
                        right_to_compare => right_to_compare.permission === right.permission,
                    );
                    if (right.hasIt !== RIGHT_TO_COMPARE.hasIt) {
                        DIFFERING_RIGHTS_ARRAY.push(RIGHT_TO_COMPARE);
                    }
                });
                if (DIFFERING_RIGHTS_ARRAY.length) {
                    PAYLOAD.ids.push(MEMBER_TO_COMPARE._id);
                    PAYLOAD.permissions.push(DIFFERING_RIGHTS_ARRAY);
                }
            });

            return PAYLOAD;
        },
        openConfirmationModal() {
            document.getElementById('confirmation-modal-coalition').style.transform = 'translateY(-20px)';
            document.getElementById('confirmation-modal-coalition').style.opacity = '0';
            document.getElementById('confirmation-modal-coalition').style.display = 'block';
            document.getElementById('confirmation-modal-coalition').classList.add('show');
            setTimeout(() => {
                document.getElementById('confirmation-modal-coalition').style.transition =
                    'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
                document.getElementById('confirmation-modal-coalition').style.transform = 'translateY(0)';
                document.getElementById('confirmation-modal-coalition').style.opacity = '1';
            }, 0);
        },
        openEndWarModal() {
            document.getElementById('end-war-modal').style.transform = 'translateY(-20px)';
            document.getElementById('end-war-modal').style.opacity = '0';
            document.getElementById('end-war-modal').style.display = 'block';
            document.getElementById('end-war-modal').classList.add('show');
            setTimeout(() => {
                document.getElementById('end-war-modal').style.transition =
                    'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
                document.getElementById('end-war-modal').style.transform = 'translateY(0)';
                document.getElementById('end-war-modal').style.opacity = '1';
            }, 0);
        },
        openTributeInfoModal() {
            document.getElementById('tribute-info-modal').style.transform = 'translateY(-20px)';
            document.getElementById('tribute-info-modal').style.opacity = '0';
            document.getElementById('tribute-info-modal').style.display = 'block';
            document.getElementById('tribute-info-modal').classList.add('show');
            setTimeout(() => {
                document.getElementById('tribute-info-modal').style.transition =
                    'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
                document.getElementById('tribute-info-modal').style.transform = 'translateY(0)';
                document.getElementById('tribute-info-modal').style.opacity = '1';
            }, 0);
        },
        openInviteLinkModal() {
            document.getElementById('invite-link-modal-2').style.transform = 'translateY(-20px)';
            document.getElementById('invite-link-modal-2').style.opacity = '0';
            document.getElementById('invite-link-modal-2').style.display = 'block';
            document.getElementById('invite-link-modal-2').classList.add('show');
            setTimeout(() => {
                document.getElementById('invite-link-modal-2').style.transition =
                    'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
                document.getElementById('invite-link-modal-2').style.transform = 'translateY(0)';
                document.getElementById('invite-link-modal-2').style.opacity = '1';
            }, 0);
        },
        openWarEffectModal() {
            document.getElementById('war-effect-modal').style.transform = 'translateY(-20px)';
            document.getElementById('war-effect-modal').style.opacity = '0';
            document.getElementById('war-effect-modal').style.display = 'block';
            document.getElementById('war-effect-modal').classList.add('show');
            setTimeout(() => {
                document.getElementById('war-effect-modal').style.transition =
                    'transform 0.15s ease-in-out, opacity 0.15s ease-in-out';
                document.getElementById('war-effect-modal').style.transform = 'translateY(0)';
                document.getElementById('war-effect-modal').style.opacity = '1';
            }, 0);
        },
        toggleEditDescriptionWindow({ reset }) {
            if (reset) {
                this.editableCoalitionDescription = this.coalitionInfo.description;
            }
            this.editDescriptionWindowVisible = !this.editDescriptionWindowVisible;
            if (!this.editDescriptionWindowVisible) {
                setTimeout(() => {
                    this.createEventHandlersForMarkup(MarkupLocationIdentifier.COALITION_DESCRIPTION);
                }, 0);
            } else {
                this.editableCoalitionDescription = this.removeBreakLineTagsFromString(this.editableCoalitionDescription);
                setTimeout(() => {
                    const DESCRIPTION_INPUT = document.getElementById('coalition-description-input');
                    DESCRIPTION_INPUT.addEventListener('click', () => this.setSelectionPoints(DESCRIPTION_INPUT));
                    DESCRIPTION_INPUT.addEventListener('keyup', () => this.setSelectionPoints(DESCRIPTION_INPUT));
                }, 0);
            }
        },
        setSelectionPoints(element) {
            this.descriptionInputSelectionPoints.start = element.selectionStart;
            this.descriptionInputSelectionPoints.end = element.selectionEnd;
        },
        toggleMemberActionsWrapperVisible(index) {
            this.memberActionsWrapperVisible[index] = !this.memberActionsWrapperVisible[index];
        },
        onResize() {
            this.smallScreen = window.innerWidth <= 850;
        },
        changeEnlistmentOption(option) {
            this.enlistmentOption = option;
        },
        createUnifiedMembersArray() {
            const MEMBERS_ARRAY = [];
			console.log('coalitionInfo |||||', this.coalitionInfo);
            this.coalitionInfo.allMembers.forEach(member => {
                const MEMBER = JSON.parse(JSON.stringify(member));
                const MEMBER_RIGHTS = this.coalitionInfo.rights.find(member_rights => member._id === member_rights.user);
                if (MEMBER_RIGHTS) {
                    MEMBER.rights = [...JSON.parse(JSON.stringify(MEMBER_RIGHTS.permissions))];
                    MEMBER.coalitionRank = MEMBER_RIGHTS.rank;
                }
                MEMBERS_ARRAY.push(MEMBER);
            });
            console.log('MEMBERS:', MEMBERS_ARRAY);
            return MEMBERS_ARRAY;
        },
        addMarkupTags(opening_tag, closing_tag, selection_points) {
            const FIRST_INSERTION =
                this.editableCoalitionDescription.slice(0, selection_points.end) +
                closing_tag +
                this.editableCoalitionDescription.slice(selection_points.end);
            const SECOND_INSERTION =
                FIRST_INSERTION.slice(0, selection_points.start) + opening_tag + FIRST_INSERTION.slice(selection_points.start);

            this.editableCoalitionDescription = SECOND_INSERTION;
        },
        addPlayerMarkupTags(selection_points) {
            const OPENING_TAG = '<player>';
            const CLOSING_TAG = '</player>';
            this.addMarkupTags(OPENING_TAG, CLOSING_TAG, selection_points);
        },
        addCityMarkupTags(selection_points) {
            const OPENING_TAG = '<city>';
            const CLOSING_TAG = '</city>';
            this.addMarkupTags(OPENING_TAG, CLOSING_TAG, selection_points);
        },
        addCoalitionMarkupTags(selection_points) {
            const OPENING_TAG = '<coalition>';
            const CLOSING_TAG = '</coalition>';
            this.addMarkupTags(OPENING_TAG, CLOSING_TAG, selection_points);
        },
        createEventHandlersForMarkup(location_identifier) {
            const PLAYER_ELEMENTS = document.getElementsByClassName(`${MarkupTypeIdentifier.PLAYER}-${location_identifier}`);
            // console.log('PLAYER_ELEMENTS', PLAYER_ELEMENTS);
            const CITY_ELEMENTS = document.getElementsByClassName(`${MarkupTypeIdentifier.CITY}-${location_identifier}`);
            // console.log('CITY_ELEMENTS', CITY_ELEMENTS);
            const COALITION_ELEMENTS = document.getElementsByClassName(
                `${MarkupTypeIdentifier.COALITION}-${location_identifier}`,
            );
            // console.log('COALITION_ELEMENTS', COALITION_ELEMENTS);

            for (const element of PLAYER_ELEMENTS) {
                element.addEventListener('click', () => this.openPlayerDetailsModal(element.textContent));
            }
            for (const element of CITY_ELEMENTS) {
                element.addEventListener('click', () => this.openCityDetailsModal(element.textContent));
            }
            for (const element of COALITION_ELEMENTS) {
                element.addEventListener('click', () => this.openCoalitionDetailsModal(element.textContent));
            }
        },
        openPlayerDetailsModal(player) {
            console.log('PLAYER:', player);
        },
        openCityDetailsModal(city) {
            console.log('CITY:', city);
        },
        openCoalitionDetailsModal(coalition) {
            console.log('COALITION:', coalition);
        },
        addCustomMarkupToString(string, location_identifier) {
            if (!string) {
                return '';
            }

            const PLAYER_OPENING_TAG = /<player>/g;
            const PLAYER_CLOSING_TAG = /<\/player>/g;

            const CITY_OPENING_TAG = /<city>/g;
            const CITY_CLOSING_TAG = /<\/city>/g;

            const COALITION_OPENING_TAG = /<coalition>/g;
            const COALITION_CLOSING_TAG = /<\/coalition>/g;

            const PLAYER_OPENING_MARKUP = `<span
				class="${MarkupTypeIdentifier.PLAYER}-${location_identifier}"
				onMouseOver="this.style.color='cornflowerblue'"
       			onMouseOut="this.style.color='burlywood'"
       			style="cursor: pointer; color: burlywood;">`;
            const PLAYER_CLOSING_MARKUP = `</span>`;

            const CITY_OPENING_MARKUP = `<span
				class="${MarkupTypeIdentifier.CITY}-${location_identifier}"
				onMouseOver="this.style.color='cornflowerblue'"
       			onMouseOut="this.style.color='burlywood'"
       			style="cursor: pointer; color: burlywood;">`;
            const CITY_CLOSING_MARKUP = `</span>`;

            const COALITION_OPENING_MARKUP = `<span
				class="${MarkupTypeIdentifier.COALITION}-${location_identifier}"
				onMouseOver="this.style.color='cornflowerblue'"
       			onMouseOut="this.style.color='burlywood'"
       			style="cursor: pointer; color: burlywood;">`;
            const COALITION_CLOSING_MARKUP = `</span>`;

            return string
                .replace(PLAYER_OPENING_TAG, PLAYER_OPENING_MARKUP)
                .replace(PLAYER_CLOSING_TAG, PLAYER_CLOSING_MARKUP)
                .replace(CITY_OPENING_TAG, CITY_OPENING_MARKUP)
                .replace(CITY_CLOSING_TAG, CITY_CLOSING_MARKUP)
                .replace(COALITION_OPENING_TAG, COALITION_OPENING_MARKUP)
                .replace(COALITION_CLOSING_TAG, COALITION_CLOSING_MARKUP);
        },
        addBreakLineTagsToString(string) {
            const NEWLINE_CHARACTER = /\n/g;
            const BREAK_LINE_TAG = '<br>';
            return string.replace(NEWLINE_CHARACTER, BREAK_LINE_TAG);
        },
        removeBreakLineTagsFromString(string) {
            const BREAK_LINE_TAG = /<br>/g;
            const NEWLINE_CHARACTER = '\n';
            return string.replace(BREAK_LINE_TAG, NEWLINE_CHARACTER);
        },
        getPlayerNamesFromMarkup(string, opening_tag, closing_tag) {
            const PLAYER_NAMES = [];
            const LENGTH = string.split(closing_tag).length;
            string.split(closing_tag).forEach((substring, index) => {
                if (index >= LENGTH - 1) {
                    return;
                }
                PLAYER_NAMES.push(substring.split(opening_tag)[1]);
            });
            return PLAYER_NAMES;
        },
        getCityCoordsFromMarkup(string, opening_tag, closing_tag) {
            const CITY_COORDS = [];
            const LENGTH = string.split(closing_tag).length;
            string.split(closing_tag).forEach((substring, index) => {
                if (index >= LENGTH - 1) {
                    return;
                }
                const COORDS_STRING = substring.split(opening_tag)[1];
                const POINTS = COORDS_STRING.split('|');
                CITY_COORDS.push({
                    x: Number(POINTS[0].trim()),
                    y: Number(POINTS[1].trim()),
                });
            });
            return CITY_COORDS;
        },
        getCoalitionNamesFromMarkup(string, opening_tag, closing_tag) {
            const COALITION_NAMES = [];
            const LENGTH = string.split(closing_tag).length;
            string.split(closing_tag).forEach((substring, index) => {
                if (index >= LENGTH - 1) {
                    return;
                }
                COALITION_NAMES.push(substring.split(opening_tag)[1]);
            });
            return COALITION_NAMES;
        },
        getInvalidPlayers(players) {
            const INVALID_PLAYERS = [];
            players.forEach(player => {
                if (!this.realUsers.find(user => user.username === player)) {
                    INVALID_PLAYERS.push(player);
                }
            });
            return INVALID_PLAYERS;
        },
        getInvalidCoords(coords) {
            const INVALID_COORDS = [];
            coords.forEach(coords_object => {
                if (
                    !this.realUsers.find(user =>
                        user.cities.find(city => city.x === coords_object.x && city.y === coords_object.y),
                    )
                ) {
                    INVALID_COORDS.push(coords_object.x + ' | ' + coords_object.y);
                }
            });
            return INVALID_COORDS;
        },
        getInvalidCoalitions(coalitions) {
            const INVALID_COALITIONS = [];
            coalitions.forEach(coalition => {
                if (!this.realUsers.find(user => user.coalition && user.coalition?.name === coalition)) {
                    INVALID_COALITIONS.push(coalition);
                }
            });
            return INVALID_COALITIONS;
        },
        validateMarkup(players, coords, coalitions) {
            const INVALID_PLAYERS = this.getInvalidPlayers(players);
            const INVALID_COORDS = this.getInvalidCoords(coords);
            const INVALID_COALITIONS = this.getInvalidCoalitions(coalitions);

            const TOTAL_INVALID_ELEMENTS = INVALID_PLAYERS.length + INVALID_COORDS.length + INVALID_COALITIONS.length;

            if (INVALID_PLAYERS.length) {
                console.error('Invalid player names:', INVALID_PLAYERS.join(', '));
            }
            if (INVALID_COORDS.length) {
                console.error('Invalid city coordinates:', INVALID_COORDS.join(', '));
            }
            if (INVALID_COALITIONS.length) {
                console.error('Invalid coalition names:', INVALID_COALITIONS.join(', '));
            }

            return TOTAL_INVALID_ELEMENTS <= 0;
        },
    },
};
</script>

<style scoped>
.modal {
    background: rgba(0, 0, 0, 0.5);
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.modal::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.modal::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.modal-header {
    border: none;
    display: flex;
    flex-direction: column;
}

.modal-header {
    background: linear-gradient(to right, rgba(0, 0, 0, 0), black, rgba(0, 0, 0, 0));
    display: flex;
    justify-content: center;
}

.modal-title {
    width: 60%;
    font-family: 'bebas', sans-serif;
    letter-spacing: 1px;
    text-align: center;
    padding-bottom: 0.75rem;
    border-width: 1px;
    border-style: solid;
    border-image: linear-gradient(to right, rgba(0, 0, 0, 0), lightgray, rgba(0, 0, 0, 0)) 1;
    border-left: none;
    border-right: none;
    border-top: none;
    font-size: 1.5rem;
}

.building-description {
    width: 60%;
    font-family: 'lato', sans-serif;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    margin-top: 0.5rem;
    margin-left: 1rem;
    padding-right: 1rem;
    max-height: 2.5rem;
    overflow-y: overlay;
    color: #b7b6b6;
    text-align: justify;
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.building-description::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.building-description::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.close {
    position: absolute;
    top: 16px;
    right: 24px;
    border: none;
    background: none;
    opacity: 0.7;
    transition: opacity 0.3s;
}

.close:hover {
    opacity: 1;
}

.modal-content {
    font-family: 'sansation', sans-serif;
    top: 5vh;
    padding: 4rem;
    background-image: url('../assets/background.avif');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 0;
    color: ghostwhite;
    border: none;
}

.modal-body {
    padding: 3rem 1rem 1rem 1rem;
}

.nav-tabs {
    border: none;
}

.nav-tabs .nav-link {
    border-radius: 0;
    border: 2px solid #434343;
    border-bottom: none;
    color: #afafaf;
    transform: translateY(15px);
}

.nav-tabs .nav-item {
    margin-right: 0.25rem;
}

.nav-tabs .nav-item:nth-last-child(2) {
    margin-right: 0;
}

.nav-tabs .nav-link:hover {
    color: lightgray;
    border: 2px solid #434343;
    border-bottom: none;
}

.nav-tabs .nav-link.active {
    background: none;
    border: 2px solid #434343;
    border-bottom: none;
    color: ghostwhite;
    padding-top: 1rem;
    padding-bottom: 1rem;
    transform: translateY(-1px);
}

.nav-filler {
    flex-grow: 1;
    border-bottom: 2px solid #434343;
}

.tab-pane {
    padding: 3rem 1.5rem 1.5rem 1.5rem;
    background: none;
    border: 2px solid #434343;
    border-top: none;
}

.page-transition-enter-active {
    transition: filter 0.2s linear;
}

.page-transition-enter-from {
    filter: blur(3px);
}

.buttons-transition-enter-active,
.buttons-transition-leave-active {
    transition: filter 0.2s linear;
}

.buttons-transition-enter-from,
.buttons-transition-leave-to {
    filter: blur(3px);
}

.form-check-input {
    cursor: pointer;
}

.form-check-input:checked {
    background-color: #d34848;
    border: none;
    background-size: 10px;
}

.form-check-input:focus {
    box-shadow: none;
}

.form-check-input:disabled {
    opacity: 0.375;
}

.form-check-input:checked:disabled {
    background-color: dimgray;
    opacity: 0.375;
}

.form-select {
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0);
    color: ghostwhite;
    border-color: ghostwhite;
    filter: brightness(10);
    font-size: 0.875rem;
    cursor: pointer;
    height: 2rem;
}

.form-select:focus {
    box-shadow: none;
    border-color: ghostwhite;
}

.row {
    flex-wrap: nowrap;
    gap: 2px;
    margin: 0;
}

.row.current-user {
    border: 1px solid silver;
}

.label .col {
    background: linear-gradient(to bottom, #2a2a2a, #181818);
    color: ghostwhite;
    display: flex;
    align-items: center;
    font-size: 1rem;
}

.col {
    position: relative;
    flex: 1 1 auto;
    background: rgba(0, 0, 0, 0.5);
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    gap: 2px;
    display: flex;
    font-size: 0.875rem;
    align-items: center;
}

.large {
    flex-basis: 70%;
}

.small {
    flex-basis: 30%;
}

.btn {
    padding: 0.25rem 1rem;
    width: fit-content;
    height: fit-content;
}

.clickable {
    cursor: pointer;
}

.clickable:hover {
    color: cornflowerblue;
}

.red-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.15), rgba(255, 200, 200, 0.15));
    border: 3px solid brown;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
}

.red-button:hover {
    background: linear-gradient(to bottom, rgba(224, 0, 0, 0.2), rgba(255, 200, 200, 0.2));
    color: ghostwhite;
}

.yellow-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.15), rgba(255, 255, 255, 0.15));
    border: 3px solid burlywood;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
}

.yellow-button:hover {
    background: linear-gradient(to bottom, rgba(100, 100, 100, 0.2), rgba(255, 255, 255, 0.2));
    color: ghostwhite;
}

.blue-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgb(34, 41, 58), rgba(255, 255, 255, 0.2));
    border: 3px solid dodgerblue;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
}

.blue-button:hover {
    background: linear-gradient(to bottom, rgba(96, 95, 42, 0.2), rgb(52, 52, 70));
    color: ghostwhite;
}

.neutral-button {
    font-family: 'bebas', sans-serif;
    border-radius: 0;
    background: linear-gradient(to bottom, rgb(30, 30, 30), rgba(255, 255, 255, 0.2));
    border: 3px solid dimgray;
    font-size: 0.9375rem;
    line-height: 0.9375rem;
    color: ghostwhite;
}

.neutral-button:hover {
    background: linear-gradient(to bottom, rgba(40, 40, 40, 0.2), rgb(60, 60, 60));
    color: ghostwhite;
}

.form-control {
    width: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    border: 1px solid whitesmoke;
    border-radius: 0;
    padding-left: 1rem;
    opacity: 0.8;
    color: ghostwhite;
    height: 2rem;
}

.form-control:focus {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    color: ghostwhite;
    border: 1px solid ghostwhite;
}

.form-control:-webkit-autofill,
.form-control:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0 1000px #1a1a1a inset;
    -webkit-text-fill-color: ghostwhite;
    caret-color: ghostwhite;
}

.form-control:disabled {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(255, 255, 255, 0.1));
    opacity: 0.25;
}

.form-select {
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0);
    color: ghostwhite;
    border-color: ghostwhite;
    filter: brightness(10);
    font-size: 0.875rem;
    cursor: pointer;
    height: 2rem;
}

.form-select:focus {
    box-shadow: none;
    border-color: ghostwhite;
}

.section-description {
    padding-bottom: 2rem;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1rem;
    color: #b7b6b6;
    text-indent: 1rem;
}

.events-table,
.members-table {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.members-table .col:not(:first-child) {
    justify-content: center;
}

.members-table .col:first-child {
    flex-basis: 16%;
    max-width: 16%;
    min-width: 16%;
    word-break: break-all;
}

.members-table .col:last-child {
    padding: 0.25rem;
}

.members-table .large {
    flex-basis: calc(10% - 2px);
    max-width: calc(10% - 2px);
    min-width: calc(10% - 2px);
}

.members-table .small {
    flex-basis: calc(6% - 2px);
    max-width: calc(6% - 2px);
    min-width: calc(6% - 2px);
    padding-left: 0.125rem;
    padding-right: 0.125rem;
}

.members-icon-wrapper .members-icon {
    width: 1.375rem;
    height: 1.375rem;
    cursor: pointer;
}

.members-table-buttons-wrapper {
    position: relative;
    display: flex;
    gap: 2px;
}

.members-table .btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    width: 100%;
    height: 1.25rem;
    font-size: 0.875rem !important;
    line-height: 0.875rem;
    padding: 0;
}

.members-table .btn > span {
    writing-mode: vertical-lr;
}

.members-table .members-table-combined-button {
    display: none;
}

.coalition-member {
    position: relative;
    margin-left: 0.875rem;
}

.coalition-member:hover {
    color: cornflowerblue;
    cursor: pointer;
}

.coalition-member:before {
    content: '';
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    position: absolute;
    left: -1rem;
    top: 50%;
    transform: translateY(-50%);
}
.coalition-member.inactive:before {
    background: brown;
}

.coalition-member.active:before {
    background: gold;
}

.coalition-member.very-active:before {
    background: forestgreen;
}

.left-wrapper,
.right-wrapper {
    width: 48%;
}

.invite-player-table,
.invitations-table,
.enlistment-options,
.requests-table,
.nearby-coalitions-table,
.tribute-table {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.invitations-table .col:last-child:not(:first-child) {
    justify-content: flex-end;
}

.nearby-coalitions-table .col {
    min-width: 0;
}

.nearby-coalitions-table .col:first-child {
    flex-basis: 70%;
    padding-right: 0;
    word-break: break-all;
}

.search-input-wrapper {
    position: relative;
}

.search-input-wrapper .search-results-wrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.9);
    width: 100%;
    left: 0;
    top: 2.125rem;
    z-index: 1;
    padding: 0.125rem;
    gap: 0.125rem;
    cursor: pointer;
}

.search-input-wrapper .search-result {
    padding: 0.375rem 1rem;
}

.search-input-wrapper .search-result:hover {
    background: brown;
}

.scrollable-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2px;
    max-height: 10rem;
    overflow-y: overlay;
    scrollbar-color: dimgray rgba(0, 0, 0, 0); /* firefox */
    scrollbar-width: thin;
}

.scrollable-wrapper::-webkit-scrollbar {
    width: 6px;
    height: 0;
    background-color: rgba(0, 0, 0, 0);
}
.scrollable-wrapper::-webkit-scrollbar-thumb {
    background-color: dimgray;
    border-radius: 10px;
}

.requests-table .row:not(.label) .col:last-child {
    justify-content: center;
}

.coalition-properties-table,
.coalition-description-table,
.edit-description-table {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.markup-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    border: 1px solid #434343;
    margin-right: 0.125rem;
}

.markup-icon {
    height: 1rem;
}

.write-message-window {
    width: 100%;
    background: rgba(0, 0, 0, 0.35);
    padding: 1rem;
    outline: none;
    color: ghostwhite;
    border: 1px solid #434343;
    height: 8rem;
}

.diplomacy-table {
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.diplomacy-table:not(:first-child) {
    display: flex;
    flex-direction: column;
    gap: 2px;
    margin-top: 1.5rem;
}

.diplomacy-table .row:not(.label) .col:last-child {
    justify-content: flex-end;
    gap: 0.5rem;
}

.war-table {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.war-table:not(:first-child) {
    margin-top: 1.5rem;
}

.war-table .row:not(.label) .col {
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.war-objectives-wrapper {
    width: 10rem;
    display: flex;
    flex-direction: column;
    padding-left: 2.5rem;
    gap: 0.5rem;
}

.war-objective {
    color: darkgray;
    position: relative;
    line-height: 1;
    cursor: pointer;
}

.war-objective.completed {
    color: ghostwhite;
}

.war-objective:before {
    content: '\2716';
    position: absolute;
    left: -1.25rem;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 600;
    color: brown;
}

.war-objective.completed:before {
    content: '\2713';
    position: absolute;
    left: -1.25rem;
    font-weight: 600;
    color: forestgreen;
}

.war-table .btn {
    position: absolute;
    left: 0.5rem;
    bottom: 0.5rem;
}

.war-bonuses-wrapper {
    position: absolute;
    display: flex;
    gap: 0.125rem;
    top: 0.5rem;
    left: 0.5rem;
}

.bonus-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 2rem;
    height: 2rem;
    border: 1px solid #434343;
    margin-right: 0.125rem;
    position: relative;
}

.bonus-icon {
    height: 1rem;
}

.icon-tooltip {
    z-index: 1;
    opacity: 0;
    filter: blur(3px);
    position: absolute;
    bottom: 1.5rem;
    pointer-events: none;
    transition: opacity 0.3s, filter 0.3s;
    background: black;
    color: ghostwhite;
    font-size: 0.75rem;
    line-height: 0.75rem;
    padding: 0.25rem;
    border: 1px solid #434343;
    max-width: 10rem;
    width: max-content;
}

.members-icon-wrapper:hover .icon-tooltip,
.bonus-icon-wrapper:hover .icon-tooltip,
.markup-icon-wrapper:hover .icon-tooltip,
.war-objective:hover .icon-tooltip {
    opacity: 1;
    filter: blur(0);
}

.tribute-resources-group {
    display: flex;
    gap: 0.5rem;
    align-items: center;
    width: auto;
}

.tribute-resources-icon {
    height: 1rem;
}

.info-icon {
    width: 1.25rem;
    height: 1.25rem;
    margin-left: 0.5rem;
    transform: translateY(-0.125rem);
    opacity: 0.5;
    cursor: pointer;
}

.info-icon:hover {
    opacity: 1;
}

@media screen and (max-width: 1199px) {
    .modal-dialog {
        max-width: 90%;
        margin: 0 auto;
    }
}

@media screen and (max-width: 850px) {
    .col,
    .label .col {
        font-size: 0.75rem;
        line-height: 0.75rem;
        padding: 0.25rem;
    }
    .col {
        gap: 0.25rem;
    }
    .form-check-input {
        transform: scale(0.9);
    }
    .members-icon {
        width: 0.75rem;
        height: 0.75rem;
    }
    .nav-link {
        font-size: 0.875rem;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
    }
    .btn {
        font-size: 0.75rem;
        padding: 0.25rem;
    }
    .modal-content {
        padding: 4rem 0 1rem 0;
    }
    #events-page,
    #members-page,
    #enlistment-page,
    #properties-page,
    #diplomacy-page,
    #wars-page,
    #tributes-page {
        padding: 3rem 0.5rem 0.5rem 0.5rem;
    }
    .form-control {
        padding: 0.25rem;
        font-size: 0.75rem;
    }
    .members-table .members-table-combined-button {
        display: flex;
    }
    .members-table-buttons-wrapper {
        position: absolute;
        bottom: -2rem;
        z-index: 1;
        width: 6rem;
        padding: 0.25rem;
        background: black;
        border: 2px solid #434343;
    }
}

.tooltip-transition-enter-active,
.tooltip-transition-leave-active {
    transition: filter 0.05s linear;
}

.tooltip-transition-enter-from,
.tooltip-transition-leave-to {
    filter: blur(3px);
}

@media screen and (max-width: 767px) {
    .not-in-coalition-wrapper {
        width: 70%;
        margin-left: auto;
        margin-right: auto;
    }
    .not-in-coalition-wrapper .left-wrapper,
    .not-in-coalition-wrapper .right-wrapper {
        width: 100%;
    }
    .not-in-coalition-wrapper .right-wrapper {
        margin-top: 1.5rem;
    }
}

@media screen and (max-width: 576px) {
    .modal-dialog {
        max-width: 96%;
    }
    .building-description {
        width: 100%;
        font-size: 0.75rem;
        line-height: 0.75rem;
    }
    .section-description {
        font-size: 0.75rem;
        line-height: 0.75rem;
        padding-bottom: 1rem;
    }
    .not-in-coalition-wrapper {
        width: 80%;
    }
}

@media screen and (max-width: 500px) {
    .nav-tabs .nav-item {
        margin-right: 0.125rem;
    }
    .nav-link {
        font-size: 0.75rem;
        padding-left: 0.25rem;
        padding-right: 0.25rem;
    }
    .members-table .col:nth-child(n + 2):nth-child(-n + 5) {
        display: none;
    }
    .members-table .col:first-child {
        flex-basis: calc(16% - 2px);
        max-width: calc(16% - 2px);
        min-width: calc(16% - 2px);
    }
    .members-table .col {
        flex-basis: calc(10% - 2px);
        max-width: calc(10% - 2px);
        min-width: calc(10% - 2px);
    }
    .members-table .large {
        flex-basis: calc(14% - 2px);
        max-width: calc(14% - 2px);
        min-width: calc(14% - 2px);
    }
    .coalition-member {
        font-size: 0.625rem;
        line-height: 0.625rem;
        margin-left: 0.375rem;
    }
    .coalition-member:before {
        width: 0.25rem;
        height: 0.25rem;
        left: -0.5rem;
    }
    .members-table .btn {
        font-size: 0.75rem !important;
    }
    .invite-player-table .row:not(.label) .col:first-child:not(:last-child) {
        display: none;
    }
    .not-in-coalition-wrapper {
        width: 85%;
    }
}

@media screen and (max-width: 450px) {
    .diplomacy-tables-wrapper {
        flex-direction: column;
    }
    #diplomacy-page .left-wrapper {
        width: 100%;
    }
    .diplomacy-tables-wrapper .left-wrapper {
        margin-bottom: 2rem;
    }
    #diplomacy-page .right-wrapper {
        width: 100%;
        padding-top: 2rem;
        border-width: 1px;
        border-style: solid;
        border-image: linear-gradient(to right, rgba(0, 0, 0, 0), lightgray, rgba(0, 0, 0, 0)) 1;
        border-left: none;
        border-right: none;
        border-bottom: none;
    }
    .not-in-coalition-wrapper {
        width: 90%;
    }
}

@media screen and (max-width: 400px) {
    .not-in-coalition-wrapper {
        width: 100%;
    }
}

@media screen and (orientation: landscape) and (max-height: 800px) {
    .modal-content {
        transform: scale(0.8);
        top: -10vh;
    }
}
</style>

export default function getTotalRecruitTime(troop) {
    // TO DO: change this when troop.time comes as float
    const TimeArray = troop.getRecruitmentTime().split(':');

    const SecondsPerTroop = Number(TimeArray[0]) * 3600 + Number(TimeArray[1]) * 60 + Number(TimeArray[2]);
    const TotalSeconds = SecondsPerTroop * troop.getNumberToRecruit();

    const Hours = Math.floor(TotalSeconds / 3600);
    const Minutes = Math.floor((TotalSeconds % 3600) / 60);
    const Seconds = TotalSeconds % 60;

    return `${Hours ? Hours + 'h ' : ''}
        ${Minutes ? Minutes + 'm ' : ''}
        ${Seconds + 's'}`;
}
